import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import PriceTableSelect from "components/PriceTableSelect";
import SelectInput from "components/SelectInput";
import { useCompany } from "providers/Company";
import { api } from "services";
import { Company } from "types/company";
import { PixType } from "types/enums";
import { CompanyEditData } from "types/register";
import { brazilianStates } from "utils/brazilianStates";
import checkCep from "utils/checkCep";
import {
  accountMask,
  cnpjMask,
  cpfAndCnpjMask,
  ieMask,
  mobilePhoneMask,
  phoneMask,
  phoneOrMobilePhoneMask,
} from "utils/formMasks";
import { validateCnpj } from "utils/formValidations";
import {
  handleAccountInput,
  handleCepInput,
  handleCnpjInput,
  handleCpfOrCnpjInput,
  handleDateInput,
  handleIeInput,
  handleMobilePhoneInput,
  handlePhoneInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";

interface CompanyEditFormProps {
  onHide: () => void;
  company: Company;
}

enum ContextEnum {
  General = 1,
  Payment,
}

const CompanyEditForm = ({ onHide, company }: CompanyEditFormProps) => {
  const [contextType, setContextType] = useState<ContextEnum>(
    ContextEnum.General
  );
  const [inputCep, setInputCep] = useState(company.addressCEP);
  const [isSearchingCep, setIsSearchingCep] = useState(false);
  const [pixType, setPixType] = useState(
    company.dataRecipient?.pixType
      ? PixType[company.dataRecipient?.pixType].toString()
      : "None"
  );
  const { editCompany, isLoading } = useCompany();

  const schema = yup.object().shape({
    id: yup.number().default(company.id),
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    url: yup.string().notRequired().trim(),
    imageLogoName: yup.string().notRequired().trim(),
    legalName: yup
      .string()
      .required("*Campo obrigatório")
      .min(5, "*Mínimo de 5 caracteres")
      .uppercase()
      .trim(),
    cnpj: yup
      .string()
      .required("*Campo obrigatório")
      .test("cnpjValidator", "*CNPJ inválido", validateCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*E-mail inválido")
      .trim(),
    phone: yup.string().max(14, "*Número de telefone inválido"),
    mobilePhone: yup.string().max(15, "*Número de celular inválido"),

    contactName: yup.string().required("*Campo obrigatório").trim(),
    contactJobPosition: yup.string().required("*Campo obrigatório").trim(),
    contactEmail: yup
      .string()
      .required("*Campo obrigatório")
      .email("*E-mail inválido")
      .trim(),
    contactPhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(14, "*Número de telefone inválido"),

    addressCEP: yup.string().required("*Campo obrigatório"),
    addressRoad: yup.string().required("*Campo obrigatório").trim(),
    addressNumber: yup.string().required("*Campo obrigatório").trim(),
    addressComplement: yup.string().trim(),
    addressDistrict: yup.string().required("*Campo obrigatório").trim(),
    addressCity: yup.string().required("*Campo obrigatório").trim(),
    addressState: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
    priceTable: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().uppercase(),
      })
      .nullable(),
    enableCheckList: yup.boolean().required("*Campo obrigatório"),
    minLimitDateProcedure: yup.number().required("*Campo obrigatório"),

    documentState: yup.string(),
    dateFounding: yup.string(),
    dataRecipient: yup.object().shape({
      accountType: yup.string(),
      account: yup.string(),
      agency: yup.string(),
      bankAccountType: yup.string(),
      bank: yup.string(),
      accountDigit: yup.string(),
      pixType: yup.string().notRequired(),
      pixKey: yup.string().notRequired(),
    }),

    cardMachines: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        value: yup.boolean(),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Omit<CompanyEditData, "ddd" | "dddMobilePhone" | "contactDDD">>({
    resolver: yupResolver(schema),
    defaultValues: {
      priceTable: {
        value: company?.priceTable?.id,
        label: company?.priceTable?.description,
      },
      enableCheckList: company?.enableCheckList,
      minLimitDateProcedure: company?.minLimitDateProcedure ?? 5,
    },
  });

  const [checkboxes, setCheckboxes] = useState<
    {
      id: number;
      description: string;
      value: boolean;
    }[]
  >([]);
  const [isLoadingCheckboxes, setIsLoadingCheckboxes] = useState(true);

  useEffect(() => {
    const loadCheckboxes = async () => {
      try {
        const { data } = await api.get(`/Company/${company.id}/TerminalPDQ`);

        setCheckboxes(data);

        reset({
          ...getValues(),
          cardMachines: data,
        });

        setIsLoadingCheckboxes(false);
      } catch (error) {
        console.error(error);

        setIsLoadingCheckboxes(false);
      }
    };

    loadCheckboxes();
  }, []);

  const onSubmit = (
    data: Omit<CompanyEditData, "ddd" | "dddMobilePhone" | "contactDDD">
  ) => {
    editCompany(data, company.id, onHide);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <ul
          className="nav nav-tabs"
          style={{
            marginBottom: "20px",
          }}
        >
          <li
            className="nav-item"
            onClick={() => setContextType(ContextEnum.General)}
          >
            <span
              id="personal-data"
              className={`nav-link link-primary ${
                contextType === ContextEnum.General ? "active" : "false"
              }`}
            >
              Geral
            </span>
          </li>
          <li
            className="nav-item"
            onClick={() => setContextType(ContextEnum.Payment)}
          >
            <span
              id="access-data"
              className={`nav-link link-primary ${
                contextType === ContextEnum.Payment ? "active" : "false"
              }`}
            >
              Pagamento
            </span>
          </li>
        </ul>
        {contextType === ContextEnum.General && (
          <div className="row">
            <div className="col-xl-6">
              <h5 className="mb-4">Dados da Instituição</h5>
              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    className="text-uppercase"
                    type="text"
                    label="Nome da Instituição:"
                    placeholder="Nome da Empresa, Clínica, Hospital..."
                    register={register("name")}
                    error={errors?.name?.message}
                    defaultValue={company.name}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    type="text"
                    label="Url da Instituição:"
                    placeholder="https://www.site.com"
                    register={register("url")}
                    error={errors?.url?.message}
                    defaultValue={company.url}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="CNPJ:"
                    placeholder="99.999.999/9999-99"
                    register={register("cnpj")}
                    error={errors?.cnpj?.message}
                    onChange={(e) => handleCnpjInput(e, setValue, "cnpj")}
                    defaultValue={cnpjMask(company.cnpj)}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    type="text"
                    label="E-mail:"
                    placeholder="instituicao@email.com"
                    register={register("email")}
                    error={errors?.email?.message}
                    defaultValue={company.email}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Telefone:"
                    placeholder="(99) 9999-9999"
                    register={register("phone")}
                    error={errors?.phone?.message}
                    onChange={(e) => handlePhoneInput(e, setValue, "phone")}
                    defaultValue={phoneMask(`${company.ddd}${company.phone}`)}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Celular:"
                    placeholder="(99) 99999-9999"
                    register={register("mobilePhone")}
                    error={errors?.mobilePhone?.message}
                    onChange={(e) =>
                      handleMobilePhoneInput(e, setValue, "mobilePhone")
                    }
                    defaultValue={mobilePhoneMask(
                      `${company.dddMobilePhone}${company.mobilePhone}`
                    )}
                  />
                </div>
              </div>

              <h5 className="mt-2 mb-4">Dados do Contato</h5>
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  type="text"
                  label="Nome do Contato:"
                  placeholder="Nome Completo"
                  register={register("contactName")}
                  error={errors?.contactName?.message}
                  defaultValue={company.contactName}
                />
              </div>
              <div className="row">
                <div className="form-group col-md-7">
                  <Input
                    className="text-uppercase"
                    type="text"
                    label="Nome Social do Contato:"
                    minLength={5}
                    placeholder="Nome Social"
                    register={register("legalName")}
                    error={errors?.legalName?.message}
                    defaultValue={company.legalName}
                  />
                </div>
                <div className="form-group col-md-5">
                  <Input
                    className="text-uppercase"
                    type="text"
                    label="Cargo:"
                    placeholder="CEO, Administrativo, Comercial"
                    register={register("contactJobPosition")}
                    error={errors?.contactJobPosition?.message}
                    defaultValue={company.contactJobPosition}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-7">
                  <Input
                    type="text"
                    label="E-mail do Contato:"
                    placeholder="contato@email.com"
                    register={register("contactEmail")}
                    error={errors?.contactEmail?.message}
                    defaultValue={company.contactEmail}
                  />
                </div>

                <div className="form-group col-md-5">
                  <Input
                    type="text"
                    label="Telefone do Contato:"
                    placeholder="(99) 99999-9999"
                    register={register("contactPhone")}
                    error={errors?.contactPhone?.message}
                    onChange={(e) =>
                      handlePhoneOrMobilePhoneInput(e, setValue, "contactPhone")
                    }
                    defaultValue={phoneOrMobilePhoneMask(
                      `${company.contactDDD}${company.contactPhone}`
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <h5 className="mt-2 mt-xl-0 mb-4">Endereço da Instituição</h5>
              <div className="form-group">
                <div className="row flex-column gap-2 flex-sm-row gap-md-0 align-items-center">
                  <div className="col">
                    <Input
                      className="text-uppercase"
                      label="Consulte pelo CEP:"
                      placeholder="99999-999"
                      type="text"
                      register={register("addressCEP")}
                      error={errors?.addressCEP?.message}
                      onChange={(e) =>
                        handleCepInput(e, setValue, "addressCEP", setInputCep)
                      }
                      defaultValue={company.addressCEP}
                    />
                    <div className="col-12 col-md-auto small ps-0">
                      <a
                        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Não sei meu CEP!
                      </a>
                    </div>
                  </div>

                  <div className="col col-auto">
                    <Button
                      type="button"
                      className="btn-sm btn-dark mt-3"
                      onClick={() =>
                        checkCep(
                          inputCep,
                          setIsSearchingCep,
                          setValue,
                          setFocus
                        )
                      }
                      disabled={isSearchingCep}
                    >
                      Consultar
                      {!!isSearchingCep && (
                        <CircularProgress
                          color="inherit"
                          size={12}
                          className="align-text-top ms-2"
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <Input
                      label="Endereço:"
                      placeholder="Nome da Rua"
                      type="text"
                      register={register("addressRoad")}
                      error={errors?.addressRoad?.message}
                      defaultValue={company.addressRoad}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group">
                    <Input
                      label="Número:"
                      placeholder="nº"
                      type="text"
                      register={register("addressNumber")}
                      error={errors?.addressNumber?.message}
                      defaultValue={company.addressNumber}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Input
                      className="text-uppercase"
                      label="Complemento:"
                      placeholder="Casa, apto, bloco"
                      type="text"
                      register={register("addressComplement")}
                      error={errors?.addressComplement?.message}
                      defaultValue={company.addressComplement}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <Input
                    className="text-uppercase"
                    label="Bairro:"
                    placeholder="Nome do Bairro"
                    type="text"
                    register={register("addressDistrict")}
                    error={errors?.addressDistrict?.message}
                    defaultValue={company.addressDistrict}
                  />
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Input
                      className="text-uppercase"
                      label="Cidade:"
                      placeholder="Nome da Cidade"
                      type="text"
                      register={register("addressCity")}
                      error={errors?.addressCity?.message}
                      defaultValue={company.addressCity}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <SelectInput
                    name="addressState"
                    label="Estado:"
                    placeholder="Selecione"
                    control={control}
                    error={errors?.addressState?.message}
                    defaultValue={company.addressState ?? "selecione"}
                  >
                    <MenuItem value="selecione" disabled>
                      Selecione
                    </MenuItem>
                    {brazilianStates.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </div>
              </div>

              <h5 className="mb-4">Preferências</h5>
              <div className="form-check">
                <label
                  className="control control--checkbox mb-2"
                  htmlFor="enableCheckList"
                >
                  <span className="caption">Habilitar Checklist</span>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="enableCheckList"
                    {...register("enableCheckList")}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <Input
                    className="text-uppercase"
                    type="number"
                    label="Número de dias mínimo para agendamento de procedimentos:"
                    minLength={5}
                    min={1}
                    placeholder="5"
                    register={register("minLimitDateProcedure")}
                    error={errors?.minLimitDateProcedure?.message}
                  />
                </div>
                <small>1 = agendamento disponível amanhã</small>
              </div>
            </div>
          </div>
        )}
        {contextType === ContextEnum.Payment && (
          <div className="row">
            <div className="col-xl-6">
              <h5 className="mb-4">Dados bancários</h5>
              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Número da conta:"
                    placeholder="9999-999"
                    register={register("dataRecipient.account")}
                    maxLength={8}
                    error={errors?.dataRecipient?.account?.message}
                    onChange={(e) =>
                      handleAccountInput(e, setValue, "dataRecipient.account")
                    }
                    defaultValue={
                      company.dataRecipient && company.dataRecipient.account
                        ? accountMask(company.dataRecipient.account)
                        : ""
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Dígito da conta:"
                    placeholder="9"
                    register={register("dataRecipient.accountDigit")}
                    maxLength={1}
                    error={errors?.dataRecipient?.accountDigit?.message}
                    defaultValue={
                      company.dataRecipient &&
                      company.dataRecipient.accountDigit
                        ? company.dataRecipient.accountDigit
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Agência:"
                    placeholder="9999"
                    register={register("dataRecipient.agency")}
                    maxLength={4}
                    error={errors?.dataRecipient?.agency?.message}
                    defaultValue={
                      company.dataRecipient && company.dataRecipient.agency
                        ? company.dataRecipient.agency
                        : ""
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <SelectInput
                    label="Tipo de cliente:"
                    placeholder="Selecione"
                    control={control}
                    name="dataRecipient.accountType"
                    defaultValue={
                      company.dataRecipient && company.dataRecipient.accountType
                        ? company.dataRecipient.accountType
                        : "PF"
                    }
                    error={errors?.dataRecipient?.accountType?.message}
                  >
                    <MenuItem value={"PF"}>PF</MenuItem>
                    <MenuItem value={"PJ"}>PJ</MenuItem>
                  </SelectInput>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Banco:"
                    placeholder="999"
                    register={register("dataRecipient.bank")}
                    maxLength={3}
                    error={errors?.dataRecipient?.bank?.message}
                    defaultValue={
                      company.dataRecipient && company.dataRecipient.bank
                        ? company.dataRecipient.bank
                        : ""
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <SelectInput
                    isDisabled
                    label="Tipo de conta:"
                    placeholder="Selecione"
                    control={control}
                    name="dataRecipient.bankAccountType"
                    defaultValue={
                      company.dataRecipient &&
                      company.dataRecipient.bankAccountType
                        ? company.dataRecipient.bankAccountType
                        : "C"
                    }
                    error={errors?.dataRecipient?.bankAccountType?.message}
                  >
                    <MenuItem value={"C"}>Corrente</MenuItem>
                    <MenuItem value={"P"}>Poupança</MenuItem>
                  </SelectInput>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Inscrição estadual:"
                    placeholder="999.999.999.999"
                    register={register("documentState")}
                    maxLength={15}
                    error={errors?.documentState?.message}
                    onChange={(e) =>
                      handleIeInput(e, setValue, "documentState")
                    }
                    defaultValue={
                      company.documentState ? ieMask(company.documentState) : ""
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    label="Fundação da empresa:"
                    placeholder="dd/mm/aaaa"
                    type="tel"
                    maxLength={10}
                    register={register("dateFounding")}
                    error={errors?.dateFounding?.message}
                    onChange={(e) =>
                      handleDateInput(e, setValue, "dateFounding")
                    }
                    defaultValue={
                      company.dateFounding
                        ? moment(company.dateFounding).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <SelectInput
                    label="Tipo de Pix:"
                    placeholder="Selecione"
                    control={control}
                    name="dataRecipient.pixType"
                    defaultValue={pixType}
                    customOnChange={async () => {
                      const values = getValues();
                      setPixType(values.dataRecipient?.pixType ?? "");
                    }}
                    error={errors?.dataRecipient?.pixType?.message}
                  >
                    <MenuItem value={"None"}>Nenhum</MenuItem>
                    {Object.keys(PixType)
                      .filter((v) => isNaN(Number(v)))
                      .map((typeName, typeIndex) => (
                        <MenuItem value={typeName} key={typeIndex}>
                          {typeName}
                        </MenuItem>
                      ))}
                  </SelectInput>
                </div>
                <div className="form-group col-md-6">
                  {pixType === "None" && (
                    <Input label="Chave Pix:" type="text" disabled={true} />
                  )}
                  {pixType === PixType[0] && (
                    <Input
                      label="Chave Pix:"
                      type="email"
                      placeholder="seuemailpix@email.com"
                      autoComplete="new-off"
                      maxLength={255}
                      register={register("dataRecipient.pixKey")}
                      pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
                      error={errors?.dataRecipient?.pixKey?.message}
                      defaultValue={company.dataRecipient?.pixKey ?? ""}
                    />
                  )}
                  {pixType === PixType[1] && (
                    <Input
                      label="Chave Pix:"
                      type="tel"
                      placeholder="(99) 99999-9999"
                      register={register("dataRecipient.pixKey")}
                      error={errors?.dataRecipient?.pixKey?.message}
                      onChange={(e) =>
                        handlePhoneOrMobilePhoneInput(
                          e,
                          setValue,
                          "dataRecipient.pixKey"
                        )
                      }
                      defaultValue={
                        company.dataRecipient?.pixKey
                          ? phoneOrMobilePhoneMask(company.dataRecipient.pixKey)
                          : ""
                      }
                    />
                  )}
                  {pixType === PixType[2] && (
                    <Input
                      label="Chave Pix:"
                      type="text"
                      placeholder="Chave aleatória"
                      register={register("dataRecipient.pixKey")}
                      defaultValue={company.dataRecipient?.pixKey ?? ""}
                      error={errors?.name?.message}
                    />
                  )}
                  {pixType === PixType[3] && (
                    <Input
                      label="Chave Pix:"
                      type="text"
                      placeholder="CPF ou CNPJ"
                      autoComplete="new-off"
                      maxLength={17}
                      register={register("dataRecipient.pixKey", {
                        onChange: (event) =>
                          handleCpfOrCnpjInput(
                            event,
                            setValue,
                            "dataRecipient.pixKey"
                          ),
                      })}
                      defaultValue={
                        company.dataRecipient?.pixKey
                          ? cpfAndCnpjMask(company.dataRecipient?.pixKey)
                          : ""
                      }
                      error={errors?.dataRecipient?.pixKey?.message}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <h5 className="mb-4">Cálculo de Orçamentos</h5>
              <div className="form-group">
                <label className="mb-2">{"Tabela de preço (Link):"}</label>
                <PriceTableSelect control={control} />
              </div>
              {!isLoadingCheckboxes && (
                <div className="form-group">
                  <label className="mb-2">
                    {"Tabela de preço (Máquinas físicas):"}
                  </label>
                  {checkboxes.map((checkbox, i) => (
                    <Controller
                      key={checkbox.id}
                      name={`cardMachines.${i}`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="form-check">
                          <label
                            className="control control--checkbox mb-2"
                            htmlFor={checkbox.id.toString()}
                          >
                            <span className="caption">
                              {checkbox.description}
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={checkbox.id.toString()}
                              checked={value.value}
                              onChange={() => {
                                onChange({
                                  ...value,
                                  value: !value.value,
                                });
                              }}
                            />
                            <div className="control__indicator"></div>
                          </label>
                        </div>
                      )}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Salvar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default CompanyEditForm;
