import { useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useUser } from "providers/User";

import ActiveUserList from "./components/ActiveUserList";
import InactiveUserList from "./components/InactiveUserList";
import UserSearch from "./components/UserSearch";

const Users = () => {
  const [searchData, setSearchData] = useState<string>("");

  const {
    activeUsersTotal,
    inactiveUsersTotal,
    getActiveUsers,
    getInactiveUsers,
  } = useUser();

  const [selectedNavTab, setSelectedNavTab] = useState("active-data");
  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center gx-2">
            <div className="col-12 col-sm-auto col-lg-6 pb-2">
              <h1 className="mb-0">Usuários</h1>
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-2">
              <UserSearch setSearchData={setSearchData} />
            </div>
            
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    id="active-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "active-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Ativos
                    <Badge bg="primary ml-1 text-white">
                      {activeUsersTotal}
                    </Badge>
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    id="inative-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "inative-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Inativos
                    <Badge bg="dark ml-1 text-white">
                      {inactiveUsersTotal}
                    </Badge>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "active-data" && (
            <ActiveUserList searchData={searchData} />
          )}
          {selectedNavTab === "inative-data" && (
            <InactiveUserList searchData={searchData} />
          )}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Users;
