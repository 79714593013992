export const formatPrice = (price: number) =>
  Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price);

export const formatAsPercent = (num: number) => {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
};

export const checkString = (value: string): string => {
  if (!value || value == "" || value === null) {
    return "NÃO INFORMADO";
  }

  return value;
};