import moment from "moment";
import { createContext, ReactNode, useContext, useState } from "react";

import { api } from "services";
import { Company } from "types/company";
import { PixType } from "types/enums";
import { Professional } from "types/professional";
import { CompanyEditData, CompanyRegisterData } from "types/register";
import { toast } from "utils/toast";

interface CompanyProviderProps {
  children: ReactNode;
}

interface CompanyProviderData {
  inactiveCompanies: Company[];
  inactiveCompaniesTotal: number;
  inactiveCompaniesTotalPages: number;
  isLoadingInactiveCompanies: boolean;
  inactiveCompaniesCurrentPage: number;
  getInactiveCompanies: (
    currentPage?: number,
    search?: string
  ) => Promise<void>;
  setInactiveCompaniesCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  activeCompanies: Company[];
  activeCompaniesTotal: number;
  activeCompaniesTotalPages: number;
  isLoadingActiveCompanies: boolean;
  activeCompaniesCurrentPage: number;
  getActiveCompanies: (currentPage?: number, search?: string) => Promise<void>;
  setActiveCompaniesCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  editCompany: (
    data: Omit<CompanyEditData, "ddd" | "dddMobilePhone" | "contactDDD">,
    companyId: number,
    onHide: () => void
  ) => Promise<void>;

  createCompany: (
    data: Omit<CompanyRegisterData, "ddd" | "dddMobilePhone" | "contactDDD">,
    onHide: () => void
  ) => Promise<void>;

  addProfessionalToCompany: (
    companyId: number,
    professionalId: number,
    onHide: () => void
  ) => Promise<void>;
  removeProfessionalFromCompany: (
    companyId: number,
    professionalId: number,
    onHide: () => void
  ) => Promise<void>;

  companyProfessionals: Professional[];
  companyProfessionalsTotal: number;
  companyProfessionalsTotalPages: number;
  isLoadingCompanyProfessionals: boolean;
  companyProfessionalsCurrentPage: number;
  getCompanyProfessionals: (
    companyId: number,
    currentPage?: number
  ) => Promise<void>;
  setCompanyProfessionalsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  professionalCompanies: Company[];
  professionalCompaniesTotal: number;
  professionalCompaniesTotalPages: number;
  isLoadingProfessionalCompanies: boolean;
  professionalCompaniesCurrentPage: number;
  getProfessionalCompanies: (
    companyId: number,
    currentPage?: number
  ) => Promise<void>;
  setProfessionalCompaniesCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  isLoading: boolean;
}

export const CompanyContext = createContext<CompanyProviderData>(
  {} as CompanyProviderData
);

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const companiesLimit = 10;

  const [inactiveCompanies, setInactiveCompanies] = useState<Company[]>([]);

  const [inactiveCompaniesTotal, setInactiveCompaniesTotal] = useState(0);

  const [inactiveCompaniesTotalPages, setInactiveCompaniesTotalPages] = useState(0);

  const [inactiveCompaniesCurrentPage, setInactiveCompaniesCurrentPage] = useState(1);

  const [isLoadingInactiveCompanies, setIsLoadingInactiveCompanies] = useState(false);

  const [activeCompanies, setActiveCompanies] = useState<Company[]>([]);

  const [activeCompaniesTotal, setActiveCompaniesTotal] = useState(0);

  const [activeCompaniesTotalPages, setActiveCompaniesTotalPages] = useState(0);

  const [activeCompaniesCurrentPage, setActiveCompaniesCurrentPage] = useState(1);

  const [isLoadingActiveCompanies, setIsLoadingActiveCompanies] = useState(false);

  const companyProfessionalsLimit = 10;

  const [companyProfessionals, setCompanyProfessionals] = useState<
    Professional[]
  >([]);

  const [companyProfessionalsTotal, setCompanyProfessionalsTotal] = useState(0);

  const [companyProfessionalsTotalPages, setCompanyProfessionalsTotalPages] = useState(0);

  const [companyProfessionalsCurrentPage, setCompanyProfessionalsCurrentPage] = useState(1);

  const [isLoadingCompanyProfessionals, setIsLoadingCompanyProfessionals] = useState(false);

  const professionalCompaniesLimit = 10;

  const [professionalCompanies, setProfessionalCompanies] = useState<Company[]>(
    []
  );

  const [professionalCompaniesTotal, setProfessionalCompaniesTotal] = useState(0);

  const [professionalCompaniesTotalPages, setProfessionalCompaniesTotalPages] = useState(0);

  const [
    professionalCompaniesCurrentPage,
    setProfessionalCompaniesCurrentPage,
  ] = useState(1);

  const [isLoadingProfessionalCompanies, setIsLoadingProfessionalCompanies] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getInactiveCompanies = async (currentPage = 1, search = "") => {
    setIsLoadingInactiveCompanies(true);

    try {
      const response = await api.get("/Company/search", {
        params: {
          page: currentPage,
          size: companiesLimit,
          search: search,
          active: false,
        },
      });

      setIsLoadingInactiveCompanies(false);
      setInactiveCompanies(response.data);
      setInactiveCompaniesTotal(Number(response.headers["x-total-count"]));

      setInactiveCompaniesTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / companiesLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingInactiveCompanies(false);
    }
  };

  const getActiveCompanies = async (currentPage = 1, search = "") => {
    setIsLoadingActiveCompanies(true);

    try {
      const response = await api.get("/Company/search", {
        params: {
          page: currentPage,
          size: companiesLimit,
          search: search,
          active: true,
        },
      });

      setIsLoadingActiveCompanies(false);
      setActiveCompanies(response.data);
      setActiveCompaniesTotal(Number(response.headers["x-total-count"]));

      setActiveCompaniesTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / companiesLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingActiveCompanies(false);
    }
  };

  const createCompany = async (
    data: Omit<CompanyRegisterData, "ddd" | "dddMobilePhone" | "contactDDD">,
    onHide: () => void
  ) => {
    setIsLoading(true);

    let _pixType: PixType | undefined = undefined;

    if (data.dataRecipient) {
      switch (data.dataRecipient.pixType) {
        case "None":
          _pixType = undefined;
          break;
        case "Email":
          _pixType = PixType["Email"];
          break;
        case "Telefone":
          _pixType = PixType["Telefone"];
          break;
        case "Chave aleatória":
          _pixType = PixType["Chave aleatória"];
          break;
        case "CPF ou CNPJ":
          _pixType = PixType["CPF ou CNPJ"];
          break;
      }
    }

    const body = {
      ...data,
      ddd: data?.phone?.slice(1, 3),
      phone: data?.phone?.replace("-", "").slice(5),
      dddMobilePhone: data?.mobilePhone?.slice(1, 3),
      mobilePhone: data?.mobilePhone?.replace("-", "").slice(5),
      contactDDD: data?.contactPhone?.slice(1, 3),
      contactPhone: data?.contactPhone?.replace("-", "").slice(5),
      priceTable: {
        id: data?.priceTable?.value,
        description: data?.priceTable?.label,
      },
      documentState: data.documentState ? data.documentState : "ISENTO",
      dateFounding: moment(data.dateFounding, "DD/MM/YYYY").toISOString(),
      dataRecipient: data.dataRecipient
        ? {
            ...data.dataRecipient,
            uniqueSplit: true,
            pixType: _pixType,
          }
        : undefined,
    };

    try {
      await api.post(`/Company/register`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getInactiveCompanies(inactiveCompaniesCurrentPage);
      getActiveCompanies(activeCompaniesCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const editCompany = async (
    data: Omit<CompanyEditData, "ddd" | "dddMobilePhone" | "contactDDD">,
    companyId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);

    let _pixType: PixType | undefined = undefined;

    if (data.dataRecipient) {
      switch (data.dataRecipient.pixType) {
        case "None":
          _pixType = undefined;
          break;
        case "Email":
          _pixType = PixType["Email"];
          break;
        case "Telefone":
          _pixType = PixType["Telefone"];
          break;
        case "Chave aleatória":
          _pixType = PixType["Chave aleatória"];
          break;
        case "CPF ou CNPJ":
          _pixType = PixType["CPF ou CNPJ"];
          break;
      }
    }

    const body = {
      ...data,
      ddd: data?.phone?.slice(1, 3),
      phone: data?.phone?.replace("-", "").slice(5),
      dddMobilePhone: data?.mobilePhone?.slice(1, 3),
      mobilePhone: data?.mobilePhone?.replace("-", "").slice(5),
      contactDDD: data.contactPhone.slice(1, 3),
      contactPhone: data.contactPhone.replace("-", "").slice(5),
      priceTable: {
        id: data.priceTable.value,
        description: data.priceTable.label,
      },
      documentState: data.documentState ? data.documentState : "ISENTO",
      dateFounding: moment(data.dateFounding, "DD/MM/YYYY").toISOString(),
      dataRecipient: data.dataRecipient
        ? {
            ...data.dataRecipient,
            uniqueSplit: true,
            pixType: _pixType,
          }
        : undefined,
    };

    try {
      await api.put(`/Company/${companyId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getInactiveCompanies(inactiveCompaniesCurrentPage);
      getActiveCompanies(activeCompaniesCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const getCompanyProfessionals = async (
    companyId: number,
    currentPage = 1
  ) => {
    setIsLoadingCompanyProfessionals(true);

    try {
      const response = await api.get(
        `/Company/${companyId}/professionals` /*{
        params: {
          page: currentPage,
          size: companiesLimit,
          active: false,
        },
      }*/
      );

      setIsLoadingCompanyProfessionals(false);
      setCompanyProfessionals(response.data);
      setCompanyProfessionalsTotal(Number(response.headers["x-total-count"]));

      setCompanyProfessionalsTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / companiesLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingCompanyProfessionals(false);
    }
  };

  const addProfessionalToCompany = async (
    companyId: number,
    professionalId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);
    try {
      await api.post(`/Company/${companyId}/professional/${professionalId}`);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Profissional vinculado com sucesso!",
      });

      getCompanyProfessionals(companyId, companyProfessionalsCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const removeProfessionalFromCompany = async (
    companyId: number,
    professionalId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);
    try {
      await api.delete(`/Company/${companyId}/professional/${professionalId}`);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Profissional desvinculado com sucesso!",
      });

      getCompanyProfessionals(companyId, companyProfessionalsCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const getProfessionalCompanies = async (
    ProfessionalId: number,
    currentPage = 1
  ) => {
    setIsLoadingProfessionalCompanies(true);
    try {
      const response = await api.get(
        `/Professional/${ProfessionalId}/companies` /*{
        params: {
          page: currentPage,
          size: companiesLimit,
          active: false,
        },
      }*/
      );
      setIsLoadingProfessionalCompanies(false);
      setProfessionalCompanies(response.data);
      setProfessionalCompaniesTotal(Number(response.headers["x-total-count"]));
      setProfessionalCompaniesTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / companiesLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionalCompanies(false);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        inactiveCompanies,
        inactiveCompaniesTotal,
        inactiveCompaniesTotalPages,
        isLoadingInactiveCompanies,
        inactiveCompaniesCurrentPage,
        getInactiveCompanies,
        setInactiveCompaniesCurrentPage,

        activeCompanies,
        activeCompaniesTotal,
        activeCompaniesTotalPages,
        isLoadingActiveCompanies,
        activeCompaniesCurrentPage,
        getActiveCompanies,
        setActiveCompaniesCurrentPage,

        createCompany,
        editCompany,

        addProfessionalToCompany,
        removeProfessionalFromCompany,

        companyProfessionals,
        companyProfessionalsTotal,
        companyProfessionalsTotalPages,
        isLoadingCompanyProfessionals,
        companyProfessionalsCurrentPage,
        getCompanyProfessionals,
        setCompanyProfessionalsCurrentPage,

        professionalCompanies,
        professionalCompaniesTotal,
        professionalCompaniesTotalPages,
        isLoadingProfessionalCompanies,
        professionalCompaniesCurrentPage,
        getProfessionalCompanies,
        setProfessionalCompaniesCurrentPage,

        isLoading,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);
