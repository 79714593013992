import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import GeneralTerms from "components/GeneralTerms";
import LinkButton from "components/LinkButton";
import LoadingScreen from "components/LoadingScreen";
import ModalComponent from "components/ModalComponent";
import TermsOfService from "components/TermsOfService";
import { api } from "services";
import { QuoteInfo } from "types/quotes";
import { toast } from "utils/toast";

interface TermsAcceptData {
  isTermsOfUseAccepted: boolean;
  isTermsOfServiceAccepted: boolean;
}

const Terms = () => {
  const params = useParams();
  const [termsOfUseModalShow, setTermsOfUseModalShow] = useState(false);
  const [termsOfServiceModalShow, setTermsOfServiceModalShow] = useState(false);
  const [quoteInfo, setQuoteInfo] = useState<QuoteInfo>({} as QuoteInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuoteInfo, setIsLoadingQuoteInfo] = useState(false);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    isTermsOfUseAccepted: yup
      .boolean()
      .test(
        "termsValidator",
        "*É necessário concordar com os Termos Gerais para prosseguir.",
        (value) => !!value
      ),
    isTermsOfServiceAccepted: yup
      .boolean()
      .test(
        "termsValidator",
        "*É necessário concordar com os Termos de Prestação de Serviço para prosseguir.",
        (value) => !!value
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TermsAcceptData>({ resolver: yupResolver(schema) });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await api.patch(`/Quote/${params.quoteCode}/RegisterTerms`);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Os termos foram aceitos com sucesso!",
      });

      navigate(-1);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const handleTermsOfUseInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setValue("isTermsOfUseAccepted", true);
    } else {
      setValue("isTermsOfUseAccepted", false);
    }
  };

  const handleTermsOfServiceInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setValue("isTermsOfServiceAccepted", true);
    } else {
      setValue("isTermsOfServiceAccepted", false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingQuoteInfo(true);

      try {
        const response = await api.get(`/Quote/byCode/${params.quoteCode}`);

        setIsLoadingQuoteInfo(false);

        setQuoteInfo(response.data);
      } catch (error: any) {
        setIsLoadingQuoteInfo(false);

        let message = "";
        if (error.response.status === 400) {
          message = (Object.values(error.response.data)[0] as string[])[0];
        } else {
          message = "Ocorreu um erro, tente novamente.";
        }

        toast.fire({
          icon: "error",
          title: message,
        });
      }
    })();
  }, []);

  return (
    <>
      {!!isLoadingQuoteInfo && (
        <ContainerAnimation className="content">
          <LoadingScreen />
        </ContainerAnimation>
      )}

      {!isLoadingQuoteInfo && (
        <ContainerAnimation className="content container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <h4
                className="text-center aos-init"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Bem-vindo à Docttorpay!
              </h4>

              <div
                className="border rounded p-3 my-3 aos-init"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <p className="text-justify">
                  Somos gratos por usar nossos serviços. Nós realmente levamos à
                  sério os dados e o relacionamento com nossos clientes, e por
                  este motivo orientamos a leitura dos termos com cuidado e
                  atenção.
                </p>
                <p className="m-0 text-justify">
                  Esperamos que nossa relação seja duradoura e que traga muita
                  facilidade e realização pessoal, seja no uso dos recursos em
                  procedimentos funcionais ou na realização de sonhos nos
                  procedimentos estéticos.
                </p>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="aos-init"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="form-group px-3">
                  <label
                    htmlFor="acceptTermsOfUse"
                    className="control control--checkbox mb-0"
                  >
                    <span className="caption">
                      Li e concordo com os{" "}
                      <LinkButton
                        className="align-baseline fs-6 text-start"
                        onClick={() => setTermsOfUseModalShow(true)}
                      >
                        Termos Gerais
                      </LinkButton>
                      .
                    </span>
                    <input
                      id="acceptTermsOfUse"
                      type="checkbox"
                      {...register("isTermsOfUseAccepted")}
                      onChange={handleTermsOfUseInput}
                    />
                    <div
                      className={`control__indicator ${
                        errors?.isTermsOfUseAccepted && "border border-danger"
                      }`}
                    ></div>
                  </label>
                  <div className="text-danger">
                    <small>
                      {!!errors?.isTermsOfUseAccepted &&
                        errors.isTermsOfUseAccepted.message}
                    </small>
                  </div>
                </div>

                <div className="form-group px-3">
                  <label
                    htmlFor="acceptTermsOfService"
                    className="control control--checkbox mb-0"
                  >
                    <span className="caption">
                      Li e concordo com os{" "}
                      <LinkButton
                        className="align-baseline fs-6 text-start"
                        onClick={() => setTermsOfServiceModalShow(true)}
                      >
                        Termos de Prestação de Serviço
                      </LinkButton>
                      .
                    </span>
                    <input
                      id="acceptTermsOfService"
                      type="checkbox"
                      {...register("isTermsOfServiceAccepted")}
                      onChange={handleTermsOfServiceInput}
                    />
                    <div
                      className={`control__indicator ${
                        errors?.isTermsOfServiceAccepted &&
                        "border border-danger"
                      }`}
                    ></div>
                  </label>
                  <div className="text-danger">
                    <small>
                      {!!errors?.isTermsOfServiceAccepted &&
                        errors.isTermsOfServiceAccepted.message}
                    </small>
                  </div>
                </div>

                <div className="d-flex justify-content-center text-center mt-5">
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Aceitar
                    {!!isLoading && (
                      <CircularProgress
                        color="inherit"
                        size={12}
                        className="align-text-top ms-2"
                      />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </ContainerAnimation>
      )}

      {/* Modal de Termos Gerais */}
      <ModalComponent
        title="Termos Gerais"
        show={termsOfUseModalShow}
        onHide={() => setTermsOfUseModalShow(false)}
        size="lg"
      >
        <Modal.Body>
          <GeneralTerms />
        </Modal.Body>
      </ModalComponent>

      {/* Modal de Termos de Prestação de Serviço */}
      <ModalComponent
        title="Termos de Prestação de Serviço"
        show={termsOfServiceModalShow}
        onHide={() => setTermsOfServiceModalShow(false)}
        size="lg"
      >
        <Modal.Body>
          <TermsOfService quoteInfo={quoteInfo} />
        </Modal.Body>
      </ModalComponent>
    </>
  );
};

export default Terms;
