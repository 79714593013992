import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import AlertModal from "components/AlertModal";
import ModalComponent from "components/ModalComponent";
import WppAttendance from "components/WppAttendance";
import { useUser } from "providers/User";
import { api } from "services";
import { PwdRecoveryStepOneResult } from "types/auth";
import { User } from "types/user";
import { cpfAndCnpjMask, mobilePhoneMask } from "utils/formMasks";
import { toast } from "utils/toast";

import {
  autoCloseValidationMessage,
  pwdRecoverySteps2and3,
} from "../../utils/pwdRecoveryPopUp";
import UserEditForm from "../Forms/UserEditForm";

import NewPwdAdminForm from "./components/NewPwdAdminForm";

interface UserCardProps {
  user: User;
  isActive?: boolean;
}

const UserCard = ({ user, isActive = false }: UserCardProps) => {
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [resetPwdModalShow, setResetPwdModalShow] = useState(false);
  const [userEditModalShow, setUserEditModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    setInactiveUsersCurrentPage,
    setActiveUsersCurrentPage,
    getInactiveUsers,
    getActiveUsers,
  } = useUser();

  const handleActivateUser = async () => {
    setIsLoading(true);

    try {
      await api.patch(
        `/User/admin/${user.id}/${isActive ? "disable" : "enable"}`
      );

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: `Usuário ${isActive ? "des" : ""}ativado com sucesso!`,
      });

      setInactiveUsersCurrentPage(1);
      setActiveUsersCurrentPage(1);

      getInactiveUsers();
      getActiveUsers();

      setAlertModalShow(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const openPwdRecoveryPopUp = async () => {
    const emailOrCpf = user.cpf;

    Swal.showLoading();
    Swal.resetValidationMessage();

    try {
      const response = await api.get(`/Auth/recovery-contacts/${emailOrCpf}`);

      const resultStepOne: PwdRecoveryStepOneResult = {
        request: { emailOrCpf: user.cpf },
        response: response.data,
      };

      Swal.hideLoading();

      // Step 2 - Recovery Code Request and Step 3 - Code Validation
      pwdRecoverySteps2and3(emailOrCpf, <WppAttendance />, resultStepOne);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage(
        "CPF/e-mail não encontrado no sistema. Verifique seus dados e tente novamente.",
        7000
      );
    }
  };

  const userName = user.socialName
    ? `${user.socialName} (${user.name})`
    : user.name;

  return (
    <>
      <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(isActive ? 'border-primary' : 'border-dark')}>
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do profissional */}
              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Usuário</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{userName}</Tooltip>}>
                  <b className="d-block text-sm-truncate">{userName}</b>
                </OverlayTrigger>
              </div>

              {/* CPF do profissional */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">CPF</p>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{cpfAndCnpjMask(user.cpf)}</Tooltip>}>
                  <b className="d-block text-sm-truncate">
                    {cpfAndCnpjMask(user.cpf)}
                  </b>
                </OverlayTrigger>
              </div>

              {/* Telefone */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Telefone</p>
                <b>
                  {mobilePhoneMask(`${user.dddMobilePhone}${user.mobilePhone}`)}
                </b>
              </div>

              {/* E-mail */}

              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1 text-sm-truncate">
                <p className="small opacity-75 mb-0">E-mail</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{user.email}</Tooltip>}>
                  <b className="d-block text-sm-truncate">{user.email}</b>
                </OverlayTrigger>
              </div>

              {/* Endereço do profissional */}
              <div className="col-12 col-lg-6 col-xl-4 pb-3 px-1">
                <p className="small opacity-75 mb-0">Endereço</p>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    !user.addressCEP ? (
                      <Tooltip>Sem endereço cadastrado</Tooltip>
                    ) : (
                      <Tooltip>
                        {user.addressRoad}, {user.addressNumber} -{" "}
                        {user.addressComplement && `${user.addressComplement},`}{" "}
                        {user.addressDistrict}, {user.addressCity} -{" "}
                        {user.addressState}
                      </Tooltip>
                    )
                  }>
                  <b className="d-block text-lg-truncate">
                    {!user.addressCEP && "Sem endereço cadastrado"}

                    {!!user.addressCEP && (
                      <>
                        {user.addressRoad}, {user.addressNumber} -{" "}
                        {user.addressComplement && `${user.addressComplement},`}{" "}
                        {user.addressDistrict}, {user.addressCity} -{" "}
                        {user.addressState}
                      </>
                    )}
                  </b>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          {/* Botão*/}
          <div className="col-12 col-sm-auto pb-3">
            <Dropdown>
              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={openPwdRecoveryPopUp}>
                  <i
                    className={`uil uil-key-skeleton-alt uil-envelope-alt mr-2`}
                  />
                  Recuperar senha
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setResetPwdModalShow(true)}>
                  <i className={`uil uil-key-skeleton-alt mr-2`} />
                  Redefinir senha
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setUserEditModalShow(true)}>
                  <i className="uil uil-edit-alt mr-2" />
                  Editar
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setAlertModalShow(true)}
                  className={isActive ? "text-danger" : "text-success"}>
                  <i
                    className={`uil uil-${
                      isActive ? "times" : "check"
                    }-circle mr-2`}
                  />
                  {isActive ? "Desativar" : "Ativar"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Modal de confirmar ativação/desativação de profssional */}
      <AlertModal
        show={alertModalShow}
        onHide={() => setAlertModalShow(false)}
        title={`Tem certeza que deseja ${
          isActive ? "des" : ""
        }ativar esse profissional?`}
        onClickButton={handleActivateUser}
        btnVariant={isActive ? "danger" : "success"}
        isLoading={isLoading}
      />
      <ModalComponent
        title="Editar Usuário"
        show={userEditModalShow}
        onHide={() => setUserEditModalShow(false)}
        size="xl">
        <UserEditForm onHide={() => setUserEditModalShow(false)} user={user} />
      </ModalComponent>
      <Modal
        backdrop="static"
        centered
        show={resetPwdModalShow}
        onHide={() => setResetPwdModalShow(false)}>
        <Modal.Header
          closeButton
          className="justify-content-end pb-0 border-0"></Modal.Header>
        <Modal.Body className="text-body">
          <div className="text-center">
            <p>
              <i className="uil uil-user-circle display-4" />
            </p>

            <h4 className="mb-4">Defina uma nova senha para esse usuário:</h4>

            <NewPwdAdminForm
              userId={user.id as number}
              onHide={() => setResetPwdModalShow(false)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserCard;
