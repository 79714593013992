import { ButtonGroup, Dropdown } from "react-bootstrap";

interface ButtonEditInt {
  editFunc: () => void;
  deleteFunc: () => void;
}

export const ButtonEdit = ({ editFunc, deleteFunc }: ButtonEditInt) => {
  return (
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle split variant="light" id="dropdown-split-basic" size="sm" />

      <Dropdown.Menu>
        <Dropdown.Item as="button" onClick={editFunc}>
          <i className="uil uil-edit-alt mr-2" />
          Editar
        </Dropdown.Item>
        <Dropdown.Item as="button" className="text-danger" onClick={deleteFunc}>
          <i className="uil uil-trash mr-2" />
          Excluir
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
