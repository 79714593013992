import { useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";

import ProfessionalFinishedQuoteList from "./components/ProfessionalFinishedQuoteList";
import ProfessionalPendingQuoteList from "./components/ProfessionalPendingQuoteList";
import ProfessionalQuotesSearch from "./components/ProfessionalQuoteSearch";

const ProfessionalQuote = () => {
  const {
    professionalPendingQuotesTotal,
    professionalPendingQuotesFoundTotal,
    professionalFinishedQuotesTotal,
    professionalFinishedQuotesFoundTotal,
    searchData,
  } = useProfessionalQuote();

  const [selectedNavTab, setSelectedNavTab] = useState("pending-data");

  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  return (
    <ContainerAnimation className="content">
      {/* Título e Pesquisa */}
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-auto">
              <h1>Orçamentos</h1>
            </div>
            <div className="col-12 col-md-auto">
              <ProfessionalQuotesSearch />
            </div>
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    id="pending-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "pending-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Pendentes
                    <Badge bg="primary ml-1 text-white">
                      {!Object.values(searchData).some(
                        (currentValue) => !!currentValue
                      )
                        ? professionalPendingQuotesTotal
                        : professionalPendingQuotesFoundTotal}
                    </Badge>
                  </span>
                </li>

                <li className="nav-item">
                  <span
                    id="finished-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "finished-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Finalizados
                    <Badge bg="primary ml-1 text-white">
                      {!Object.values(searchData).some(
                        (currentValue) => !!currentValue
                      )
                        ? professionalFinishedQuotesTotal
                        : professionalFinishedQuotesFoundTotal}
                    </Badge>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "pending-data" && (
            <ProfessionalPendingQuoteList />
          )}
          {selectedNavTab === "finished-data" && (
            <ProfessionalFinishedQuoteList />
          )}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default ProfessionalQuote;
