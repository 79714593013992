import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import WppAttendance from "components/WppAttendance";
import {
  autoCloseValidationMessage,
  pwdRecoverySteps2and3,
} from "pages/Login/utils/pwdRecoveryPopUp";
import { api } from "services/index";
import { IdentificationData, PwdRecoveryStepOneResult } from "types/auth";
import { cpfAndCnpjMask } from "utils/formMasks";
import { validateCpfAndCnpj } from "utils/formValidations";

interface IdentificationFormProps {
  isToEditPwd?: boolean;
}

const IdentificationForm = ({ isToEditPwd }: IdentificationFormProps) => {
  const schema = yup.object().shape({
    emailOrCpf: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF/CNPJ inválido", validateCpfAndCnpj),
      }),
    isEmail: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IdentificationData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IdentificationData) => {
    const { emailOrCpf } = data;

    Swal.showLoading();
    Swal.resetValidationMessage();

    try {
      const response = await api.get(`/Auth/recovery-contacts/${emailOrCpf}`);

      const resultStepOne: PwdRecoveryStepOneResult = {
        request: { emailOrCpf },
        response: response.data,
      };

      Swal.hideLoading();

      // Step 2 - Recovery Code Request and Step 3 - Code Validation
      pwdRecoverySteps2and3(emailOrCpf, <WppAttendance />, resultStepOne);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage(
        "CPF/e-mail não encontrado no sistema. Verifique seus dados e tente novamente.",
        7000
      );
    }
  };

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("emailOrCpf", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("emailOrCpf", cpfAndCnpjMask(inputValue));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-2">
      <h5 className="mb-1">
        {isToEditPwd ? "Gostaria de atualizar " : "Esqueceu "}sua senha?
      </h5>
      <p>
        Informe seu CPF ou E-mail para
        {isToEditPwd ? " alterá-la" : " recuperá-la"}.
      </p>
      <Input
        type="text"
        placeholder="CPF ou E-mail"
        register={register("emailOrCpf")}
        onChange={handleUserInput}
        error={errors.emailOrCpf?.message}
        autoFocus
      />
      <Button type="submit" className="btn btn-primary mt-3">
        Recuperar senha
      </Button>
    </form>
  );
};

export default IdentificationForm;
