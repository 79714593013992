import { useState } from "react";
import { Offcanvas, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import GeneralTerms from "components/GeneralTerms";
import LinkButton from "components/LinkButton";
import ModalComponent from "components/ModalComponent";
import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import { useUser } from "providers/User";
import { RoleDescription } from "types/enums";

const OffCanvasMenu = () => {
  const [show, setShow] = useState(false);
  const { userRole, logout, userContract } = useAuth();
  const { userData } = useUser();
  const { toggleDarkMode } = useDarkMode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [termsModalShow, setTermsModalShow] = useState(false);

  return (
    <>
      <div className={
        userRole === RoleDescription.Admin
          ? "col col-auto d-block d-xxl-none"
          : "col col-auto d-block d-lg-none"
      }>
        <div
          className="burger ml-auto float-right site-menu-toggle js-menu-toggle"
          onClick={handleShow}
        >
          <span></span>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="site-mobile-menu"
      >
        <Offcanvas.Header
          closeButton
          className="site-mobile-menu-header justify-content-end"
        />

        {/* Opções de navegação não exibidas enquanto suas respectivas páginas não estão prontas */}
        <Offcanvas.Body className="site-mobile-menu-body">
          <ul className="site-nav-wrap">
            <li className="d-md-none px-3 pb-2 lh-1">
              <p className="fw-bold mb-2">{userData?.socialName || userData?.name}</p>
              <span className="d-block small text-truncate">{userData?.email}</span>
              <span className="fw-bold small">
                {userRole === RoleDescription.Admin && "Administrador"}
                {userRole === RoleDescription.Professional && "Profissional"}
                {userRole === RoleDescription.Secretary && "Secretária"}
                {userRole === RoleDescription.Patient && "Paciente"}
              </span>
            </li>
            <li className="d-md-none">
              <NavLink
                className="text-decoration-none"
                to="/meus-dados"
                onClick={handleClose}
                id="meus-dados"
              >
                Meus Dados
              </NavLink>
            </li>

            {!userContract && userRole === RoleDescription.Professional && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/contract"
                  id="contract"
                  onClick={handleClose}
                >
                  Contrato
                </NavLink>
              </li>
            )}

            {/* <li className="d-md-none">
              <NavLink
                className="text-decoration-none"
                to=""
                onClick={() => {
                  handleClose();
                  setTermsModalShow(true);
                }}
              >
                Termos
              </NavLink>
            </li> */}
            <li className="d-md-none">
              <LinkButton
                className="border-0 mx-1 py-1 px-3 btn-offcanvas dark-mode"
                onClick={toggleDarkMode}
              >
                Alterar Tema <i className="uil uil-moon ml-2"></i>
              </LinkButton>
            </li>
            <li className="d-md-none border-bottom pt-2 mb-2"></li>


            {/* {userRole !== RoleDescription.Patient && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/agenda"
                  onClick={handleClose}
                  style={{ pointerEvents: "none" }}
                  id="agenda"
                >
                  Agenda
                </NavLink>
              </li>
            )} */}

            {/* {userRole !== RoleDescription.Patient && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/crm"
                  onClick={handleClose}
                  style={{ pointerEvents: "none" }}
                  id="crm"
                >
                  CRM
                </NavLink>
              </li>
            )} */}

            <li>
              <NavLink
                className="text-decoration-none offcanvas-item"
                to={
                  userRole === RoleDescription.Patient
                    ? "orcamentos-paciente"
                    : "/orcamentos-profissional"
                }
                onClick={handleClose}
                id="orcamentos"
              >
                Orçamentos
              </NavLink>
            </li>

            {userRole === RoleDescription.Admin && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/profissionais"
                  onClick={handleClose}
                  id="profissionais"
                >
                  Profissionais
                </NavLink>
              </li>
            )}

            {userRole === RoleDescription.Admin && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/secretarias"
                  onClick={handleClose}
                  id="secretarias"
                >
                  Secretárias
                </NavLink>
              </li>
            )}

            {userRole === RoleDescription.Admin && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/instituicoes"
                  onClick={handleClose}
                  id="instituicoes"
                >
                  Instituições
                </NavLink>
              </li>
            )}
            {userRole !== RoleDescription.Patient && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/pacientes"
                  id="pacientes"
                  onClick={handleClose}
                >
                  Pacientes
                </NavLink>
              </li>
            )}
            {userRole === RoleDescription.Admin && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/usuarios"
                  id="usuarios"
                  onClick={handleClose}
                >
                  Usuários
                </NavLink>
              </li>
            )}
            {userRole !== RoleDescription.Patient && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/agenda"
                  id="agenda"
                  onClick={handleClose}
                >
                  Calendário
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                className="text-decoration-none"
                to="/relatorios"
                id="relatorios"
                onClick={handleClose}
              >
                Relatórios
              </NavLink>
            </li>

            {/* {userRole !== RoleDescription.Patient && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/financeiro"
                  onClick={handleClose}
                  style={{ pointerEvents: "none" }}
                  id="financeiro"
                >
                  Financeiro
                </NavLink>
              </li>
            )} */}

            {/* {userRole === RoleDescription.Professional && (
              <li>
                <NavLink
                  className="text-decoration-none"
                  to="/pacientes"
                  onClick={handleClose}
                  id="pacientes"
                >
                  Pacientes
                </NavLink>
              </li>
            )} */}

            {/* <li>
              <NavLink
                className="text-decoration-none"
                to="/instituicoes"
                onClick={handleClose}
                id="instituicoes"
              >
                Instituições
              </NavLink>
            </li> */}
            
            <li>
              <LinkButton
                className="border-0 mx-1 py-1 px-3 btn-offcanvas"
                onClick={logout}
              >
                Sair
              </LinkButton>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Modal de Termos */}
      <ModalComponent
        title="Termos Gerais"
        show={termsModalShow}
        onHide={() => setTermsModalShow(false)}
        size="lg"
      >
        <Modal.Body>
          <GeneralTerms />
        </Modal.Body>
      </ModalComponent>
    </>
  );
};

export default OffCanvasMenu;
