import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import ModalComponent from "components/ModalComponent";
import { api } from "services";
import { ContractType } from "types/enums";

interface EmbeddedContractModalProps {
  userId?: number;
  signed: boolean;
  show: boolean;
  onHide: () => void;
}

const EmbeddedContractModal = ({
  userId,
  signed,
  show,
  onHide,
}: EmbeddedContractModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [urlPdf, setUrlPdf] = useState<string>("");

  const contractPDFUrl = async () => {
    setIsLoading(true);
    try {
      const { data }: AxiosResponse<string> = await api.get("Contract/pdf", {
        params: {
          signed: signed,
          contractType: ContractType.Partnership,
          userId,
        },
      });

      setUrlPdf(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    contractPDFUrl();
  }, [userId]);

  return (
    <ModalComponent
      title="Contrato"
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
    >
      <Modal.Body>
        <div className="modal-body text-body h-70vh">
          {!!isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress color="inherit" size={50} />
            </div>
          ) : (
            <embed
              src={signed ? "data:application/pdf;base64," + urlPdf : urlPdf}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          )}
        </div>
      </Modal.Body>
    </ModalComponent>
  );
};

export default EmbeddedContractModal;
