import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

import NavTabItem from "./components";

interface NavTabsProps {
  selectedNavTab: string;
  setSelectedNavTab: React.Dispatch<React.SetStateAction<string>>;
}

const NavTab = ({ selectedNavTab, setSelectedNavTab }: NavTabsProps) => {
  const { userRole, userContract, newPasswordRequired } = useAuth();

  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  return (
    <ul className="nav nav-tabs">
      <NavTabItem
        title="Dados Pessoais"
        id="personal-data"
        selectedNavTab={selectedNavTab}
        handleTabClick={handleTabClick}
      />

      {userRole === RoleDescription.Professional && (
        <NavTabItem
          title="Dados Profissionais"
          id="professional-data"
          selectedNavTab={selectedNavTab}
          handleTabClick={handleTabClick}
        />
      )}

      <NavTabItem
        title="Dados de Acesso"
        id="access-data"
        selectedNavTab={selectedNavTab}
        handleTabClick={handleTabClick}
        newPasswordRequired={newPasswordRequired}
      />

      <NavTabItem
        title="Endereço"
        id="address-data"
        selectedNavTab={selectedNavTab}
        handleTabClick={handleTabClick}
      />

      {!!userContract && userRole === RoleDescription.Professional && (
        <li className="nav-item">
          <span
            id="professional-secretaries-data"
            className={`nav-link link-primary ${
              selectedNavTab === "professional-secretaries-data" && "active"
            }`}
            onClick={handleTabClick}
          >
            Secretárias
          </span>
        </li>
      )}
    </ul>
  );
};

export default NavTab;
