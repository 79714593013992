import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { RoleDescription } from "types/enums";
import { UserEditData, User, UserAdminEditData } from "types/user";
import { toast } from "utils/toast";

interface UserProviderProps {
  children: ReactNode;
}

interface UserProviderData {
  userData: User;
  editUserData: (data: UserEditData) => Promise<void>;
  editAnotherUserData: (
    data: UserAdminEditData,
    profileId: number,
    onHide: () => void
  ) => Promise<void>;
  isLoading: boolean;

  inactiveUsers: User[];
  inactiveUsersTotal: number;
  inactiveUsersTotalPages: number;
  isLoadingInactiveUsers: boolean;
  inactiveUsersCurrentPage: number;
  getInactiveUsers: (currentPage?: number, search?: string) => Promise<void>;
  setInactiveUsersCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  activeUsers: User[];
  activeUsersTotal: number;
  activeUsersTotalPages: number;
  isLoadingActiveUsers: boolean;
  activeUsersCurrentPage: number;
  getActiveUsers: (currentPage?: number, search?: string) => Promise<void>;
  setActiveUsersCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const UserContext = createContext<UserProviderData>(
  {} as UserProviderData
);

export const UserProvider = ({ children }: UserProviderProps) => {
  const { token, userId, profileId, userRole } = useAuth();
  const [userData, setUserData] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const usersLimit = 10;

  const [inactiveUsers, setInactiveUsers] = useState<User[]>([]);

  const [inactiveUsersTotal, setInactiveUsersTotal] = useState(0);

  const [inactiveUsersTotalPages, setInactiveUsersTotalPages] = useState(0);

  const [inactiveUsersCurrentPage, setInactiveUsersCurrentPage] = useState(1);

  const [isLoadingInactiveUsers, setIsLoadingInactiveUsers] = useState(false);

  const [activeUsers, setActiveUsers] = useState<User[]>([]);

  const [activeUsersTotal, setActiveUsersTotal] = useState(0);

  const [activeUsersTotalPages, setActiveUsersTotalPages] = useState(0);

  const [activeUsersCurrentPage, setActiveUsersCurrentPage] = useState(1);

  const [isLoadingActiveUsers, setIsLoadingActiveUsers] = useState(false);

  const getUserData = async () => {
    const endpoint =
      userRole === RoleDescription.Patient
        ? `/User/perfil/${profileId}`
        : `/Professional/${userId}`;

    try {
      const response = await api.get(endpoint);

      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const editUserData = async (data: UserEditData) => {
    setIsLoading(true);

    const body: UserEditData = {
      ...userData,
      ...data,
      cpf: data.cpf ? data.cpf.replace(/\D/g, "") : userData.cpf,
      birthDate: data.birthDate
        ? moment(data.birthDate, "DD/MM/YYYY").toISOString()
        : userData.birthDate,
      dddMobilePhone: data.mobilePhone
        ? data.mobilePhone.slice(1, 3)
        : userData.dddMobilePhone,
      mobilePhone: data.mobilePhone
        ? data.mobilePhone.replace("-", "").slice(5)
        : userData.mobilePhone,
    };

    const endpoint =
      userRole === RoleDescription.Patient
        ? `/User/perfil/${profileId}`
        : `/Professional/${userId}`;

    try {
      await api.put(endpoint, body);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getUserData();
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const editAnotherUserData = async (
    data: UserAdminEditData,
    profileId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);

    const body: UserAdminEditData = {
      ...userData,
      ...data,
      cpf: data.cpf ? data.cpf.replace(/\D/g, "") : userData.cpf,
      birthDate: data.birthDate
        ? moment(data.birthDate, "DD/MM/YYYY").toISOString()
        : userData.birthDate,
      dddMobilePhone: data.mobilePhone
        ? data.mobilePhone.slice(1, 3)
        : userData.dddMobilePhone,
      mobilePhone: data.mobilePhone
        ? data.mobilePhone.replace("-", "").slice(5)
        : userData.mobilePhone,
    };

    try {
      await api.put(`/User/perfil/${profileId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getInactiveUsers(inactiveUsersCurrentPage);
      getActiveUsers(activeUsersCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  const getInactiveUsers = async (currentPage = 1, search = "") => {
    setIsLoadingInactiveUsers(true);

    try {
      const response = await api.get("/User/admin/search", {
        params: {
          page: currentPage,
          size: usersLimit,
          active: false,
          search: search,
        },
      });

      setIsLoadingInactiveUsers(false);
      setInactiveUsers(response.data);
      setInactiveUsersTotal(Number(response.headers["x-total-count"]));

      setInactiveUsersTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / usersLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingInactiveUsers(false);
    }
  };

  const getActiveUsers = async (currentPage = 1, search = "") => {
    setIsLoadingActiveUsers(true);

    try {
      const response = await api.get("/User/admin/search", {
        params: {
          page: currentPage,
          size: usersLimit,
          active: true,
          search: search,
        },
      });

      setIsLoadingActiveUsers(false);
      setActiveUsers(response.data);
      setActiveUsersTotal(Number(response.headers["x-total-count"]));

      setActiveUsersTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / usersLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingActiveUsers(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        editUserData,
        editAnotherUserData,
        isLoading,

        inactiveUsers,
        inactiveUsersTotal,
        inactiveUsersTotalPages,
        isLoadingInactiveUsers,
        inactiveUsersCurrentPage,
        getInactiveUsers,
        setInactiveUsersCurrentPage,

        activeUsers,
        activeUsersTotal,
        activeUsersTotalPages,
        isLoadingActiveUsers,
        activeUsersCurrentPage,
        getActiveUsers,
        setActiveUsersCurrentPage,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
