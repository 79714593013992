import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select, { StylesConfig, Theme } from "react-select";
import SwitchSelector from "react-switch-selector";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import FileUploadCard from "components/FileUploadCard";
import Input from "components/Input";
import LinkButton from "components/LinkButton";
import ModalComponent from "components/ModalComponent";
import RequiredDocuments from "components/RequiredDocuments";
import SelectDropdown from "components/SelectDropdown";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import PatientUnconfirmedRegisterForm from "pages/Quotes/ProfessionalQuote/components/Forms/PatientUnconfirmedRegisterForm";
import { useDarkMode } from "providers/DarkMode";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { api } from "services";
import {
  PaymentFeeOwner,
  PaymentMethod,
  PaymentMethodDescription,
  PaymentTool,
} from "types/enums";
import { DocumentType } from "types/files";
import { QuoteCreateData } from "types/quotes";
import { calculateFees, calculateFinalValue } from "utils/calculations";
import { validateProcedureDate } from "utils/formValidations";
import {
  getCompanyOptions,
  getPatientOptions,
  getProfessionalOptions,
} from "utils/getOptions";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import {
  creditParcelOptions,
  financingParcelOptions,
  paymentMethodOptions,
} from "utils/selectOptions";

interface QuoteCreateFormProps {
  companyFromSimulator?: { value: number; label: string };
  paymentToolAndMachineFromSimulator?: {
    paymentTool: PaymentTool | null;
    cardMachine: {
      value: number;
      label: string;
    };
  };
  onHide: () => void;
}

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
     * multiValue(remove)/color:hover
     */
    danger: "var(--danger)",
    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: "var(--danger-light)",
    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: "var(--neutral-0)",
    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "var(--neutral-5)",
    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "var(--neutral-10)",
    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: "var(--neutral-20)",
    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: "var(--neutral-30)",
    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "var(--neutral-40)",
    /*
     * placeholder/color
     */
    neutral50: "var(--neutral-50)",
    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: "var(--primary)",
    /*
     * option/backgroundColor(focused)
     */
    primary25: "var(--primary-25)",
    /*
     * option/backgroundColor:active
     */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

const customStyles: StylesConfig = {
  control: (base: Record<string, unknown>) => ({
    ...base,
    height: 48,
    borderColor: "var(--border)",
    cursor: "pointer",
    "&:hover": {},
  }),
  singleValue: (base: Record<string, unknown>) => ({
    ...base,
    textTransform: "uppercase",
  }),
  option: (base: Record<string, unknown>) => ({
    ...base,
    cursor: "pointer",
    textTransform: "uppercase",
  }),
  menu: (base: Record<string, unknown>) => ({
    ...base,
    zIndex: 100,
  }),
};

const QuoteCreateForm = ({
  companyFromSimulator,
  paymentToolAndMachineFromSimulator,
  onHide,
}: QuoteCreateFormProps) => {
  const { createProfessionalQuote, simulatedQuotePayment } =
    useProfessionalQuote();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { darkMode } = useDarkMode();

  const patientInputRef = useRef(null);
  const professionalInputRef = useRef(null);

  const schema = yup.object().shape({
    company: yup
      .object()
      .required("**Campo obrigatório**")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    patient: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    professional: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    description: yup
      .string()
      .required("*Campo obrigatório")
      .min(4, "*Informe um procedimento com pelo menos 4 caracteres")
      .uppercase()
      .trim(),
    date: yup
      .date()
      .typeError("*Data inválida")
      .nullable()
      .required("*Campo obrigatório")
      .test("procedureDateValidator", "*Data inválida", (value) =>
        validateProcedureDate(value, companyMinLimitDateProcedure)
      ),
    priceValue: yup.string().required("*Campo obrigatório"),
    paymentMethod: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    parcelQtd: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    feeMonthly: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    feeAnnual: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    parcelValue: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    finalValue: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    fileTypes: yup.array().of(
      yup.object().shape({
        value: files.length
          ? yup.string().required("*Selecione o tipo do arquivo")
          : yup.string(),
      })
    ),
    paymentFeeOwner: yup
      .number()
      .nullable()
      .default(simulatedQuotePayment.paymentFeeOwner),

    priceTable: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().uppercase(),
      })
      .nullable(),
    paymentTool: yup.number().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    control,
    formState: { errors },
  } = useForm<QuoteCreateData>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      company: companyFromSimulator
        ? {
            value: companyFromSimulator.value,
            label: companyFromSimulator.label,
          }
        : undefined,
      priceValue: Object.keys(simulatedQuotePayment).length
        ? simulatedQuotePayment.value.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })
        : undefined,
      paymentMethod: Object.keys(simulatedQuotePayment).length
        ? {
            value: simulatedQuotePayment.paymentMethod,
            label:
              PaymentMethodDescription[simulatedQuotePayment.paymentMethod],
          }
        : undefined,
      parcelQtd: Object.keys(simulatedQuotePayment).length
        ? {
            value: simulatedQuotePayment.parcelQtd,
            label: `${simulatedQuotePayment.parcelQtd}x`,
          }
        : undefined,
      paymentFeeOwner: simulatedQuotePayment.paymentFeeOwner,

      paymentTool: paymentToolAndMachineFromSimulator
        ? paymentToolAndMachineFromSimulator.paymentTool
        : undefined,
      priceTable: paymentToolAndMachineFromSimulator
        ? paymentToolAndMachineFromSimulator.cardMachine
        : undefined,
    },
  });

  const [companyId, setCompanyId] = useState<number>(
    companyFromSimulator?.value || -1
  );

  const [companyMinLimitDateProcedure, setCompanyMinLimitDateProcedure] =
    useState(0);

  useEffect(() => {
    async function getCompanyMinLimitDateProcedure() {
      if (companyId === -1) return;

      try {
        const { data } = await api.get(
          `/Company/${companyId}/data-procedimento`
        );

        setCompanyMinLimitDateProcedure(data);
      } catch (error) {
        console.error(error);
      }
    }

    getCompanyMinLimitDateProcedure();
  }, [companyId]);

  const onSubmit = async (data: QuoteCreateData) => {
    setIsLoading(true);
    createProfessionalQuote(data, files, onHide, navigate).then(() =>
      setIsLoading(false)
    );
  };

  const handlePaymentMethod = () => {
    resetField("parcelQtd", { defaultValue: {} });
    resetField("feeMonthly");
    resetField("feeAnnual");
    resetField("parcelValue");
    resetField("finalValue");
  };

  const handleParcelQtd = (event: any) => {
    const parcelQtdNumber = event.value;

    const { calculatedFeeMonthly, calculatedFeeAnnual } =
      calculateFees(parcelQtdNumber);

    setValue("feeMonthly", calculatedFeeMonthly);
    setValue("feeAnnual", calculatedFeeAnnual);

    if (watch("parcelValue")) {
      const parcelValueNumber = Number(
        watch("parcelValue")?.replace(/\./g, "").replace(",", ".")
      );

      const calculatedFinalValue = calculateFinalValue(
        parcelQtdNumber,
        parcelValueNumber
      );

      setValue("finalValue", calculatedFinalValue);
    }
  };

  const handleParcelValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTwoDecimalPlacesInput(event, setValue, "parcelValue");

    const parcelValueNumber = Number(
      event.target.value.replace(/\./g, "").replace(",", ".")
    );

    const parcelQtdNumber = watch("parcelQtd.value");

    const calculatedFinalValue = calculateFinalValue(
      parcelQtdNumber,
      parcelValueNumber
    );

    setValue("finalValue", calculatedFinalValue);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];

      append({ value: "", fileName: file.name });

      setFiles([...files, file]);
    }
  };

  const removeFile = (fieldIndex: number) => {
    remove(fieldIndex);
    setFiles(files.filter((_, fileIndex) => fileIndex !== fieldIndex));
  };

  const { fields, append, remove } = useFieldArray({
    name: "fileTypes",
    control,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/FileAttachment/documenttypes");

        setDocumentTypes(response.data);
      } catch (error) {
        console.error(error);
      }
    })();

    if (simulatedQuotePayment.paymentMethod === PaymentMethod.Financing) {
      const { calculatedFeeMonthly, calculatedFeeAnnual } = calculateFees(
        simulatedQuotePayment.parcelQtd
      );

      setValue("feeMonthly", calculatedFeeMonthly);
      setValue("feeAnnual", calculatedFeeAnnual);
    }
  }, []);

  const [companyCardMachines, setCompanyCardMachines] = useState<
    {
      id: number;
      description: string;
    }[]
  >([]);
  const [isLoadingCardMachines, setIsLoadingCardMachines] = useState(true);

  useEffect(() => {
    const loadCompanyCardMachines = async () => {
      try {
        if (companyId !== -1) {
          const { data } = await api.get("/Quote/TerminalPDQ", {
            params: { companyId: companyId },
          });

          setCompanyCardMachines(data);
        }

        setIsLoadingCardMachines(false);
      } catch (error) {
        console.error(error);

        setIsLoadingCardMachines(false);
      }
    };

    loadCompanyCardMachines();
  }, [companyId]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-group">
            <SelectSearch
              label="Instituição:"
              placeholder="Buscar Instituição"
              url={`${API_URL}Company/search`}
              control={control}
              name="company"
              get={getCompanyOptions}
              isClearable
              error={errors.company?.label?.message}
              handleChange={(companyId: number) => {
                setCompanyId(companyId);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                patientInputRef.current?.clearValue();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                professionalInputRef.current?.clearValue();

                resetField("patient");
                resetField("professional");
                resetField("date");
              }}
              isDisabled={!!companyFromSimulator}
            />
          </div>

          <div className="form-group position-relative">
            <div className="position-absolute end-0">
              <LinkButton className="btn-lg" onClick={() => setModalShow(true)}>
                <i className="uil uil-plus mr-2" />
                Adicionar Cliente
              </LinkButton>
            </div>

            <SelectSearch
              label="CPF do Cliente:"
              placeholder="Buscar Cliente pelo CPF"
              url={`${API_URL}Company/${companyId}/patients`}
              control={control}
              name="patient"
              get={getPatientOptions}
              isClearable
              error={errors.patient?.label?.message}
              isCpf
              isDisabled={companyId === -1}
              ref={patientInputRef}
            />
          </div>

          <div className="form-group">
            <SelectSearch
              label="Profissional:"
              placeholder="Buscar Profissional"
              url={`${API_URL}Company/${companyId}/professionals`}
              control={control}
              name="professional"
              get={getProfessionalOptions}
              isClearable
              error={errors.professional?.label?.message}
              isDisabled={companyId === -1}
              ref={professionalInputRef}
            />
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-7">
              <Input
                className="text-uppercase"
                type="text"
                label="Procedimento:"
                placeholder="Nome do Procedimento"
                register={register("description")}
                error={errors?.description?.message}
              />
            </div>

            <div className="form-group col-12 col-md-5">
              <DatePicker
                label="Data do Procedimento:"
                control={control}
                name="date"
                defaultValue={null}
                minDate={moment().add(companyMinLimitDateProcedure, "d")}
                error={errors?.date?.message}
                disabled={companyId === -1}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="label">Quem assume a taxa?</label>
            <div style={{ height: 48 }}>
              <Controller
                name="paymentFeeOwner"
                control={control}
                render={({ field }) => (
                  <SwitchSelector
                    name="paymentFeeOwner"
                    wrapperBorderRadius={50}
                    optionBorderRadius={48}
                    fontSize={16}
                    border="1px solid var(--border)"
                    fontColor="var(--text)"
                    backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                    selectedBackgroundColor="var(--primary)"
                    selectedFontColor="var(--white)"
                    selectionIndicatorMargin={5}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    initialSelectedIndex={
                      (simulatedQuotePayment.paymentFeeOwner as number) - 1
                    }
                    options={[
                      {
                        value: PaymentFeeOwner.Paciente,
                        label: "Cliente",
                      },
                      {
                        value: PaymentFeeOwner.Estabelecimento,
                        label: "Estabelecimento",
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <Input
              type="tel"
              label="Valor:"
              placeholder="0,00"
              isPriceField
              register={register("priceValue")}
              error={errors?.priceValue?.message}
              onChange={(e) =>
                handleTwoDecimalPlacesInput(e, setValue, "priceValue")
              }
              readOnly={!!simulatedQuotePayment.value}
            />
          </div>

          <div className="row">
            <div className="form-group col-md-7">
              <SelectDropdown
                options={paymentMethodOptions}
                label="Método de Pagamento:"
                name="paymentMethod"
                placeholder="Selecione"
                control={control}
                error={errors.paymentMethod?.label?.message}
                changeOptionSelected={handlePaymentMethod}
                isSearchable={false}
                isDisabled={!!simulatedQuotePayment.paymentMethod}
              />
            </div>

            <OverlayTrigger
              placement="bottom"
              overlay={
                !watch("paymentMethod") ? (
                  <Tooltip>Escolha primeiro o método de pagamento</Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div className="form-group col-md-5">
                <SelectDropdown
                  options={
                    watch("paymentMethod")?.value === PaymentMethod.Credit
                      ? creditParcelOptions
                      : watch("paymentMethod")?.value ===
                        PaymentMethod.Financing
                      ? financingParcelOptions
                      : undefined
                  }
                  label="Parcelas:"
                  name="parcelQtd"
                  placeholder="Selecione"
                  control={control}
                  error={errors.parcelQtd?.label?.message}
                  isSearchable={false}
                  isDisabled={
                    !watch("paymentMethod") || !!simulatedQuotePayment.parcelQtd
                  }
                  changeOptionSelected={
                    watch("paymentMethod.value") ===
                    PaymentMethodDescription.Financiamento
                      ? handleParcelQtd
                      : undefined
                  }
                />
              </div>
            </OverlayTrigger>
          </div>

          {watch("paymentMethod")?.value === PaymentMethod.Credit && (
            <div className="form-group">
              <label className="label">
                Qual será a ferramenta de pagamento?
              </label>
              <div style={{ height: 48 }}>
                <Controller
                  name="paymentTool"
                  control={control}
                  render={({ field }) => (
                    <SwitchSelector
                      name="paymentTool"
                      wrapperBorderRadius={50}
                      optionBorderRadius={48}
                      fontSize={16}
                      border="1px solid var(--border)"
                      fontColor="var(--text)"
                      backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                      selectedBackgroundColor=" var(--primary)"
                      selectedFontColor=" var(--white)"
                      selectionIndicatorMargin={5}
                      onChange={(value) => {
                        field.onChange(value);
                        resetField("priceTable");
                      }}
                      initialSelectedIndex={
                        paymentToolAndMachineFromSimulator?.paymentTool as number
                      }
                      options={[
                        {
                          value: PaymentTool["Link de pagamento"],
                          label: "Link de pagamento",
                        },
                        {
                          value: PaymentTool["Máquina física"],
                          label: "Máquina física",
                        },
                      ]}
                      disabled={
                        !!paymentToolAndMachineFromSimulator?.paymentTool
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}

          {watch("paymentMethod")?.value === PaymentMethod.Credit &&
            watch("paymentTool") === PaymentTool["Máquina física"] && (
              <div className="form-group">
                <label className="label">Máquina</label>
                <Controller
                  name="priceTable"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={companyCardMachines.map((cardMachine) => ({
                        value: cardMachine.id,
                        label: cardMachine.description,
                      }))}
                      onChange={(newValue) => {
                        onChange(newValue as { value: number; label: string });
                      }}
                      placeholder="Buscar Máquina"
                      isClearable={true}
                      value={value}
                      theme={customTheme}
                      styles={customStyles}
                      isDisabled={
                        isLoadingCardMachines ||
                        companyId === -1 ||
                        !!paymentToolAndMachineFromSimulator?.cardMachine
                      }
                    />
                  )}
                />
              </div>
            )}

          {watch("paymentMethod")?.value === PaymentMethod.Financing && (
            <div className="animate__animated animate__fadeIn">
              <div className="row">
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Taxa Nominal Mensal:"
                    placeholder="0,00"
                    isFeeField
                    register={register("feeMonthly")}
                    error={errors?.feeMonthly?.message}
                    onChange={(e) =>
                      handleTwoDecimalPlacesInput(e, setValue, "feeMonthly")
                    }
                    readOnly
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Taxa Nominal Anual:"
                    placeholder="0,00"
                    isFeeField
                    register={register("feeAnnual")}
                    error={errors?.feeAnnual?.message}
                    onChange={(e) =>
                      handleTwoDecimalPlacesInput(e, setValue, "feeAnnual")
                    }
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    !watch("parcelQtd")?.value ? (
                      <Tooltip>
                        Selecione primeiro a quantidade de parcelas
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="form-group col-md-6">
                    <Input
                      type="tel"
                      label="Valor da Parcela:"
                      placeholder="0,00"
                      isPriceField
                      register={register("parcelValue")}
                      error={errors?.parcelValue?.message}
                      onChange={handleParcelValue}
                      disabled={!watch("parcelQtd")?.value}
                    />
                  </div>
                </OverlayTrigger>

                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Valor Final:"
                    placeholder="0,00"
                    isPriceField
                    register={register("finalValue")}
                    error={errors?.finalValue?.message}
                    onChange={(e) =>
                      handleTwoDecimalPlacesInput(e, setValue, "finalValue")
                    }
                    readOnly
                  />
                </div>
              </div>

              <p>
                <small>
                  *Na taxa nominal não estão inclusos valores de taxas de
                  abertura de crédito, de análise de crédito, IOF ou seguros.
                </small>
                <br />
                <small>
                  **O valor da parcela e Valor final serão inseridos após a
                  análise de crédito e envio da proposta oficial pela
                  instituição de crédito parceira.
                </small>
              </p>
            </div>
          )}

          <div className="form-group">
            <label className="label" htmlFor="orcamento">
              Arquivos:
              {watch("paymentMethod.value") === PaymentMethod.Financing && (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <RequiredDocuments />
                    </Tooltip>
                  }
                >
                  <i className="uil uil-info-circle ml-2 opacity-75 text-body" />
                </OverlayTrigger>
              )}
            </label>

            <div className="custom-file mb-2">
              <input
                type="file"
                className="custom-file-input"
                onChange={handleFileSelect}
              />
              <label className="custom-file-label" htmlFor="orcamento">
                {!!files.length && (
                  <span>
                    {files.length} arquivo{files.length > 1 && "s"} selecionado
                    {files.length > 1 && "s"}
                  </span>
                )}

                {!files.length && "Nenhum arquivo"}
              </label>
            </div>

            {fields.map((field, index) => (
              <FileUploadCard
                key={field.id}
                fileName={field.fileName}
                register={register(`fileTypes.${index}.value`)}
                documentTypes={documentTypes}
                index={index}
                error={errors.fileTypes?.[index]?.value?.message}
                removeFile={() => removeFile(index)}
              />
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            className="btn-light"
            onClick={onHide}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" className="btn-primary" disabled={isLoading}>
            <i className="uil uil-check mr-2" />
            Adicionar
            {!!isLoading && (
              <CircularProgress
                color="inherit"
                size={12}
                className="align-text-top ms-2"
              />
            )}
          </Button>
        </Modal.Footer>
      </form>

      <ModalComponent
        title="Adicionar Cliente"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <PatientUnconfirmedRegisterForm
          companyId={companyId}
          onHide={() => setModalShow(false)}
          setQuoteValue={setValue}
        />
      </ModalComponent>
    </>
  );
};

export default QuoteCreateForm;
