import { createTheme, ThemeProvider } from "@mui/material/styles";
import AOS from "aos";
import { useEffect } from "react";

import CacheBuster from "components/CacheBuster";
import ScrollToTop from "components/ScrollToTop";
import { useDarkMode } from "providers/DarkMode";
import Router from "routes";
import GlobalStyle from "styles/global";

import "aos/dist/aos.css";

const theme = createTheme({
  palette: {
    common: {
      white: '#FFF',
      black: '#000'
    },
    primary: {
      main: '#34e98b',
      light: '#26c070',
      contrastText: '#fff',
    },
    secondary: {
      main: '#34e98b',
      light: '#26c070',
      contrastText: '#fff',
    },
    background: {
      default: '#FFF',
      paper: '#f8f9fa',
    },
    text: {
      primary: '#666666',
      secondary: '#595f64',
      disabled: '#818181',
      // hint: '#9794a4',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#28a745',
    },
    divider: 'rgba(0,0,0, 0.1)',
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 16,
  }
});
const darktheme = createTheme({
  palette: {
    common: {
      white: '#1a1c1a',
      black: '#fff'
    },
    primary: {
      main: '#34e98b'
    },
    secondary: {
      main: '#34e98b'
    },
    background: {
      default: '#1a1c1a',
      paper: '#242725',
    },
    text: {
      primary: '#dbdbdb',
      secondary: '#595f64',
      disabled: '#818181',
      // hint: '#9794a4',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#28a745',
    },
    divider: 'rgba(255,255,255, 0.1)',
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 16,
  }
});

const App = () => {
  const { darkMode } = useDarkMode();
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <CacheBuster>
      <ThemeProvider theme={(!darkMode ? theme : darktheme)}>
        <GlobalStyle />
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
