import { Badge } from "react-bootstrap";

import {
  QuoteStatusDescription,
  QuoteStatusWaitingDescriptionByPaymentMethod,
} from "types/enums";

interface TimeLineProps {
  step: number;
  quoteStatus: number;
  paymentMethod?: number;
  isPendingItems?: boolean;
}

const quoteStatusIcon = new Map([
  [1, "bill"],
  [2, "hourglass"],
  [3, "hourglass"],
  [4, "dollar-sign-alt"],
  [5, "calender"],
  [6, "check-circle"],
]);

const TimeLineStep = (props: TimeLineProps) => {
  return (
    <div className="px-md-3 py-2 py-md-0 ml-n1 mt-md-n2 text-start text-md-center d-flex align-items-center d-md-block">
      <span
        className={`border position-relative ${
          props.quoteStatus >= props.step && "border-primary"
        } bg-${
          props.quoteStatus === props.step ? "primary" : "light"
        } rounded-circle btn-icon p-3 ml-n4 mt-md-n4 ml-md-0`}
      >
        <i
          className={`h3 uil uil-${quoteStatusIcon.get(props.step)} ${
            props.quoteStatus === props.step
              ? "text-white"
              : props.quoteStatus < props.step && "opacity-25"
          }`}
        />
        {props.isPendingItems && (
          <Badge className="animate__animated animate__pulse animate__infinite position-absolute rounded-circle bg-danger top-0 mt-n2 p-1">
            <i className="uil uil-exclamation-circle text-white display-6"></i>
          </Badge>
        )}
      </span>
      <p
        className={`${
          props.quoteStatus === props.step && "fw-bold"
        } m-0 ml-1 ml-md-0 mt-md-1`}
      >
        {!props.paymentMethod && QuoteStatusDescription[props.step]}
        {!!props.paymentMethod &&
          QuoteStatusWaitingDescriptionByPaymentMethod[props.paymentMethod]}
      </p>
    </div>
  );
};

export default TimeLineStep;
