import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AlertModal from "components/AlertModal";
import ModalComponent from "components/ModalComponent";
import { usePatient } from "providers/Patient";
import { api } from "services";
import { Patient } from "types/patient";
import { cpfAndCnpjMask, mobilePhoneMask } from "utils/formMasks";
import { toast } from "utils/toast";

import PatientEditForm from "../Forms/PatientEditForm";

interface PatientCardProps {
  patient: Patient;
  companyId: number;
  isActive?: boolean;
}

const PatientCard = ({
  patient,
  companyId,
  isActive = false,
}: PatientCardProps) => {
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [patientEditModalShow, setPatientEditModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    setInactivePatientsCurrentPage,
    setActivePatientsCurrentPage,
    getInactivePatients,
    getActivePatients,
  } = usePatient();

  const handleActivatePatient = async () => {
    setIsLoading(true);

    try {
      await api.patch(
        `/Patient/${patient.id}/${isActive ? "disable" : "enable"}`
      );

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: `Paciente ${isActive ? "des" : ""}ativado com sucesso!`,
      });

      setInactivePatientsCurrentPage(1);
      setActivePatientsCurrentPage(1);

      getInactivePatients(companyId);
      getActivePatients(companyId);

      setAlertModalShow(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const patientName = patient.socialName
    ? `${patient.socialName} (${patient.name})`
    : patient.name;

  return (
    <>
      <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(isActive ? 'border-primary' : 'border-dark')}>
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do cliente */}
              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Paciente</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{patientName}</Tooltip>}>
                  <b className="d-block text-sm-truncate">{patientName}</b>
                </OverlayTrigger>
              </div>

              {/* CPF do cliente */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">CPF</p>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{cpfAndCnpjMask(patient.cpf)}</Tooltip>}>
                  <b className="d-block text-sm-truncate">
                    {cpfAndCnpjMask(patient.cpf)}
                  </b>
                </OverlayTrigger>
              </div>

              {/* Telefone */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Telefone</p>
                <b>
                  {mobilePhoneMask(
                    `${patient.dddMobilePhone}${patient.mobilePhone}`
                  )}
                </b>
              </div>

              {/* E-mail */}

              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1 text-sm-truncate">
                <p className="small opacity-75 mb-0">E-mail</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{patient.email}</Tooltip>}>
                  <b className="d-block text-sm-truncate">{patient.email}</b>
                </OverlayTrigger>
              </div>

              {/* Endereço do paciente */}
              <div className="col-12 col-lg-6 col-xl-4 pb-3 px-1">
                <p className="small opacity-75 mb-0">Endereço</p>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    !patient.addressCEP ? (
                      <Tooltip>Sem endereço cadastrado</Tooltip>
                    ) : (
                      <Tooltip>
                        {patient.addressRoad}, {patient.addressNumber} -{" "}
                        {patient.addressComplement &&
                          `${patient.addressComplement},`}{" "}
                        {patient.addressDistrict}, {patient.addressCity} -{" "}
                        {patient.addressState}
                      </Tooltip>
                    )
                  }>
                  <b className="d-block text-lg-truncate">
                    {!patient.addressCEP && "Sem endereço cadastrado"}

                    {!!patient.addressCEP && (
                      <>
                        {patient.addressRoad}, {patient.addressNumber} -{" "}
                        {patient.addressComplement &&
                          `${patient.addressComplement},`}{" "}
                        {patient.addressDistrict}, {patient.addressCity} -{" "}
                        {patient.addressState}
                      </>
                    )}
                  </b>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          {/* Botão*/}
          <div className="col-12 col-sm-auto pb-3">
            <Dropdown>
              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => setAlertModalShow(true)}
                  className={isActive ? "text-danger" : "text-success"}>
                  <i
                    className={`uil uil-${
                      isActive ? "times" : "check"
                    }-circle mr-2`}
                  />
                  {isActive ? "Desativar" : "Ativar"}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setPatientEditModalShow(true)}>
                  <i className="uil uil-edit-alt mr-2" />
                  Editar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Modal de confirmar ativação/desativação de paciente */}
      <AlertModal
        show={alertModalShow}
        onHide={() => setAlertModalShow(false)}
        title={`Tem certeza que deseja ${
          isActive ? "des" : ""
        }ativar esse paciente?`}
        onClickButton={handleActivatePatient}
        btnVariant={isActive ? "danger" : "success"}
        isLoading={isLoading}
      />
      <ModalComponent
        title="Editar Paciente"
        show={patientEditModalShow}
        onHide={() => setPatientEditModalShow(false)}
        size="xl">
        <PatientEditForm
          onHide={() => setPatientEditModalShow(false)}
          patient={patient}
          companyId={companyId}
        />
      </ModalComponent>
    </>
  );
};

export default PatientCard;
