import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import { useUser } from "providers/User";
import { RoleDescription } from "types/enums";

import * as C from "./styles";

const DropdownMenu = () => {
  const { logout, userRole, userContract } = useAuth();
  const { userData } = useUser();
  const { toggleDarkMode } = useDarkMode();
  const [termsModalShow, setTermsModalShow] = useState(false);

  return (
    <>
      <C.Container className="col col-auto d-none d-md-block">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm">
            {userData?.socialName || userData?.name}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header className="lh-1">
              {userData?.email}
              <br />
              <span className="fw-bold small">
                {userRole === RoleDescription.Admin && "Administrador"}
                {userRole === RoleDescription.Professional && "Profissional"}
                {userRole === RoleDescription.Secretary && "Secretária"}
                {userRole === RoleDescription.Patient && "Paciente"}
              </span>
            </Dropdown.Header>

            <Dropdown.Divider />

            <NavLink className="dropdown-item" to="/meus-dados">
              Meus Dados
            </NavLink>

            {/* <Dropdown.Item as="a" onClick={() => setTermsModalShow(true)}>
              Termos
            </Dropdown.Item> */}
            {!!userContract && (
              <NavLink className="dropdown-item" to="/termos-e-contrato">
                Termos{" "}
                {userRole === RoleDescription.Professional ? "e Contratos" : ""}
              </NavLink>
            )}

            {!userContract && userRole === RoleDescription.Professional && (
              <NavLink className="dropdown-item" to="/contract">
                Contrato
              </NavLink>
            )}

            <Dropdown.Item
              className="dark-mode"
              as="a"
              onClick={toggleDarkMode}
            >
              Alterar Tema <i className="uil uil-moon ml-2"></i>
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item as="a" onClick={logout}>
              Sair
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </C.Container>

      {/* Modal de Termos */}
      {/* <ModalComponent
        title="Termos Gerais"
        show={termsModalShow}
        onHide={() => setTermsModalShow(false)}
        size="lg"
      >
        <Modal.Body>
          <GeneralTerms />
        </Modal.Body>
      </ModalComponent> */}
    </>
  );
};

export default DropdownMenu;
