function debounce<TArgs extends any[]>(func: (...args: TArgs) => void, timeout = 300){
    let timer: NodeJS.Timeout;
    return (...args: TArgs) => {
      clearTimeout(timer);

      // @ts-expect-error: Cannot type this correctly
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

export default debounce;