import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { useProfessional } from "providers/Professional";

import ProfessionalCard from "../ProfessionalCard";

const InactiveProfessionalList = ({
  showProfessionalEmbeddedContractModal,
}: {
  showProfessionalEmbeddedContractModal: (userId: number) => void;
}) => {
  const inactiveProfessionalsRef = useRef<HTMLDivElement>(null);

  const {
    inactiveProfessionals,
    inactiveProfessionalsTotalPages,
    inactiveProfessionalsCurrentPage,
    inactiveProfessionalsFound,
    inactiveProfessionalsFoundCurrentPage,
    inactiveProfessionalsFoundTotalPages,
    getInactiveProfessionals,
    getActiveProfessionals,
    searchInactiveProfessionals,
    searchActiveProfessionals,
    searchData,
    setSearchData,
    setInactiveProfessionalsCurrentPage,
    setInactiveProfessionalsFoundCurrentPage,
    isLoadingInactiveProfessionals,
  } = useProfessional();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInactiveProfessionalsCurrentPage(value);

    getInactiveProfessionals(searchData.companyId, value, searchData.search);

    inactiveProfessionalsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSearchPagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInactiveProfessionalsFoundCurrentPage(value);

    searchInactiveProfessionals(searchData, value);

    inactiveProfessionalsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const notShowFilters = () => {
    if (!Object.keys(searchData).length) {
      return true;
    }

    if (
      !searchData.search &&
      (!searchData.companyId || searchData.companyId === -1) &&
      (searchData.contract === undefined || searchData.contract === -1)
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {notShowFilters() ? (
        <></>
      ) : (
        <div className="bg-white shadow-sm p-3 rounded-xl mb-3">
          <div className="row no-gutters">
            <div className="col col-auto pr-2 pt-1">
              <small className="text-uppercase">Filtros:</small>
            </div>

            {/* Filtros */}
            <div className="col">
              <div className="row g-2">
                {/* Search */}
                {!!Object.keys(searchData).length &&
                  !!searchData.search && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              search: "",
                            })
                          }
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Pesquisado:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.search}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Company */}
                {!!Object.keys(searchData).length &&
                  searchData.companyId !== -1 && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              companyId: -1,
                              companyName: undefined,
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Empresa selecionada:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Contract */}
                {!!Object.keys(searchData).length &&
                  searchData.contract !== -1 && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              contract: -1,
                              contractName: undefined,
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Contrato selecionado:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.contractName}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}

      {!!isLoadingInactiveProfessionals &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de profissionais inativos */}
      {!Object.values(searchData).some((currentValue) => !!currentValue) && (
        <>
          {!isLoadingInactiveProfessionals && (
            <>
              {!!inactiveProfessionals.length &&
                inactiveProfessionals?.map((inactiveProfessional) => (
                  <ProfessionalCard
                    key={inactiveProfessional.id}
                    professional={inactiveProfessional}
                    companyId={searchData.companyId}
                    showProfessionalEmbeddedContractModal={
                      showProfessionalEmbeddedContractModal
                    }
                  />
                ))}

              {/* Lista de profissionais inativos vazia */}
              {!inactiveProfessionals.length ? (
                <EmptyList
                  icon="uil-user-md"
                  title="Nenhum profissional inativo"
                />
              ) : null}
            </>
          )}
          {!!inactiveProfessionals.length && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={inactiveProfessionalsTotalPages}
              page={inactiveProfessionalsCurrentPage}
              onChange={handleChangePage}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}

      {/* Lista de profissionais pesquisados */}
      {!!Object.values(searchData).some((currentValue) => !!currentValue) && (
        <>
          {!isLoadingInactiveProfessionals && (
            <>
              {!!inactiveProfessionalsFound.length &&
                inactiveProfessionalsFound?.map((inactiveProfessional) => (
                  <ProfessionalCard
                    key={inactiveProfessional.id}
                    professional={inactiveProfessional}
                    companyId={searchData.companyId}
                    isActive={false}
                    showProfessionalEmbeddedContractModal={
                      showProfessionalEmbeddedContractModal
                    }
                  />
                ))}

              {/* Lista de profissionais vazia */}
              {!inactiveProfessionalsFound.length && (
                <EmptyList
                  icon="uil-bill"
                  title="Nenhum profissional encontrado"
                />
              )}
            </>
          )}
          {inactiveProfessionalsFoundTotalPages > 1 && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={inactiveProfessionalsFoundTotalPages}
              page={inactiveProfessionalsFoundCurrentPage}
              onChange={handleSearchPagination}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}
    </>
  );
};

export default InactiveProfessionalList;
