import { ReactNode, useEffect, useState } from "react";

import packageJson from "../../../package.json";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (latestVersion: string, currentVersion: string) => {
  const versionsA = latestVersion.split(/\./g);
  const versionsB = currentVersion.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }

  return false;
};

function hardRefresh(): void {
  const t: number = Math.floor(Date.now() / 10000); // 10s tics
  const x: string | null = localStorage.getItem("t");
  localStorage.setItem("t", t.toString());

  if (x !== null && parseInt(x) !== t) {
    location.reload(); // force page refresh from server
  } else {
    // refreshed from server within 10s
    const elements: NodeListOf<any> = document.querySelectorAll(
      "a, link, script, img"
    );
    let n: number = elements.length;

    while (n--) {
      const element: any = elements[n];
      const url: URL = new URL(element.href || element.src);
      url.searchParams.set("r", t.toString());
      element.href = url.toString(); // a, link, ...
      element.src = element.href; // rerun script, refresh img
    }
  }
}

type CacheBusterProps = {
  children: ReactNode;
};

export default function CacheBuster({
  children,
}: CacheBusterProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches || "caches" in window) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    fetch(window.location.href, {
      cache: "no-cache",
    })
      .then(() => {
        window.location.replace(window.location.href);
        // hardRefresh()
      })
      .catch(() => {
        hardRefresh();
      });
  };

  useEffect(() => {
    fetch("/meta.json", {
      cache: "no-cache",
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
      },
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );

        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          setLoading(false);
          setIsLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );

          setLoading(false);
          setIsLatestVersion(true);
        }
      });
  }, []);

  if (loading) return <>{null}</>;

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();

    return <>{children}</>;
  }

  return <>{children}</>;
}
