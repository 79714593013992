import { useAccordionButton } from "react-bootstrap";

interface CustomToggleProps {
  title: string;
  eventKey: string;
}

const CustomToggle = ({ title, eventKey }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-sm btn-primary"
      onClick={decoratedOnClick}
    >
      <i className="uil uil-plus mr-2"></i>
      {title.toUpperCase()}
    </button>
  );
};

export default CustomToggle;
