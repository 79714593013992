import * as signalR from "@microsoft/signalr";
import { AxiosResponse } from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { API_URL } from "config";
import { useAuth } from "providers/Auth";
import { api } from "services";
import {
  CountUnread,
  NotificationSendToUser,
  NotifyMessage,
  ReadNotification,
  ReadOption,
} from "types/notifyMessage";
import { sessStorage } from "utils/storage";
import { toast } from "utils/toast";

interface NotifyProps {
  children: ReactNode;
}

interface NotifyProviderData {
  sendNotificationToUser: (body: NotificationSendToUser) => void;
  getAllNotifications: (read?: boolean) => void;
  getCountUnreadNotifications: () => void;
  changeNotificationToRead: (body: ReadNotification) => void;
  changeAllNotificationToRead: () => void;
  deleteNotification: (id: number) => void;
  deleteAllNotification: () => void;
  messages: NotifyMessage[];
  notifyCount: CountUnread;
  notificationRead: Omit<ReadOption, "statusColor">;
  connected: boolean;
  isLoadingNotify: boolean;
}

export const NotifyContext = createContext<NotifyProviderData>(
  {} as NotifyProviderData
);

export const NotifyProvider = ({ children }: NotifyProps) => {
  const { token } = useAuth();

  const [notificationRead, setNotificationRead] = useState<
    Omit<ReadOption, "statusColor">
  >({} as Omit<ReadOption, "statusColor">);
  const [messages, setMessages] = useState<NotifyMessage[]>([]);
  const [connected, setConnected] = useState<boolean>(false);
  const [isLoadingNotify, setIsLoadingNotify] = useState<boolean>(false);
  const [notifyCount, setNotifyCount] = useState<CountUnread>(
    {} as CountUnread
  );

  useEffect(() => {
    if (token) {
      setIsLoadingNotify(true);
      const sessToken = sessStorage.getToken();
      const connect = new signalR.HubConnectionBuilder()
        .withUrl(`${API_URL}hub/authorize`, {
          accessTokenFactory: () => sessToken,
        })
        .withAutomaticReconnect()
        .build();

      if (connect) {
        connect.on("NotifyUser", (message: NotifyMessage) => {
          setMessages((prevMessages) => [...prevMessages, message]);
          toast.fire({
            icon: "info",
            title: `${message.title}`,
            text: `${message.message}`,
          });

          getAllNotifications(false);
          getCountUnreadNotifications();
        });

        connect
          .start()
          .then(() => {
            console.log(`Conectado ao servidor SignalR`);
            setConnected(true);
            setIsLoadingNotify(false);
          })
          .catch((error: any) => {
            console.error("Erro ao conectar com o SignalR: ", error);
            setConnected(false);
            setIsLoadingNotify(false);
          });
      }
    }
  }, [token]);

  const sendNotificationToUser = async (body: NotificationSendToUser) => {
    setIsLoadingNotify(true);

    try {
      await api.post(`Notification/SendToUser`, body);
      setIsLoadingNotify(false);

      toast.fire({
        icon: "success",
        title: "Notificação enviada com sucesso!",
      });
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const getAllNotifications = async (read?: boolean) => {
    setIsLoadingNotify(true);

    try {
      const { data }: AxiosResponse<NotifyMessage[]> = await api.get(
        "Notification/all",
        {
          params: {
            read: read ?? null,
          },
        }
      );

      setMessages(data);
      setIsLoadingNotify(false);
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const getCountUnreadNotifications = async () => {
    setIsLoadingNotify(true);
    try {
      const { data }: AxiosResponse<CountUnread> = await api.get(
        "Notification/countUnread"
      );
      setIsLoadingNotify(false);

      setNotifyCount(data);
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const changeNotificationToRead = async (body: ReadNotification) => {
    setNotificationRead(body.newRead as Omit<ReadOption, "statusColor">);

    setIsLoadingNotify(true);
    try {
      await api.patch(`Notification/${body.id}/read`);
      setIsLoadingNotify(false);

      toast.fire({
        icon: "success",
        title: "Notificação lida!",
      });

      await getAllNotifications(false);
      await getCountUnreadNotifications();
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const changeAllNotificationToRead = async () => {
    setIsLoadingNotify(true);
    try {
      await api.patch("Notification/all/read");
      setIsLoadingNotify(false);

      toast.fire({
        icon: "success",
        title: "Todas as Notificações foram lidas!",
      });

      await getAllNotifications(false);
      await getCountUnreadNotifications();
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const deleteNotification = async (id: number) => {
    setIsLoadingNotify(true);
    try {
      await api.delete(`Notification/${id}`);
      setIsLoadingNotify(false);

      toast.fire({
        icon: "success",
        title: "Notificação deletada com sucesso!",
      });

      await getAllNotifications(false);
      await getCountUnreadNotifications();
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  const deleteAllNotification = async () => {
    setIsLoadingNotify(true);
    try {
      await api.delete("Notification/all/delete");
      setIsLoadingNotify(false);

      toast.fire({
        icon: "success",
        title: "Todas as Notificações deletadas com sucesso!",
      });

      await getAllNotifications(false);
      await getCountUnreadNotifications();
    } catch (error: any) {
      console.error(error);
      setIsLoadingNotify(false);
    }
  };

  return (
    <NotifyContext.Provider
      value={{
        sendNotificationToUser,
        getAllNotifications,
        getCountUnreadNotifications,
        changeNotificationToRead,
        changeAllNotificationToRead,
        deleteNotification,
        deleteAllNotification,
        messages,
        notifyCount,
        notificationRead,
        connected,
        isLoadingNotify,
      }}
    >
      {children}
    </NotifyContext.Provider>
  );
};

export const useNotify = () => useContext(NotifyContext);
