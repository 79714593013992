import { useState } from "react";
import { Navigate } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import ModalComponent from "components/ModalComponent";
import NavCard from "components/NavCard";
import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

import PatientUnconfirmedNewPassword from "./components/PatientUnconfirmedNewPassword";
import PatientUnconfirmedTerms from "./components/PatientUnconfirmedTerms";

const Menus = () => {
  const { userContract, userRole, hasTerms } = useAuth();
  const [termsModalShow, setTermsModalShow] = useState(!hasTerms);
  const [newPasswordModalShow, setNewPasswordModalShow] = useState(false);

  const quoteNavCardDescription =
    userRole !== RoleDescription.Patient
      ? "Cadastro, simulação  e alteração de orçamento"
      : "Cadastro e acompanhamento de orçamento";

  if (!userContract && userRole == RoleDescription.Professional) {
    return <Navigate to="/contract" />;
  }

  return (
    <>
      <ContainerAnimation className="content container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <h1>O que deseja fazer?</h1>
            {userRole !== RoleDescription.Patient
                ? <p className="m-0">Tenha acesso e acompanhe em tempo real o status dos orçamentos dos seus clientes.</p>
                : <p className="m-0">Tenha acesso e acompanhe em tempo real seus orçamentos e simulações.</p>}

            {/* Opções de navegação não exibidas enquanto suas respectivas páginas não estão prontas */}
            <div className="row no-gutters mx-n2 pt-5">
              {/* {userRole !== RoleDescription.Patient && (
              <>
                <NavCard
                  title="Agenda"
                  description="ASDas asd asda sda sd"
                  icon="uil-calender"
                  to="/agenda"
                  isInactive
                />

                <NavCard
                  title="CRM"
                  description="ASDas asd asda sda sd"
                  icon="uil-meeting-board"
                  to="/crm"
                  isInactive
                />
              </>
            )} */}

              {/* <a href="https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1a5181bb-295b-402f-bc64-42ad532cfb6a&env=demo&acct=6a7c7586-9910-4063-a769-ee6c9753a2eb&v=2">
                Contrato Parceria Docttorpay
              </a> */}

              <NavCard
                title="Orçamentos"
                description={quoteNavCardDescription}
                icon="uil-bill"
                to={
                  userRole === RoleDescription.Patient
                    ? "/orcamentos-paciente"
                    : "/orcamentos-profissional"
                }
              />

              {/* {userRole !== RoleDescription.Patient && (
                <NavCard
                  title="Financeiro"
                  description="ASDas asd asda sda sd"
                  icon="uil-usd-circle"
                  to="/financeiro"
                  isInactive
                />
              )} */}

              <NavCard
                title="Meus Dados"
                description="Atualização de dados pessoais e infomações cadastrais"
                icon="uil-user-circle"
                to="/meus-dados"
              />

              {userRole === RoleDescription.Admin && (
                <>
                  <NavCard
                    title="Profissionais"
                    description="Controle o acesso dos profissionais ao sistema"
                    icon="uil-user-md"
                    to="/profissionais"
                  />
                  <NavCard
                    title="Instituições"
                    description="Atualização dos dados cadastrais e inclusão dos profissionais dentro da instituição"
                    icon="uil-clinic-medical"
                    to="/instituicoes"
                  />
                </>
              )}
              {userRole !== RoleDescription.Patient && (
                <NavCard
                  title="Calendário"
                  description="Acompanhamento de pagamentos e recebimentos previstos e efetuados"
                  icon="uil-calender"
                  to="/agenda"
                />
              )}

              <NavCard
                title="Relatórios"
                description="Acompanhamento e impressão dos relatórios disponíveis"
                icon="uil-file-copy-alt"
                to="/relatorios"
              />
            </div>
          </div>
        </div>
      </ContainerAnimation>

      {/* Modal de Primeiro Acesso de Paciente Unconfirmed */}
      <ModalComponent
        title="Termos Gerais"
        hasCloseButton={false}
        show={termsModalShow}
        size="xl"
        keyboard={false}
      >
        <PatientUnconfirmedTerms
          setTermsModalShow={setTermsModalShow}
          setNewPasswordModalShow={setNewPasswordModalShow}
        />
      </ModalComponent>

      {/* Modal de Direcionamento para Alterar Senha */}
      <ModalComponent
        title="Alterar Senha"
        show={newPasswordModalShow}
        onHide={() => setNewPasswordModalShow(false)}
      >
        <PatientUnconfirmedNewPassword />
      </ModalComponent>
    </>
  );
};

export default Menus;
