import Button from "components/Button";

interface EmptyListProps {
  icon: string;
  title: string;
  subtitle?: string;
  buttonTitle?: string;
  onClickButton?: () => void;
}

const EmptyList = ({
  icon,
  title,
  subtitle,
  buttonTitle,
  onClickButton,
}: EmptyListProps) => {
  return (
    <div className="text-center py-5">
      <p>
        <i className={`uil ${icon} display-1`} />
      </p>
      <h2>{title}</h2>
      {!!subtitle && <p>{subtitle}</p>}
      {!!buttonTitle && (
        <p>
          <Button type="button" className="btn-primary" onClick={onClickButton}>
            <i className="uil uil-plus mr-2" />
            {buttonTitle}
          </Button>
        </p>
      )}
    </div>
  );
};

export default EmptyList;
