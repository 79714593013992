import moment from "moment";
import { createContext, ReactNode, useContext, useState } from "react";

import { api } from "services";
import { Patient, PatientEditData } from "types/patient";
import { toast } from "utils/toast";

interface PatientProviderProps {
  children: ReactNode;
}

interface PatientProviderData {
  editPatient: (
    data: PatientEditData,
    patientId: number,
    companyId: number,
    onHide: () => void
  ) => Promise<void>;
  isLoading: boolean;

  inactivePatients: Patient[];
  inactivePatientsTotal: number;
  inactivePatientsTotalPages: number;
  isLoadingInactivePatients: boolean;
  inactivePatientsCurrentPage: number;
  getInactivePatients: (
    companyId: number,
    currentPage?: number,
    search?: string
  ) => Promise<void>;
  setInactivePatientsCurrentPage: React.Dispatch<React.SetStateAction<number>>;

  activePatients: Patient[];
  activePatientsTotal: number;
  activePatientsTotalPages: number;
  isLoadingActivePatients: boolean;
  activePatientsCurrentPage: number;
  getActivePatients: (
    companyId: number,
    currentPage?: number,
    search?: string
  ) => Promise<void>;
  setActivePatientsCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const PatientContext = createContext<PatientProviderData>(
  {} as PatientProviderData
);

export const PatientProvider = ({ children }: PatientProviderProps) => {
  const patientsLimit = 10;

  const [inactivePatients, setInactivePatients] = useState<Patient[]>([]);

  const [inactivePatientsTotal, setInactivePatientsTotal] = useState(0);

  const [inactivePatientsTotalPages, setInactivePatientsTotalPages] =
    useState(0);

  const [inactivePatientsCurrentPage, setInactivePatientsCurrentPage] =
    useState(1);

  const [isLoadingInactivePatients, setIsLoadingInactivePatients] =
    useState(false);

  const [activePatients, setActivePatients] = useState<Patient[]>([]);

  const [activePatientsTotal, setActivePatientsTotal] = useState(0);

  const [activePatientsTotalPages, setActivePatientsTotalPages] = useState(0);

  const [activePatientsCurrentPage, setActivePatientsCurrentPage] = useState(1);

  const [isLoadingActivePatients, setIsLoadingActivePatients] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const editPatient = async (
    data: PatientEditData,
    patientId: number,
    companyId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);

    const body = {
      ...data,
      birthDate: moment(data.birthDate, "DD/MM/YYYY").toISOString(),
      dddMobilePhone: data?.mobilePhone?.slice(1, 3),
      mobilePhone: data?.mobilePhone?.replace("-", "").slice(5),
    };

    try {
      await api.put(`/Patient/${patientId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getInactivePatients(companyId, inactivePatientsCurrentPage);
      getActivePatients(companyId, activePatientsCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const getInactivePatients = async (
    companyId: number,
    currentPage = 1,
    search = ""
  ) => {
    setIsLoadingInactivePatients(true);

    try {
      const response = await api.get("/Patient/search", {
        params: {
          page: currentPage,
          size: patientsLimit,
          active: false,
          companyId: companyId,
          search: search,
        },
      });

      setIsLoadingInactivePatients(false);
      setInactivePatients(response.data);
      setInactivePatientsTotal(Number(response.headers["x-total-count"]));

      setInactivePatientsTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / patientsLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingInactivePatients(false);
    }
  };

  const getActivePatients = async (
    companyId: number,
    currentPage = 1,
    search = ""
  ) => {
    setIsLoadingActivePatients(true);

    try {
      const response = await api.get("/Patient/search", {
        params: {
          page: currentPage,
          size: patientsLimit,
          active: true,
          companyId: companyId,
          search: search,
        },
      });

      setIsLoadingActivePatients(false);
      setActivePatients(response.data);
      setActivePatientsTotal(Number(response.headers["x-total-count"]));

      setActivePatientsTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / patientsLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingActivePatients(false);
    }
  };

  return (
    <PatientContext.Provider
      value={{
        editPatient,
        isLoading,

        inactivePatients,
        inactivePatientsTotal,
        inactivePatientsTotalPages,
        isLoadingInactivePatients,
        inactivePatientsCurrentPage,
        getInactivePatients,
        setInactivePatientsCurrentPage,

        activePatients,
        activePatientsTotal,
        activePatientsTotalPages,
        isLoadingActivePatients,
        activePatientsCurrentPage,
        getActivePatients,
        setActivePatientsCurrentPage,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
