import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --black: #000000;
  --light: #f8f9fa;
  --dark: #4B4B4D;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2ed17c;
  --primary-hover: #26c070;
  --primary-light: #cdebdb;
  --primary-rgb: 46, 209, 124;
  --secondary: #6c757d;
  --secondary-hover: #9ea5ac;
  --secondary-rgb: 108, 17, 125;
  --success: var(--green);
  --info: var(--cyan);
  --warning: var(--yellow);
  --danger: var(--red);
  --danger-light: #ffbdad;
  --border: #dee2e6;
  --border-radius: 0.4rem;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --text: #666666;
  --text-white: #FFFFFF;
  --white-rgb: 255,255,255;
  --black-rgb: 0,0,0;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --border-width: 1px;

  /* MUI */
  --neutral-0: var(--white);
  --neutral-5: var(--light);
  --neutral-10: var(--light);
  --neutral-20: var(--border);
  --neutral-30: var(--border);
  --neutral-40: var(--secondary-hover);
  --neutral-50: var(--secondary);
  --neutral-60: var(--gray);
  --neutral-70: var(--gray-dark);
  --neutral-80: var(--dark);
  --neutral-90: var(--black);
  --primary-25: var(--primary-light);
  --primary-50: var(--primary-hover);
  --primary-75: var(--primary-hover);
}
html, body {
  min-height: 100vh;
}
html {
  height: -webkit-fill-available;
}
body {
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  background-color: var(--white);
  overflow-x: hidden;
  font-size: 16px;
  color: var(--text);
  min-height: -webkit-fill-available;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  right: 0;
  bottom: 0;
  background: rgba(var(--black-rgb), 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
body.offcanvas-menu:before {
  opacity: 1;
  visibility: visible;
}
img {
  image-rendering: -moz-crisp-edges;
 /* Firefox */
  image-rendering: -o-crisp-edges;
 /* Opera */
  image-rendering: -webkit-optimize-contrast;
 /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
 /* IE (non-standard property) */
}
.container {
  max-width: 90%;
}
@media (max-width:575.98px) {
  .container {
      max-width: initial;
 }
}
@media (min-width:1780px) {
  .container {
      max-width: 1600px;
 }
}
.text-black {
  color: var(--black) !important;
}
.text-pure-white {
  color: var(--text-white) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}
.bg-primary-light {
  background-color: rgba(var(--primary-rgb), 0.05);
}
.bg-secondary-light {
  background-color: rgba(var(--secondary-rgb), 0.05);
}
a {
  color: var(--primary);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
a:hover {
  color: var(--primary-hover);
  text-decoration: none;
}
::-moz-selection {
  background: var(--black);
  color: var(--text-white);
}
::selection {
  background: var(--black);
  color: var(--text-white);
}
.text-primary {
  color: var(--primary) !important;
}
a.text-primary {
  color: var(--primary) !important;
}
a.text-primary:hover {
  color: var(--primary) !important;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-family: "Roboto", sans-serif;
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}
h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
      font-size: 2.5rem;
 }
}
h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
      font-size: 2rem;
 }
}
h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
      font-size: 1.75rem;
 }
}
h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
      font-size: 1.5rem;
 }
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.btn {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05rem;
}
.btn:active, .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled):focus, .btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem var(--border);
}
.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
}
.btn-primary.focus, .btn-primary:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn.btn-primary:not(:disabled):not(.disabled).active, .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled):hover, .show>.btn.btn-primary.dropdown-toggle {
  border-color: var(--primary-hover);
  background: var(--primary-hover);
}
.btn.btn-secondary {
  background: var(--secondary);
  border-color: var(--secondary);
}
.btn.btn-secondary:not(:disabled):not(.disabled).active, .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled):hover, .show>.btn.btn-secondary.dropdown-toggle {
  border-color: var(--secondary-hover);
  background: var(--secondary-hover);
}
.btn.btn-sm {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn.btn-lg {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
.btn.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}
.btn.btn-outline-primary:hover {
  background: var(--primary);
  color: var(--white);
}
.btn-offcanvas {
  font-size: 16px;
  color: var(--black);
}
.btn-offcanvas:hover {
  color: var(--primary);
}
@media (max-width: 767.98px) {
  .btn-offcanvas {
      font-size: 14px;
 }
}
.btn .badge-notification{
  position:absolute;
  top: 0;
  right: 0;
}
.form-control, .custom-select {
  border: none;
  font-size: 16px;
  height: 48px;
  background: transparent;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  color: var(--black);
}
.form-control:read-only {
  color: hsl(0, 0%, 60%);
}
.form-control.form-control-sm, .custom-select.form-control-sm {
  height: 38px;
}
.form-control:active, .form-control:focus, .custom-select:active, .custom-select:focus {
  color: var(--black);
  border-color: var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}
.form-control::-webkit-input-placeholder, .custom-select::-webkit-input-placeholder {
 /* Chrome/Opera/Safari */
  color: var(--secondary-hover);
}
.form-control::-moz-placeholder, .custom-select::-moz-placeholder {
 /* Firefox 19+ */
  color: var(--secondary-hover);
}
.form-control:-ms-input-placeholder, .custom-select:-ms-input-placeholder {
 /* IE 10+ */
  color: var(--secondary-hover);
}
.form-control:-moz-placeholder, .custom-select:-moz-placeholder {
 /* Firefox 18- */
  color: var(--secondary-hover);
}
.gutter-v1 {
  margin-right: -20px;
  margin-left: -20px;
}
@media (max-width: 991.98px) {
  .gutter-v1 {
      margin-left: -15px;
      margin-right: -15px;
 }
}
.gutter-v1>.col, .gutter-v1>[class*="col-"] {
  padding-right: 20px;
  padding-left: 20px;
}
@media (max-width: 991.98px) {
  .gutter-v1>.col, .gutter-v1>[class*="col-"] {
      padding-right: 15px;
      padding-left: 15px;
 }
}
.gutter-v2 {
  margin-right: -5px;
  margin-left: -5px;
}
@media (max-width: 991.98px) {
  .gutter-v2 {
      margin-left: -15px;
      margin-right: -15px;
 }
}
.gutter-v2>.col, .gutter-v2>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
@media (max-width: 991.98px) {
  .gutter-v2>.col, .gutter-v2>[class*="col-"] {
      padding-right: 15px;
      padding-left: 15px;
 }
}
.gutter-v3 {
  margin-right: -40px;
  margin-left: -40px;
}
@media (max-width: 991.98px) {
  .gutter-v3 {
      margin-left: -15px;
      margin-right: -15px;
 }
}
.gutter-v3>.col, .gutter-v3>[class*="col-"] {
  padding-right: 40px;
  padding-left: 40px;
}
@media (max-width: 991.98px) {
  .gutter-v3>.col, .gutter-v3>[class*="col-"] {
      padding-right: 15px;
      padding-left: 15px;
 }
}
.spinner-border {
  color: var(--primary);
}
/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: var(--white);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.top-bar a {
  color: var(--text-white);
}
.logo {
  font-size: 24px;
  color: var(--text-white);
  font-weight: 700;
  left: -15px;
  width: 200px;
}
.logo:hover {
  color: var(--text-white);
}
.logo img {
  width: 200px;
}
.menu-absolute {
  position: absolute !important;
}
.btn-book {
  position: absolute;
  right: 15px;
  margin-top: -2px;
}
@media (max-width: 991.98px) {
  .btn-book {
      right: 60px;
 }
}
.site-nav {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
  border-top: 10px solid var(--secondary);
  padding-top: 0;
}
.site-nav a {
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.site-nav h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}
.site-nav .site-navigation .site-menu {
  margin-bottom: 0;
}
.site-nav .site-navigation .site-menu>li {
  display: inline-block;
  position: relative;
}
.site-nav .site-navigation .site-menu>li>a {
  font-size: 14px;
  padding: 10px 15px;
  display: inline-block;
  font-weight: bold;
  text-decoration: none !important;
  color: var(--black);
  position: relative;
}
.site-nav .site-navigation .site-menu>li>a:hover {
  color: var(--primary);
}
.site-nav .site-navigation .site-menu>li.cta-button a {
  padding: 5px 22px;
  border: 1px solid var(--border);
  color: var(--black);
  border-radius: 30px;
}
.site-nav .site-navigation .site-menu>li.cta-button a:hover {
  color: var(--text-white);
  background: var(--white);
  border-color: var(--white);
}
.site-nav .site-navigation .site-menu>li.cta-button.active a {
  color: var(--black);
  background: var(--white);
  border-color: var(--white);
}
.site-nav .site-navigation .site-menu>li.active>a {
  color: var(--text-white);
}
.site-nav .site-navigation .site-menu .has-children {
  position: relative;
}
.site-nav .site-navigation .site-menu .has-children>a {
  position: relative;
  padding-right: 20px;
}
.site-nav .site-navigation .site-menu .has-children>a:before {
  position: absolute;
  content: "\\eb3a";
  font-size: 14px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: unicons-line;
}
.site-nav .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  z-index: 999;
  position: absolute;
  text-align: left;
  -webkit-box-shadow: 0 2px 10px -2px var(--border);
  box-shadow: 0 2px 10px -2px var(--border);
  padding: 20px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: var(--white);
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  display: none;
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  display: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: var(--white);
  border-width: 10px;
  margin-left: -10px;
}
.site-nav .site-navigation .site-menu .has-children .dropdown a {
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: var(--black);
}
.site-nav .site-navigation .site-menu .has-children .dropdown .active {
  color: var(--primary) !important;
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 180px;
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li>a {
  padding: 5px 20px;
  display: block;
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li>a:hover {
  color: var(--primary);
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li.active>a {
  color: var(--primary);
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
  content: "\\e315";
  right: 20px;
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown, .site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
  left: 100%;
  top: 0;
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown li a:hover, .site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>ul li a:hover {
  color: var(--primary);
}
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a, .site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a, .site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
  color: var(--primary);
}
.site-nav .site-navigation .site-menu .has-children:hover>a, .site-nav .site-navigation .site-menu .has-children:focus>a, .site-nav .site-navigation .site-menu .has-children:active>a {
  color: var(--text-white);
}
.site-nav .site-navigation .site-menu .has-children:hover, .site-nav .site-navigation .site-menu .has-children:focus, .site-nav .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-nav .site-navigation .site-menu .has-children:hover>.dropdown, .site-nav .site-navigation .site-menu .has-children:focus>.dropdown, .site-nav .site-navigation .site-menu .has-children:active>.dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.site-mobile-menu-close {
  display: block;
  position: relative;
  height: 30px;
  width: 30px;
  z-index: 99;
  cursor: pointer;
  top: -20px;
}
.site-mobile-menu-close>span {
  cursor: pointer;
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
}
.site-mobile-menu-close>span:before, .site-mobile-menu-close>span:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 30px;
  background: var(--black);
}
.site-mobile-menu-close>span:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.site-mobile-menu-close>span:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: var(--white);
  height: calc(100vh);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
}
.offcanvas-menu .site-mobile-menu {
  -webkit-box-shadow: -10px 0 20px -10px var(--border);
  box-shadow: -10px 0 20px -10px var(--border);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  display: block;
}
.site-mobile-menu .site-nav-wrap a:not(.btn) {
  padding: 5px 20px;
  display: block;
  position: relative;
  color: var(--black);
}
.site-mobile-menu .site-nav-wrap a:not(.btn):hover {
  color: var(--primary);
}
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
.site-mobile-menu .site-nav-wrap li>a.active {
  color: var(--primary);
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--light);
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 14px;
  z-index: 20;
  font-family: unicons-line;
  content: "\\eb3a";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap>li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
@media (max-width: 767.98px) {
  .site-mobile-menu .site-nav-wrap>li>a:not(.btn) {
      font-size: 14px;
 }
}
.site-mobile-menu .site-nav-wrap>li>ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap>li>ul>li {
  display: block;
}
.site-mobile-menu .site-nav-wrap>li>ul>li>a {
  padding-left: 40px;
  font-size: 14px;
}
.site-mobile-menu .site-nav-wrap>li>ul>li>ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
  display: block;
}
.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
  font-size: 14px;
  padding-left: 60px;
}
.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.sticky-wrapper .site-navbar {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.sticky-wrapper .site-navbar .site-menu-toggle {
  color: var(--black);
}
.sticky-wrapper .site-navbar .site-logo a {
  color: var(--black);
}
.sticky-wrapper .site-navbar .site-menu>li>a {
  color: rgba(var(--black-rgb), 0.8) !important;
}
.sticky-wrapper .site-navbar .site-menu>li>a:hover, .sticky-wrapper .site-navbar .site-menu>li>a.active {
  color: var(--black) !important;
}
.sticky-wrapper.is-sticky .burger:before, .sticky-wrapper.is-sticky .burger span, .sticky-wrapper.is-sticky .burger:after {
  background: var(--black);
  -webkit-transition: .0s all ease;
  -o-transition: .0s all ease;
  transition: .0s all ease;
}
.sticky-wrapper.is-sticky .site-navbar {
  background: var(--black);
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: 4px 0 20px -5px var(--border);
  box-shadow: 4px 0 20px -5px var(--border);
}
.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: var(--black) !important;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu>li>a {
  color: var(--black) !important;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu>li>a:hover, .sticky-wrapper.is-sticky .site-navbar .site-menu>li>a.active {
  color: var(--primary) !important;
}
.sticky-wrapper.is-sticky .site-navbar .site-menu>li.active a {
  color: var(--primary) !important;
}
.sticky-wrapper .shrink {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.sticky-nav {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  background: rgba(255, 255, 255, .8);
  padding-top: 10px;
  padding-bottom: 10px;
}
#sticky-wrapper.is-sticky .sticky-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--white);
  -webkit-box-shadow: 4px 0 20px -5px var(--border);
  box-shadow: 4px 0 20px -5px var(--border);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
}
#sticky-wrapper.is-sticky .sticky-nav .burger:before, #sticky-wrapper.is-sticky .sticky-nav .burger span, #sticky-wrapper.is-sticky .sticky-nav .burger:after {
  background: var(--black);
  -webkit-transition: .0s all ease;
  -o-transition: .0s all ease;
  transition: .0s all ease;
}
#sticky-wrapper.is-sticky .sticky-nav .logo {
  color: var(--black) !important;
}
#sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a {
  color: var(--black) !important;
}
#sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a:hover, #sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a.active {
  color: var(--primary) !important;
}
#sticky-wrapper.is-sticky .sticky-nav .site-menu>li.active a {
  color: var(--primary) !important;
}
/* Burger */
.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
}
.burger.light:before, .burger.light span, .burger.light:after {
  background: var(--text-white);
}
.burger:before, .burger span, .burger:after {
  width: 100%;
  height: 2px;
  display: block;
  background: #000;
  border-radius: 2px;
  position: absolute;
  opacity: 1;
}
.burger:before, .burger:after {
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
}
.burger:before {
  top: 4px;
}
.burger span {
  top: 15px;
}
.burger:after {
  top: 26px;
}
/* Hover */
.burger:hover:before {
  top: 7px;
}
.burger:hover:after {
  top: 23px;
}
/* Click */
.burger.active span {
  opacity: 0;
}
.burger.active:before, .burger.active:after {
  top: 40%;
}
.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
 /*for IE*/
}
.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
 /*for IE*/
}
.burger:focus {
  outline: none;
}
.top-bar {
  padding-top: 10px;
  background: rgba(var(--black-rgb), .3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.docttorpay-hero, .bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.docttorpay-hero.overlay, .bg-img.overlay {
  position: relative;
}
.docttorpay-hero.overlay:before, .bg-img.overlay:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--primary-rgb), 0.85);
  z-index: -0;
}
.docttorpay-hero>.container, .bg-img>.container {
  position: relative;
}
.docttorpay-hero, .docttorpay-hero>.container>.row, .bg-img, .bg-img>.container>.row {
  min-height: 100vh;
}
.docttorpay-hero>.container>.row {
  padding-top: 170px;
}
.docttorpay-hero.inner-page, .docttorpay-hero.inner-page>.container>.row, .bg-img.inner-page, .bg-img.inner-page>.container>.row {
  height: 50vh;
  min-height: 450px;
}
@media (max-width: 767.98px) {
  .docttorpay-hero.inner-page, .docttorpay-hero.inner-page>.container>.row, .bg-img.inner-page, .bg-img.inner-page>.container>.row {
      min-height: 450px;
 }
}
.bg-img .caption {
  text-transform: uppercase;
  color: var(--text-white);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .1rem;
}
.docttorpay-hero .heading, .bg-img .heading {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.4;
}
@media (max-width: 991.98px) {
  .docttorpay-hero .heading, .bg-img .heading {
      font-size: 30px;
 }
}
.docttorpay-hero .link-highlight, .bg-img .link-highlight {
  color: var(--text-white);
  position: relative;
  display: inline;
  -webkit-box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.docttorpay-hero .link-highlight:hover, .bg-img .link-highlight:hover {
  -webkit-box-shadow: 0 2px 0 0 var(--white);
  box-shadow: 0 2px 0 0 var(--white);
}
.docttorpay-hero .desc, .bg-img .desc {
  font-size: 18px;
  max-width: 450px;
}
@media (max-width: 991.98px) {
  .docttorpay-hero .desc, .bg-img .desc {
      margin: 0 auto;
 }
}
.docttorpay-hero .cta-section, .docttorpay-hero .cta-section>.container>.row, .bg-img .cta-section, .bg-img .cta-section>.container>.row {
  height: 70vh;
  min-height: 450px;
}
.owl-banner .owl-item {
  height: 100vh;
}
.owl-banner .owl-item img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.owl-banner .owl-item .container {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767.98px) {
  .owl-banner .owl-item .container {
      top: initial;
      bottom: 10vh;
 }
}
.box {
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  padding: 1.25rem 1.25rem 0 1.25rem;
  max-width: 480px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.box:before {
  display: block;
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  z-index: -1;
}
.docttorpay-section {
  padding: 100px 0;
  position: relative;
}
@media (max-width: 991.98px) {
  .docttorpay-section {
      padding: 50px 0;
 }
}
.docttorpay-section .heading h3 {
  font-size: 35px;
  color: var(--black);
}
@media (max-width: 991.98px) {
  .docttorpay-section .heading {
      font-size: 20px;
 }
}
.docttorpay-section .heading strong {
  font-weight: 700;
}
.position-relative {
  position: relative;
}
.block-v1 {
  position: relative;
  z-index: 8;
  padding: 30px;
}
@media (max-width: 991.98px) {
  .block-v1 {
      margin-bottom: 70px;
 }
}
@media (max-width: 767.98px) {
  .block-v1 {
      margin-top: 0px;
      margin-bottom: 30px;
 }
}
.block-v1 [class^="icon-"] {
  font-size: 35px;
  color: var(--text-white);
  margin-bottom: 30px;
  display: block;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}
.block-v1 h3 {
  font-size: 20px;
  color: var(--text-white);
}
.block-v1 p {
  color: rgba(255, 255, 255, 0.7);
}
.block-v1 *:last-child {
  margin-bottom: 0;
}
.block-v1.color-1 {
  background-color: #5cb100;
}
.block-v1.color-2 {
  background-color: #ec661f;
}
.block-v1.color-3 {
  background-color: #4fb0dc;
}
.feature {
  margin-bottom: 40px;
  border: 1px solid rgba(var(--black-rgb), 0.05);
  background: var(--white);
  padding: 30px;
  border-radius: 4px;
  text-align: center;
}
.feature [class^="flaticon-"], .feature [class^="icon-"], .feature [class^="uil uil-"] {
  position: relative;
  margin-bottom: 30px;
  font-size: 30px;
  color: var(--primary);
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.feature [class^="flaticon-"]:after, .feature [class^="icon-"]:after, .feature [class^="uil uil-"]:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  border: 1px solid rgba(var(--primary-rgb), 0.2);
}
.feature [class^="flaticon-"].color-1, .feature [class^="icon-"].color-1, .feature [class^="uil uil-"].color-1 {
  background: rgba(92, 177, 0, 0.1);
  color: #5cb100;
}
.feature [class^="flaticon-"].color-1:after, .feature [class^="icon-"].color-1:after, .feature [class^="uil uil-"].color-1:after {
  border: 1px solid rgba(92, 177, 0, 0.2);
}
.feature [class^="flaticon-"].color-2, .feature [class^="icon-"].color-2, .feature [class^="uil uil-"].color-2 {
  background: rgba(236, 102, 31, 0.1);
  color: #ec661f;
}
.feature [class^="flaticon-"].color-2:after, .feature [class^="icon-"].color-2:after, .feature [class^="uil uil-"].color-2:after {
  border: 1px solid rgba(236, 102, 31, 0.2);
}
.feature [class^="flaticon-"].color-3, .feature [class^="icon-"].color-3, .feature [class^="uil uil-"].color-3 {
  background: rgba(79, 176, 220, 0.1);
  color: #4fb0dc;
}
.feature [class^="flaticon-"].color-3:after, .feature [class^="icon-"].color-3:after, .feature [class^="uil uil-"].color-3:after {
  border: 1px solid rgba(79, 176, 220, 0.2);
}
.feature [class^="flaticon-"].color-4, .feature [class^="icon-"].color-4, .feature [class^="uil uil-"].color-4 {
  background: rgba(107, 83, 171, 0.1);
  color: #6b53ab;
}
.feature [class^="flaticon-"].color-4:after, .feature [class^="icon-"].color-4:after, .feature [class^="uil uil-"].color-4:after {
  border: 1px solid rgba(107, 83, 171, 0.2);
}
.feature [class^="flaticon-"].color-5, .feature [class^="icon-"].color-5, .feature [class^="uil uil-"].color-5 {
  background: rgba(var(--primary-rgb), 0.1);
  color: var(--primary);
}
.feature [class^="flaticon-"].color-5:after, .feature [class^="icon-"].color-5:after, .feature [class^="uil uil-"].color-5:after {
  border: 1px solid rgba(var(--primary-rgb), 0.2);
}
.feature [class^="flaticon-"].color-6, .feature [class^="icon-"].color-6, .feature [class^="uil uil-"].color-6 {
  background: rgba(253, 152, 0, 0.1);
  color: #fd9800;
}
.feature [class^="flaticon-"].color-6:after, .feature [class^="icon-"].color-6:after, .feature [class^="uil uil-"].color-6:after {
  border: 1px solid rgba(253, 152, 0, 0.2);
}
.feature h3 {
  font-size: 18px;
  color: var(--primary);
}
.feature p {
  font-size: 14px;
  color: rgba(var(--black-rgb), 0.5) !important;
}
a.feature:hover {
  background: rgba(var(--primary-rgb), 0.05);
}
.docttorpay-hero a.feature:hover {
  background: rgba(255, 255, 255, 0.8);
}
.bg-1:before {
  content: "";
  position: absolute;
  height: 70%;
  right: -5vw;
  top: -40px;
  left: 50%;
  z-index: -1;
  background: rgba(var(--primary-rgb), 0.2);
}
@media (max-width:575.98px) {
  .bg-1:before {
      right: -15px;
 }
}
.bg-2:before {
  content: "";
  position: absolute;
  height: 40%;
  width: 70%;
  left: -50%;
  top: 0px;
  z-index: -1;
  background: rgba(236, 102, 31, 0.1);
}
@media (max-width: 991.98px) {
  .bg-2 {
      display: none;
 }
}
.line-bottom {
  position: relative;
  padding-bottom: 0px;
  color: var(--primary);
}
.line-bottom:before {
  display: none;
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background: #ec661f;
  bottom: 0;
}
.line-bottom.text-center:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.media-h {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 15px 30px -10px var(--border);
  box-shadow: 0 15px 30px -10px var(--border);
  background: var(--white);
}
.media-h figure {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 170px;
  flex: 0 0 170px;
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}
.media-h figure img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  height: 100%;
  margin-bottom: 0;
  width: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s transform ease;
  -o-transition: .3s transform ease;
  transition: .3s transform ease;
}
.media-h .media-h-body {
  padding: 20px;
}
.media-h .media-h-body h2 {
  font-size: 20px;
}
.media-h:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.meta {
  font-size: 14px;
  color: #ccc;
}
.video-play-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  border-radius: 50%;
  background: var(--primary);
  color: var(--text-white);
  display: inline-block;
}
.video-play-btn:before {
  width: 90px;
  height: 90px;
  border: 2px solid var(--primary);
  position: absolute;
  content: "";
  border-radius: 50%;
  left: -5px;
  top: -5px;
}
.video-play-btn span {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--text-white);
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}
.video-play-btn:hover span {
  color: var(--text-white);
}
.block-testimonial p {
  font-size: 18px;
  color: var(--black);
  line-height: 2;
}
.block-testimonial .author {
  display: block;
}
.block-testimonial .author img {
  margin: 0 auto;
  width: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
}
.block-testimonial .author h3 {
  font-size: 18px;
  color: var(--primary);
  margin-bottom: 0;
}
.block-testimonial .author .position {
  font-size: 14px;
}
.wide-slider-testimonial .owl-dots {
  position: absolute;
  display: inline-block;
  width: auto;
}
.accordion-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
}
.instagram-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-left: -8px;
}
.instagram-gallery li {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
  padding-left: 8px;
  padding-bottom: 8px;
}
.count-numbers .counter {
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: var(--primary);
}
.pricing {
  text-align: center;
  padding: 40px;
  border: 1px solid #efefef;
  border-radius: 4px;
}
.pricing .pricing-plan-title {
  color: var(--primary);
}
@media (max-width: 991.98px) {
  .pricing {
      padding: 40px 25px;
 }
}
.pricing .pricing-img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
.pricing .pricing-img:before {
  position: absolute;
  content: "";
  background: rgba(var(--primary-rgb), 0.2);
  z-index: -1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.pricing .pricing-img img {
  height: 90px;
}
.pricing .pricing-body .price {
  margin-bottom: 20px;
}
.pricing .pricing-body .price .fig {
  color: var(--primary);
  font-size: 30px;
}
.staff {
  padding: 20px;
}
.staff img {
  border-radius: 40px;
  max-width: 100%;
  width: 200px;
}
.staff h3 {
  font-size: 20px;
  color: var(--primary);
}
.staff .position {
  color: rgba(52, 58, 64, 0.6);
}
.custom-pagination li {
  display: inline-block;
}
.custom-pagination li a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
}
.custom-pagination li.active>a {
  background: var(--primary);
  color: var(--text-white);
  border-radius: 50%;
}
.custom-pagination li button {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
}
.custom-pagination li.active>button {
  background: var(--primary);
  color: var(--text-white);
  border-radius: 50%;
}
.contact-info i {
  font-size: 20px;
  float: left;
  width: 44px;
  height: 44px;
  background: var(--primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: var(--text-white);
}
.contact-info h4 {
  font-size: 18px;
  padding: 0 0 0 60px;
}
.contact-info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
}
.item {
  border: none;
  margin-bottom: 30px;
  border-radius: 4px;
  display: block;
}
.item a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: block;
}
.item a img {
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}
.item .item-wrap {
  display: block;
  position: relative;
}
.item .item-wrap:after {
  z-index: 2;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--black-rgb), 0.4);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}
.item .item-wrap>span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  color: var(--text-white);
  font-size: 1.7rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.item .item-wrap:hover:after {
  opacity: 1;
  visibility: visible;
}
.item .item-wrap:hover span {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.item:hover a img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}
.category {
  display: inline-block;
  padding: 20px;
  background: rgba(var(--primary-rgb), 0.05);
  border-radius: 4px;
  text-decoration: none !important;
}
.category, .category h3, .category span {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.category h3 {
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 1;
  color: #343a40;
}
.category span {
  font-size: 13px;
  color: rgba(52, 58, 64, 0.6);
}
.category .uil {
  text-align: center;
  display: inline-block;
  color: var(--secondary);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 20px;
  background: var(--white);
  font-size: 30px;
  line-height: 40px;
}
.category:hover, .category.active {
  background: var(--secondary);
}
.category:hover h3, .category:hover span, .category.active h3, .category.active span {
  color: var(--text-white);
}
.category:hover span, .category.active span {
  color: rgba(255, 255, 255, 0.7);
}
.section-title .heading {
  font-size: 30px;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .section-title .heading {
      font-size: 20px;
 }
}
.services-section {
  background: var(--light);
  padding: 70px 0;
}
.img-wrap-2 {
  margin-top: -120px;
  position: sticky;
  z-index: 9;
  top: 120px;
}
@media (max-width: 991.98px) {
  .img-wrap-2 {
      margin-top: 0;
      top: 0;
 }
}
.sticky {
  position: sticky;
  top: 120px;
}
.img-wrap-2 .dotted:before {
  position: absolute;
  content: "";
  background-image: url("../images/dotted.png");
  height: 273px;
  width: 313px;
  z-index: -1;
  bottom: -50px;
  left: -50px;
}
.custom-media {
  background: var(--white);
  -webkit-box-shadow: 0 15px 30px -5px var(--border);
  box-shadow: 0 15px 30px -5px var(--border);
}
.custom-media h3 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: var(--black);
}
.custom-media p {
  color: rgba(var(--black-rgb), 0.4);
}
.custom-media .review {
  color: #ffc107;
}
.custom-media .custom-media-body {
  padding: 30px;
}
.custom-media .custom-media-body .price {
  font-size: 22px;
  color: #343a40;
}
.form-box {
  background: var(--white);
  border-radius: var(--border-radius);
  -webkit-box-shadow: 0 15px 30px -5px var(--border);
  box-shadow: 0 15px 30px -5px var(--border);
}
.custom-block {
  margin-bottom: 30px;
}
.custom-block .section-title {
  font-size: 18px;
  font-weight: 700;
}
.custom-accordion .accordion-item {
  margin-bottom: 0px;
  position: relative;
  border-radius: 0px;
  overflow: hidden;
}
.custom-accordion .accordion-item .btn-link {
  display: block;
  width: 100%;
  padding: 15px 0;
  text-decoration: none;
  text-align: left;
  color: var(--black);
  background: transparent;
  border: none;
  padding-left: 40px;
  border-radius: 0;
  position: relative;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: normal !important;
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
}
.custom-accordion .accordion-item .btn-link:before {
  font-family: unicons-line;
  content: "\\e98c";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
}
.custom-accordion .accordion-item .btn-link[aria-expanded="true"] {
  font-weight: 700;
  color: var(--text-white) !important;
  background: var(--primary);
}
.custom-accordion .accordion-item .btn-link[aria-expanded="true"]:before {
  font-family: unicons-line;
  content: "\\e983";
  position: absolute;
  color: var(--text-white);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
}
.custom-accordion .accordion-item.active {
  z-index: 2;
}
.custom-accordion .accordion-item.active .btn-link {
  color: var(--black);
  -webkit-box-shadow: 0 1px 10px 0 var(--border);
  box-shadow: 0 1px 10px 0 var(--border);
}
.custom-accordion .accordion-item .accordion-body {
  padding: 20px 20px 20px 20px;
}
.custom-accordion .accordion-item .btn-link [class^="flaticon-"], .custom-accordion .accordion-item .btn-link [class^="icon-"], .custom-accordion .accordion-item .btn-link [class^="uil uil-"] {
  position: relative;
  font-size: 20px;
  color: var(--primary);
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.custom-accordion .accordion-item .btn-link [class^="flaticon-"]:after, .custom-accordion .accordion-item .btn-link [class^="icon-"]:after, .custom-accordion .accordion-item .btn-link [class^="uil uil-"]:after {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  border: 1px solid rgba(var(--primary-rgb), 0.2);
}
.custom-accordion .accordion-item.active .btn-link [class^="flaticon-"], .custom-accordion .accordion-item.active .btn-link [class^="icon-"], .custom-accordion .accordion-item.active .btn-link [class^="uil uil-"] {
  color: var(--text-white);
}
.custom-accordion .accordion-item.active .btn-link [class^="flaticon-"]:after, .custom-accordion .accordion-item.active .btn-link [class^="icon-"]:after, .custom-accordion .accordion-item.active .btn-link [class^="uil uil-"]:after {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}
.control .caption {
  position: relative;
  top: -3px;
  font-size: 16px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: var(--border);
  border-radius: 4px;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input~.control__indicator, .control input:focus~.control__indicator {
  background: var(--light);
}
.control input:focus~.control__indicator {
  box-shadow: 0 0 0 0.2rem var(--border);
}
.control input:checked~.control__indicator {
  background: var(--primary);
}
.control:hover input:not([disabled]):checked~.control__indicator, .control input:checked:focus~.control__indicator {
  background: var(--primary);
}
.control input:disabled~.control__indicator {
  background: var(--border);
  opacity: 0.9;
  pointer-events: none;
}
.control__indicator:after {
  font-family: unicons-line;
  content: '✓';
  position: absolute;
  display: none;
  font-size: 14px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.control input:checked~.control__indicator:after {
  display: block;
  color: var(--text-white);
}
.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}
.control--checkbox input:disabled:checked~.control__indicator {
  background-color: var(--primary);
  opacity: .2;
}
.gal-item {
  display: block;
  margin-bottom: 8px;
}
.gal-item img {
  border-radius: 4px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  opacity: 1;
}
.gal-item:hover img {
  opacity: .5;
}
.social-icons li {
  display: inline-block;
}
.social-icons li a {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: #303030;
}
.social-icons li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.social-icons li a:hover {
  color: var(--text-white);
}
.social-icons li:first-child a {
  padding-left: 0;
}
.social-icons.light li a {
  color: var(--black);
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: var(--light);
}
.video-wrap {
  position: relative;
  display: block;
}
.video-wrap .play-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: rgba(var(--black-rgb), 0.2);
  border-radius: 50%;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.video-wrap .play-wrap>span {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--text-white);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-wrap:hover .play-wrap {
  width: 60px;
  height: 60px;
}
.owl-single.dots-absolute .owl-dots, .owl-carousel.dots-absolute .owl-dots {
  bottom: 40px;
}
.owl-single.dots-absolute .owl-dots .owl-dot span, .owl-carousel.dots-absolute .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.7);
}
.owl-single .owl-dots, .owl-carousel .owl-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.owl-single .owl-dots .owl-dot, .owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  margin: 5px;
}
.owl-single .owl-dots .owl-dot span, .owl-carousel .owl-dots .owl-dot span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(var(--black-rgb), 0.2);
}
.owl-single .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot.active span {
  background: var(--primary);
}
.owl-single.no-dots .owl-dots, .owl-carousel.no-dots .owl-dots {
  display: none;
}
.owl-single.no-nav .owl-nav, .owl-carousel.no-nav .owl-nav {
  display: none;
}
.testimonial {
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}
.testimonial .name {
  font-size: 18px;
  color: var(--black);
}
.testimonial .img-wrap img {
  margin: 0 auto;
  width: 70px;
  border-radius: 50%;
}
.list-check li {
  display: block;
  padding-left: 30px;
  position: relative;
}
.list-check li:before {
  content: "\\e9c3";
  font-family: unicons-line;
  position: absolute;
  top: -.3rem;
  font-size: 20px;
  left: 0;
}
.owl-single .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .owl-single .owl-nav {
      display: none;
 }
}
.owl-single .owl-nav .owl-prev, .owl-single .owl-nav .owl-next {
  position: absolute;
  top: 0;
}
.owl-single .owl-nav .owl-prev span, .owl-single .owl-nav .owl-next span {
  display: block;
  background: var(var(--white));
  padding: 15px;
}
.owl-single .owl-nav .owl-prev {
  left: -30px;
}
.owl-single .owl-nav .owl-next {
  right: -30px;
}
.owl-single .owl-nav .owl-next>span {
  position: relative;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.ul-check li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.ul-check li:before {
  position: absolute;
  left: 0;
  top: -.2rem;
  content: "\\e9c3";
  font-size: 20px;
  font-family: unicons-line;
}
.ul-check.primary li:before {
  color: var(--primary);
}
.ul-check.white li:before {
  color: var(--text-white);
}
.section-title {
  color: var(--black);
  margin-bottom: 30px;
}
.site-footer {
  background: var(--dark);
  font-size: 14px;
  color: var(--text-white);
  padding: 70px 0;
}
.site-footer a {
  color: var(--text-white);
  position: relative;
  display: inline-block;
}
.site-footer .btn:before {
  display: none;
}
.site-footer .widget {
  margin-bottom: 40px;
  display: block;
  position: relative;
  z-index: 1;
}
.site-footer .widget h3 {
  font-size: 16px;
  color: var(--text-white);
  margin-bottom: 30px;
}
.site-footer .widget .links {
  width: 150px;
}
.site-footer .widget .links li {
  margin-bottom: 10px;
}
.site-footer .widget .links li a {
  color: rgba(255, 255, 255, 0.7);
}
.site-footer .widget .links li a:hover {
  color: var(--text-white);
}
.site-footer .social li {
  display: inline-block;
}
.site-footer .social li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  background-color: var(--white);
  border-radius: 50%;
  color: var(--black);
}
.site-footer .social li a:before {
  display: none;
}
.site-footer .social li a>span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-footer .social li a:hover {
  background: var(--primary-hover);
  color: var(--text-white);
}
.site-footer .copyright {
  color: rgba(255, 255, 255, 0.7);
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-group>* {
  margin: 0.5rem 0.5rem;
}
.checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input:checked+.checkbox-tile {
  border-color: var(--primary);
  box-shadow: 0 5px 10px var(--border);
  color: var(--primary);
}
.checkbox-input:checked+.checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: var(--primary);
  border-color: var(--primary);
}
.checkbox-input:checked+.checkbox-tile .checkbox-icon, .checkbox-input:checked+.checkbox-tile .checkbox-label {
  color: var(--primary);
}
.checkbox-input:focus+.checkbox-tile {
  border-color: var(--primary);
  box-shadow: 0 5px 10px var(--border), 0 0 0 4px var(--border);
}
.checkbox-input:focus+.checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 7rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid var(--border);
  background-color: var(--white);
  box-shadow: 0 5px 10px var(--border);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  outline: none;
  color: var(--text);
}
.checkbox-group .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--border);
  background-color: var(--white);
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.checkbox-group.icon-right .checkbox-tile:before {
  right: 0.25rem;
  left: initial;
}
.checkbox-tile:focus, .checkbox-tile:hover {
  border-color: var(--primary);
}
.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-icon {
  transition: 0.375s ease;
  color: var(--black);
  font-size: 40px;
}
.checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}
.checkbox-label {
  transition: 0.375s ease;
  text-align: center;
}
input.validation {
  display: block;
  border: none;
  padding: 0 !important;
  width: 270px;
  background: repeating-linear-gradient(90deg, var(--border) 0, var(--border) 30px, transparent 0, transparent 45px) 0 100%/ 255px 2px no-repeat;
  font-size: 50px;
  font-family: 'Verdana', monospace;
  letter-spacing: 12.8px;
  outline: none;
}
input.validation:focus {
  background: repeating-linear-gradient(90deg, var(--primary) 0, var(--primary) 30px, transparent 0, transparent 45px) 0 100%/ 255px 2px no-repeat;
}
body.dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
}
body.dashboard .content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
body.dashboard .content>.bg-white {
  margin-top: -3rem;
  padding-top: 3rem;
  margin-bottom: 3rem;
}
body.dashboard .content>.bg-white .nav-tabs, body.dashboard .content>.bg-white .nav-tabs .nav-link {
  border-bottom: 0;
}
body.dashboard .content>.bg-white .nav-tabs .nav-item {
  margin-bottom: 0;
}
body.dashboard .content>.bg-white .nav-tabs .nav-item.show .nav-link, body.dashboard .content>.bg-white .nav-tabs .nav-link.active {
  background-color: var(--light);
}
body.dashboard .content .row.align-items-center {
  flex: 1 0 auto;
}
body.dashboard .site-footer {
  padding-top: 1rem;
  padding-bottom: 0;
  flex-shrink: 0;
}
body.dashboard .site-nav {
  border-top: none;
  position: relative;
}
body.dashboard .site-nav .sticky-nav {
  background: var(--primary);
}
body.dashboard .site-nav .site-navigation .site-menu>li>a {
  color: var(--white);
}
body.dashboard .site-nav .site-navigation .site-menu>li>a:hover {
  opacity: .8;
}
body.dashboard .site-nav .site-navigation .site-menu>li.active:before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: -18px;
  border-bottom: 5px solid var(--white);
  opacity: .5;
}
body.dashboard .site-nav .site-navigation .site-menu>li.active>a {
  color: var(--white);
}
body.dashboard .logo {
  background: var(--white);
  padding: 10px 0;
  top: -10px;
  margin-bottom: -30px !important;
  display: block;
  box-shadow: rgba(var(--black-rgb), 0.16) 0px 3px 6px, rgba(var(--black-rgb), 0.03) 0px 3px 6px;
}
body.dashboard .logo:hover {
  background: var(--light);
  padding-top: 12px;
  margin-bottom: -32px !important;
  box-shadow: rgba(var(--black-rgb), 0.16) 0px 3px 6px, rgba(var(--black-rgb), 0.03) 0px 3px 6px;
}
@media(max-width: 540px) {
  body.dashboard .logo {
      left: 0;
 }
}
body.dashboard .logo.logo-center {
  left: 50%;
  transform: translateX(-50%);
}
body.dashboard .burger:before, body.dashboard .burger span, body.dashboard .burger:after {
  background: var(--white);
}
.row-item {
  position: relative;
  -webkit-box-shadow: 0 15px 30px -10px var(--border);
  box-shadow: 0 15px 30px -10px var(--border);
  background: var(--white);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  border-radius: var(--border-radius);
}
.row-item:not(.no-hover):hover {
  transform: scale(1.01);
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px 0px var(--border);
  box-shadow: 0 15px 30px 0px var(--border);
}
.badge {
  vertical-align: super;
}
.badge-primary {
  background-color: var(--primary);
}
/** BOOTSTRAP SELECT **/
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  text-overflow: ellipsis;
}
.bootstrap-select .dropdown-toggle .filter-option {
  line-height: 34px;
}
.bootstrap-select .dropdown-toggle.form-control-sm .filter-option {
  line-height: 28px;
}
.dropdown .dropdown-toggle.no-arrow:after{
  display:none;
}
/** custom file **/
.custom-file, .custom-file-input, .custom-file-label{
  height: 48px;
  line-height: 48px;
  cursor: pointer;
}
.custom-file-label {
  border: 1px solid var(--border);
  padding: 0 .75rem;
  background-color: transparent;
  border-radius: var(--border-radius);
  margin: 0;
}
.custom-file-label::after {
  height: 46px;
  content:"Selecionar";
  padding: 0 .75rem;
  line-height: 46px;
  border-radius: 0 calc(var(--border-radius) - 1px) calc(var(--border-radius) - 1px) 0;
}
.custom-file-input:focus~.custom-file-label, .custom-file-input.has-focus~.custom-file-label {
  outline: none;
  border-color: var(--primary);
  box-shadow: none;
}
.error-custom-file-input~.custom-file-label {
  border-color: var(--danger);
}
/**OPACITY**/
.opacity-100 {
  opacity: 1;
}
.opacity-75 {
  opacity: .75;
}
.opacity-50 {
  opacity: .5;
}
.opacity-25 {
  opacity: .25;
}
.opacity-0 {
  opacity: 0;
}
.fc-toolbar.fc-header-toolbar {
  background-color: var(--white);
  margin-top: -3rem;
  margin-bottom: 3rem;
  margin-left: calc(-50vw + 800px - 5vw - 15px);
  margin-right: calc(-50vw + 800px - 5vw - 15px);
  padding-left: calc(50vw - 800px + 5vw + 15px);
  padding-right: calc(50vw - 800px + 5vw + 15px);
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}
@media(max-width: 1600px) {
  .fc-toolbar.fc-header-toolbar {
      margin-left: calc(-5vw - 15px);
      margin-right: calc(-5vw - 15px);
      padding-left: calc(5vw + 15px);
      padding-right: calc(5vw + 15px);
 }
}
@media(max-width: 992px) {
  .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
 }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
      order: 2;
      flex: 0 0 auto;
      padding-top: 1rem;
 }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
      order: 1;
      flex: 0 0 100%;
      text-align: center;
 }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
      order: 3;
      flex: 0 0 auto;
      padding-top: 1rem;
 }
}
@media(max-width: 540px) {
  .fc-toolbar.fc-header-toolbar {
      justify-content: center;
      flex-direction: column;
 }
}
.btn-icon {
  line-height: 1;
  display: inline-block;
}
.btn-icon .uil, .btn-icon i {
  line-height: 1;
}
.btn-close{
  background:none;
}
.btn-close:before {
  content: '\\e9b8';
  font-family: unicons-line;
  font-size:32px;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: -0.5rem;
}
.gu-mirror {
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80) 
}
.gu-transit {
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20) 
}
.kanban-parent {
  position: relative;
  width: auto;
  overflow: auto;
  padding: 0 0 3rem 5vw;
  height: calc(70vh - 15px);
  margin-bottom: -2rem;
}
.kanban-container {
  display: flex;
  width: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
.kanban-container>div:last-child {
  padding-right: 5vw;
}
.kanban-board {
  position: relative;
  background: var(--border);
  transition: all .3s cubic-bezier(.23, 1, .32, 1);
  border-radius: .5rem;
  width: 25%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}
.kanban-board.disabled-board {
  opacity: .3 
}
.kanban-board.is-moving.gu-mirror {
  transform: rotate(3deg) 
}
.kanban-board.is-moving.gu-mirror .kanban-drag {
  overflow: hidden;
  padding-right: 50px 
}
.kanban-board-header {
  padding: 1rem;
  background: var(--border);
}
.kanban-board .drag-handle {
  cursor: move;
}
.kanban-board .kanban-drag {
  min-height: 200px;
  padding: 1rem;
  height: 100%;
}
.kanban-item {
  background: var(--white);
  padding: 1rem;
  margin-bottom: .75rem;
  transition: all .3s cubic-bezier(.23, 1, .32, 1) 
}
.kanban-item img.w-100 {
  border-radius: .5rem 
}
.kanban-item:hover {
  cursor: move 
}
.kanban-item:last-child {
  margin: 0 
}
.kanban-item.is-moving.gu-mirror {
  transform: rotate(3deg);
  height: auto !important 
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important 
}
.gu-hide {
  display: none !important 
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important 
}
.gu-transit {
  box-shadow: inset 0 0 2px #fefefed1;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, .8) !important;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, .9), 0 20px 27px 0 rgba(var(--black-rgb), .05) !important 
}
.drag_handler {
  background: var(--white);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 4px 
}
.drag_handler:hover {
  cursor: move 
}
.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: .5s ease-in-out 
}
.drag_handler_icon:after, .drag_handler_icon:before {
  background: #000;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s ease-in-out 
}
.drag_handler_icon:before {
  top: 6px 
}
.drag_handler_icon:after {
  bottom: 6px 
}
.avatar {
  color: var(--white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 50rem;
  height: 48px;
  width: 48px;
  transition: all .2s ease-in-out 
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar+.avatar-content {
  display: inline-block;
  margin-left: .75rem 
}
.avatar.avatar-raised {
  margin-top: -24px 
}
.active .avatar.avatar-scale-up, .avatar.avatar-scale-up:hover {
  transform: scale(1.2) 
}
.avatar-xxl {
  width: 110px !important;
  height: 110px !important 
}
.avatar-xxl.avatar-raised {
  margin-top: -55px 
}
.avatar-xl {
  width: 74px !important;
  height: 74px !important 
}
.avatar-xl.avatar-raised {
  margin-top: -37px 
}
.avatar-lg {
  width: 58px !important;
  height: 58px !important;
  font-size: .875rem 
}
.avatar-lg.avatar-raised {
  margin-top: -29px 
}
.avatar-sm {
  width: 36px !important;
  height: 36px !important;
  font-size: .875rem 
}
.avatar-sm.avatar-raised {
  margin-top: -18px 
}
.avatar-xs {
  width: 24px !important;
  height: 24px !important;
  font-size: .75rem 
}
.avatar-xs.avatar-raised {
  margin-top: -12px 
}
.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid var(--white) 
}
.avatar-group .avatar:hover {
  z-index: 3 
}
.avatar-group .avatar+.avatar {
  margin-left: -1rem 
}
.avatar.rounded-circle img {
  border-radius: 50% !important;
}
/* toogle */
.switch {
  font-size: 14px;
}
.switch-toggle {
  width: 100px;
  border-radius: 30px;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  background: rgba(255,255,255,.3);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.switch-toggle.sm {
  width: 60px;
}
.switch-toggle:before {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  content: "";
  position: absolute;
  left: 1px;
  height: 28px;
  width: 28px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  background: var(--white);
  -webkit-box-shadow: 0 5px 10px 0 rgba(var(--black-rgb), 0.1);
  box-shadow: 0 5px 10px 0 rgba(var(--black-rgb), 0.1);
}
.switch-toggle.active:before {
  left: calc(100% - 28px);
}
.switch-toggle.primary {
  background: var(--border);
}
.switch-toggle.primary:before {
  background: var(--primary);
}
/* ion range slider */
.irs--round {
  height: 50px 
}
.irs--round.irs-with-grid {
  height: 65px 
}
.irs--round .irs-line {
  top: 36px;
  height: 8px;
  background-color: #dee4ec;
  border-radius: 4px;
  border:1px solid var(--border);
}
.irs--round .irs-bar {
  top: 36px;
  height: 8px;
  background-color: var(--primary) 
}
.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px 
}
.irs--round .irs-shadow {
  height: 8px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5) 
}
.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid var(--primary);
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3) 
}
.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
  background-color: #f0f6ff 
}
.irs--round .irs-min, .irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(var(--black-rgb), 0.1);
  border-radius: 4px 
}
.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 4px 
}
.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--black) 
}
.irs--round .irs-grid {
  height: 25px 
}
.irs--round .irs-grid-pol {
  background-color: #dedede 
}
.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px 
}
/* recibo */
.zig-zag-top {
  background-repeat: repeat-x;
  content: " ";
  display: block;
  height: 32px;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  background: linear-gradient(-45deg, var(--white) 16px, red 16px, blue 16px, transparent 0), linear-gradient(45deg, var(--white) 16px, transparent 0);
  background-size: 22px 32px;
  background-position: left top;
  top: -30px;
}
.zig-zag-bottom {
  background-repeat: repeat-x;
  display: block;
  height: 32px;
  z-index: 0;
  background: linear-gradient(-135deg, var(--white) 16px, red 16px, blue 16px, transparent 0), linear-gradient(135deg, var(--white) 16px, transparent 0);
  background-position: left bottom;
  background-size: 22px 32px;
}
.cep-loading{
  margin-top:0;
  position: absolute;
  right: 1rem;
  bottom: .5rem;
  background-color: var(--white);
}
/* Spin Animation Start */
.animate.spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
.animate.spin-slow {
  -moz-animation: spin 4s infinite linear;
  -o-animation: spin 4s infinite linear;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
  display: inline-block;
}
.animate.spin-fast {
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  display: inline-block;
}
@keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
 }
  100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
 }
}
@-moz-keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
 }
  100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
 }
}
@-o-keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
 }
  100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
 }
}
@-webkit-keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
 }
  100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
 }
}
@-ms-keyframes spin {
  0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
 }
  100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
 }
}
/* Spin Animation End */
/* Pulse Animation Start */
.animate.pulse {
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
  display: inline-block;
}
.animate.pulse-slow {
  -moz-animation: pulse 4s infinite linear;
  -o-animation: pulse 4s infinite linear;
  -webkit-animation: pulse 4s infinite linear;
  animation: pulse 4s infinite linear;
  display: inline-block;
}
.animate.pulse-fast {
  -moz-animation: pulse 1s infinite linear;
  -o-animation: pulse 1s infinite linear;
  -webkit-animation: pulse 1s infinite linear;
  animation: pulse 1s infinite linear;
  display: inline-block;
}
@keyframes pulse {
  50% {
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
 }
}
@-moz-keyframes pulse {
  50% {
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
 }
}
@-o-keyframes pulse {
  50% {
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
 }
}
@-webkit-keyframes pulse {
  50% {
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
 }
}
@-ms-keyframes pulse {
  50% {
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
 }
}
/* Pulse Animation End */
/* Vibrate Animation Start */
.animate.vibrate {
  -moz-animation: vibrate 0.1s infinite linear;
  -o-animation: vibrate 0.1s infinite linear;
  -webkit-animation: vibrate 0.1s infinite linear;
  animation: vibrate 0.1s infinite linear;
  display: inline-block;
}
.animate.vibrate-slow {
  -moz-animation: vibrate 0.2s infinite linear;
  -o-animation: vibrate 0.2s infinite linear;
  -webkit-animation: vibrate 0.2s infinite linear;
  animation: vibrate 0.2s infinite linear;
  display: inline-block;
}
.animate.vibrate-fast {
  -moz-animation: vibrate 0.07s infinite linear;
  -o-animation: vibrate 0.07s infinite linear;
  -webkit-animation: vibrate 0.07s infinite linear;
  animation: vibrate 0.07s infinite linear;
  display: inline-block;
}
@keyframes vibrate {
  25% {
      -moz-transform: translate(-2px);
      -o-transform: translate(-2px);
      -webkit-transform: translate(-2px);
      transform: translate(-2px);
 }
  75% {
      -moz-transform: translate(2px);
      -o-transform: translate(2px);
      -webkit-transform: translate(2px);
      transform: translate(2px);
 }
}
@-moz-keyframes vibrate {
  25% {
      -moz-transform: translate(-2px);
      -o-transform: translate(-2px);
      -webkit-transform: translate(-2px);
      transform: translate(-2px);
 }
  75% {
      -moz-transform: translate(2px);
      -o-transform: translate(2px);
      -webkit-transform: translate(2px);
      transform: translate(2px);
 }
}
@-o-keyframes vibrate {
  25% {
      -moz-transform: translate(-2px);
      -o-transform: translate(-2px);
      -webkit-transform: translate(-2px);
      transform: translate(-2px);
 }
  75% {
      -moz-transform: translate(2px);
      -o-transform: translate(2px);
      -webkit-transform: translate(2px);
      transform: translate(2px);
 }
}
@-webkit-keyframes vibrate {
  25% {
      -moz-transform: translate(-2px);
      -o-transform: translate(-2px);
      -webkit-transform: translate(-2px);
      transform: translate(-2px);
 }
  75% {
      -moz-transform: translate(2px);
      -o-transform: translate(2px);
      -webkit-transform: translate(2px);
      transform: translate(2px);
 }
}
@-ms-keyframes vibrate {
  25% {
      -moz-transform: translate(-2px);
      -o-transform: translate(-2px);
      -webkit-transform: translate(-2px);
      transform: translate(-2px);
 }
  75% {
      -moz-transform: translate(2px);
      -o-transform: translate(2px);
      -webkit-transform: translate(2px);
      transform: translate(2px);
 }
}
/* Vibrate Animation End */
/* Blink Smooth Animation Start */
.animate.blink-smooth {
  -moz-animation: blink-s 0.8s infinite linear;
  -o-animation: blink-s 0.8s infinite linear;
  -webkit-animation: blink-s 0.8s infinite linear;
  animation: blink-s 0.8s infinite linear;
  display: inline-block;
}
.animate.blink-smooth-slow {
  -moz-animation: blink-s 1.2s infinite linear;
  -o-animation: blink-s 1.2s infinite linear;
  -webkit-animation: blink-s 1.2s infinite linear;
  animation: blink-s 1.2s infinite linear;
  display: inline-block;
}
.animate.blink-smooth-fast {
  -moz-animation: blink-s 0.4s infinite linear;
  -o-animation: blink-s 0.4s infinite linear;
  -webkit-animation: blink-s 0.4s infinite linear;
  animation: blink-s 0.4s infinite linear;
  display: inline-block;
}
@keyframes blink-s {
  50% {
      opacity: 0;
      filter: alpha(opacity=100);
 }
}
@-moz-keyframes blink-s {
  50% {
      opacity: 0;
      filter: alpha(opacity=100);
 }
}
@-o-keyframes blink-s {
  50% {
      opacity: 0;
      filter: alpha(opacity=100);
 }
}
@-webkit-keyframes blink-s {
  50% {
      opacity: 0;
      filter: alpha(opacity=100);
 }
}
@-ms-keyframes blink-s {
  50% {
      opacity: 0;
      filter: alpha(opacity=100);
 }
}
/* Blink Smooth Animation End */
/* Blink Animation Start */
.animate.blink {
  -moz-animation: blink 0.8s infinite linear;
  -o-animation: blink 0.8s infinite linear;
  -webkit-animation: blink 0.8s infinite linear;
  animation: blink 0.8s infinite linear;
  display: inline-block;
}
.animate.blink-slow {
  -moz-animation: blink 1.2s infinite linear;
  -o-animation: blink 1.2s infinite linear;
  -webkit-animation: blink 1.2s infinite linear;
  animation: blink 1.2s infinite linear;
  display: inline-block;
}
.animate.blink-fast {
  -moz-animation: blink 0.4s infinite linear;
  -o-animation: blink 0.4s infinite linear;
  -webkit-animation: blink 0.4s infinite linear;
  animation: blink 0.4s infinite linear;
  display: inline-block;
}
@keyframes blink {
  50%, 100% {
      visibility: hidden;
 }
}
@-moz-keyframes blink {
  50%, 100% {
      visibility: hidden;
 }
}
@-o-keyframes blink {
  50%, 100% {
      visibility: hidden;
 }
}
@-webkit-keyframes blink {
  50%, 100% {
      visibility: hidden;
 }
}
@-ms-keyframes blink {
  50%, 100% {
      visibility: hidden;
 }
}
/* Blink Animation End */
.card-wrapper{
  min-height: 200px;
}
input.invalid-feedback, input.valid-feedback{
  display: block;
  margin-top: initial;
}
::-webkit-input-placeholder {
 /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
 /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
 /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
 /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
 /* Recent browsers */
  text-transform: none;
}
/* LINE -HEIGHT */
.lh-1 {
  line-height: 1!important;
}
.lh-sm {
  line-height: 1.25!important;
}
.lh-base {
  line-height: 1.5!important;
}
.lh-lg {
  line-height: 2!important;
}


.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text);
  background-color: var(--white);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
      transition: none;
 }
}
.form-select:focus {
  border-color: var(--primary);
  outline: 0;
 /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  */
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group > .form-control, .input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3;
}
.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}
.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
  z-index: 3;
}
.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}
.rounded-search-input {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-search-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}
.z-index-1 {
  z-index: 1 !important;
}
.link-primary {
  color: var(--primary);
  cursor: pointer;
}
.link-primary:hover, .link-primary:focus {
  color: var(--primary-hover);
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
      opacity: 0.2;
 }
}
@keyframes placeholder-glow {
  50% {
      opacity: 0.2;
 }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}
@-webkit-keyframes placeholder-wave {
  100% {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
 }
}
@keyframes placeholder-wave {
  100% {
      -webkit-mask-position: -200% 0%;
      mask-position: -200% 0%;
 }
}
.react-switch-selector-option-label {
  margin-bottom: 0;
}
/**CHAT**/
.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2304773B'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.nav-tabs.chat-nav{
  border-bottom:none;
}
.nav-tabs.chat-nav .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: var(--light);
  border-color: var(--border) var(--border) var(--light);
}
.box-header .nav-tabs, .box-header .nav-tabs .nav-link{
  border-bottom: 0;
}
.box-header .nav-tabs .nav-item{
  margin-bottom: 0;
}
.box-header .nav-tabs .nav-item.show .nav-link, .box-header .nav-tabs .nav-link.active{
  background-color: var(--light) ;
}
.user-image{
  border-radius: 50px;
  text-align: center;
  color: #FFF;
  background: var(--dark);
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  top: 0;
}
.user-image img{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  object-fit: cover;
}
.chat{
  max-height: calc(100vh - 400px);
  overflow-x: hidden;
  overflow-y: auto;
}
.chat .message{
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
}
.chat .message .content{
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
}
/* react */
select .react-select-uppercase div[class$="-singleValue"]{
  text-transform: uppercase;
}
.zig-zag-bottom {
  background-repeat: repeat-x;
  display: block;
  height: 32px;
  z-index: 0;
  background: linear-gradient(-135deg, #FFF 16px, red 16px, blue 16px, transparent 0), linear-gradient(135deg, #FFF 16px, transparent 0);
  background-position: left bottom;
  background-size: 22px 32px;
}
/* FullCalendar */
.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}
.fc .fc-button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .05rem;
  &:focus {
      outline: 0;
 }
}
.fc .fc-button-primary {
  background-color: var(--white);
  border-color: var(--primary);
  color: var(--primary);
  &:hover {
      background-color: var(--primary);
      color: var(--text-white);
      border-color: var(--primary) 
 }
}
.fc .fc-button .fc-icon {
  font-size: unset;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--primary);
  color: var(--text-white);
  border-color: var(--primary);
}
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--primary);
  color: var(--text-white);
  border-color: var(--primary);
}
.fc .fc-button-primary:disabled {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.fc .fc-view-harness {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 15px 30px -5px var(--border);
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
  padding: .5rem 
}
@media (max-width:575.98px){
  .fc .fc-view-harness {
      max-width: calc(100vw - 30px);
 }
}
@media (min-width:1780px){
  .fc .fc-view-harness {
      max-width: 1600px;
 }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  cursor: pointer;
}
.react-switch-selector-wrapper {
  z-index: 0;
}
.audit-logs-list{
  max-height: calc(100vh - 400px);
  overflow-x: hidden;
  overflow-y: auto;
}
.text-black-60 {
  color: rgba(0,0,0,0.6);
}
.compensation-value b {
  visibility: hidden;
}
.compensation-value:hover b {
  visibility: visible;
}

.span-btn:hover {
  cursor: pointer
}

.span-btn {
  color: var(--primary);
}

@media print {
  .print-col {
    flex: 1
  }
}

/*swal2*/
.swal2-popup{
  background-color: var(--white);
  border: 1px solid rgba(var(--black-rgb), 0.2);
  color:var(--text);
}
.swal2-popup.swal2-toast {
  background-color: var(--white);
}
.swal2-checkbox, .swal2-radio{
  background-color: var(--white);
  color:var(--text);
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0,0,0, 0.5);
}
.swal2-progress-steps .swal2-progress-step{
  color:var(--white);
}


/**DARK**/
body.dark{
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #1a1c1a;
  --black: #fff;
  --light: #242725;
  --dark: #d5d5d5;
  --gray: #818181;
  --gray-dark: #cecece;
  --primary: #34e98b;
  --primary-hover: #2bcf7a;
  --primary-rgb: 43,207,122;
  --primary-light: #3c8f64;
  --secondary: #6c757d;
  --secondary-hover: #595f64;
  --secondary-rgb: 108, 17, 125;
  --success: var(--green);
  --info: var(--cyan);
  --warning: var(--yellow);
  --danger: var(--red);
  --danger-light: #81222b;
  --border: #3d3d3d;
  --border-radius: 7px;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --text: #dbdbdb;
  --text-white: #FFFFFF;
  --white-rgb: 7, 8, 7;
  --black-rgb: 255,255,255;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;

  --fc-page-bg-color: var(--light)!important;

  /* MUI */
  --neutral-0: var(--white);
  --neutral-5: var(--light);
  --neutral-10: var(--light);
  --neutral-20: var(--border);
  --neutral-30: var(--border);
  --neutral-40: var(--secondary);
  --neutral-50: var(--secondary-hover);
  --neutral-60: var(--gray);
  --neutral-70: var(--gray-dark);
  --neutral-80: var(--dark);
  --neutral-90: var(--black);
  --primary-25: var(--primary-light);
  --primary-50: var(--primary-hover);
  --primary-75: var(--primary-hover);
}
body.dark.dashboard .logo{
  background-color: var(--light);
  box-shadow: rgba(var(--white-rgb), 0.08) 0px 3px 6px, rgba(var(--white-rgb), 0.5) 0px -3px 16px;
}
body.dark.dashboard .logo:hover {
  background: var(--border);
  box-shadow: rgba(var(--white-rgb), 0.16) 0px 3px 6px, rgba(var(--white-rgb), 0.23) 0px 3px 6px;
}
body.dark .site-footer{
  background-color: var(--white);
}
body.dark .dark-mode .uil-moon:before {
  content: '\\e9cc';
}


body.dark .alert-primary {
  color: inherit;
  background-color: #002752;
  border-color: #004085;
}
body.dark .alert-primary hr {
  border-top-color: #004085;
}
body.dark .alert-secondary {
  color: inherit;
  background-color: #202326;
  border-color: #383d41;
}
body.dark .alert-secondary hr {
  border-top-color: #383d41;
}
body.dark .alert-success {
  color: inherit;
  background-color: #0b2e13;
  border-color: #155724;
}
body.dark .alert-success hr {
  border-top-color: #155724;
}
body.dark .alert-info {
  color: inherit;
  background-color: #062c33;
  border-color: #0c5460;
}
body.dark .alert-info hr {
  border-top-color: #0c5460;
}
body.dark .alert-warning {
  color: inherit;
  background-color: #533f03;
  border-color: #856404;
}
body.dark .alert-warning hr {
  border-top-color: #856404;
}
body.dark .alert-danger {
  color: inherit;
  background-color: #491217;
  border-color: #721c24;
}
body.dark .alert-danger hr {
  border-top-color: #721c24;
}
body.dark .alert-light {
  color: inherit;
  background-color: #686868;
  border-color: #818182;
}
body.dark .alert-light hr {
  border-top-color: #818182;
}
body.dark .alert-dark {
  color: inherit;
  background-color: #1b1e21;
  border-color: #040505;
}
body.dark .alert-dark hr {
  border-top-color: #040505;
}
body.dark .alert-primary .alert-link,
body.dark .alert-secondary .alert-link,
body.dark .alert-success .alert-link,
body.dark .alert-info .alert-link,
body.dark .alert-warning .alert-link,
body.dark .alert-danger .alert-link,
body.dark .alert-light .alert-link,
body.dark .alert-dark .alert-link  {
  color: inherit;
}
`;

export default GlobalStyle;
