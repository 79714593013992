import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import ModalComponent from "components/ModalComponent";
import { useTeam } from "providers/Team";

import TeamMemberCard from "../TeamMemberCard";
import TeamMemberForm from "../TeamMemberForm";

const TeamMemberList = () => {
  const teamMembersRef = useRef<HTMLDivElement>(null);
  const [modalShow, setModalShow] = useState(false);

  const {
    teamMembers,
    teamMembersTotal,
    teamMembersTotalPages,
    isLoadingTeamMembers,
    getTeamMembers,
  } = useTeam();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getTeamMembers(value);

    teamMembersRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  return (
    <>
      {/* Membros da Equipe */}
      <div ref={teamMembersRef} className="row mb-4">
        <div className="col">
          <h5>
            Equipe
            <Badge bg="primary ml-1 text-white">{teamMembersTotal}</Badge>
          </h5>
        </div>
        <div className="col col-auto">
          <Button
            type="button"
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}
          >
            <i className="uil uil-plus mr-2" />
            Adicionar membro
          </Button>
        </div>
      </div>

      {!!isLoadingTeamMembers &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de membros da equipe */}
      {!isLoadingTeamMembers && (
        <>
          {!!teamMembers.length &&
            teamMembers?.map((member) => (
              <TeamMemberCard key={member.id} teamMember={member} />
            ))}

          {/* Lista de contas bancárias vazia */}
          {!teamMembers.length && (
            <EmptyList
              icon="uil-users-alt"
              title="Nenhum membro de equipe cadastrado"
              subtitle="Clique abaixo e adicione um membro à sua equipe"
              buttonTitle="Adicionar membro"
              onClickButton={() => setModalShow(true)}
            />
          )}
        </>
      )}
      {!!teamMembers.length && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={teamMembersTotalPages}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}

      {/* Modal de adicionar membro de equipe */}
      <ModalComponent
        title="Adicionar Membro à Equipe"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
      >
        <TeamMemberForm onHide={() => setModalShow(false)} />
      </ModalComponent>
    </>
  );
};

export default TeamMemberList;
