import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import { createContext, ReactNode, useContext, useState } from "react";
import { NavigateFunction } from "react-router-dom";

import { api } from "services";
import { ContractData, UserLoginData } from "types/auth";
import { ContractStatus, RoleDescription } from "types/enums";
import { sessStorage } from "utils/storage";
import { toast } from "utils/toast";

import { API_URL } from "../../config";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthProviderData {
  token: string;
  userId: string;
  profileId: string;
  userRole: string;
  userContract: string;
  hasTerms: boolean;
  newPasswordRequired: boolean;
  isLoading: boolean;
  login: (data: UserLoginData, navigate: NavigateFunction) => void;
  logout: () => Promise<void>;
  not_used_contract: (data: ContractData) => Promise<void>;
  refreshToken: (id: string) => Promise<void>;
  setHasTerms: React.Dispatch<React.SetStateAction<boolean>>;
  setNewPasswordRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

interface JwtDecoded {
  sid: string;
  nome: string;
  role: string;
  contract: string;
  exp: number;
  iss: string;
  aud: string;
}

export const AuthContext = createContext<AuthProviderData>(
  {} as AuthProviderData
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState<string>(sessStorage.getToken() || "");

  const [userId, setUserId] = useState<string>(sessStorage.getUserId() || "");

  const [profileId, setProfileId] = useState<string>(
    sessStorage.getProfileId() || ""
  );

  const [userRole, setUserRole] = useState<string>(() => {
    if (token) {
      const decoded: JwtDecoded = jwt_decode(token);
      return decoded.role;
    }
    return "";
  });

  const [userContract, setUserContract] = useState<string>(() => {
    if (token) {
      const decoded: JwtDecoded = jwt_decode(token);
      return decoded.contract ?? "";
    }
    return "";
  });

  const [hasTerms, setHasTerms] = useState<boolean>(() => {
    if (sessStorage.getHasTermsOfUse() === null) {
      return true;
    }

    return sessStorage.getHasTermsOfUse();
  });

  const [newPasswordRequired, setNewPasswordRequired] = useState<boolean>(
    () => {
      if (sessStorage.getNewPasswordRequired() === null) {
        return false;
      }

      return sessStorage.getNewPasswordRequired();
    }
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = async (data: UserLoginData, navigate: NavigateFunction) => {
    setIsLoading(true);

    const { email, password } = data;
    const body = { email, password };

    try {
      const response = await api.post("/Auth/login", body);

      sessStorage.setToken(response.data.token);
      sessStorage.setUserId(
        response.data.professionalId || response.data.patientId
      );

      sessStorage.setProfileId(response.data.perfilId);

      if (!response.data.hasTermsOfUse) {
        sessStorage.setHasTermsOfUse(response.data.hasTermsOfUse);
      }

      if (response.data.newPasswordRequired) {
        sessStorage.setNewPasswordRequired(response.data.newPasswordRequired);
      }

      setToken(response.data.token);
      setUserId(response.data.professionalId || response.data.patientId);
      setProfileId(response.data.perfilId);
      setUserRole(response.data.role);
      setHasTerms(response.data.hasTermsOfUse);
      setNewPasswordRequired(response.data.newPasswordRequired);
      const decoded: JwtDecoded = jwt_decode(response.data.token);
      setUserContract(decoded.contract ?? "");

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Login realizado com sucesso!",
      });

      if (!decoded.contract && userRole == RoleDescription.Professional) {
        navigate("/contract");
        return;
      }

      navigate("/");
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 404) {
        message = !!error.response.data.title
          ? "Usuário ou senha inválidos. Verifique seus dados e tente novamente."
          : error.response.data;
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const refreshToken = async (id: string) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}Auth/refreshToken/${id}`);

      sessStorage.setToken(response.data.token);
      sessStorage.setUserId(
        response.data.professionalId || response.data.patientId
      );

      sessStorage.setProfileId(response.data.perfilId);

      if (!response.data.hasTermsOfUse) {
        sessStorage.setHasTermsOfUse(response.data.hasTermsOfUse);
      }

      if (response.data.newPasswordRequired) {
        sessStorage.setNewPasswordRequired(response.data.newPasswordRequired);
      }

      setToken(response.data.token);
      setUserId(response.data.professionalId || response.data.patientId);
      setProfileId(response.data.perfilId);
      setUserRole(response.data.role);
      setHasTerms(response.data.hasTermsOfUse);
      setNewPasswordRequired(response.data.newPasswordRequired);
      const decoded: JwtDecoded = jwt_decode(response.data.token);
      setUserContract(decoded.contract ?? "");

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 404) {
        message = !!error.response.data.title
          ? "Usuário ou senha inválidos. Verifique seus dados e tente novamente."
          : error.response.data;
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const logout = async () => {
    try {
      const response: AxiosResponse = await api.post("/Auth/logout");

      toast.fire({
        icon: "success",
        title:
          response.status !== 203
            ? response.data
            : "Logout efetuado com sucesso",
      });

      setToken("");
      setUserContract("");
      setUserRole("");

      sessionStorage.clear();
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const not_used_contract = async ({ contractStatus }: ContractData) => {
    setIsLoading(true);
    try {
      const { data }: AxiosResponse = await api.patch(
        `${API_URL}Auth/contract/status`,
        { contractStatus: contractStatus }
      );

      setIsLoading(false);

      if (contractStatus === ContractStatus.Rejected) {
        toast.fire({
          icon: "success",
          title: "Contrato rejeitado com sucesso!",
        });

        return;
      } else if (contractStatus === ContractStatus.Negociating) {
        toast.fire({
          icon: "success",
          title: "Negociação iniciada com sucesso!",
        });
      } else {
        toast.fire({
          icon: "success",
          title: "Contrato assinado com sucesso!",
        });
      }

      await refreshToken(data.idUsuario);
    } catch (error: any) {
      console.error(error);
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        profileId,
        userRole,
        userContract,
        hasTerms,
        newPasswordRequired,
        isLoading,
        login,
        logout,
        refreshToken,
        not_used_contract,
        setHasTerms,
        setNewPasswordRequired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
