import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";
import { Badge } from "react-bootstrap";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { useCompany } from "providers/Company";

import CompanyCard from "../CompanyCard";

interface CompanyListProps {
  searchData: string;
}

const ActiveCompanyList = ({ searchData }: CompanyListProps) => {
  const activeCompaniesRef = useRef<HTMLDivElement>(null);

  const {
    activeCompanies,
    activeCompaniesTotal,
    activeCompaniesTotalPages,
    isLoadingActiveCompanies,
    activeCompaniesCurrentPage,
    getActiveCompanies,
    setActiveCompaniesCurrentPage,
  } = useCompany();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setActiveCompaniesCurrentPage(value);

    getActiveCompanies(value, searchData);

    activeCompaniesRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      {!!isLoadingActiveCompanies &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de empresas ativas */}
      {!isLoadingActiveCompanies && (
        <>
          {!!activeCompanies.length &&
            activeCompanies?.map((activeCompany) => (
              <CompanyCard key={activeCompany.id} company={activeCompany} />
            ))}

          {/* Lista de instituições inativas vazia */}
          {!activeCompanies.length && (
            <EmptyList
              icon="uil-clinic-medical"
              title="Nenhuma instituição inativa"
            />
          )}
        </>
      )}

      {!!activeCompanies.length && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={activeCompaniesTotalPages}
          page={activeCompaniesCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}
    </>
  );
};

export default ActiveCompanyList;
