import moment from "moment";
import { useEffect } from "react";

import ContainerAnimation from "components/ContainerAnimation";
import LoadingScreen from "components/LoadingScreen";
import { useQuoteDetails } from "providers/QuoteDetails";
import getInitials from "utils/getInitials";

interface AuditLogListProps {
  quoteId: number;
}

export default function AuditLogList({ quoteId }: AuditLogListProps) {
  const { auditLogs, getAuditLogs, isLoading } = useQuoteDetails();

  useEffect(() => {
    getAuditLogs(quoteId);
  }, [quoteId]);

  return (
    <div className="position-relative audit-logs-list">
      <div className="px-3 px-sm-4 px-xl-5 mx-n2 mx-sm-n3 mx-xl-n4 py-3">
        {!!isLoading && (
          <ContainerAnimation className="content">
            <LoadingScreen />
          </ContainerAnimation>
        )}
        {!isLoading && (
          <div className="d-flex flex-column">
            {auditLogs.map((log, i) => (
              <div key={i} className="d-flex flex-column mx-1">
                <div className="border-bottom pb-2 mb-3">
                  <div className="row gx-2">
                    <div className="col col-auto">
                      <div
                        className={`user-image ${
                          log.user ? "bg-primary" : "bg-dark"
                        }`}
                      >
                        {log.user ? getInitials(log.user.name) : "S"}
                      </div>
                    </div>
                    <div className="col">
                      <p className="pt-2 mb-2">
                        <b className="text-black">
                          {log.user ? log.user.name : "Sistema"}
                        </b>{" "}
                        <small>
                          alterou em:{" "}
                          {moment(log.date).format("DD/MM/YYYY HH:mm")}{" "}
                        </small>
                      </p>
                      {log.values.map((value, j) => {
                        const { description, oldValue, newValue } = value;

                        return (
                          <div key={j} className="row gx-2 mb-2 text-muted">
                            <div className="col-12 col-lg-auto small font-wight-light pt-1 font-weight-bold">
                              {description}:{" "}
                            </div>
                            <div className="col">
                              {oldValue ? (
                                <span> {oldValue}</span>
                              ) : (
                                <span>Nenhuma</span>
                              )}{" "}
                              -{"> "}
                              <span>{newValue}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
