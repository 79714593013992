/* eslint-disable import/order */
import moment from "moment";
import { useAuth } from "providers/Auth";
import { createContext, ReactNode, useContext, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { NavigateFunction } from "react-router-dom";

import { api } from "services";
import {
  AddressRegisterData,
  ProfessionalRegisterAccessData,
  ProfessionalRegisterPersonalData,
  ProfessionalRegisterData,
  CompanyRegisterAsLeadData,
  PatientRegisterAccessData,
  PatientRegisterPersonalData,
  PatientRegisterData,
  PatientUnconfirmedRegisterData,
} from "types/register";
import { SecretaryBody } from "types/secrataries";
import { toast } from "utils/toast";
import { QuoteCreateData } from "types/quotes";

enum Role {
  Admin = 1,
  Professional,
  Secretary,
  Patient,
}

interface RegisterProviderProps {
  children: ReactNode;
}

interface RegisterProviderData {
  registerType: string;
  isLoading: boolean;

  setRegisterType: React.Dispatch<React.SetStateAction<string>>;

  setAddressData: React.Dispatch<React.SetStateAction<AddressRegisterData>>;

  setProfessionalPersonalData: React.Dispatch<
    React.SetStateAction<ProfessionalRegisterPersonalData>
  >;
  setPatientPersonalData: React.Dispatch<
    React.SetStateAction<PatientRegisterPersonalData>
  >;

  registerProfessional: (
    professionalAccessData: ProfessionalRegisterAccessData,
    navigate: NavigateFunction
  ) => void;
  registerPatient: (
    patientAccessData: PatientRegisterAccessData,
    navigate: NavigateFunction
  ) => void;

  registerCompanyAsLead: (
    companyRegisterAsLeadData: CompanyRegisterAsLeadData
  ) => void;

  registerPatientUnconfirmed: (
    data: PatientUnconfirmedRegisterData,
    setQuoteValue: UseFormSetValue<QuoteCreateData>,
    onHide: () => void
  ) => Promise<void>;
}

export const RegisterContext = createContext<RegisterProviderData>(
  {} as RegisterProviderData
);

export const RegisterProvider = ({ children }: RegisterProviderProps) => {
  const [registerType, setRegisterType] = useState("");
  const [addressData, setAddressData] = useState({} as AddressRegisterData);

  const [professionalPersonalData, setProfessionalPersonalData] = useState(
    {} as ProfessionalRegisterPersonalData
  );

  const [patientPersonalData, setPatientPersonalData] = useState(
    {} as PatientRegisterPersonalData
  );

  const [isLoading, setIsLoading] = useState(false);

  const { userId } = useAuth();

  const registerProfessional = async (
    professionalAccessData: ProfessionalRegisterAccessData,
    navigate: NavigateFunction
  ) => {
    setIsLoading(true);

    const body: ProfessionalRegisterData = {
      ...professionalPersonalData,
      birthDate: moment(
        professionalPersonalData.birthDate,
        "DD/MM/YYYY"
      ).toISOString(),
      dddMobilePhone: professionalPersonalData.mobilePhone.slice(1, 3),
      mobilePhone: professionalPersonalData.mobilePhone
        .replace("-", "")
        .slice(5),
      ...addressData,
      ...professionalAccessData,
      role: Role.Professional,
    };

    try {
      await api.post("/User/professional/register", body);

      setIsLoading(false);

      toast.fire({
        icon: "warning",
        title:
          "Seu cadastro passará por uma análise, aguarde a confirmação do cadastro por e-mail.",
        timer: 8000,
      });

      navigate("/login");
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const registerPatient = async (
    patientAccessData: PatientRegisterAccessData,
    navigate: NavigateFunction
  ) => {
    setIsLoading(true);

    const body: PatientRegisterData = {
      ...patientPersonalData,
      birthDate: moment(
        patientPersonalData.birthDate,
        "DD/MM/YYYY"
      ).toISOString(),
      dddMobilePhone: patientPersonalData.mobilePhone.slice(1, 3),
      mobilePhone: patientPersonalData.mobilePhone.replace("-", "").slice(5),
      ...addressData,
      ...patientAccessData,
      role: Role.Patient,
    };

    try {
      await api.post("/User/patient/register", body);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Cadastro realizado com sucesso!",
      });

      navigate("/login");
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const registerCompanyAsLead = async (
    companyRegisterAsLeadData: CompanyRegisterAsLeadData
  ) => {
    setIsLoading(true);

    const body = {
      ...companyRegisterAsLeadData,
      dddMobilePhone: companyRegisterAsLeadData.mobilePhone.slice(1, 3),
      mobilePhone: companyRegisterAsLeadData.mobilePhone
        .replace("-", "")
        .slice(5),
    };

    try {
      await api.post("/Company/lead", body);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Cadastro realizado com sucesso!",
      });
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const registerPatientUnconfirmed = async (
    data: PatientUnconfirmedRegisterData,
    setQuoteValue: UseFormSetValue<QuoteCreateData>,
    onHide: () => void
  ) => {
    setIsLoading(true);

    const patientUnconfirmedRegisterData = {
      ...data,
      birthDate: moment(data.birthDate, "DD/MM/YYYY").toISOString(),
      dddMobilePhone: data.mobilePhone.slice(1, 3),
      mobilePhone: data.mobilePhone.replace("-", "").slice(5),
    };

    try {
      const response = await api.post(
        "/Patient/registerUnconfirmed",
        patientUnconfirmedRegisterData // passar o id da empresa
      );

      setIsLoading(false);

      setQuoteValue("patient", {
        value: response.data.id,
        label: response.data.nome,
      });

      toast.fire({
        icon: "info",
        title:
          "O cliente receberá um e-mail com os dados para acessar a plataforma.",
        timer: 7000,
      });

      onHide();
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const registerSecretary = async (body: SecretaryBody) => {
    try {
      const { data } = await api.post(
        `/Professional/${userId}/secretary`,
        body
      );

      console.log(data);
    } catch (error) {}
  };

  return (
    <RegisterContext.Provider
      value={{
        registerType,
        isLoading,

        setRegisterType,
        setProfessionalPersonalData,
        setPatientPersonalData,
        setAddressData,

        registerProfessional,
        registerPatient,
        registerCompanyAsLead,
        registerPatientUnconfirmed,
      }}>
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegister = () => useContext(RegisterContext);
