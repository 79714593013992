import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import { useQuoteDetails } from "providers/QuoteDetails";
import {
  ActionFormQuote,
  Form,
  FormQuote,
  UnionTypeDescription,
} from "types/formQuote";
import { cpfMask } from "utils/formMasks";
import { validateCpfAndCnpj } from "utils/formValidations";
import {
  handleCpfInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";

interface Step4OthersInfoFormProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formQuote?: FormQuote;
  setFormQuote: React.Dispatch<React.SetStateAction<FormQuote>>;
  onHide: () => void;
  quoteId: number;
  action: ActionFormQuote;
}

const Step4OthersInfoForm = ({
  setActiveStep,
  formQuote,
  onHide,
  quoteId,
  action,
}: Step4OthersInfoFormProps) => {
  const {
    createFormQuote,
    editFormQuote,
    saveFormAsDraft,
    setSaveFormAsDraft,
  } = useQuoteDetails();

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const schema = yup.object().shape({
    personalReference1: yup.object().shape({
      name: yup.string().required("*Campo obrigatório"),
      relationship: yup.string().required("*Campo obrigatório"),
      phone: yup
        .string()
        .required("*Campo obrigatório")
        .min(15, "*Número de phone inválido"),
    }),

    personalReference2: yup.object().shape({
      name: yup.string().required("*Campo obrigatório"),
      relationship: yup.string().required("*Campo obrigatório"),
      phone: yup
        .string()
        .required("*Campo obrigatório")
        .min(15, "*Número de phone inválido"),
    }),

    spouse: yup.object().shape({
      name: yup.string().required("*Campo obrigatório"),
      cpf: yup
        .string()
        .required("*Campo obrigatório")
        .test("cpfValidator", "*CPF inválido", validateCpfAndCnpj)
        .transform((value) => value.replace(/\D/g, "")),
      unionType: yup.number().required("*Campo obrigatório"),
    }),
  });

  const onSubmit = (data: Form) => {
    const request: FormQuote = {
      ...formQuote,
      form: {
        ...formQuote?.form,
        isDraft: false,
        personalReference1: {
          name: data.personalReference1.name,
          relationship: data.personalReference1.relationship,
          phone: data.personalReference1.phone,
          ddd: data.personalReference1.phone.slice(1, 3),
        },
        personalReference2: {
          name: data.personalReference2.name,
          relationship: data.personalReference2.relationship,
          phone: data.personalReference2.phone,
          ddd: data.personalReference2.phone.slice(1, 3),
        },
        spouse: data.spouse,
      } as Form,
    } as FormQuote;

    if (action === ActionFormQuote.Create) {
      createFormQuote(request, quoteId, onHide);
    }

    if (action === ActionFormQuote.Edit) {
      editFormQuote(request, quoteId, onHide);
    }
  };

  useEffect(() => {
    setSaveFormAsDraft(false);
  }, []);

  useEffect(() => {
    if (!saveFormAsDraft) {
      return;
    }

    if (!formQuote) {
      return;
    }

    const data = getValues() as Form;

    const cache = {
      ...formQuote,
      form: {
        ...formQuote?.form,
        isDraft: true,
        personalReference1: {
          ...formQuote?.form?.personalReference1,
          ddd: data.personalReference1.ddd
            ? data.personalReference1.phone.slice(1, 3)
            : "",
          phone: data.personalReference1.phone
            ? data.personalReference1.phone
            : "",
          name: data.personalReference1.name
            ? data.personalReference1.name
            : "",
          relationship: data.personalReference1.relationship
            ? data.personalReference1.relationship
            : "",
        },
        personalReference2: {
          ...formQuote?.form?.personalReference2,
          ddd: data.personalReference2.ddd
            ? data.personalReference2.phone.slice(1, 3)
            : "",
          phone: data.personalReference2.phone
            ? data.personalReference2.phone
            : "",
          name: data.personalReference2.name
            ? data.personalReference2.name
            : "",
          relationship: data.personalReference2.relationship
            ? data.personalReference2.relationship
            : "",
        },
        spouse: {
          ...formQuote?.form?.spouse,
          cpf: data.spouse.cpf ? data.spouse.cpf : "",
          name: data.spouse.name ? data.spouse.name : "",
          unionType: data.spouse.unionType
            ? data.spouse.unionType
            : UnionTypeDescription["NÃO INFORMADO"],
        },
      } as Form,
    } as FormQuote;

    if (action === ActionFormQuote.Create) {
      createFormQuote(cache, quoteId, onHide);
    }

    if (action === ActionFormQuote.Edit) {
      editFormQuote(cache, quoteId, onHide);
    }

    setSaveFormAsDraft(false);
    setActiveStep(0);
  }, [saveFormAsDraft]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<Form>({ resolver: yupResolver(schema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="pt-0">
        <div className="p-xl-2">
          <div className="row">
            <h5 className="py-4">Referências Pessoais</h5>
            <div className="form-group col-md-6">
              <Input
                className="text-uppercase"
                label="Nome 1:"
                placeholder="Nome Completo"
                type="text"
                register={register("personalReference1.name")}
                error={errors?.personalReference1?.name?.message}
                defaultValue={formQuote?.form?.personalReference1?.name ?? ""}
              />
            </div>
            <div className="form-group col-md-3">
              <Input
                label="Relacionamento 1:"
                placeholder="Tipo do Relacionamento"
                type="text"
                register={register("personalReference1.relationship")}
                error={errors?.personalReference1?.relationship?.message}
                defaultValue={
                  formQuote?.form?.personalReference1?.relationship ?? ""
                }
              />
            </div>
            <div className="form-group col-md-3">
              <Input
                label="Telefone 1:"
                placeholder="(99) 99999-9999"
                type="text"
                register={register("personalReference1.phone")}
                onChange={(e) =>
                  handlePhoneOrMobilePhoneInput(
                    e,
                    setValue,
                    "personalReference1.phone"
                  )
                }
                error={errors?.personalReference1?.phone?.message}
                defaultValue={formQuote?.form?.personalReference1?.phone ?? ""}
              />
            </div>
            <div className="form-group col-md-6">
              <Input
                className="text-uppercase"
                label="Nome 2:"
                placeholder="Nome Completo"
                type="text"
                register={register("personalReference2.name")}
                error={errors?.personalReference2?.name?.message}
                defaultValue={formQuote?.form?.personalReference2?.name ?? ""}
              />
            </div>
            <div className="form-group col-md-3">
              <Input
                label="Relacionamento 2:"
                placeholder="Tipo do Relacionamento"
                type="text"
                register={register("personalReference2.relationship")}
                error={errors?.personalReference2?.relationship?.message}
                defaultValue={
                  formQuote?.form?.personalReference2?.relationship ?? ""
                }
              />
            </div>
            <div className="form-group col-md-3">
              <Input
                label="Telefone 2:"
                placeholder="(99) 99999-9999"
                type="text"
                register={register("personalReference2.phone")}
                onChange={(e) =>
                  handlePhoneOrMobilePhoneInput(
                    e,
                    setValue,
                    "personalReference2.phone"
                  )
                }
                error={errors?.personalReference2?.phone?.message}
                defaultValue={
                  formQuote?.form?.personalReference2?.phone ?? ""
                }
              />
            </div>
          </div>
          <div className="row">
            <h5 className="py-4">Conjuge</h5>
            <div className="form-group col-md-4">
              <Input
                className="text-uppercase"
                label="Nome do Conjuge:"
                placeholder="Nome Completo"
                type="text"
                register={register("spouse.name")}
                error={errors?.spouse?.name?.message}
                defaultValue={formQuote?.form?.spouse?.name ?? ""}
              />
            </div>
            <div className="form-group col-md-4">
              <Input
                label="CPF:"
                placeholder="999.999.999-99"
                type="tel"
                maxLength={14}
                register={register("spouse.cpf")}
                error={errors?.spouse?.cpf?.message}
                onChange={(e) => handleCpfInput(e, setValue, "spouse?.cpf")}
                defaultValue={
                  formQuote?.form?.spouse?.cpf
                    ? cpfMask(formQuote.form.spouse.cpf)
                    : ""
                }
              />
            </div>
            <div className="form-group col-md-4">
              <SelectInput
                label="Tipo de União:"
                placeholder="selecione"
                control={control}
                name="spouse.unionType"
                defaultValue={
                  formQuote?.form?.spouse?.unionType ??
                  UnionTypeDescription["NÃO INFORMADO"]
                }
                error={errors?.spouse?.unionType?.message}
              >
                <MenuItem value={UnionTypeDescription["NÃO INFORMADO"]}>
                  NÃO INFORMADO
                </MenuItem>
                <MenuItem
                  value={UnionTypeDescription["COMUNHÃO PARCIAL DE BENS"]}
                >
                  COMUNHÃO PARCIAL DE BENS
                </MenuItem>
                <MenuItem
                  value={UnionTypeDescription["COMUNHÃO UNIVERSAL DE BENS"]}
                >
                  COMUNHÃO UNIVERSAL DE BENS
                </MenuItem>
                <MenuItem
                  value={
                    UnionTypeDescription["PARTICIPAÇÃO FINAL NOS AQUESTOS"]
                  }
                >
                  PARTICIPAÇÃO FINAL NOS AQUESTOS
                </MenuItem>
                <MenuItem value={UnionTypeDescription["SEPARAÇÃO DE BENS"]}>
                  SEPARAÇÃO DE BENS
                </MenuItem>
                <MenuItem
                  value={UnionTypeDescription["SEPARAÇÃO OBRIGATÓRIA DE BENS"]}
                >
                  SEPARAÇÃO OBRIGATÓRIA DE BENS
                </MenuItem>
              </SelectInput>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" className="btn-light" onClick={handleBack}>
          Voltar
        </Button>
        <Button className="btn-primary" type="submit">
          <i className="uil uil-check mr-2" />
          Adicionar
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Step4OthersInfoForm;
