import { Link } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";

import UserTypeForm from "./components/UserTypeForm";

const Register = () => {
  return (
    <ContainerAnimation className="content container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-11 col-lg-9 col-xl-7">
          <h1
            className="text-center aos-init"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-once
          >
            Cadastro
          </h1>
          <p
            className="text-center aos-init"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-once
            id="registration-description"
          >
            Qual tipo de usuário você deseja cadastrar?
          </p>

          <UserTypeForm />

          <p
            className="text-center aos-init"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-once
            data-aos-anchor="#registration-description"
          >
            Já possui login?{" "}
            <Link to="/login" className="fw-bold">
              Faça login aqui!
            </Link>
          </p>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Register;
