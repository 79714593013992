import {api} from "services";
import {Company} from "types/company";
import {PaymentMethod, Role} from "types/enums";
import {Patient} from "types/patient";
import {PriceTable} from "types/priceTable";
import {Professional} from "types/professional";

export const getPatientOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredPatients = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
        },
      });

      const patients = response.data;

      filteredPatients = patients.map((patient: Patient) => {
        return {
          value: patient.id,
          label: patient.socialName
            ? `${patient.socialName} (${patient.name})`
            : patient.name,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredPatients,
    hasMore,
  };
};

export const getProfessionalOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredProfessional = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
          professionalType: Role.Professional,
        },
      });

      const professionals = response.data;

      filteredProfessional = professionals.map((professional: Professional) => {
        return {
          value: professional.id,
          label: professional.socialName
            ? `${professional.socialName} (${professional.name})`
            : professional.name,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredProfessional,
    hasMore,
  };
};

export const getCompanyOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredCompanies = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
          active: true,
        },
      });

      const companies = response.data;

      filteredCompanies = companies.map((company: Company) => {
        return {
          value: company.id,
          label: company.name,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredCompanies,
    hasMore,
  };
};

export const getPriceTableOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredPriceTables = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
          active: true,
        },
      });

      const priceTables = response.data;

      filteredPriceTables = priceTables.map((priceTable: PriceTable) => {
        return {
          value: priceTable.id,
          label: priceTable.description,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredPriceTables,
    hasMore,
  };
};

export const getCreditPriceTableOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredPriceTables = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
          active: true,
          tipo: PaymentMethod.Credit
        },
      });

      const priceTables = response.data;

      filteredPriceTables = priceTables.map((priceTable: PriceTable) => {
        return {
          value: priceTable.id,
          label: priceTable.description,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredPriceTables,
    hasMore,
  };
};

export const getFinancingPriceTableOptions = async (
  url: string,
  params: {
    [key: string]: any;
  }
) => {
  const {search, limit, page, offset} = params;

  let filteredPriceTables = [];
  let hasMore = false;

  if (search) {
    try {
      const response = await api.get(url, {
        params: {
          search,
          page,
          size: limit,
          active: true,
          tipo: PaymentMethod.Financing
        },
      });

      const priceTables = response.data;

      filteredPriceTables = priceTables.map((priceTable: PriceTable) => {
        return {
          value: priceTable.id,
          label: priceTable.description,
        };
      });

      hasMore = response.headers["x-total-count"] > offset + limit;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    options: filteredPriceTables,
    hasMore,
  };
};
