import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import { useAuth } from "providers/Auth";
import { api } from "services";
import { toast } from "utils/toast";

interface PasswordChangeData {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm?: string;
}

interface PasswordChangeFormProps {
  onHide: () => void;
}

const PasswordChangeForm = ({ onHide }: PasswordChangeFormProps) => {
  const { setNewPasswordRequired } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    oldPassword: yup.string().required("*Campo Obrigatório"),
    newPassword: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*A senha deve ter pelo menos 8 caracteres")
      .matches(
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})(?=.*[!.@#$%^&*\"'¨()_+=`´{}~,<>;:?|\\]\\\\/[-])"
        ),
        "*Utilize letras maiúsculas e minúsculas, números e caracteres especiais (*#.!@)"
      ),
    newPasswordConfirm: yup
      .string()
      .required("*Campo obrigatório")
      .oneOf([yup.ref("newPassword")], "*As senhas não conferem"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordChangeData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: PasswordChangeData) => {
    setIsLoading(true);

    delete data.newPasswordConfirm;

    try {
      const response = await api.post("/Auth/passwordModify", data);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Senha alterada com sucesso!",
      });

      sessionStorage.setItem(
        "@docttorpay:newPasswordRequired",
        JSON.stringify(response.data.newPasswordRequired)
      );

      setNewPasswordRequired(response.data.newPasswordRequired);

      onHide();
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <Input
            label="Senha Atual:"
            register={register("oldPassword")}
            error={errors?.oldPassword?.message}
            placeholder="Digitar senha atual"
            autoFocus
            className="mb-3"
            isPasswordInput
          />
        </div>

        <div className="form-group">
          <Input
            label="Nova Senha:"
            register={register("newPassword")}
            error={errors?.newPassword?.message}
            placeholder="Digitar nova senha"
            className="mb-3"
            isPasswordInput
          />
        </div>

        <div className="form-group">
          <Input
            label="Confirmar Senha:"
            register={register("newPasswordConfirm")}
            error={errors?.newPasswordConfirm?.message}
            placeholder="Repetir nova senha"
            isPasswordInput
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Salvar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default PasswordChangeForm;
