import { TextField } from "@mui/material";
import {
  DatePicker as DatePickerMui,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import "moment/locale/pt-br";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { Controller } from "react-hook-form";

interface DatePickerProps {
  control: any;
  name: string;
  label?: string;
  error?: string;
  defaultValue?: Moment | null;
  type?: string;
  disablePast?: boolean;
  minDate?: any;
  disabled?: boolean;
  labelClassName?: string;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatePicker = (props: DatePickerProps) => {
  const customStyles = {
    "& .MuiInputBase-root": {
      borderRadius: "7px",
      height: 48,
      color: "var(--text)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: props.type === "search" ? "none" : undefined,
      borderColor: props.error ? "var(--danger)" : "var(--border)",
    }
  };

  return (
    <>
      {!!props.label && (
        <label className={props.labelClassName}>{props.label}</label>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"pt-br"}>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          render={({ field }) => (
            <DatePickerMui
              PopperProps={{
                placement: "left",
              }}
              DialogProps={{
                sx: {
                  '& .MuiDialog-paper': {
                    color:'var(--text)',
                    backgroundColor: 'var(--light)'
                  },
                  '& .MuiButtonBase-root': {
                    color:'var(--primary)'
                  },
                  '& .MuiPickersDay-root':{
                    color:'var(--text)',
                    backgroundColor: 'var(--white)'
                  },
                  '& .Mui-selected': {
                    color:'var(--white)',
                    backgroundColor: 'var(--primary)'
                  }
                },
              }}
              value={field.value}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                field.onChange(date);
              }}
              disablePast={props.disablePast}
              minDate={props.minDate}
              disabled={props.disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={customStyles}
                  className="w-100"
                  onChange={props.onInputChange}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "dd/mm/aaaa",
                  }}
                />
              )}
            />
          )}
        />
      </LocalizationProvider>

      {!!props.error && (
        <div className="text-start text-danger">
          <small>{props.error}</small>
        </div>
      )}
    </>
  );
};

export default DatePicker;
