import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem, SelectChangeEvent } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select, { StylesConfig, Theme } from "react-select";
import SwitchSelector from "react-switch-selector";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import * as yup from "yup";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import PriceTableSelect from "components/PriceTableSelect";
import SelectDropdown from "components/SelectDropdown";
import SelectInput from "components/SelectInput";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { useDarkMode } from "providers/DarkMode";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { useUser } from "providers/User";
import { api } from "services";
import {
  PaymentFeeOwner,
  PaymentMethod,
  PaymentTool,
  RoleDescription,
  TypePriceTable,
} from "types/enums";
import { BrazilianCity, BrazilianState } from "types/ibge";
import {
  ParcelValue,
  QuoteSimulationResponse,
  SimulateData,
  SimulatorParcel,
} from "types/quotes";
import { getBrazilianStates } from "utils/brazilianStates";
import debounce from "utils/debounce";
import {
  getCompanyOptions,
  getCreditPriceTableOptions,
} from "utils/getOptions";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import {
  financingAmountOptions,
  financingOptionsInterface,
} from "utils/selectOptions";
import { toast } from "utils/toast";

import PDFSimulatorParcels from "./components/PdfSimulatorParcels";
import SimulatorParcelCard from "./components/SimulatorParcelCard";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
     * multiValue(remove)/color:hover
     */
    danger: "var(--danger)",
    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: "var(--danger-light)",
    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: "var(--neutral-0)",
    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "var(--neutral-5)",
    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "var(--neutral-10)",
    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: "var(--neutral-20)",
    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: "var(--neutral-30)",
    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "var(--neutral-40)",
    /*
     * placeholder/color
     */
    neutral50: "var(--neutral-50)",
    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: "var(--primary)",
    /*
     * option/backgroundColor(focused)
     */
    primary25: "var(--primary-25)",
    /*
     * option/backgroundColor:active
     */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

const customStyles: StylesConfig = {
  control: (base: Record<string, unknown>) => ({
    ...base,
    height: 48,
    borderColor: "var(--border)",
    cursor: "pointer",
    "&:hover": {},
  }),
  singleValue: (base: Record<string, unknown>) => ({
    ...base,
    textTransform: "uppercase",
  }),
  option: (base: Record<string, unknown>) => ({
    ...base,
    cursor: "pointer",
    textTransform: "uppercase",
  }),
  menu: (base: Record<string, unknown>) => ({
    ...base,
    zIndex: 100,
  }),
};

interface SimulatorProps {
  onHide: () => void;
}

const Simulator = ({ onHide }: SimulatorProps) => {
  const { userRole } = useAuth();
  const { darkMode } = useDarkMode();
  const {
    professionalCompanies,
    isLoadingProfessionalCompanies,
    getProfessionalCompanies,
  } = useCompany();
  const { simulatedQuotePayment, setSimulatedQuotePayment } =
    useProfessionalQuote();
  const { userData } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [simulatorParcels, setSimulatorParcels] = useState<SimulatorParcel[]>(
    []
  );
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);
  const [brazilianCities, setBrazilianCities] = useState<BrazilianCity[]>([]);
  const [financingValueOptions, setFinancingValueOptions] = useState<
    financingOptionsInterface[]
  >(financingAmountOptions);
  const componentRef = useRef(null);

  const schema = yup.object().shape({
    value: yup
      .mixed()
      .when("method", {
        is: PaymentMethod.Credit,
        then: yup.string(),
        otherwise: yup.string().transform((value) => value.value),
      })
      .required("*Campo obrigatório"),
    firstDate: yup.string().default(moment().toISOString(true)),
    company: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    method: yup.number(),
    paymentFeeOwner: yup.number().nullable(),
    typePriceTable: yup.number().nullable(),
    priceTable: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    paymentTool: yup.number().nullable(),
    city: yup
      .string()
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
    state: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm<SimulateData>({
    resolver: yupResolver(schema),
    defaultValues: {
      method: PaymentMethod.Credit,
      paymentFeeOwner: 1,
      typePriceTable: TypePriceTable.Companies,
    },
  });

  const changePaymentMethod = (paymentMethod: PaymentMethod) => {
    resetField("value");
    resetField("city");
    resetField("state");
    // resetField("company");
    resetField("paymentFeeOwner");
    resetField("priceTable");
    resetField("paymentTool");

    setValue("method", paymentMethod);

    if (paymentMethod === PaymentMethod.Financing) {
      setValue("paymentFeeOwner", null);
      setValue("typePriceTable", null);
    }

    if (paymentMethod === PaymentMethod.Credit) {
      setValue("paymentFeeOwner", PaymentFeeOwner.Paciente);
      setValue("typePriceTable", 1);
    }
  };

  const changeTypePriceTable = (typePriceTable: TypePriceTable) => {
    resetField("value");
    resetField("paymentTool");
    resetField("priceTable");

    setValue("typePriceTable", typePriceTable);

    if (typePriceTable === TypePriceTable.Companies) {
      resetField("priceTable");
    }

    // if (typePriceTable === TypePriceTable.Tables) {
    //   resetField("company");
    // }
  };

  const changePaymentTool = () => {
    resetField("priceTable");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `simulacao_da_${
      getValues("priceTable")
        ? getValues("priceTable.label")
        : getValues("company.label")
    }_valor_total_${
      typeof getValues("value") == "object"
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getValues("value.value")
        : getValues("value")
    }`,
  });

  useEffect(() => {
    if (userRole !== RoleDescription.Admin) {
      getProfessionalCompanies(userData.id as number);
    }
  }, []);

  const onSubmit = async (data: SimulateData) => {
    setSimulatedQuotePayment({
      ...simulatedQuotePayment,
      paymentFeeOwner: data.paymentFeeOwner,
    });

    setIsLoading(true);

    const body = {
      value: Number(data.value.replace(/\./g, "").replace(",", ".")),
      city:
        data.method === PaymentMethod.Financing
          ? data.city.toUpperCase()
          : undefined,
      state:
        data.method === PaymentMethod.Financing
          ? data.state.toUpperCase()
          : undefined,
      firstDate: data.firstDate,
      method: data.method,
      priceTable: data.priceTable ? data.priceTable.value : undefined,
      paymentFeeOwner:
        data.method !== PaymentMethod.Financing
          ? data.paymentFeeOwner
          : undefined,
      CompanyId: data.company ? data.company.value : undefined,
    };

    try {
      const { data }: AxiosResponse<QuoteSimulationResponse> = await api.post(
        "/Quote/simulate",
        body
      );

      setIsLoading(false);

      setSimulatorParcels(data.parcels);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const handleSelectBrazilianState = async (event: SelectChangeEvent<any>) => {
    const brazilianState = event.target.value;

    setValue("city", "");

    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${brazilianState}/municipios?orderBy=nome`
    );

    setBrazilianCities(response.data);
  };

  const handleFinancingValueOptions = async ({
    state,
    city,
    method,
  }: SimulateData) => {
    setIsLoading(true);
    try {
      const { data }: AxiosResponse<ParcelValue[]> = await api.post(
        "Quote/parcelsValues",
        {
          state: state,
          city: city,
          paymentMethod: method,
        }
      );

      setFinancingValueOptions(data);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);

    return () => {
      setSimulatedQuotePayment({} as SimulatorParcel);
    };
  }, []);

  const debounceSearch = debounce((values) => {
    const numValue = parseInt(values.value.replace(/\./g, ""));

    if (numValue >= 100) {
      onSubmit(values);
    }
  }, 500);

  const validateValues = (values: SimulateData) => {
    if (userRole !== RoleDescription.Admin && !values?.company?.value) {
      return;
    }

    if (userRole === RoleDescription.Admin) {
      if (
        values.typePriceTable === TypePriceTable.Companies &&
        !values?.company?.value
      ) {
        return;
      }
      if (
        values.typePriceTable === TypePriceTable.Tables &&
        !values?.priceTable?.value
      ) {
        return;
      }
    }

    debounceSearch(values);
  };

  const onChangeSearchInput = (e: any) => {
    handleTwoDecimalPlacesInput(e, setValue, "value");

    const values = getValues();

    validateValues(values);
  };

  const [companyCardMachines, setCompanyCardMachines] = useState<
    {
      id: number;
      description: string;
    }[]
  >([]);
  const [isLoadingCardMachines, setIsLoadingCardMachines] = useState(true);

  useEffect(() => {
    const loadCompanyCardMachines = async () => {
      try {
        if (watch("company").value) {
          const { data } = await api.get("/Quote/TerminalPDQ", {
            params: { companyId: watch("company").value },
          });

          setCompanyCardMachines(data);
        }
        setIsLoadingCardMachines(false);
      } catch (error) {
        console.error(error);

        setIsLoadingCardMachines(false);
      }
    };

    loadCompanyCardMachines();
  }, [watch("company")]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Body>
          <div
            className="row p-3 justify-content-center"
            style={{ minHeight: "70vh" }}
          >
            <div className="col-12 col-lg-6 col-xl-4 pe-xl-0 d-flex flex-column align-self-center">
              <div className="form-group">
                <label className="label">Método de Pagamento:</label>
                <div style={{ height: 48 }}>
                  <Controller
                    name="method"
                    control={control}
                    render={({ field }) => (
                      <SwitchSelector
                        name="method"
                        wrapperBorderRadius={50}
                        optionBorderRadius={48}
                        fontSize={16}
                        border="1px solid var(--border)"
                        fontColor="var(--text)"
                        backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                        selectedBackgroundColor=" var(--primary)"
                        selectedFontColor=" var(--white)"
                        selectionIndicatorMargin={5}
                        onChange={(value) => {
                          field.onChange(value);
                          changePaymentMethod(value as number);

                          setSimulatorParcels([]);
                        }}
                        options={[
                          {
                            value: PaymentMethod.Credit,
                            label: "Parcelamento",
                          },
                          {
                            value: PaymentMethod.Financing,
                            label: "Financiamento",
                          },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>

              {userRole === RoleDescription.Admin ||
              userRole === RoleDescription.Patient ? (
                <div className="form-group">
                  <SelectSearch
                    label="Instituição:"
                    placeholder="Buscar Instituição"
                    url={`${API_URL}Company/search`}
                    control={control}
                    name="company"
                    get={getCompanyOptions}
                    isClearable
                    error={errors.company?.label?.message}
                  />
                </div>
              ) : (
                <div className="form-group">
                  <SelectDropdown
                    options={(function () {
                      return professionalCompanies.map((company) => ({
                        value: company.id,
                        label: company.name,
                      }));
                    })()}
                    label="Instituição:"
                    name="company"
                    placeholder="Selecione"
                    control={control}
                    error={errors.company?.label?.message}
                    isSearchable={false}
                    isDisabled={isLoadingProfessionalCompanies}
                  />
                </div>
              )}

              {userRole === RoleDescription.Admin &&
                watch("method") === PaymentMethod.Credit && (
                  <div className="form-group">
                    <label className="label">Tabela Preço por:</label>
                    <div style={{ height: 48 }}>
                      <Controller
                        name="typePriceTable"
                        control={control}
                        render={({ field }) => (
                          <SwitchSelector
                            name="typePriceTable"
                            wrapperBorderRadius={50}
                            optionBorderRadius={48}
                            fontSize={16}
                            border="1px solid var(--border)"
                            fontColor="var(--text)"
                            backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                            selectedBackgroundColor="var(--primary)"
                            selectedFontColor="var(--white)"
                            selectionIndicatorMargin={5}
                            onChange={(value) => {
                              field.onChange(value);
                              changeTypePriceTable(value as number);
                              setSimulatorParcels([]);
                            }}
                            options={[
                              {
                                value: TypePriceTable.Companies,
                                label: "Instituição",
                              },
                              {
                                value: TypePriceTable.Tables,
                                label: "Tabelas Padrões",
                              },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

              {userRole === RoleDescription.Admin &&
                watch("method") === PaymentMethod.Credit &&
                watch("typePriceTable") !== 1 && (
                  <div className="form-group">
                    <div className="form-group">
                      <PriceTableSelect
                        withCardMachines={true}
                        control={control}
                        customOnChange={() => {
                          const values = getValues();

                          validateValues(values);
                        }}
                      />
                    </div>
                  </div>
                )}

              {watch("method") === PaymentMethod.Credit &&
                watch("typePriceTable") !== TypePriceTable.Tables && (
                  <div className="form-group">
                    <label className="label">
                      Qual será a ferramenta de pagamento?
                    </label>
                    <div style={{ height: 48 }}>
                      <Controller
                        name="paymentTool"
                        control={control}
                        render={({ field }) => (
                          <SwitchSelector
                            name="paymentTool"
                            wrapperBorderRadius={50}
                            optionBorderRadius={48}
                            fontSize={16}
                            border="1px solid var(--border)"
                            fontColor="var(--text)"
                            backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                            selectedBackgroundColor=" var(--primary)"
                            selectedFontColor=" var(--white)"
                            selectionIndicatorMargin={5}
                            onChange={(value) => {
                              field.onChange(value);
                              changePaymentTool();

                              setSimulatorParcels([]);
                            }}
                            options={[
                              {
                                value: PaymentTool["Link de pagamento"],
                                label: "Link de pagamento",
                              },
                              {
                                value: PaymentTool["Máquina física"],
                                label: "Máquina física",
                              },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

              {watch("method") === PaymentMethod.Credit &&
                watch("typePriceTable") !== TypePriceTable.Tables &&
                watch("paymentTool") === PaymentTool["Máquina física"] && (
                  <div className="form-group">
                    <label className="label">Máquina</label>
                    <Controller
                      name="priceTable"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          options={companyCardMachines.map((cardMachine) => ({
                            value: cardMachine.id,
                            label: cardMachine.description,
                          }))}
                          onChange={(newValue) => {
                            onChange(
                              newValue as { value: number; label: string }
                            );
                          }}
                          placeholder="Buscar Máquina"
                          isClearable={true}
                          value={value}
                          theme={customTheme}
                          styles={customStyles}
                          isDisabled={
                            isLoadingCardMachines || !getValues("company")
                          }
                        />
                      )}
                    />
                  </div>
                )}

              {watch("method") === PaymentMethod.Credit && (
                <div className="form-group">
                  <label className="label">Quem assume a taxa?</label>
                  <div style={{ height: 48 }}>
                    <Controller
                      name="paymentFeeOwner"
                      control={control}
                      render={({ field }) => (
                        <SwitchSelector
                          name="paymentFeeOwner"
                          wrapperBorderRadius={50}
                          optionBorderRadius={48}
                          fontSize={16}
                          border="1px solid var(--border)"
                          fontColor="var(--text)"
                          backgroundColor={!darkMode ? "#FFF" : "#1a1c1a"}
                          selectedBackgroundColor=" var(--primary)"
                          selectedFontColor=" var(--white)"
                          selectionIndicatorMargin={5}
                          onChange={(e) => {
                            field.onChange(e);

                            const values = getValues();

                            if (values.value) {
                              onSubmit(values);
                            }
                          }}
                          options={[
                            {
                              value: PaymentFeeOwner.Paciente,
                              label: "Cliente",
                            },
                            {
                              value: PaymentFeeOwner.Estabelecimento,
                              label: "Estabelecimento",
                            },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              {watch("method") === PaymentMethod.Financing && (
                <div className="form-group">
                  <p className="label">Residência:</p>
                  <div className="row">
                    <div className="col-sm-6">
                      <SelectInput
                        name="state"
                        label="Estado:"
                        placeholder="Selecione"
                        control={control}
                        error={errors?.state?.message}
                        changeOptionSelected={handleSelectBrazilianState}
                        defaultValue=""
                        customOnChange={() => {
                          setSimulatorParcels([]);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Selecione
                        </MenuItem>
                        {brazilianStates?.map((brazilianState) => (
                          <MenuItem
                            key={brazilianState.id}
                            value={brazilianState.sigla}
                          >
                            {brazilianState.sigla}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        !watch("state") ? (
                          <Tooltip>Selecione primeiro o Estado</Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div className="col-sm-6">
                        <SelectInput
                          name="city"
                          label="Cidade:"
                          placeholder="Selecione"
                          control={control}
                          error={errors?.city?.message}
                          isDisabled={!brazilianCities.length}
                          defaultValue=""
                          customOnChange={async () => {
                            const values = getValues();
                            handleFinancingValueOptions(values);
                            setSimulatorParcels([]);
                          }}
                        >
                          <MenuItem value="selecione" disabled>
                            Selecione
                          </MenuItem>
                          {brazilianCities?.map((brazilianCity) => (
                            <MenuItem
                              key={brazilianCity.id}
                              value={brazilianCity.nome}
                            >
                              {brazilianCity.nome.toUpperCase()}
                            </MenuItem>
                          ))}
                        </SelectInput>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="label" htmlFor="valor">
                  Valor:
                </label>
                <div className="row align-items-center no-gutters flex-nowrap">
                  <div className="col col-auto pr-2">R$</div>
                  <div className="col">
                    {watch("method") === PaymentMethod.Credit && (
                      <>
                        <input
                          type="tel"
                          placeholder="0,00"
                          {...register("value")}
                          onChange={onChangeSearchInput}
                          className={`form-control bg-white ${
                            !!errors.value && "border-danger"
                          }`}
                        />
                        {!!errors.value && (
                          <div className="text-start text-danger">
                            <small>{errors.value?.message}</small>
                          </div>
                        )}
                      </>
                    )}

                    {watch("method") === PaymentMethod.Financing && (
                      <SelectDropdown
                        options={financingValueOptions}
                        name="value"
                        placeholder="Selecione"
                        control={control}
                        error={errors.value?.message}
                        isSearchable={false}
                        customOnChange={() => {
                          const values = getValues();

                          if (values.state && values.city) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values.value = values.value.value;
                            onSubmit(values);
                          } else {
                            setSimulatorParcels([]);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {watch("method") === PaymentMethod.Financing && (
                <p className="animate__animated animate__fadeIn">
                  <small>
                    *Valores de referência. Crédito sujeito à aprovação.
                  </small>
                  <br />
                  <small>
                    *As taxas podem variar de 2% a.m. até 5% a.m. - elas dependem
                    da quantidade de parcelas, score do solicitante, valor do
                    seguro, local de moradia e trabalho do solicitante e tarifa de
                    IOF conforme legislação vigente.
                  </small>
                  <br />
                  <small>
                    *Não deve ser enviado print da simulação para o paciente, os
                    valores são de referência e podem ter alterações de acordo com
                    o perfil financeiro do paciente. Apenas usar a simulação como
                    base e informar que a parcela é a partir do valor simulado.
                  </small>
                </p>
              )}
            </div>

            {!!simulatorParcels.length && (
              <>
                <div className="col-12 d-xl-none">
                  <hr className="my-4" />
                </div>

                <div className="col-12 col-xl-8 ps-xl-0">
                  {/* <Modal.Body> */}
                  <div className="grid w-full mb-4 d-flex flex-row">
                    <span className="col-1">&nbsp;</span>
                    <h5 className="col m-0 align-self-center text-center">
                      Simulação
                    </h5>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Imprimir Pdf</Tooltip>}
                    >
                      <Button
                        onClick={handlePrint}
                        className="col-1 btn btn-outline-primary border-0 rounded-0 p-0 text-center"
                      >
                        <i className="uil uil-print fs-4" />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  {!!isLoading && (
                    <div
                      className="p-2"
                      style={{
                        height: "70vh",
                      }}
                    >
                      {[...Array(3)].map((_, index) => (
                        <ListCardSkeleton key={index} />
                      ))}
                    </div>
                  )}
                  {!isLoading && (
                    <div
                      className="p-2 animate__animated animate__fadeIn"
                      style={{
                        // minHeight: "100%",
                        maxHeight: "70vh",
                        overflowY: "auto",
                      }}
                    >
                      {!simulatorParcels.length ? (
                        <EmptyList
                          icon="uil-bill"
                          title="Simular orçamento"
                          subtitle="Preencha o formulário para realizar uma simulação"
                        />
                      ) : (
                        <>
                          <div>
                            {simulatorParcels?.map((simulatorParcel, index) => (
                              <SimulatorParcelCard
                                key={index}
                                simulatorParcel={simulatorParcel}
                                company={getValues("company")}
                                showAddQuote={
                                  getValues("priceTable") &&
                                  getValues("paymentTool") !==
                                    PaymentTool["Máquina física"]
                                    ? false
                                    : true
                                }
                                paymentToolAndMachine={{
                                  paymentTool: getValues("paymentTool"),
                                  cardMachine: getValues("priceTable"),
                                }}
                              />
                            ))}
                          </div>

                          <div style={{ display: "none" }}>
                            <div ref={componentRef}>
                              <PDFSimulatorParcels
                                method={getValues("method")}
                                simulatorParcels={simulatorParcels}
                                company={getValues("company")}
                                value={getValues("value")}
                                currentPaymentFeeOwner={
                                  getValues("paymentFeeOwner") ?? null
                                }
                                priceTable={getValues("priceTable") ?? null}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}

          </div>
          
        </Modal.Body>

        <Modal.Footer className="justify-content-center bg-white position-sticky" style={{ bottom: 0 }}>
          <Button
            type="button"
            className="btn-light"
            onClick={onHide}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" className="btn-primary" disabled={isLoading}>
            <i className="uil uil-check mr-2" />
            Simular
            {!!isLoading && (
              <CircularProgress
                color="inherit"
                size={12}
                className="align-text-top ms-2"
              />
            )}
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default Simulator;
