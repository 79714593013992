import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import SwitchSelector from "react-switch-selector";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import SelectDropdown from "components/SelectDropdown";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import { useDarkMode } from "providers/DarkMode";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { useQuoteDetails } from "providers/QuoteDetails";
import { api } from "services";
import {
  PaymentMethod,
  PaymentMethodDescription,
  QuotePaymentStatusDescription,
} from "types/enums";
import { Quote, QuoteCreateData } from "types/quotes";
import { calculateFees, calculateFinalValue } from "utils/calculations";
import { validateProcedureDate } from "utils/formValidations";
import {
  getCompanyOptions,
  getPatientOptions,
  getProfessionalOptions,
} from "utils/getOptions";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import {
  creditParcelOptions,
  financingParcelOptions,
  paymentMethodOptions,
} from "utils/selectOptions";

interface QuoteEditFormProps {
  onHide: () => void;
  quote: Quote;
  setQuote: React.Dispatch<React.SetStateAction<Quote>>;
}

const QuoteEditForm = ({ onHide, quote, setQuote }: QuoteEditFormProps) => {
  const { isLoading, editProfessionalQuote } = useProfessionalQuote();
  const { getAuditLogs } = useQuoteDetails();
  const { darkMode } = useDarkMode();

  const patientInputRef = useRef(null);
  const professionalInputRef = useRef(null);

  const schema = yup.object().shape({
    company: yup
      .object()
      .required("**Campo obrigatório**")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    patient: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    professional: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    description: yup
      .string()
      .required("*Campo obrigatório")
      .min(4, "*Informe um procedimento com pelo menos 4 caracteres")
      .uppercase()
      .trim(),
    date: yup
      .date()
      .typeError("*Data inválida")
      .nullable()
      .required("*Campo obrigatório")
      .test("procedureDateValidator", "*Data inválida", (value) =>
        validateProcedureDate(value, companyMinLimitDateProcedure)
      ),
    paymentFeeOwner: yup.number().nullable().notRequired(),
    priceValue: yup.string().required("*Campo obrigatório"),
    paymentMethod: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    parcelQtd: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    feeMonthly: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    feeAnnual: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    parcelValue: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    finalValue: yup.string().when("paymentMethod", {
      is: (val: { value: number; label: string }) =>
        val.value === PaymentMethod.Financing,
      then: yup.string().required("*Campo obrigatório"),
    }),
    compensationValue: yup.string().notRequired(),
    paymentLiquidationDate: yup
      .date()
      .nullable()
      .notRequired()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      .transform((v: any) => (v instanceof Date && !isNaN(v) ? v : null)),
  });

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    control,
    formState: { errors },
  } = useForm<Omit<QuoteCreateData, "fileTypes">>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      company: {
        value: quote.company.id,
        label: quote.company.name,
      },
      patient: {
        value: quote.patient.id,
        label: quote.patient.name,
      },
      professional: {
        value: quote.professional?.id,
        label: quote.professional?.name,
      },
      description: quote?.description,
      date: moment(quote.date).format("YYYY-MM-DD"),
      paymentFeeOwner: quote?.payment.paymentFeeOwner,
      priceValue: quote?.payment.value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      }),
      paymentMethod: {
        value: quote.payment.method,
        label: PaymentMethodDescription[quote.payment.method],
      },
      parcelQtd: {
        value: quote.payment.parcelQtd,
        label: `${quote.payment.parcelQtd}x`,
      },
      feeMonthly:
        quote?.payment.feeMonthly !== null
          ? quote?.payment.feeMonthly.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          : "",
      feeAnnual:
        quote?.payment.feeAnnual !== null
          ? quote?.payment.feeAnnual.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          : "",
      parcelValue:
        quote?.payment.parcelValue !== null
          ? quote?.payment.parcelValue.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          : "",
      finalValue:
        quote?.payment.finalValue !== null
          ? quote?.payment.finalValue.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          : "",
      compensationValue:
        quote?.payment.compensationValue !== null
          ? quote?.payment.compensationValue.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          : "",
      paymentLiquidationDate: moment(
        quote?.payment?.paymentLiquidationDate
      ).format("YYYY-MM-DD"),
    },
  });

  const [companyId, setCompanyId] = useState<number>(quote.company.id);

  const [companyMinLimitDateProcedure, setCompanyMinLimitDateProcedure] =
    useState(0);

  useEffect(() => {
    async function getCompanyMinLimitDateProcedure() {
      if (companyId === -1) return;

      try {
        const { data } = await api.get(
          `/Company/${companyId}/data-procedimento`
        );

        setCompanyMinLimitDateProcedure(data);
      } catch (error) {
        console.error(error);
      }
    }

    getCompanyMinLimitDateProcedure();
  }, [companyId]);

  const onSubmit = async (data: Omit<QuoteCreateData, "fileTypes">) => {
    await editProfessionalQuote(data, quote.id, onHide, setQuote);

    getAuditLogs(quote.id);
  };

  const handlePaymentMethod = () => {
    resetField("parcelQtd", {
      defaultValue: {},
    });
    resetField("feeMonthly", { defaultValue: "" });
    resetField("feeAnnual", { defaultValue: "" });
    resetField("parcelValue", { defaultValue: "" });
    resetField("finalValue", { defaultValue: "" });
  };

  const handleParcelQtd = (event: any) => {
    const parcelQtdNumber = event.value;

    const { calculatedFeeMonthly, calculatedFeeAnnual } =
      calculateFees(parcelQtdNumber);

    setValue("feeMonthly", calculatedFeeMonthly);
    setValue("feeAnnual", calculatedFeeAnnual);

    if (watch("parcelValue")) {
      const parcelValueNumber = Number(
        watch("parcelValue")?.replace(/\./g, "").replace(",", ".")
      );

      const calculatedFinalValue = calculateFinalValue(
        parcelQtdNumber,
        parcelValueNumber
      );

      setValue("finalValue", calculatedFinalValue);
    }
  };

  const handleParcelValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTwoDecimalPlacesInput(event, setValue, "parcelValue");

    const parcelValueNumber = Number(
      event.target.value.replace(/\./g, "").replace(",", ".")
    );

    const parcelQtdNumber = watch("parcelQtd.value");

    const calculatedFinalValue = calculateFinalValue(
      parcelQtdNumber,
      parcelValueNumber
    );

    setValue("finalValue", calculatedFinalValue);
  };

  const onFocusParcelValueInput = () => {
    const { calculatedFeeMonthly, calculatedFeeAnnual } = calculateFees(
      watch("parcelQtd.value")
    );

    setValue("feeMonthly", calculatedFeeMonthly);
    setValue("feeAnnual", calculatedFeeAnnual);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <SelectSearch
            label="Instituição:"
            placeholder="Buscar Instituição"
            url={`${API_URL}Company/search`}
            control={control}
            name="company"
            get={getCompanyOptions}
            isClearable
            error={errors.company?.label?.message}
            handleChange={(companyId: number) => {
              setCompanyId(companyId);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              patientInputRef.current?.clearValue();

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              professionalInputRef.current?.clearValue();

              // A lib do react hook form não suporta resetar um campo para undefined, e por algum motivo o valor
              // null não dispara a mensagem de campo obrigatório, no entanto espera-se que o usuário perceba os
              // campos em branco e seu instinto seja de preencher novamente, por isso a melhor alternativa foi
              // resetar para null mesmo.
              resetField("patient", { defaultValue: null });
              resetField("professional", { defaultValue: null });
              resetField("date", { defaultValue: null });
            }}
          />
        </div>

        <div className="form-group position-relative">
          <SelectSearch
            label="CPF do Cliente:"
            placeholder="Buscar Cliente pelo CPF"
            url={`${API_URL}Company/${companyId}/patients`}
            control={control}
            name="patient"
            get={getPatientOptions}
            isClearable
            error={errors.patient?.label?.message}
            isCpf
            isDisabled={companyId === -1}
            ref={patientInputRef}
          />
        </div>

        <div className="form-group">
          <SelectSearch
            label="Profissional:"
            placeholder="Buscar Profissional"
            url={`${API_URL}Company/${companyId}/professionals`}
            control={control}
            name="professional"
            get={getProfessionalOptions}
            isClearable
            error={errors.professional?.label?.message}
            isDisabled={companyId === -1}
            ref={professionalInputRef}
          />
        </div>

        <div className="row">
          <div className="form-group col-12 col-md-7">
            <Input
              className="text-uppercase"
              type="text"
              label="Procedimento:"
              placeholder="Nome do Procedimento"
              register={register("description")}
              error={errors?.description?.message}
            />
          </div>

          <div className="form-group col-12 col-md-5">
            <DatePicker
              label="Data do Procedimento:"
              control={control}
              name="date"
              defaultValue={null}
              minDate={moment().add(companyMinLimitDateProcedure, "d")}
              error={errors?.date?.message}
              disabled={companyId === -1}
            />
          </div>
        </div>

        {quote.payment.status == QuotePaymentStatusDescription.Aberto && (
          <div className="form-group">
            <label className="label">Quem assume a taxa?</label>
            <div style={{ height: 48 }}>
              <Controller
                name="paymentFeeOwner"
                control={control}
                render={({ field }) => (
                  <SwitchSelector
                    name="paymentFeeOwner"
                    initialSelectedIndex={quote.payment.paymentFeeOwner - 1}
                    wrapperBorderRadius={50}
                    optionBorderRadius={48}
                    fontSize={16}
                    border="1px solid var(--border)"
                    fontColor="var(--text)"
                    backgroundColor={!darkMode ? '#FFF' : '#1a1c1a'}
                    selectedBackgroundColor= "var(--primary)"
                    selectedFontColor="var(--white)"
                    selectionIndicatorMargin={5}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    options={[
                      {
                        value: 1,
                        label: "Cliente"
                      },
                      {
                        value: 2,
                        label: "Estabelecimento"
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="form-group">
          <label className="label" htmlFor="valor">
            Valor:
          </label>
          <div className="row align-items-center no-gutters flex-nowrap">
            <div className="col col-auto pr-2">R$</div>
            <div className="col">
              <input
                type="tel"
                placeholder="0,00"
                {...register("priceValue")}
                onChange={(e) =>
                  handleTwoDecimalPlacesInput(e, setValue, "priceValue")
                }
                className={`form-control ${
                  !!errors.priceValue && "border-danger"
                }`}
              />
            </div>
          </div>
          {!!errors.priceValue && (
            <div className="text-start text-danger">
              <small>{errors.priceValue?.message}</small>
            </div>
          )}
        </div>

        {quote.payment.status == QuotePaymentStatusDescription.Pago && (
          <div className="row">
            <div className="form-group col-12 col-md-7">
              <label className="label" htmlFor="valor">
                Valor de Compensação:
              </label>
              <div className="row align-items-center no-gutters flex-nowrap">
                <div className="col col-auto pr-2">R$</div>
                <div className="col">
                  <input
                    type="tel"
                    placeholder="0,00"
                    {...register("compensationValue")}
                    onChange={(e) =>
                      handleTwoDecimalPlacesInput(
                        e,
                        setValue,
                        "compensationValue"
                      )
                    }
                    className={`form-control ${
                      !!errors.compensationValue && "border-danger"
                    }`}
                  />
                </div>
              </div>
              {!!errors.compensationValue && (
                <div className="text-start text-danger">
                  <small>{errors.compensationValue?.message}</small>
                </div>
              )}
            </div>
            <div className="form-group col-12 col-md-5">
              <DatePicker
                label="Data de Compensação:"
                control={control}
                defaultValue={null}
                name="paymentLiquidationDate"
                minDate={moment().add(5, "d")}
                error={errors?.paymentLiquidationDate?.message}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="form-group col-12 col-md-7">
            <SelectDropdown
              options={paymentMethodOptions}
              label="Método de Pagamento"
              name="paymentMethod"
              placeholder="Selecione"
              control={control}
              error={errors.paymentMethod?.label?.message}
              changeOptionSelected={handlePaymentMethod}
              isSearchable={false}
            />
          </div>

          <div className="form-group col-md-5">
            <SelectDropdown
              options={
                watch("paymentMethod")?.value === PaymentMethod.Credit
                  ? creditParcelOptions
                  : watch("paymentMethod")?.value === PaymentMethod.Financing
                  ? financingParcelOptions
                  : undefined
              }
              label="Parcelas:"
              name="parcelQtd"
              placeholder="Selecione"
              control={control}
              error={errors.parcelQtd?.label?.message}
              isSearchable={false}
              changeOptionSelected={
                watch("paymentMethod.value") ===
                PaymentMethodDescription.Financiamento
                  ? handleParcelQtd
                  : undefined
              }
            />
          </div>
        </div>

        {watch("paymentMethod")?.value === PaymentMethod.Financing && (
          <div className="animate__animated animate__fadeIn">
            <div className="row">
              <div className="form-group col-md-6">
                <Input
                  type="tel"
                  label="Taxa Nominal Mensal:"
                  placeholder="0,00"
                  isFeeField
                  register={register("feeMonthly")}
                  error={errors?.feeMonthly?.message}
                  onChange={(e) =>
                    handleTwoDecimalPlacesInput(e, setValue, "feeMonthly")
                  }
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <Input
                  type="tel"
                  label="Taxa Nominal Anual:"
                  placeholder="0,00"
                  isFeeField
                  register={register("feeAnnual")}
                  error={errors?.feeAnnual?.message}
                  onChange={(e) =>
                    handleTwoDecimalPlacesInput(e, setValue, "feeAnnual")
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  !watch("parcelQtd")?.value ? (
                    <Tooltip>
                      Selecione primeiro a quantidade de parcelas
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div className="form-group col-md-6">
                  <Input
                    type="tel"
                    label="Valor da Parcela:"
                    placeholder="0,00"
                    isPriceField
                    register={register("parcelValue")}
                    error={errors?.parcelValue?.message}
                    onChange={handleParcelValue}
                    onFocus={
                      !watch("feeMonthly") ? onFocusParcelValueInput : undefined
                    }
                    disabled={!watch("parcelQtd")?.value}
                  />
                </div>
              </OverlayTrigger>

              <div className="form-group col-md-6">
                <Input
                  type="tel"
                  label="Valor Final:"
                  placeholder="0,00"
                  isPriceField
                  register={register("finalValue")}
                  error={errors?.finalValue?.message}
                  onChange={(e) =>
                    handleTwoDecimalPlacesInput(e, setValue, "finalValue")
                  }
                  readOnly
                />
              </div>
            </div>

            <p>
              <small>
                *Na taxa nominal não estão inclusos valores de taxas de abertura
                de crédito, de análise de crédito, IOF ou seguros.
              </small>
              <br />
              <small>
                **O valor da parcela e Valor final serão inseridos após a
                análise de crédito e envio da proposta oficial pela instituição
                de crédito parceira.
              </small>
            </p>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Salvar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default QuoteEditForm;
