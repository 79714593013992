import { ReactNode } from "react";

import { LinkBtn } from "./styles";

interface LinkButtonProps {
  id?: string;
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
}

const LinkButton = ({
  children,
  onClick,
  className,
  ...rest
}: LinkButtonProps) => {
  return (
    <LinkBtn
      {...rest}
      type="button"
      className={`btn btn-link p-0 text-decoration-none fw-normal ${className}`}
      onClick={onClick}
    >
      {children}
    </LinkBtn>
  );
};

export default LinkButton;
