import { createContext, ReactNode, useContext, useState } from "react";

import { useUser } from "providers/User";
import { api } from "services";
import { BankAccount, IBankAccountForm } from "types";
import { toast } from "utils/toast";

interface BankAccountProviderProps {
  children: ReactNode;
}

interface BankAccountProviderData {
  bankAccounts: BankAccount[];
  bankAccountsTotal: number;
  bankAccountsTotalPages: number;

  getBankAccounts: (currentPage?: number) => Promise<void>;

  createBankAccount: (
    data: IBankAccountForm,
    onHide: () => void
  ) => Promise<void>;
  editBankAccount: (
    data: IBankAccountForm,
    onHide: () => void
  ) => Promise<void>;
  removeBankAccount: (bankAccountId: number) => Promise<void>;

  isLoadingBankAccounts: boolean;
}

export const BankAccountContext = createContext<BankAccountProviderData>(
  {} as BankAccountProviderData
);

export const BankAccountProvider = ({ children }: BankAccountProviderProps) => {
  const bankAccountsLimit = 10;

  const { userData } = useUser();
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankAccountsTotal, setBankAccountsTotal] = useState(0);
  const [bankAccountsTotalPages, setBankAccountsTotalPages] = useState(0);
  const [isLoadingBankAccounts, setIsLoadingBankAccounts] = useState(false);

  const getBankAccounts = async (currentPage = 1) => {
    setIsLoadingBankAccounts(true);

    try {
      const response = await api.get("/bankAccounts", {
        params: {
          _page: currentPage,
          // _limit: bankAccountsLimit,
        },
      });

      setIsLoadingBankAccounts(false);
      setBankAccounts(response.data);
      setBankAccountsTotal(Number(response.headers["x-total-count"]));

      setBankAccountsTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / bankAccountsLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingBankAccounts(false);
    }
  };

  const createBankAccount = async (
    data: IBankAccountForm,
    onHide: () => void
  ) => {
    const { bank, accountType, ...rest } = data;
    const bankAccountCreationData = {
      ...rest,
      bankCode: bank?.id,
      bankName: bank?.label.split(" - ")[1],
      userId: userData.id,
    };

    try {
      await api.post("/bankAccounts", bankAccountCreationData);

      toast.fire({
        icon: "success",
        title: "Conta bancária cadastrada com sucesso!",
      });

      getBankAccounts();
      onHide();
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const editBankAccount = async (
    data: IBankAccountForm,
    onHide: () => void
  ) => {
    const { bank, accountType, ...rest } = data;
    const bankAccountUpdateData = {
      ...rest,
      bankCode: bank?.id,
      bankName: bank?.label.split(" - ")[1],
    };

    try {
      await api.patch(`/bankAccounts/${data.id}`, bankAccountUpdateData);

      getBankAccounts();
      onHide();
      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const removeBankAccount = async (bankAccountId: number) => {
    try {
      await api.delete(`/bankAccounts/${bankAccountId}`);

      getBankAccounts();
      toast.fire({
        icon: "success",
        title: "Conta bancária excluída com sucesso!",
      });
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  return (
    <BankAccountContext.Provider
      value={{
        bankAccounts,
        bankAccountsTotal,
        bankAccountsTotalPages,

        isLoadingBankAccounts,

        getBankAccounts,
        createBankAccount,
        editBankAccount,
        removeBankAccount,
      }}
    >
      {children}
    </BankAccountContext.Provider>
  );
};

export const useBankAccount = () => useContext(BankAccountContext);
