import { UseFormRegisterReturn } from "react-hook-form";

interface RadioInputProps {
  label?: string;
  value: number | string;
  register?: UseFormRegisterReturn;
  defaultChecked?: boolean;
}

const RadioInput = ({ label, register, ...rest }: RadioInputProps) => {
  return (
    <label className="control control--radio mb-0">
      <span className="caption">{label}</span>
      <input type="radio" {...register} {...rest} />
      <div className="control__indicator"></div>
    </label>
  );
};

export default RadioInput;
