import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ComboBox from "components/ComboBox";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import { useBankAccount } from "providers/BankAccount";
import { BankAccount, BankCode, IBankAccountForm, ComboBoxOption } from "types";
import { handleCpfOrCnpjInput, handleDigitInput } from "utils/handleInput";

interface BankAccountFormProps {
  onHide: () => void;
  bankAccount?: BankAccount;
}

const BankAccountForm = ({ onHide, bankAccount }: BankAccountFormProps) => {
  const [banks, setBanks] = useState<BankCode[]>([]);
  const { createBankAccount, editBankAccount } = useBankAccount();

  let bank = {} as ComboBoxOption;

  if (!!bankAccount) {
    bank = {
      id: bankAccount.bankCode,
      label: `${bankAccount.bankCode} - ${bankAccount.bankName}`,
    };
  }

  const schema = yup.object().shape({
    id: yup.number().default(bankAccount?.id),
    holder: yup.string().required("*Campo obrigatório").trim(),
    holderCpfOrCnpj: yup.string().required("*Campo obrigatório"),
    corporateName: yup.string().required("*Campo obrigatório").trim(),
    bank: yup
      .object()
      .shape({
        label: yup.string().required("*Selecione uma opção"),
        id: yup.string().required("*Selecione uma opção"),
      })
      .nullable(),
    accountType: yup.number().required("*Selecione uma opção"),
    branch: yup.string().required("*Campo obrigatório"),
    branchDigit: yup.string().required("*Campo obrigatório"),
    account: yup.string().required("*Campo obrigatório"),
    accountDigit: yup.string().required("*Campo obrigatório"),
    pixKey: yup.string().required("*Campo obrigatório").trim(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IBankAccountForm>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = (data: IBankAccountForm) => {
    console.log(data);
    // if (!!bankAccount) {
    //   // TODO: fazer requisição na API para atualizar conta bancária
    //   editBankAccount(data, onHide);
    // } else {
    //   // TODO: fazer requisição na API para criar conta bancária
    //   createBankAccount(data, onHide);
    // }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/banco_codigo.json");

        setBanks(response.data.banks);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <Input
            type="text"
            label="Nome do Titular:"
            placeholder="Nome completo do titular"
            register={register("holder")}
            error={errors?.holder?.message}
            defaultValue={!!bankAccount ? bankAccount.holder : ""}
          />
        </div>

        <div className="form-group">
          <Input
            type="tel"
            label="CPF / CNPJ do Titular:"
            placeholder="CPF ou CNPJ do titular"
            register={register("holderCpfOrCnpj")}
            error={errors?.holderCpfOrCnpj?.message}
            onChange={(e) =>
              handleCpfOrCnpjInput(e, setValue, "holderCpfOrCnpj")
            }
            defaultValue={!!bankAccount ? bankAccount.holderCpfOrCnpj : ""}
          />
        </div>

        <div className="form-group">
          <Input
            type="text"
            label="Razão Social:"
            placeholder="Nome completo do titular"
            register={register("corporateName")}
            defaultValue={!!bankAccount ? bankAccount.corporateName : ""}
          />
        </div>

        <div className="row">
          <div className="col-sm-8">
            <div className="form-group">
              <ComboBox
                label="Código do Banco:"
                control={control}
                name="bank"
                placeholder="Selecione"
                searchPlaceholder="Buscar Banco"
                options={banks}
                defaultValue={!!bankAccount ? bank : undefined}
                error={errors.bank?.label?.message}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <SelectInput
                label="Tipo de Conta:"
                placeholder="Selecione"
                control={control}
                name="accountType"
                defaultValue={!!bankAccount ? bankAccount.accountType : ""}
                error={errors?.accountType?.message}
              >
                <MenuItem value={0}>Corrente</MenuItem>
                <MenuItem value={1}>Poupança</MenuItem>
              </SelectInput>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Agência:"
                    placeholder="Nº da Agência"
                    register={register("branch")}
                    error={errors?.branch?.message}
                    onChange={(e) => handleDigitInput(e, setValue, "branch")}
                    defaultValue={!!bankAccount ? bankAccount.branch : ""}
                  />
                </div>
              </div>

              <div className="col-4 pl-0">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Dígito:"
                    placeholder="Dígito"
                    register={register("branchDigit")}
                    onChange={(e) =>
                      handleDigitInput(e, setValue, "branchDigit")
                    }
                    defaultValue={!!bankAccount ? bankAccount.branchDigit : ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Conta:"
                    placeholder="Nº da Conta"
                    register={register("account")}
                    error={errors?.account?.message}
                    onChange={(e) => handleDigitInput(e, setValue, "account")}
                    defaultValue={!!bankAccount ? bankAccount.account : ""}
                  />
                </div>
              </div>

              <div className="col-4 pl-0">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Dígito:"
                    placeholder="Dígito"
                    register={register("accountDigit")}
                    error={errors?.accountDigit?.message}
                    onChange={(e) =>
                      handleDigitInput(e, setValue, "accountDigit")
                    }
                    defaultValue={!!bankAccount ? bankAccount.accountDigit : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <Input
            type="text"
            label="Chave PIX:"
            placeholder="Chave PIX da conta"
            register={register("pixKey")}
            error={errors?.pixKey?.message}
            defaultValue={!!bankAccount ? bankAccount.pixKey : ""}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" className="btn-light" onClick={onHide}>
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary">
          <i className="uil uil-check mr-2" />
          Adicionar
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default BankAccountForm;
