import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import ModalComponent from "components/ModalComponent";
import { useCompany } from "providers/Company";

import CompanyCard from "../CompanyCard";
import CompanyCreateForm from "../Forms/CompanyCreateForm";

interface CompanyListProps {
  searchData: string;
}

const InactiveCompanyList = ({ searchData }: CompanyListProps) => {
  const inactiveCompaniesRef = useRef<HTMLDivElement>(null);

  const {
    inactiveCompanies,
    inactiveCompaniesTotal,
    inactiveCompaniesTotalPages,
    isLoadingInactiveCompanies,
    inactiveCompaniesCurrentPage,
    getInactiveCompanies,
    setInactiveCompaniesCurrentPage,
  } = useCompany();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInactiveCompaniesCurrentPage(value);

    getInactiveCompanies(value, searchData);

    inactiveCompaniesRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      {!!isLoadingInactiveCompanies &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de instituições inativas */}
      {!isLoadingInactiveCompanies && (
        <>
          {!!inactiveCompanies.length &&
            inactiveCompanies?.map((inactiveCompany) => (
              <CompanyCard
                key={inactiveCompany.id}
                company={inactiveCompany}
                isActive={false}
              />
            ))}

          {/* Lista de instituições inativas vazia */}
          {!inactiveCompanies.length && (
            <EmptyList
              icon="uil-clinic-medical"
              title="Nenhuma instituição inativa"
            />
          )}
        </>
      )}

      {!!inactiveCompanies.length && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={inactiveCompaniesTotalPages}
          page={inactiveCompaniesCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}
    </>
  );
};

export default InactiveCompanyList;
