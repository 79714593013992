import axios from "axios";
import { UseFormSetFocus, UseFormSetValue } from "react-hook-form";

import { toast } from "./toast";

const checkCep = async (
  inputCep: string,
  setIsSearchingCep: React.Dispatch<React.SetStateAction<boolean>>,
  setValue: UseFormSetValue<any>,
  setFocus: UseFormSetFocus<any>
) => {
  setIsSearchingCep(true);

  try {
    const cepResponse = await axios.get(
      `https://viacep.com.br/ws/${inputCep}/json/`
    );

    setIsSearchingCep(false);

    setValue("addressRoad", cepResponse.data.logradouro);
    setValue("addressDistrict", cepResponse.data.bairro);
    setValue("addressCity", cepResponse.data.localidade);
    setValue("addressState", cepResponse.data.uf);

    if (!cepResponse.data.logradouro.length) {
      return setFocus("addressRoad");
    }
    setFocus("addressNumber");
  } catch (error) {
    setIsSearchingCep(false);

    toast.fire({
      icon: "error",
      title: "O CEP consultado não foi encontrado.",
    });
  }
};

export default checkCep;
