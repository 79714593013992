import moment from "moment";

import { PaymentMethod, PaymentMethodDescription } from "types/enums";
import { QuoteInfo } from "types/quotes";
import { formatPrice } from "utils/formats";

interface TermsOfServiceProps {
  quoteInfo: QuoteInfo;
}

const TermsOfService = (props: TermsOfServiceProps) => {
  return (
    <div className="modal-body text-body">
      <div
        style={{ maxHeight: "50vh", overflowY: "auto" }}
        className="p-3 border rounded"
      >
        <h5 className="mb-4 text-uppercase">
          ADITIVO DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE GESTÃO DE PAGAMENTO E
          OUTRAS AVENÇAS -{" "}
          {PaymentMethodDescription[props.quoteInfo.paymentMethod]}
        </h5>

        <h6>PARTES:</h6>

        <ul className="list-unstyled">
          <li>Usuário Paciente (UPAC): {props.quoteInfo.paciente}</li>
          <li>Usuário profissional (UPRO): {props.quoteInfo.profissional}</li>
        </ul>

        <h6>PROCEDIMENTO:</h6>

        <ul className="list-unstyled">
          <li>Procedimento: {props.quoteInfo.procedureName}</li>
          <li>Valor principal: {formatPrice(props.quoteInfo.value)}</li>
          <li>Número de Parcelas: {props.quoteInfo.paymentParcelQtd}</li>
          <li>
            Modalidade:{" "}
            {PaymentMethodDescription[props.quoteInfo.paymentMethod]}
            {props.quoteInfo.paymentMethod === 2 && " no cartão de crédito"}
          </li>
          {/* <li>Valor Mensal: [Valor da parcela]</li> */}
          <li>Taxa de juros mensal: {props.quoteInfo.paymentFeeMonthly}</li>
          <li>Taxa de juros anual: {props.quoteInfo.paymentFeeAnnual}</li>
          <li>Valor total: {props.quoteInfo.paymentFinalValue}</li>
        </ul>

        {props.quoteInfo.paymentMethod === PaymentMethod.Credit && (
          <>
            <br />

            <p className="m-0">
              Comprovante das transações realizadas no processo:
            </p>
            <p>
              #{props.quoteInfo.paymentHash} - Pedido{" "}
              {props.quoteInfo.paymentOrderId} pago em{" "}
              {moment(props.quoteInfo.paymentDate).format("DD/MM/YYYY")} no
              valor de {formatPrice(props.quoteInfo.paymentFinalValue)}
            </p>

            <p className="mt-5">
              Curitiba, {moment(props.quoteInfo.actualDate).format("LL")}
            </p>
          </>
        )}

        <div className="row align-items-end mt-5">
          <div className="col-12 col-sm-6 text-center">
            <span className="font-italic fw-bold text-primary">
              DOCTTORPAY COBRANÇA LTDA
            </span>
            <p className="border-top border-secondary">CONTRATADA</p>
          </div>
          <div className="col-12 col-sm-6 text-center">
            <span className="font-italic">{props.quoteInfo.paciente}</span>
            <p className="border-top border-secondary">CONTRATANTE</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
