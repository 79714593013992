import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PaymentInfoProps {
  payment: {
    value: number;
    method: number;
    parcelQtd: number;
    status: number;
  };
}

enum PaymentStatusDescription {
  "Pagamento em Aberto",
  "Pagamento Confirmado",
  "Pagamento Cancelado",
  "Aguardando Pagamento",
  "Pagamento Recusado",
  "Pagamento Reembolsado",
}

enum PaymentMethodDescription {
  Free,
  "Cartão de Débito",
  "Cartão de Crédito",
  Financiamento,
}

const PaymentInfo = ({ payment }: PaymentInfoProps) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <b>{PaymentStatusDescription[payment.status]}</b>
          <br />
          <br />
          <b>{PaymentMethodDescription[payment.method]}</b>
          <br />
          Parcelado em {payment.parcelQtd}x
        </Tooltip>
      }
    >
      <i className="uil uil-info-circle ml-2" />
    </OverlayTrigger>
  );
};

export default PaymentInfo;
