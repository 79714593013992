import Select, { Theme, StylesConfig } from "react-select";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
     * multiValue(remove)/color:hover
     */
    danger: "var(--danger)",
    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: "var(--danger-light)",
    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: "var(--neutral-0)",
    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "var(--neutral-5)",
    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "var(--neutral-10)",
    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: "var(--neutral-20)",
    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: "var(--neutral-30)",
    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "var(--neutral-40)",
    /*
     * placeholder/color
     */
    neutral50: "var(--neutral-50)",
    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: "var(--primary)",
    /*
     * option/backgroundColor(focused)
     */
    primary25: "var(--primary-25)",
    /*
     * option/backgroundColor:active
     */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

type TimeFrameSelectProps = {
  value: any;
  setValue: any;
  options: { value: any; label: string }[];
  defaultValue: { value: any; label: string };
};
export default function TimeFrameSelect({
  value,
  setValue,
  options,
  defaultValue,
}: TimeFrameSelectProps) {
  const customStyles: StylesConfig = {
    control: (base: Record<string, unknown>) => ({
      ...base,
      height: 48,
      borderColor: "var(--border)",
      cursor: "pointer",
      "&:hover": {},
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      textTransform: "uppercase",
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      cursor: "pointer",
      textTransform: "uppercase",
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <Select
      options={options}
      onChange={setValue}
      defaultValue={defaultValue}
      value={value || null}
      theme={customTheme}
      styles={customStyles}
    />
  );
}
