import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import { useQuoteDetails } from "providers/QuoteDetails";
import {
  ActionFormQuote,
  ProfessionalData,
  Form,
  FormQuote,
  ProofIncome,
} from "types/formQuote";
import { BrazilianCity, BrazilianState } from "types/ibge";
import { getBrazilianStates } from "utils/brazilianStates";
import { formatPrice } from "utils/formats";
import { cnpjMask, twoDecimalPlacesMask } from "utils/formMasks";
import { validateCnpj } from "utils/formValidations";
import {
  handlePhoneOrMobilePhoneInput,
  handleTwoDecimalPlacesInput,
} from "utils/handleInput";
import { handleCnpjInput, handleDateInput } from "utils/handleInput";

interface Step3ProfessionalInfoFormProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formQuote?: FormQuote;
  setFormQuote: React.Dispatch<React.SetStateAction<FormQuote>>;
  onHide: () => void;
  quoteId: number;
  action: ActionFormQuote;
}

const Step3ProfessionalInfoForm = ({
  setActiveStep,
  formQuote,
  setFormQuote,
  onHide,
  quoteId,
  action,
}: Step3ProfessionalInfoFormProps) => {
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);
  const [brazilianCities, setBrazilianCities] = useState<BrazilianCity[]>([]);
  const {
    saveFormAsDraft,
    setSaveFormAsDraft,
    createFormQuote,
    editFormQuote,
  } = useQuoteDetails();

  const handleSelectBrazilianState = async (
    value: string,
    defaultCity?: string
  ) => {
    const brazilianState = value;

    setValue("companyAddressCity", "");

    const { data }: AxiosResponse<BrazilianCity[]> = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${brazilianState}/municipios?orderBy=nome`
    );

    if (!defaultCity) {
      setBrazilianCities(data);
      return;
    }

    setBrazilianCities([{ nome: defaultCity } as BrazilianCity]);
    setBrazilianCities((prev) => [...prev, ...data]);
  };

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const schema = yup.object().shape({
    companyName: yup.string().required("*Campo obrigatório"),
    phone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de phone inválido"),
    startActivities: yup.string().required("*Campo obrigatório"),
    rent: yup.string().required("*Campo obrigatório"),
    TypeIncomeProof: yup.number().required("*Campo obrigatório"),
    jobTitle: yup.string().required("*Campo obrigatório"),
    cnpj: yup
      .string()
      .required("*Campo obrigatório")
      .test("cnpjValidator", "*CNPJ inválido", validateCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    companyAddressCity: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
    companyAddressState: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
    serviceTime: yup.string().required("*Campo obrigatório"),
  });

  const onSubmit = (data: ProfessionalData) => {
    setFormQuote({
      ...formQuote,
      form: {
        ...formQuote?.form,
        professionalData: {
          companyName: data.companyName,
          phone: data.phone,
          startActivities: moment(
            data.startActivities,
            "DD/MM/YYYY"
          ).toISOString(),
          rent: data.rent,
          typeIncomeProof: data.typeIncomeProof,
          jobTitle: data.jobTitle,
          cnpj: data.cnpj,
          companyAddressCity: data.companyAddressCity,
          companyAddressState: data.companyAddressState,
          serviceTime: data.serviceTime,
        },
      } as Form,
    } as FormQuote);

    handleNext();
  };

  useEffect(() => {
    setSaveFormAsDraft(false);
  }, []);

  useEffect(() => {
    if (!saveFormAsDraft) {
      return;
    }

    if (!formQuote) {
      return;
    }

    const data = getValues() as ProfessionalData;

    const cache = {
      ...formQuote,
      form: {
        ...formQuote?.form,
        isDraft: true,
        professionalData: {
          ...formQuote?.form.professionalData,
          companyName: data.companyName ? data.companyName : "",
          phone: data.phone ? data.phone : "",
          startActivities: data.startActivities
            ? moment(data.startActivities, "DD/MM/YYYY").toISOString()
            : "",
          rent: data.rent ? data.rent : "",
          typeIncomeProof: data.typeIncomeProof
            ? data.typeIncomeProof
            : ProofIncome["NÃO INFORMADO"],
          jobTitle: data.jobTitle ? data.jobTitle : "",
          cnpj: data.cnpj ? data.cnpj : "",
          companyAddressCity: data.companyAddressCity
            ? data.companyAddressCity
            : "",
          companyAddressState: data.companyAddressState
            ? data.companyAddressState
            : "",
          serviceTime: data.serviceTime ? data.serviceTime : "",
        },
      } as Form,
    } as FormQuote;

    if (action === ActionFormQuote.Create) {
      createFormQuote(cache, quoteId, onHide);
    }

    if (action === ActionFormQuote.Edit) {
      editFormQuote(cache, quoteId, onHide);
    }

    setSaveFormAsDraft(false);
    setActiveStep(0);
  }, [saveFormAsDraft]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<ProfessionalData>({ resolver: yupResolver(schema) });

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);

    if (
      formQuote?.form?.professionalData?.companyAddressState &&
      formQuote?.form?.professionalData?.companyAddressCity
    ) {
      handleSelectBrazilianState(
        formQuote.form.professionalData.companyAddressState,
        formQuote.form.professionalData.companyAddressCity
      );

      setValue(
        "companyAddressCity",
        formQuote.form.professionalData.companyAddressCity
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="pt-0">
        <div className="p-xl-2">
          <header className="row py-4">
            <div className="col">
              <h5>Dados Profissionais</h5>
            </div>
          </header>

          <div className="row">
            <div className="form-group col-sm-6">
              <Input
                type="text"
                label="Nome da Empresa:"
                placeholder="Nome da Empresa Aqui"
                register={register("companyName")}
                error={errors?.companyName?.message}
                defaultValue={
                  formQuote?.form?.professionalData?.companyName ?? ""
                }
              />
            </div>

            <div className="form-group col-sm-6">
              <Input
                type="tel"
                label="CNPJ:"
                placeholder="99.999.999/9999-99"
                register={register("cnpj")}
                error={errors?.cnpj?.message}
                onChange={(e) => handleCnpjInput(e, setValue, "cnpj")}
                defaultValue={
                  formQuote?.form?.professionalData?.cnpj
                    ? cnpjMask(formQuote.form.professionalData.cnpj)
                    : ""
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-6">
              <SelectInput
                name="companyAddressState"
                label="Estado:"
                placeholder="Selecione"
                control={control}
                error={errors?.companyAddressState?.message}
                changeOptionSelected={(e) =>
                  handleSelectBrazilianState(e.target.value)
                }
                defaultValue={
                  formQuote?.form?.professionalData?.companyAddressState ?? ""
                }
              >
                <MenuItem value="" disabled>
                  Selecione
                </MenuItem>
                {brazilianStates?.map((brazilianState) => (
                  <MenuItem
                    key={brazilianState.id}
                    value={brazilianState.sigla}
                  >
                    {brazilianState.sigla}
                  </MenuItem>
                ))}
              </SelectInput>
            </div>

            <OverlayTrigger
              placement="bottom"
              overlay={
                !watch("companyAddressState") ? (
                  <Tooltip>Selecione primeiro o Estado</Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div className="form-group col-sm-6">
                <SelectInput
                  name="companyAddressCity"
                  label="Cidade:"
                  placeholder="Selecione"
                  control={control}
                  error={errors?.companyAddressCity?.message}
                  isDisabled={!brazilianCities.length}
                  defaultValue={
                    formQuote?.form?.professionalData?.companyAddressCity ?? ""
                  }
                >
                  <MenuItem value="" disabled>
                    Selecione
                  </MenuItem>
                  {brazilianCities?.map((brazilianCity) => (
                    <MenuItem key={brazilianCity.id} value={brazilianCity.nome}>
                      {brazilianCity.nome.toUpperCase()}
                    </MenuItem>
                  ))}
                </SelectInput>
              </div>
            </OverlayTrigger>
          </div>

          <div className="row">
            <div className="form-group col-sm-6">
              <Input
                label="Inicio das Atividades:"
                placeholder="dd/mm/aaaa"
                type="tel"
                maxLength={10}
                register={register("startActivities")}
                error={errors?.startActivities?.message}
                onChange={(e) =>
                  handleDateInput(e, setValue, "startActivities")
                }
                defaultValue={
                  !!formQuote?.form?.professionalData?.startActivities
                    ? moment(
                        formQuote?.form?.professionalData?.startActivities
                      ).format("DD/MM/YYYY")
                    : undefined
                }
              />
            </div>

            <div className="form-group col-sm-6">
              <Input
                type="tel"
                label="Telefone:"
                placeholder="(99) 99999-9999"
                register={register("phone")}
                error={errors?.phone?.message}
                onChange={(e) =>
                  handlePhoneOrMobilePhoneInput(e, setValue, "phone")
                }
                defaultValue={formQuote?.form?.professionalData?.phone ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-6">
              <Input
                className="text-uppercase"
                type="text"
                label="Cargo:"
                placeholder="Seu Cargo aqui"
                register={register("jobTitle")}
                error={errors?.jobTitle?.message}
                defaultValue={formQuote?.form?.professionalData?.jobTitle ?? ""}
              />
            </div>

            <div className="form-group col-sm-6">
              <Input
                type="text"
                label="Tempo de Serviço:"
                placeholder="Tempo de serviço"
                register={register("serviceTime")}
                error={errors?.serviceTime?.message}
                defaultValue={
                  formQuote?.form?.professionalData?.serviceTime ?? ""
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-6">
              <SelectInput
                label="Tipo do Comprovante de Renda:"
                placeholder="Comprovante de Renda"
                control={control}
                name="TypeIncomeProof"
                defaultValue={
                  formQuote?.form?.professionalData?.typeIncomeProof ??
                  ProofIncome["NÃO INFORMADO"]
                }
                error={errors?.typeIncomeProof?.message}
              >
                <MenuItem value={ProofIncome["NÃO INFORMADO"]}>
                  NÃO INFORMADO
                </MenuItem>
                <MenuItem value={ProofIncome["EXTRATO BANCÁRIO"]}>
                  EXTRATO BANCÁRIO
                </MenuItem>
                <MenuItem value={ProofIncome.HOLERITE}>HOLERITE</MenuItem>
                <MenuItem value={ProofIncome["IMPOSTO DE RENDA"]}>
                  IMPOSTO DE RENDA
                </MenuItem>
              </SelectInput>
            </div>

            <div className="form-group col-sm-6">
              <Input
                type="tel"
                label="Renda:"
                placeholder="0,00"
                isPriceField
                register={register("rent")}
                error={errors?.rent?.message}
                onChange={(e) =>
                  handleTwoDecimalPlacesInput(e, setValue, "rent")
                }
                defaultValue={
                  formQuote?.form?.professionalData
                    ? twoDecimalPlacesMask(
                        formQuote?.form?.professionalData?.rent + ""
                      )
                    : 0
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" className="btn-light" onClick={handleBack}>
          Voltar
        </Button>

        <Button className="btn-primary" type="submit">
          <i className="uil uil-check mr-2" />
          Próximo
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Step3ProfessionalInfoForm;
