import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  QuoteStatusDescription,
  quoteStatusTitle,
  QuoteStatusWaitingDescriptionByPaymentMethod,
} from "types/enums";

interface QuoteStatusProps {
  quoteStatus: number;
  paymentMethod: number;
}

const quoteStatusBg = new Map([
  [1, "secondary"],
  [2, "warning"],
  [3, "warning"],
  [4, "success"],
  [5, "dark"],
  [6, "info"],
  [7, "danger"],
]);

const QuoteStatusInfo = ({ quoteStatus, paymentMethod }: QuoteStatusProps) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <b>
            {quoteStatus !== 2
              ? QuoteStatusDescription[quoteStatus]
              : QuoteStatusWaitingDescriptionByPaymentMethod[paymentMethod]}
          </b>
        </Tooltip>
      }
    >
      <Badge
        bg={`${quoteStatusBg.get(quoteStatus)} text-white`}
        style={{
          cursor: "default",
        }}
      >
        {quoteStatusTitle.get(quoteStatus)}
      </Badge>
    </OverlayTrigger>
  );
};

export default QuoteStatusInfo;
