import React from "react";
import { Modal } from "react-bootstrap";

import Button from "components/Button";
import Input from "components/Input";

const TermsLink = () => {
  return (
    <Modal.Body>
      <div>
        <h5 className="d-flex align-items-center mb-4">
          <span className="badge bg-success rounded-circle mr-2">
            <i className="uil uil-share-alt text-white display-6"></i>
          </span>
          Compartilhe por WhatsApp ou e-mail
        </h5>

        <form>
          <div className="form-group">
            <Input label="WhatsApp:" placeholder="(99) 99999-9999" />
          </div>

          <div className="form-group">
            <Input label="E-mail:" placeholder="nome@email.com" />
          </div>

          <div className="text-end">
            <Button className="btn-primary btn-sm">
              <i className="uil uil-message mr-2" />
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </Modal.Body>
  );
};

export default TermsLink;
