import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { Gender } from "types/enums";
import { UserEditPersonalData } from "types/register";
import { cpfAndCnpjMask, mobilePhoneMask } from "utils/formMasks";
import { validateBirthDate, validateCpfAndCnpj } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCpfOrCnpjInput,
  handleMobilePhoneInput,
} from "utils/handleInput";

const EditPersonalDataForm = () => {
  const { userRole } = useAuth();
  const { userData, isLoading, editUserData } = useUser();

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test(
        "cpfValidator",
        `CPF ${userRole === "professional" && "ou CNPJ"} inválido`,
        validateCpfAndCnpj
      )
      .transform((value) => value.replace(/\D/g, "")),
    rg:
      userRole === "Patient"
        ? yup.string().required("*Campo obrigatório").uppercase().trim()
        : yup.object(),
    sex: yup.string(),
    socialName: yup.string().uppercase().trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<UserEditPersonalData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: UserEditPersonalData) => {
    editUserData(data);
  };

  useEffect(() => {
    reset();
  }, [userData]);

  return (
    <ContainerAnimation>
      <div className="tab-pane fade active show" id="dados-pessoais">
        <form
          className="form-box p-3 p-sm-4 p-xl-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h5 className="mb-4">Dados Pessoais</h5>
          <div className="form-group">
            <Input
              className="text-uppercase"
              type="text"
              label="Nome Completo"
              placeholder="Seu Nome Aqui"
              register={register("name")}
              error={errors?.name?.message}
              defaultValue={userData?.name}
            />
          </div>

          <div className="row align-items-end">
            <div
              className={`${userRole !== "Patient" ? "col-md-12" : "col-md-6"}`}
            >
              <div className="form-group">
                <Input
                  label={userRole === "Professional" ? "CPF / CNPJ" : "CPF"}
                  placeholder={
                    userRole === "Professional" ? "CPF ou CNPJ" : "CPF"
                  }
                  type="text"
                  maxLength={15}
                  register={register("cpf")}
                  error={errors?.cpf?.message}
                  onChange={(e) => handleCpfOrCnpjInput(e, setValue, "cpf")}
                  defaultValue={cpfAndCnpjMask(`${userData.cpf}`)}
                />
              </div>
            </div>

            {userRole === "Patient" && (
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    className="text-uppercase"
                    label="RG"
                    placeholder="9999999-9"
                    type="text"
                    maxLength={15}
                    register={register("rg")}
                    error={errors?.rg?.message}
                    defaultValue={userData?.rg}
                  />
                </div>
              </div>
            )}
          </div>

          <label htmlFor="select">Sexo</label>
          <div className="row">
            <div className="col col-auto">
              <RadioInput
                value="M"
                label="Masculino"
                register={register("sex")}
                defaultChecked={userData?.sex === Gender.Male}
              />
            </div>
            <div className="col col-auto">
              <RadioInput
                value="F"
                label="Feminino"
                register={register("sex")}
                defaultChecked={userData?.sex === Gender.Female}
              />
            </div>
            <div className="col col-auto">
              <RadioInput
                value="I"
                label="Intersexo"
                register={register("sex")}
                defaultChecked={userData?.sex === Gender.Intersex}
              />
            </div>
          </div>
          <div className="text-danger mb-3">
            <small>{!!errors?.sex && "*Selecione uma opção"}</small>
          </div>

          <div className="form-group">
            <Input
              className="text-uppercase"
              type="text"
              label="Nome Social"
              placeholder="Como prefere ser chamado?"
              register={register("socialName")}
              error={errors?.socialName?.message}
              defaultValue={userData?.socialName}
            />
          </div>

          <div className="row align-items-end">
            <div className="col-md-6">
              <div className="form-group">
                <Input
                  type="tel"
                  label="Data de Nascimento"
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                  register={register("birthDate")}
                  error={errors?.birthDate?.message}
                  onChange={(e) =>
                    handleBirthDateInput(e, setValue, "birthDate")
                  }
                  defaultValue={moment(userData?.birthDate).format(
                    "DD/MM/YYYY"
                  )}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Input
                  type="tel"
                  label="Telefone"
                  placeholder="(99) 99999-9999"
                  maxLength={15}
                  register={register("mobilePhone")}
                  error={errors?.mobilePhone?.message}
                  onChange={(e) =>
                    handleMobilePhoneInput(e, setValue, "mobilePhone")
                  }
                  defaultValue={mobilePhoneMask(
                    `${userData.dddMobilePhone}${userData.mobilePhone}`
                  )}
                />
              </div>
            </div>
          </div>

          <div className="text-center pt-4">
            <Button type="submit" className="btn-primary" disabled={isLoading}>
              Salvar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </ContainerAnimation>
  );
};

export default EditPersonalDataForm;
