import CompanyRegisterForm from "../components/CompanyRegisterForm";
import RegisterContainer from "../components/RegisterContainer";

const InstitutionRegister = () => {
  return (
    <RegisterContainer title="Cadastro para Instituições">
      <CompanyRegisterForm />
    </RegisterContainer>
  );
};

export default InstitutionRegister;
