import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";

import Page from "components/Page";
import Checkout from "pages/Checkout";
import Companies from "pages/Companies";
import CompanyProfessionals from "pages/CompanyProfessionals";
import Contract from "pages/Contract";
import ContractDocuSignIntegration from "pages/ContractDocuSignIntegration";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Menus from "pages/Menus";
import MyData from "pages/MyData";
import Patients from "pages/Patients";
import Professionals from "pages/Professionals";
import QuoteDetails from "pages/QuoteDetails";
import PatientQuote from "pages/Quotes/PatientQuote";
import ProfessionalQuote from "pages/Quotes/ProfessionalQuote";
import PatientAccessDataForm from "pages/Register/components/AccessDataForm/PatientAccessDataForm";
import ProfessionalAccessDataForm from "pages/Register/components/AccessDataForm/ProfessionalAccessDataForm";
import AddressForm from "pages/Register/components/AddressForm";
import PatientPersonalDataForm from "pages/Register/components/PersonalDataForm/PatientPersonalDataForm";
import ProfessionalPersonalDataForm from "pages/Register/components/PersonalDataForm/ProfessionalPersonalDataForm";
import InstitutionRegister from "pages/Register/InstitutionRegister";
import PatientRegister from "pages/Register/PatientRegister";
import ProfessionalRegister from "pages/Register/ProfessionalRegister";
import RegisterHome from "pages/Register/RegisterHome";
import Reports from "pages/Reports";
import PatientQuoteReport from "pages/Reports/components/PatientQuoteReport";
import ProfessionalQuoteReport from "pages/Reports/components/ProfessionalQuoteReport";
import Schedule from "pages/Schedule";
import ScheduleDetails from "pages/ScheduleDetails";
import AdminSecretaries from "pages/Secretaries/AdminSecretaries";
import Terms from "pages/Terms";
import TermsAndContract from "pages/TermsAndContract";
import Users from "pages/Users";
import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

import {
  ProfessionalRoute,
  PatientRoute,
  PrivateRoute,
  PublicRoute,
  AdminRoute,
} from "./route";

const Router = () => {
  const { userRole } = useAuth();

  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route element={<Page />}>
          <Route path="/termos/:quoteCode" element={<Terms />} />
          <Route path="/checkout/:id" element={<Checkout />} />

          <Route element={<PublicRoute />}>
            <Route path="/" element={<Login />} />

            <Route path="/login" element={<Login />} />

            <Route path="/cadastro" element={<RegisterHome />} />

            <Route path="/cadastro" element={<ProfessionalRegister />}>
              <Route
                path="profissional-1"
                element={<ProfessionalPersonalDataForm />}
              />
              <Route path="profissional-2" element={<AddressForm />} />
              <Route
                path="profissional-3"
                element={<ProfessionalAccessDataForm />}
              />
            </Route>

            <Route path="/cadastro" element={<PatientRegister />}>
              <Route path="cliente-1" element={<PatientPersonalDataForm />} />
              <Route path="cliente-2" element={<AddressForm />} />
              <Route path="cliente-3" element={<PatientAccessDataForm />} />
            </Route>

            <Route
              path="/cadastro/instituicao"
              element={<InstitutionRegister />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/contract" element={<Contract />} />
            <Route
              path="contract/docusign"
              element={<ContractDocuSignIntegration />}
            />
            <Route
              index={userRole === RoleDescription.Patient}
              element={<Menus />}
            />
            <Route path="/meus-dados" element={<MyData />} />
            <Route path="/termos-e-contrato" element={<TermsAndContract />} />
            <Route path="/relatorios" element={<Reports />} />

            <Route element={<AdminRoute />}>
              <Route path="/instituicoes">
                <Route index element={<Companies />} />
                <Route
                  path="instituicao-profissionais"
                  element={<CompanyProfessionals />}
                />
              </Route>
              <Route path="/profissionais" element={<Professionals />} />
              <Route path="/usuarios" element={<Users />} />
              <Route path="/secretarias" element={<AdminSecretaries />} />
            </Route>

            <Route element={<ProfessionalRoute />}>
              <Route path="/orcamentos-profissional">
                <Route index element={<ProfessionalQuote />} />
                <Route path="orcamento-detalhes" element={<QuoteDetails />} />
              </Route>

              <Route path="/pacientes" element={<Patients />} />

              <Route path="/agenda">
                <Route index element={<Schedule />} />
                <Route path="agenda-detalhes" element={<ScheduleDetails />} />
              </Route>

              <Route
                index={userRole !== RoleDescription.Patient}
                element={<Dashboard />}
              />

              <Route
                path="/relatorios-orcamentos-profissional"
                element={<ProfessionalQuoteReport />}
              />
            </Route>

            <Route element={<PatientRoute />}>
              <Route path="/orcamentos-paciente">
                <Route index element={<PatientQuote />} />
                <Route path="orcamento-detalhes" element={<QuoteDetails />} />
              </Route>

              <Route
                path="/relatorios-orcamentos-paciente"
                element={<PatientQuoteReport />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default Router;
