import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UseFormRegisterReturn } from "react-hook-form";

import Button from "components/Button";
import { DocumentType } from "types/files";

interface FileUploadCardProps {
  fileName: string;
  register: UseFormRegisterReturn;
  documentTypes: DocumentType[];
  error?: string;
  index: number;
  removeFile: () => void;
}

const FileUploadCard = (props: FileUploadCardProps) => {
  return (
    <div className="row align-items-center py-2 border rounded-xl w-100 m-auto gap-4">
      <div className="col">
        <div className="mb-3">
          <p className="small opacity-75 mb-0">Arquivo:</p>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{props.fileName}</Tooltip>}
          >
            <b className="d-block text-truncate">{props.fileName}</b>
          </OverlayTrigger>
        </div>

        <div>
          <p className="small opacity-75 mb-0">Tipo do Arquivo:</p>
          <select
            defaultValue={""}
            {...props.register}
            className={`form-select ${!!props.error ? "border-danger" : ""}
            `}
          >
            <option value="" disabled hidden>
              Selecione...
            </option>
            {props.documentTypes?.map((documentType) => (
              <option key={documentType.id} value={documentType.id}>
                {documentType.name}
              </option>
            ))}
          </select>

          {!!props.error && (
            <small className="text-danger d-block">{props.error}</small>
          )}
        </div>
      </div>

      <div className="col col-auto text-center">
        <Button
          className="btn-outline-danger btn-sm btn-icon"
          onClick={props.removeFile}
        >
          <i className="uil uil-times fs-6"></i>
        </Button>
      </div>
    </div>
  );
};

export default FileUploadCard;
