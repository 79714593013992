import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { api } from "services";
import { ContractType } from "types/enums";

interface ContractProps {
  handleContractNotSigned: () => void;
}

const Contract = ({ handleContractNotSigned }: ContractProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [urlPdf, setUrlPdf] = useState<string>("");

  const { userContract } = useAuth();

  const contractPDFUrl = async () => {
    setIsLoading(true);
    try {
      const { data }: AxiosResponse<string> = await api.get("Contract/pdf", {
        params: {
          signed: true,
          contractType: ContractType.Partnership,
        },
      });

      setUrlPdf(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    contractPDFUrl();
  }, []);

  useEffect(() => {
    if (!userContract) {
      handleContractNotSigned();
    }
  }, []);

  return (
    <ContainerAnimation className="content h-65vh pt-0 pb-1">
      {!!isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <CircularProgress color="inherit" size={50} />
        </div>
      ) : (
        <embed
          src={"data:application/pdf;base64," + urlPdf}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}{" "}
    </ContainerAnimation>
  );
};

export default Contract;
