import styled from "styled-components";

export const Container = styled.div`
  .character {
    padding: 4px;
    border: 1px solid var(--bs-secondary);
  }

  .btn-link {
    text-transform: none !important;
    color: var(--primary);
    :hover {
      color: var(--primary-hover);
    }
  }
`;

export const SuccesMessage = styled.div`
  ::before {
    display: none;
  }

  i {
    vertical-align: sub;
  }
`;
