import ContainerAnimation from "components/ContainerAnimation";

import BankAccountsList from "./components/BankAccountsList";
import TeamMemberList from "./components/TeamMemberList";

const FinancialData = () => {
  return (
    <ContainerAnimation>
      <div className="tab-pane fade active show" id="dados-financeiros">
        <BankAccountsList />

        <hr className="my-5" />

        <TeamMemberList />
      </div>
    </ContainerAnimation>
  );
};

export default FinancialData;
