import { useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import ModalComponent from "components/ModalComponent";
import { useTeam } from "providers/Team";
import { TeamMember } from "types";

import TeamMemberForm from "../TeamMemberForm";

interface TeamMemberCardProps {
  teamMember: TeamMember;
}

enum CategoryName {
  Anestesia,
  Auxiliar,
  Cirurgião,
  "Hospital/Clínica",
  Instrumentador,
  Secretária,
}

const TeamMemberCard = ({ teamMember }: TeamMemberCardProps) => {
  const [modalShow, setModalShow] = useState(false);
  const { removeTeamMember } = useTeam();

  return (
    <>
      <div className="row-item mb-2 px-3 pt-3">
        <div className="row align-items-center">
          {/* Informações do membro da equipe */}
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do membro da equipe */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">Nome</p>
                <b className="pe-2">{teamMember.name}</b>
              </div>

              {/* CPF/CNPJ do membro da equipe */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">CPF/CNPJ</p>
                <b>{teamMember.cpfOrCnpj}</b>
              </div>

              {/* Categoria do membro da equipe */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">Categoria</p>
                <b>{CategoryName[teamMember.category]}</b>
              </div>

              {/* Chave PIX do membro da equipe */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">PIX</p>
                <b>{teamMember.pixKey}</b>
              </div>
            </div>
          </div>
          <div className="col col-auto pb-3">
            <Dropdown as={ButtonGroup}>
              <Button
                variant="light"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                Editar
              </Button>

              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  className="text-danger"
                  onClick={() => removeTeamMember(teamMember.id)}
                >
                  <i className="uil uil-trash mr-2" />
                  Excluir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <ModalComponent
        title="Editar Membro de Equipe"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <TeamMemberForm
          onHide={() => setModalShow(false)}
          teamMember={teamMember}
        />
      </ModalComponent>
    </>
  );
};

export default TeamMemberCard;
