import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Slider, Switch } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import { useAuth } from "providers/Auth";
import { usePatientQuote } from "providers/Quote/PatientQuote";
import { SearchDataPatient } from "types/quotes";

import * as C from "./styles";

const PatientQuoteReportSearch = () => {
  const {
    searchData,
    setSearchData,
    searchPatientQuotesReport,
    patientQuotesReportGlobalRef,
  } = usePatientQuote();

  const [rangeValue, setRangeValue] = useState<number[]>([0, 100000]);
  const [changeDate, setChangeDate] = useState(true);
  const [checkFinanciamento, setCheckFinanciamento] = useState(false);
  const [checkParcelamento, setCheckParcelamento] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const componentRef = useRef(patientQuotesReportGlobalRef?.current ?? null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatório dos Orçamentos Finalizados",
  });

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue("professionalName", inputValue);

    if (!inputValue) {
      setSearchData({
        ...searchData,
        professionalName: "",
      } as SearchDataPatient);
      // setPatientQuotesFound([]);
    }
  };

  const clearFilters = () => {
    reset();
    setChangeDate(false);
    setCheckFinanciamento(false);
    setCheckParcelamento(false);
    setRangeValue([0, 100000] as number[]);
    setSearchData(getValues());
  };

  const handleDateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (!inputValue) {
      setSearchData({
        ...searchData,
        beginDate: null,
        endDate: null,
      } as SearchDataPatient);
    }
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChangeDate(true);
    } else {
      setChangeDate(false);
    }
  };

  function valueRangeText(value: number) {
    return `R$ ${value}`;
  }

  const handleFinanciamentoInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setCheckFinanciamento(true);
    } else {
      setCheckFinanciamento(false);
    }
  };

  const handleParcelamentoInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setCheckParcelamento(true);
    } else {
      setCheckParcelamento(false);
    }
  };

  const handleIdQuoteInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue("idQuote", inputValue);

    if (!inputValue) {
      setSearchData({
        ...searchData,
        idQuote: null,
      } as SearchDataPatient);
    }
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setRangeValue(newValue as number[]);
  };

  const schema = yup.object().shape({
    patientName: yup.string(),
    beginDate: yup.date().typeError("*Data inválida").nullable(),
    endDate: yup.date().typeError("*Data inválida").nullable(),
    beginDateCreate: yup.date().typeError("*Data inválida").nullable(),
    endDateCreate: yup.date().typeError("*Data inválida").nullable(),
    chooseDate: yup.number(),
  });

  const { register, handleSubmit, control, setValue, reset, getValues } =
    useForm<Omit<SearchDataPatient, "patientName">>({
      resolver: yupResolver(schema),
      defaultValues: {
        beginDate: searchData.beginDate
          ? moment(searchData.beginDate).format("YYYY-MM-DD")
          : null,
        endDate: searchData.endDate
          ? moment(searchData.endDate).format("YYYY-MM-DD")
          : null,
        beginDateCreate: searchData.beginDateCreate
          ? moment(searchData.beginDateCreate).format("YYYY-MM-DD")
          : null,
        endDateCreate: searchData.endDateCreate
          ? moment(searchData.endDateCreate).format("YYYY-MM-DD")
          : null,
        paymentMethod: searchData.paymentMethod ?? {
          financing: false,
          credit: false,
        },
        range: searchData.range ?? [0, 100000],
        chooseDate: searchData.chooseDate ?? 0,
      },
    });

  const onSubmit = async (data: Omit<SearchDataPatient, "patientName">) => {
    setSearchData(data);
    searchPatientQuotesReport(data);
  };

  useEffect(() => {
    if (!Object.keys(searchData).length) {
      return;
    }

    setCheckFinanciamento(searchData?.paymentMethod?.financing);
    setCheckParcelamento(searchData?.paymentMethod?.credit);
    setRangeValue(searchData.range);
  }, [searchData]);

  useEffect(() => {
    componentRef.current = patientQuotesReportGlobalRef;
  }, [patientQuotesReportGlobalRef]);

  return (
    <div className="row gx-2">
      <div className="col col-auto pb-2">
        <Button
          className="btn-sm btn-outline-primary"
          onClick={() => {
            componentRef.current = patientQuotesReportGlobalRef;
            handlePrint();
          }}
        >
          <i className="uil uil-print mr-2" />
          Imprimir Orçamento
        </Button>
      </div>

      <form className="col col-auto pb-2" onSubmit={handleSubmit(onSubmit)}>
        <C.Container>
          <Dropdown autoClose={false} show={openDropdown}>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="dark"
              size="sm"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              FILTROS
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                width: "90vw",
                maxHeight: "29rem",
                maxWidth: "22rem",
                overflowY: "auto",
                zIndex: 3,
                paddingBottom:0
              }}
            >
              <Dropdown.Header className="lh-1">FILTROS</Dropdown.Header>

              <Dropdown.Divider />

              <Dropdown.ItemText>
                <div className="row">
                  <div className="form-group col-12 w-full">
                    <Input
                      label="Nome"
                      labelClassName="small text-muted mb-0"
                      type="text"
                      placeholder="Nome do Médico"
                      register={register("professionalName")}
                      onChange={handleTextInput}
                      defaultValue={searchData.professionalName}
                    />
                  </div>

                  <div className="form-group col-12 w-full">
                    <Input
                      label="Id do Orçamento"
                      labelClassName="small text-muted mb-0"
                      type="number"
                      placeholder="Id do Orçamento"
                      register={register("idQuote")}
                      onChange={handleIdQuoteInput}
                      defaultValue={searchData.idQuote ?? ""}
                    />
                  </div>

                  <div className="form-group col-12 w-full">
                    <Controller
                      name="paymentMethod"
                      control={control}
                      render={({ field }) => (
                        <>
                          <label className="small text-muted mb-0">
                            Tipo de Pagamento
                          </label>
                          <fieldset className="checkbox-group justify-content-center">
                            <label>
                              <input
                                type="checkbox"
                                name="tipo-usuario"
                                checked={checkFinanciamento}
                                className="checkbox-input"
                                onChange={(event) => {
                                  field.onChange({
                                    financing: event.target.checked,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    credit: field.value?.credit,
                                  });
                                  handleFinanciamentoInput(event);
                                }}
                              />
                              <span className="checkbox-tile">
                                <span className="d-flex align-items-center checkbox-label">
                                  <div
                                    className="badge badge-dark text-white rounded-circle p-2 text-center mr-2"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Parcelamento"
                                  >
                                    <i className="uil uil-credit-card"></i>
                                  </div>
                                  <span>Financiamento</span>
                                </span>
                              </span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="tipo-usuario"
                                checked={checkParcelamento}
                                className="checkbox-input"
                                onChange={(event) => {
                                  field.onChange({
                                    credit: event.target.checked,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    financing: field.value?.financing,
                                  });
                                  handleParcelamentoInput(event);
                                }}
                              />
                              <span className="checkbox-tile">
                                <span className="d-flex align-items-center checkbox-label">
                                  <div
                                    className="badge badge-primary text-white rounded-circle p-2 text-center mr-2"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Parcelamento"
                                  >
                                    <i className="uil uil-file-landscape-alt"></i>
                                  </div>
                                  <span>Parcelamento</span>
                                </span>
                              </span>
                            </label>
                          </fieldset>
                        </>
                      )}
                    ></Controller>
                  </div>

                  <div className="form-group col-11 w-full">
                    <div>
                      <Controller
                        name="range"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Typography
                              id="range-slider"
                              gutterBottom
                              className="small text-muted mb-0"
                            >
                              Valor entre
                            </Typography>
                            <Slider
                              value={rangeValue}
                              max={100000}
                              min={0}
                              onChange={(event) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                field.onChange(event.target?.value);
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                handleChange(event, event.target?.value);
                              }}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              valueLabelFormat={valueRangeText}
                              getAriaValueText={valueRangeText}
                            />
                          </>
                        )}
                      ></Controller>
                    </div>
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data Criação de"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="beginDateCreate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data Criação até"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="endDateCreate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="chooseDate"
                      className="small text-muted mb-0"
                    >
                      Data
                    </label>
                    <Controller
                      name="chooseDate"
                      control={control}
                      render={({ field }) => (
                        <div className="row w-full justify-content-center align-items-center">
                          <Typography className="col-5 w-full">
                            Procedimento
                          </Typography>
                          <Switch
                            className="w-full"
                            onChange={(event) => {
                              field.onChange(!event.target.checked ? 0 : 1);
                              handleChangeDate(event);
                            }}
                            color="primary"
                          />
                          <Typography className="col-4 w-full">
                            Limite
                          </Typography>
                        </div>
                      )}
                    ></Controller>
                  </div>
                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data de"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="beginDate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>
                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data até"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="endDate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>
                </div>
              </Dropdown.ItemText>
              <Dropdown.ItemText
                className="position-sticky bg-white py-2 border-top"
                style={{ bottom: 0 }}
              >
                <div className="row justify-content-between">
                  <div className="col col-auto">
                    <a
                      className="btn btn-light btn-sm text-uppercase"
                      onClick={clearFilters}
                    >
                      <i className="uil uil-times mr-2" />
                      Limpar Filtros
                    </a>
                  </div>
                  <div className="col col-auto">
                    <Button
                      type="submit"
                      className="btn-primary btn-sm text-uppercase"
                    >
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </C.Container>
      </form>
    </div>
  );
};

export default PatientQuoteReportSearch;
