import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import { useUser } from "providers/User";
import { AddressRegisterData } from "types/register";
import { brazilianStates } from "utils/brazilianStates";
import checkCep from "utils/checkCep";
import { handleCepInput } from "utils/handleInput";

const EditAddressDataForm = () => {
  const { userData, editUserData, isLoading } = useUser();
  const [inputCep, setInputCep] = useState("");
  const [isSearchingCep, setIsSearchingCep] = useState(false);

  const schema = yup.object().shape({
    addressCEP: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*Deve ter 8 dígitos"),
    addressRoad: yup.string().required("*Campo obrigatório").uppercase().trim(),
    addressNumber: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressComplement: yup.string().uppercase().trim(),
    addressDistrict: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressCity: yup.string().required("*Campo obrigatório").uppercase().trim(),
    addressState: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "selecione" ? false : true
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<AddressRegisterData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: AddressRegisterData) => {
    editUserData(data);
  };

  return (
    <ContainerAnimation>
      <div className="tab-pane fade active show" id="dados-endereco">
        <form
          className="form-box p-3 p-sm-4 p-xl-5"
          onSubmit={handleSubmit(onSubmit)}>
          <h5 className="mb-4">Endereço</h5>
          <div className="form-group">
            <div className="row align-items-end">
              <div className="col">
                <Input
                  label="Consulte pelo CEP:"
                  placeholder="99999-999"
                  type="text"
                  register={register("addressCEP")}
                  error={errors?.addressCEP?.message}
                  onChange={(e) =>
                    handleCepInput(e, setValue, "addressCEP", setInputCep)
                  }
                  defaultValue={userData.addressCEP}
                />
                <div className="small pt-2">
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    rel="noreferrer noopener">
                    Não sei meu CEP!
                  </a>
                </div>
              </div>
              <div className="col col-auto pl-0 pr-md-0 align-self-center mt-2">
                <Button
                  type="button"
                  className="btn-sm btn-dark"
                  onClick={() =>
                    checkCep(inputCep, setIsSearchingCep, setValue, setFocus)
                  }
                  disabled={isLoading || isSearchingCep}>
                  Consultar
                  {!!isSearchingCep && (
                    <CircularProgress
                      color="inherit"
                      size={12}
                      className="align-text-top ms-2"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Endereço"
                  placeholder="Nome da Rua"
                  type="text"
                  register={register("addressRoad")}
                  error={errors?.addressRoad?.message}
                  defaultValue={userData.addressRoad}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Número"
                  placeholder="nº"
                  type="text"
                  register={register("addressNumber")}
                  error={errors?.addressNumber?.message}
                  defaultValue={userData.addressNumber}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Complemento"
                  placeholder="Casa, apto, bloco"
                  type="text"
                  register={register("addressComplement")}
                  error={errors?.addressComplement?.message}
                  defaultValue={userData.addressComplement}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <Input
                className="text-uppercase"
                label="Bairro"
                placeholder="Nome do Bairro"
                type="text"
                register={register("addressDistrict")}
                error={errors?.addressDistrict?.message}
                defaultValue={userData.addressDistrict}
              />
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Cidade"
                  placeholder="Nome da Cidade"
                  type="text"
                  error={errors?.addressCity?.message}
                  register={register("addressCity")}
                  defaultValue={userData.addressCity}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="label" htmlFor="Estado">
                Estado:
              </label>
              <select
                id="Estado"
                className="form-control required"
                {...register("addressState")}
                defaultValue={userData.addressState}>
                <option value="selecione" disabled>
                  Selecione
                </option>
                {brazilianStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <div className="text-danger">
                <small>
                  {!!errors?.addressState && "*Selecione uma opção"}
                </small>
              </div>
            </div>
          </div>

          <div className="text-center pt-4">
            <Button
              type="submit"
              className="btn-primary"
              disabled={isLoading || isSearchingCep}>
              Salvar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </ContainerAnimation>
  );
};

export default EditAddressDataForm;
