import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";
import { Badge } from "react-bootstrap";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { useUser } from "providers/User";

import UserCard from "../UserCard";

interface InactiveUserList {
  searchData: string;
}

const InactiveUserList = ({ searchData }: InactiveUserList) => {
  const inactiveUsersRef = useRef<HTMLDivElement>(null);

  const {
    inactiveUsers,
    inactiveUsersTotal,
    inactiveUsersTotalPages,
    isLoadingInactiveUsers,
    inactiveUsersCurrentPage,
    getInactiveUsers,
    setInactiveUsersCurrentPage,
  } = useUser();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInactiveUsersCurrentPage(value);

    getInactiveUsers(value, searchData);

    inactiveUsersRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getInactiveUsers(1, searchData);
  }, [searchData]);

  return (
    <>
      <div ref={inactiveUsersRef}></div>

      {!!isLoadingInactiveUsers &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de usuários inativos */}
      {!isLoadingInactiveUsers && (
        <>
          {!!inactiveUsers.length &&
            inactiveUsers?.map((inactiveUser) => (
              <UserCard key={inactiveUser.id} user={inactiveUser} />
            ))}

          {/* Lista de usuários inativos vazia */}
          {!inactiveUsers.length && (
            <EmptyList icon="uil-user-md" title="Nenhum usuário inativo" />
          )}
        </>
      )}
      {inactiveUsersTotalPages > 1 && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={inactiveUsersTotalPages}
          page={inactiveUsersCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}
    </>
  );
};

export default InactiveUserList;
