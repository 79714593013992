import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Theme, StylesConfig } from "react-select";
import { SelectFetch } from "react-select-fetch";

import { API_URL } from "config";
import { getCompanyOptions } from "utils/getOptions";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
     * multiValue(remove)/color:hover
     */
    danger: "var(--danger)",
    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: "var(--danger-light)",
    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: "var(--neutral-0)",
    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "var(--neutral-5)",
    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "var(--neutral-10)",
    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: "var(--neutral-20)",
    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: "var(--neutral-30)",
    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "var(--neutral-40)",
    /*
     * placeholder/color
     */
    neutral50: "var(--neutral-50)",
    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: "var(--primary)",
    /*
     * option/backgroundColor(focused)
     */
    primary25: "var(--primary-25)",
    /*
     * option/backgroundColor:active
     */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

interface CompanySelectProps {
  setCompanyId: (companyId: number) => void;
  setProfessionalId: (professionalId: number) => void;

  setSelectedProfessional: Dispatch<
    SetStateAction<{
      value: number;
      label: string;
    } | null>
  >;
}

export default function CompanySelect({
  setCompanyId,
  setProfessionalId,

  setSelectedProfessional,
}: CompanySelectProps) {
  const [selectedCompany, setSelectedCompany] = useState<{
    value: number;
    label: string;
  } | null>(null);

  useEffect(() => {
    setSelectedCompany(null);
    setCompanyId(-1);
    
    setSelectedProfessional(null);
    setProfessionalId(-1);
  }, []);

  const handleCompanyChange = (newValue: { value: number; label: string }) => {
    if (!newValue) {
      setSelectedCompany({ value: -1, label: "" });
      setCompanyId(-1);

      setSelectedProfessional({ value: -1, label: "" });
      setProfessionalId(-1);

      return;
    }

    setSelectedCompany(newValue);

    setCompanyId(newValue.value);
  };

  const customStyles: StylesConfig = {
    control: (base: Record<string, unknown>) => ({
      ...base,
      height: 48,
      borderColor: "var(--border)",
      cursor: "pointer",
      "&:hover": {},
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      textTransform: "uppercase",
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      cursor: "pointer",
      textTransform: "uppercase",
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <SelectFetch
      loadingMessage={() => "Carregando..."}
      noOptionsMessage={() => "Nenhum resultado encontrado"}
      theme={customTheme}
      queryParams={{
        limit: 10,
      }}
      placeholder="Buscar Instituição"
      url={`${API_URL}Company/search`}
      get={getCompanyOptions}
      isClearable
      styles={customStyles}
      onChange={(newValue) => {
        handleCompanyChange(newValue as { value: number; label: string });
      }}
      value={selectedCompany}
    />
  );
}
