import { Modal } from "react-bootstrap";

import ModalComponent from "components/ModalComponent";

interface SolicitationSendedModalProps {
  show: boolean;
  onHide: () => void;
}

const SolicitationSendedModal = ({
  show,
  onHide,
}: SolicitationSendedModalProps) => {
  const phone = "(41) 98787-1851";
  return (
    <ModalComponent
      title="Solicitação de Negociação enviada"
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Modal.Body>
        <div className="modal-body text-body">
          <p>
            Sua solicitação foi entregue e está em analise favor aguarde e/ou
            contate nosso suporte por meio de telefone/WhatsApp: {phone}
          </p>
        </div>
      </Modal.Body>
    </ModalComponent>
  );
};

export default SolicitationSendedModal;
