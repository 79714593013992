const RequiredDocuments = () => {
  return (
    <>
      <b>Documentos obrigatórios:</b>
      <br />
      <br />
      <ul className="text-start ps-3">
        <li>RG ou CNH</li>
        <li>Orçamento</li>
        <li>Comprovante de residência</li>
        <li>Comprovante de renda</li>
        <ul className="text-start ps-3">
          <li>Holerite ou extrato bancário dos últimos 3 meses</li>
          <li>Ou imposto de renda referente ao ano anterior</li>
        </ul>
      </ul>
    </>
  );
};

export default RequiredDocuments;
