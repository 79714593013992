/* eslint-disable import/order */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";

import NavTab from "./components/NavTabs";
import GeneralTerms from "components/GeneralTerms";
import ContractModals from "components/ContractModals";
import Contract from "./components/Contract";

const TermsAndContract = () => {
  const [contractShow, setContractShow] = useState(false);

  const location = useLocation();

  const { userContract } = useAuth();

  const [selectedNavTab, setSelectedNavTab] = useState(
    (location.state as string) || "terms"
  );

  return (
    <>
      <ContainerAnimation className="content">
        <div className="bg-white">
          <div className="container">
            <div className="row justify-content-between align-items-end">
              <div className="col">
                <h1 className="mb-4">Termos e Contratos</h1>
                <NavTab
                  selectedNavTab={selectedNavTab}
                  setSelectedNavTab={setSelectedNavTab}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="tab-content">
            {selectedNavTab === "terms" && <GeneralTerms />}

            {selectedNavTab === "contract" && (
              <Contract handleContractNotSigned={() => setContractShow(true)} />
            )}
          </div>
        </div>
      </ContainerAnimation>
      <ContractModals
        contractShow={contractShow}
        setContractShow={setContractShow}
        onHide={() => setSelectedNavTab("terms")}
      />
    </>
  );
};

export default TermsAndContract;
