import { createContext, ReactNode, useContext, useState } from "react";

import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { api } from "services";
import { ITeamMemberForm, TeamMember } from "types";
import { toast } from "utils/toast";

interface TeamProviderProps {
  children: ReactNode;
}

interface TeamProviderData {
  teamMembers: TeamMember[];
  teamMembersTotal: number;
  teamMembersTotalPages: number;

  getTeamMembers: (currentPage?: number) => Promise<void>;

  createTeamMember: (
    data: ITeamMemberForm,
    onHide: () => void
  ) => Promise<void>;
  editTeamMember: (data: ITeamMemberForm, onHide: () => void) => Promise<void>;
  removeTeamMember: (teamMemberId: number) => Promise<void>;

  isLoadingTeamMembers: boolean;
}

export const TeamContext = createContext<TeamProviderData>(
  {} as TeamProviderData
);

export const TeamProvider = ({ children }: TeamProviderProps) => {
  const teamMembersLimit = 10;

  const { userData } = useUser();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const [teamMembersTotal, setTeamMembersTotal] = useState(0);
  const [teamMembersTotalPages, setTeamMembersTotalPages] = useState(0);
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);

  const getTeamMembers = async (currentPage = 1) => {
    setIsLoadingTeamMembers(true);

    try {
      const response = await api.get("/bankAccounts", {
        params: {
          _page: currentPage,
          // _limit: teamMembersLimit,
        },
      });

      setIsLoadingTeamMembers(false);
      setTeamMembers(response.data);
      setTeamMembersTotal(Number(response.headers["x-total-count"]));

      setTeamMembersTotalPages(
        Math.ceil(Number(response.headers["x-total-count"]) / teamMembersLimit)
      );
    } catch (error) {
      console.error(error);
      setIsLoadingTeamMembers(false);
    }
  };

  const createTeamMember = async (
    data: ITeamMemberForm,
    onHide: () => void
  ) => {
    const { bank, accountType, category, ...rest } = data;
    const teamMemberCreationData = {
      ...rest,
      bankCode: bank.value,
      bankName: bank.label.split(" - ")[1],
      accountType: parseInt(accountType.value),
      category: category.value,
      userId: userData.id,
    };

    try {
      await api.post("/teamMembers", teamMemberCreationData);

      toast.fire({
        icon: "success",
        title: "Memebro de equipe cadastrado com sucesso!",
      });

      getTeamMembers();
      onHide();
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const editTeamMember = async (data: ITeamMemberForm, onHide: () => void) => {
    const { bank, accountType, category, ...rest } = data;
    const teamMemberUpdateData = {
      ...rest,
      category: category.value,
      bankCode: bank?.value,
      bankName: bank?.label.split(" - ")[1],
      accountType: parseInt(accountType?.value),
    };

    try {
      await api.patch(`/teamMembers/${data.id}`, teamMemberUpdateData);

      getTeamMembers();
      onHide();
      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const removeTeamMember = async (teamMemberId: number) => {
    try {
      await api.delete(`/teamMembers/${teamMemberId}`);

      getTeamMembers();
      toast.fire({
        icon: "success",
        title: "Membro de equipe excluído com sucesso!",
      });
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  return (
    <TeamContext.Provider
      value={{
        teamMembers,
        teamMembersTotal,
        teamMembersTotalPages,

        isLoadingTeamMembers,

        getTeamMembers,
        createTeamMember,
        editTeamMember,
        removeTeamMember,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => useContext(TeamContext);
