import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select, { Theme, StylesConfig } from "react-select";
import { SelectFetch } from "react-select-fetch";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import { API_URL } from "config";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { useProfessional } from "providers/Professional";
import {
  ContractStatus,
  ContractStatusDescription,
  RoleDescription,
} from "types/enums";
import { SearchDataProfessional } from "types/professional";
import { getCompanyOptions } from "utils/getOptions";

import * as C from "./styles";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
    * multiValue(remove)/color:hover
    */
    danger: "var(--danger)",
    /*
    * multiValue(remove)/backgroundColor(focused)
    * multiValue(remove)/backgroundColor:hover
    */
    dangerLight: "var(--danger-light)",
    /*
    * control/backgroundColor
    * menu/backgroundColor
    * option/color(selected)
    */
    neutral0: "var(--neutral-0)",
    /*
    * control/backgroundColor(disabled)
    */
    neutral5: "var(--neutral-5)",
    /*
    * control/borderColor(disabled)
    * multiValue/backgroundColor
    * indicators(separator)/backgroundColor(disabled)
    */
    neutral10: "var(--neutral-10)",
    /*
    * control/borderColor
    * option/color(disabled)
    * indicators/color
    * indicators(separator)/backgroundColor
    * indicators(loading)/color
    */
    neutral20: "var(--neutral-20)",
    /*
    * control/borderColor(focused)
    * control/borderColor:hover
    */
    neutral30: "var(--neutral-30)",
    /*
    * menu(notice)/color
    * singleValue/color(disabled)
    * indicators/color:hover
    */
    neutral40: "var(--neutral-40)",
    /*
    * placeholder/color
    */
    neutral50: "var(--neutral-50)",
    /*
    * indicators/color(focused)
    * indicators(loading)/color(focused)
    */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
    * input/color
    * multiValue(label)/color
    * singleValue/color
    * indicators/color(focused)
    * indicators/color:hover(focused)
    */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
    * control/boxShadow(focused)
    * control/borderColor(focused)
    * control/borderColor:hover(focused)
    * option/backgroundColor(selected)
    * option/backgroundColor:active(selected)
    */
    primary: "var(--primary)",
    /*
    * option/backgroundColor(focused)
    */
    primary25: "var(--primary-25)",
    /*
    * option/backgroundColor:active
    */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

const ProfessionalSearch = () => {
  const { userRole } = useAuth();
  const { isLoadingProfessionalCompanies, professionalCompanies } =
    useCompany();
  const {
    searchData,
    setSearchData,
    searchInactiveProfessionals,
    searchActiveProfessionals,
  } = useProfessional();

  const professionalContracts: { value?: ContractStatus; label: string }[] = [
    { value: ContractStatus.None, label: "Pendente" },
    { value: ContractStatus.Signed, label: "Assinado" },
    { value: ContractStatus.Rejected, label: "Rejeitado" },
    { value: ContractStatus.Negociating, label: "Negociando" },
  ];

  const customStyles: StylesConfig = {
    control: (base: Record<string, unknown>) => ({
      ...base,
      height: 48,
      borderColor: "var(--border)",
      cursor: "pointer",
      "&:hover": {},
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      textTransform: "uppercase",
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      cursor: "pointer",
      textTransform: "uppercase",
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      position: "relative",
      zIndex: 5,
    }),
  };

  const [openDropdown, setOpenDropdown] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [selectedContract, setSelectedContract] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [professionalName, setProfessionalName] = useState<string>(
    searchData.search
  );

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue("search", inputValue);
    setProfessionalName(inputValue);

    if (!inputValue) {
      setSearchData({
        ...searchData,
        search: "",
      } as SearchDataProfessional);
    }
  };

  const handleCompanyChange = (newValue: { value: number; label: string }) => {
    if (!newValue) {
      setSelectedCompany({ value: -1, label: "" });
      return;
    }

    setSelectedCompany(newValue);
  };

  const handleContractChange = (newValue: { value: number; label: string }) => {
    if (!newValue) {
      setSelectedContract({ value: -1, label: "" });
      return;
    }

    setSelectedContract(newValue);
  };

  const clearFilters = () => {
    reset();
    setSelectedCompany({ value: -1, label: "" });
    setSelectedContract({ value: -1, label: "" });
    setProfessionalName("");
    setSearchData(getValues());
  };

  const schema = yup.object().shape({
    search: yup.string(),
    companyId: yup.number(),
    contract: yup.number(),
  });

  const { register, handleSubmit, control, setValue, reset, getValues } =
    useForm<SearchDataProfessional>({
      resolver: yupResolver(schema),
      defaultValues: {
        search: searchData.search ?? "",
        companyId: searchData.companyId ?? -1,
        contract: searchData.contract ?? -1,
      },
    });

  const onSubmit: SubmitHandler<SearchDataProfessional> = async (
    data: SearchDataProfessional
  ) => {
    const result = {
      ...data,
      search: professionalName,
      companyId: selectedCompany?.value,
      companyName: selectedCompany?.label,
      contract: selectedContract?.value,
      contractName: selectedContract?.label,
    };

    setSearchData(result);

    searchInactiveProfessionals(result);
    searchActiveProfessionals(result);
    setOpenDropdown(false);
  };

  useEffect(() => {
    if (userRole === RoleDescription.Admin) {
      setSelectedCompany(null);

      setSearchData({
        ...searchData,
        companyId: undefined,
        companyName: "",
      } as SearchDataProfessional);
    } else if (professionalCompanies.length > 0) {
      setSelectedCompany({
        value: professionalCompanies[0].id,
        label: professionalCompanies[0].name,
      });

      setSearchData({
        ...searchData,
        companyId: professionalCompanies[0].id,
        companyName: professionalCompanies[0].name,
      } as SearchDataProfessional);
    }
  }, [professionalCompanies]);

  useEffect(() => {
    setProfessionalName(searchData.search);

    setSelectedCompany({
      value: searchData.companyId ?? -1,
      label: searchData.companyName ?? "",
    });

    setSelectedContract({
      value: searchData.contract ?? -1,
      label: searchData.contractName ?? "",
    });
  }, [searchData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <C.Container>
          <Dropdown autoClose={false} show={openDropdown}>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="dark"
              size="sm"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              FILTROS
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                width: "90vw",
                maxHeight: "29rem",
                maxWidth: "22rem",
                overflowY: "auto",
                zIndex: 3,
                paddingBottom:0
              }}
            >
              <Dropdown.Header className="lh-1">FILTROS</Dropdown.Header>

              <Dropdown.Divider />

              <Dropdown.ItemText>
                <div className="row">
                  <div className="form-group col-12 w-full">
                    <Input
                      label="Nome do Profissional"
                      labelClassName="small text-muted mb-0"
                      type="text"
                      placeholder="Nome do profissional"
                      register={register("search")}
                      onChange={handleTextInput}
                      defaultValue={searchData.search}
                      value={professionalName}
                    />
                  </div>

                  <div className="form-group col-12 w-full flex-fill">
                    {userRole === RoleDescription.Secretary ? (
                      <>
                        <label className="small text-muted mb-0">
                          Selecione a Empresa
                        </label>
                        <Controller
                          name="companyId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              options={professionalCompanies.map(
                                (company) => ({
                                  value: company.id,
                                  label: company.name,
                                })
                              )}
                              onChange={(newValue) => {
                                const company = newValue as {
                                  value: number;
                                  label: string;
                                };

                                field.onChange(company?.value ?? -1);

                                handleCompanyChange(
                                  newValue as { value: number; label: string }
                                );
                              }}
                              placeholder="Buscar Instituição"
                              isClearable={true}
                              value={selectedCompany}
                              theme={customTheme}
                              styles={customStyles}
                              isDisabled={isLoadingProfessionalCompanies}
                            />
                          )}
                        ></Controller>
                      </>
                    ) : userRole === RoleDescription.Admin ? (
                      <>
                        <label className="small text-muted mb-0">
                          Selecione a Empresa
                        </label>
                        <Controller
                          name="companyId"
                          control={control}
                          render={({ field }) => (
                            <SelectFetch
                              loadingMessage={() => "Carregando..."}
                              noOptionsMessage={() =>
                                "Nenhum resultado encontrado"
                              }
                              theme={customTheme}
                              queryParams={{
                                limit: 10,
                              }}
                              placeholder="Buscar Instituição"
                              url={`${API_URL}Company/search`}
                              get={getCompanyOptions}
                              isClearable
                              styles={customStyles}
                              onChange={(newValue) => {
                                const company = newValue as {
                                  value: number;
                                  label: string;
                                };

                                field.onChange(company?.value ?? -1);

                                handleCompanyChange(company);
                              }}
                              value={selectedCompany}
                            />
                          )}
                        ></Controller>
                      </>
                    ) : null}
                  </div>

                  {userRole === RoleDescription.Admin && (
                    <div className="form-group">
                      <>
                        <label className="small text-muted mb-0">
                          Selecione o Status do Contrato
                        </label>
                        <Controller
                          name="contract"
                          control={control}
                          render={({ field }) => (
                            <Select
                              options={professionalContracts.map(
                                (contract) => ({
                                  value: contract.value,
                                  label: contract.label,
                                })
                              )}
                              onChange={(newValue) => {
                                const contract = newValue as {
                                  value: number;
                                  label: string;
                                };

                                field.onChange(contract?.value ?? -1);

                                handleContractChange(contract);
                              }}
                              placeholder="Status de Contrato"
                              isClearable={true}
                              value={selectedContract}
                              theme={customTheme}
                              styles={customStyles}
                            />
                          )}
                        ></Controller>
                      </>
                    </div>
                  )}
                </div>
              </Dropdown.ItemText>

              <Dropdown.ItemText
                className="position-sticky bg-white py-2 border-top"
                style={{ bottom: 0 }}
              >
                <div className="row justify-content-between">
                  <div className="col col-auto">
                    <a
                      className="btn btn-light btn-sm text-uppercase"
                      onClick={clearFilters}
                    >
                      <i className="uil uil-times mr-2" />
                      Limpar Filtros
                    </a>
                  </div>
                  <div className="col col-auto">
                    <Button
                      type="submit"
                      className="btn-primary btn-sm text-uppercase"
                    >
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </C.Container>
    </form>
  );
};

export default ProfessionalSearch;
