import { AxiosResponse } from "axios";
import { createContext, ReactNode, useContext, useState } from "react";

import { api } from "services";
import { FormQuote } from "types/formQuote";
import { AuditLog, PendingItem } from "types/quotes";
import { toast } from "utils/toast";

interface QuoteDetailsProviderProps {
  children: ReactNode;
}

interface QuoteDetailsProviderData {
  getQuotePendingItems: (quoteId: number) => Promise<void>;
  getFormQuote: (quoteId: number) => Promise<void>;
  createFormQuote: (
    body: FormQuote,
    quoteId: number,
    onHide: () => void
  ) => Promise<void>;
  editFormQuote: (
    body: FormQuote,
    quoteId: number,
    onHide: () => void
  ) => Promise<void>;
  removeQuoteForm: (id: number, quoteId: number) => Promise<void>;
  pendingItems: PendingItem[];
  isLoading: boolean;
  formQuoteForm: FormQuote[];
  setFormQuoteForm: React.Dispatch<React.SetStateAction<FormQuote[]>>;
  saveFormAsDraft: boolean;
  setSaveFormAsDraft: React.Dispatch<React.SetStateAction<boolean>>;

  auditLogs: AuditLog[];
  getAuditLogs: (quoteId: number) => Promise<void>;
}

export const QuoteDetailsContext = createContext<QuoteDetailsProviderData>(
  {} as QuoteDetailsProviderData
);

export const QuoteDetailsProvider = ({
  children,
}: QuoteDetailsProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formQuoteForm, setFormQuoteForm] = useState<FormQuote[]>([]);
  const [saveFormAsDraft, setSaveFormAsDraft] = useState<boolean>(false);
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([]);

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);

  const getQuotePendingItems = async (quoteId: number) => {
    setIsLoading(true);

    try {
      const { data }: AxiosResponse<PendingItem[]> = await api.get(
        `/PendingItems/quote/${quoteId}`
      );

      setPendingItems(data);
      setIsLoading(false);
    } catch (error: any) {
      let message = "";

      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });

      setIsLoading(false);
    }
  };

  const getFormQuote = async (quoteId: number) => {
    setIsLoading(true);

    try {
      const { data }: AxiosResponse<FormQuote[]> = await api.get(
        `/Form/quote/${quoteId}`
      );
      await getQuotePendingItems(quoteId);

      setFormQuoteForm(data);
      setIsLoading(false);
    } catch (error: any) {
      let message = "";

      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });

      setIsLoading(false);
    }
  };

  const createFormQuote = async (
    body: FormQuote,
    quoteId: number,
    onHide: () => void
  ) => {
    setIsLoading(true);

    if (body.form.professionalData) {
      if (typeof body.form.professionalData.rent === "string") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        body.form.professionalData.rent = Number(
          body.form.professionalData.rent.replace(/\./g, "").replace(",", ".")
        );
      }
    }

    if (body.form.address) {
      if (
        body.form.address.RentValue &&
        typeof body.form.address.RentValue === "string"
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        body.form.address.RentValue = Number(
          body.form.address.RentValue.replace(/\./g, "").replace(",", ".")
        );
      }
    }

    try {
      await api.post(`/Form/quote/${quoteId}`, body.form);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Formuário criado com sucesso!",
      });

      getFormQuote(quoteId);

      setIsLoading(false);
    } catch (error: any) {
      let message = "";

      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });

      setIsLoading(false);
    }
  };

  const editFormQuote = async (
    body: FormQuote,
    quoteId: any,
    onHide: () => void
  ) => {
    setIsLoading(true);

    if (body.form.professionalData) {
      if (
        body.form.professionalData.rent &&
        typeof body.form.professionalData.rent === "string"
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        body.form.professionalData.rent = Number(
          body.form.professionalData.rent.replace(/\./g, "").replace(",", ".")
        );
      }
    }

    if (body.form.address) {
      if (
        body.form.address.RentValue &&
        typeof body.form.address.RentValue === "string"
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        body.form.address.RentValue = Number(
          body.form.address.RentValue.replace(/\./g, "").replace(",", ".")
        );
      }
    }

    try {
      await api.put(`/Form/${body?.id}/quote/${quoteId}`, body.form);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Formuário atualizado com sucesso!",
      });

      getFormQuote(quoteId);

      setIsLoading(false);
    } catch (error: any) {
      let message = "";

      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });

      setIsLoading(false);
    }
  };

  const removeQuoteForm = async (id: number, quoteId: number) => {
    setIsLoading(true);
    try {
      await api.delete(`/Form/${id}/quote/${quoteId}`);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Formulário removido com sucesso!",
      });

      getFormQuote(quoteId);
    } catch (error: any) {
      let message = "";

      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });

      setIsLoading(false);
    }
  };

  const getAuditLogs = async (quoteId: number) => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/Audit/entity/1/id/${quoteId}`);

      setAuditLogs(data);

      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
  };

  return (
    <QuoteDetailsContext.Provider
      value={{
        getQuotePendingItems,
        getFormQuote,
        createFormQuote,
        editFormQuote,
        removeQuoteForm,
        pendingItems,
        formQuoteForm,
        setFormQuoteForm,
        saveFormAsDraft,
        setSaveFormAsDraft,

        isLoading,

        auditLogs,
        getAuditLogs,
      }}
    >
      {children}
    </QuoteDetailsContext.Provider>
  );
};

export const useQuoteDetails = () => useContext(QuoteDetailsContext);
