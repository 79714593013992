import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import { autoCloseValidationMessage } from "pages/Login/utils/pwdRecoveryPopUp";
import { api } from "services/index";
import { NewPasswordData } from "types/auth";
import { toast } from "utils/toast";

interface NewPwdAdminFormProps {
  userId: number;
  onHide: () => void;
}

const NewPwdAdminForm = ({ userId, onHide }: NewPwdAdminFormProps) => {
  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*A senha deve ter pelo menos 8 caracteres")
      .matches(
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})(?=.*[!.@#$%^&*\"'¨()_+=`´{}~,<>;:?|\\]\\\\/[-])"
        ),
        "*Utilize letras maiúsculas e minúsculas, números e caracteres especiais (*#.!@)"
      ),
    newPasswordConfirm: yup
      .string()
      .required("*Campo obrigatório")
      .oneOf([yup.ref("newPassword")], "*As senhas não conferem"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: NewPasswordData) => {
    Swal.showLoading();
    const body = { userId, newPassword: data.newPassword };
    try {
      await api.post("/Auth/admin/passwordModify", body);
      Swal.hideLoading();
      Swal.close();
      toast.fire({
        icon: "success",
        title: "Senha alterada com sucesso!",
      });

      onHide();
    } catch (error: any) {
      Swal.hideLoading();

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro. Por favor, tente mais tarde.";
      }

      autoCloseValidationMessage(message, 5000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-2">
      <Input
        register={register("newPassword")}
        error={errors?.newPassword?.message}
        placeholder="Nova senha"
        autoFocus
        className="mb-3"
        isPasswordInput
      />
      <Input
        register={register("newPasswordConfirm")}
        error={errors?.newPasswordConfirm?.message}
        placeholder="Repetir nova senha"
        isPasswordInput
      />

      <Button type="submit" className="btn btn-primary mt-3">
        Salvar senha
      </Button>
    </form>
  );
};

export default NewPwdAdminForm;
