import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";
import { Badge } from "react-bootstrap";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { usePatient } from "providers/Patient";

import PatientCard from "../PatientCard";

interface InactivePatientList {
  companyId: number;
  searchData: string;
}

const InactivePatientList = ({
  companyId,
  searchData,
}: InactivePatientList) => {
  const inactivePatientsRef = useRef<HTMLDivElement>(null);

  const {
    inactivePatients,
    inactivePatientsTotal,
    inactivePatientsTotalPages,
    isLoadingInactivePatients,
    inactivePatientsCurrentPage,
    getInactivePatients,
    setInactivePatientsCurrentPage,
  } = usePatient();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInactivePatientsCurrentPage(value);

    getInactivePatients(companyId, value, searchData);

    inactivePatientsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getInactivePatients(companyId, 1, searchData);
  }, [companyId, searchData]);

  return (
    <>
      <div ref={inactivePatientsRef}></div>

      {!!isLoadingInactivePatients &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de pacientes inativos */}
      {!isLoadingInactivePatients && (
        <>
          {!!inactivePatients.length &&
            inactivePatients?.map((inactivePatient) => (
              <PatientCard
                key={inactivePatient.id}
                patient={inactivePatient}
                companyId={companyId}
              />
            ))}

          {/* Lista de pacientes inativos vazia */}
          {companyId === -1 ? (
            <EmptyList
              icon="uil-user-md"
              title="Por favor, selecione uma empresa acima"
            />
          ) : !inactivePatients.length ? (
            <EmptyList icon="uil-user-md" title="Nenhum paciente inativo" />
          ) : null}
        </>
      )}
      {inactivePatientsTotalPages > 1 && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={inactivePatientsTotalPages}
          page={inactivePatientsCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}
    </>
  );
};

export default InactivePatientList;
