import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

import NavTabItem from "./components";

interface NavTabsProps {
  selectedNavTab: string;
  setSelectedNavTab: React.Dispatch<React.SetStateAction<string>>;
}

const NavTab = ({ selectedNavTab, setSelectedNavTab }: NavTabsProps) => {
  const { userRole } = useAuth();

  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  return (
    <ul className="nav nav-tabs">
      <NavTabItem
        title="Termos"
        id="terms"
        selectedNavTab={selectedNavTab}
        handleTabClick={handleTabClick}
      />

      {(userRole === RoleDescription.Professional ||
        userRole === RoleDescription.Secretary) && (
        <NavTabItem
          title="Contrato de Parceria"
          id="contract"
          selectedNavTab={selectedNavTab}
          handleTabClick={handleTabClick}
        />
      )}
    </ul>
  );
};

export default NavTab;
