import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";
import { Badge } from "react-bootstrap";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { useUser } from "providers/User";

import UserCard from "../UserCard";

interface ActiveUserList {
  searchData: string;
}

const ActiveUserList = ({ searchData }: ActiveUserList) => {
  const activeUsersRef = useRef<HTMLDivElement>(null);

  const {
    activeUsers,
    activeUsersTotal,
    activeUsersTotalPages,
    isLoadingActiveUsers,
    activeUsersCurrentPage,
    getActiveUsers,
    setActiveUsersCurrentPage,
  } = useUser();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setActiveUsersCurrentPage(value);

    getActiveUsers(value, searchData);

    activeUsersRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getActiveUsers(1, searchData);
  }, [searchData]);

  return (
    <>
      {/* Linha divisória */}
      {!!activeUsers?.length && (
        <>
          <div ref={activeUsersRef}></div>

          {!!isLoadingActiveUsers &&
            [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

          {/* Lista de Usuários ativos */}
          {!isLoadingActiveUsers && (
            <>
              {!!activeUsers.length &&
                activeUsers?.map((activeUser) => (
                  <UserCard
                    key={activeUser.id}
                    user={activeUser}
                    isActive={true}
                  />
                ))}

                {/* Lista de usuários inativos vazia */}
                {!activeUsers.length && (
                  <EmptyList icon="uil-user-md" title="Nenhum usuário inativo" />
                )}
            </>
          )}

          {activeUsersTotalPages > 1 && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={activeUsersTotalPages}
              page={activeUsersCurrentPage}
              onChange={handleChangePage}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}
    </>
  );
};

export default ActiveUserList;
