/* eslint-disable import/order */
import { useState } from "react";
import { useLocation } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";

import EditAccessDataForm from "./components/EditAccesDataForm";
import EditAddressDataForm from "./components/EditAddressDataForm";
import EditPersonalDataForm from "./components/EditPersonalDataForm";
import EditProfessionalDataForm from "./components/EditProfessionalDataForm";
import FinancialData from "./components/FinancialData";
import NavTab from "./components/NavTabs";
import ProfessionalSecretaries from "pages/Secretaries/ProfessionalSecretaries";

const MyData = () => {
  const location = useLocation();

  const { userContract } = useAuth();

  const [selectedNavTab, setSelectedNavTab] = useState(
    (location.state as string) || "personal-data"
  );

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-end">
            <div className="col">
              <h1 className="mb-4">Meus Dados</h1>
              <NavTab
                selectedNavTab={selectedNavTab}
                setSelectedNavTab={setSelectedNavTab}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "personal-data" && <EditPersonalDataForm />}

          {selectedNavTab === "professional-data" && (
            <EditProfessionalDataForm />
          )}

          {selectedNavTab === "access-data" && <EditAccessDataForm />}

          {selectedNavTab === "address-data" && <EditAddressDataForm />}

          {selectedNavTab === "financial-data" && <FinancialData />}

          {!!userContract &&
            selectedNavTab === "professional-secretaries-data" && (
              <ProfessionalSecretaries />
            )}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default MyData;
