import { useState } from "react";
import { Dropdown } from "react-bootstrap";

import AlertModal from "components/AlertModal";
import { useCompany } from "providers/Company";
import { Professional } from "types/professional";
import { mobilePhoneMask } from "utils/formMasks";

interface CompanyProfessionalCardProps {
  companyId: number;
  companyProfessional?: Professional;
}

const CompanyProfessionalCard = ({
  companyId,
  companyProfessional,
}: CompanyProfessionalCardProps) => {
  const [alertModalShow, setAlertModalShow] = useState(false);

  const { removeProfessionalFromCompany, isLoading } = useCompany();

  return (
    <>
      <div className="row-item mb-2 px-3 pt-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do profissional */}
              <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
                <p className="small opacity-75 mb-0">Profissional</p>
                <b>
                  {companyProfessional?.socialName
                    ? `${companyProfessional?.socialName} (${companyProfessional?.name})`
                    : companyProfessional?.name}
                </b>
              </div>

              {/* CPF do profissional */}
              <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
                <p className="small opacity-75 mb-0">CPF</p>
                <b className="text-break">{companyProfessional?.cpf}</b>
              </div>

              {/* Registro profissional */}
              <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
                <p className="small opacity-75 mb-0">Registro Profissional</p>
                <b className="text-break">
                  {companyProfessional?.professionalRegister || "-"}
                </b>
              </div>

              {/* Contatos */}
              <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
                <p className="small opacity-75 mb-0">Contatos</p>
                <b className="text-break">{companyProfessional?.email}</b>
                <br />
                <b className="text-break">
                  {mobilePhoneMask(
                    `${companyProfessional?.dddMobilePhone}${companyProfessional?.mobilePhone}`
                  )}
                </b>
              </div>
            </div>
          </div>

          {/* Botão*/}
          <div className="col col-auto pb-3">
            <Dropdown>
              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => setAlertModalShow(true)}
                  className="text-danger">
                  <i className="uil uil-trash mr-2" />
                  Excluir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* Modal de confirmar desvinculação de profissional */}
      <AlertModal
        show={alertModalShow}
        onHide={() => setAlertModalShow(false)}
        title={"Tem certeza que deseja desvincular esse profissional?"}
        onClickButton={() =>
          removeProfessionalFromCompany(
            companyId,
            companyProfessional!.id,
            () => setAlertModalShow(false)
          )
        }
        btnVariant={"danger"}
        isLoading={isLoading}
      />
    </>
  );
};

export default CompanyProfessionalCard;
