const storagePrefix = "@docttorpay:";

export const locStorage = {
  getToken: () => {
    return JSON.parse(localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    localStorage.removeItem(`${storagePrefix}token`);
  },

  getUserId: () => {
    return JSON.parse(localStorage.getItem(`${storagePrefix}userId`) as string);
  },
  setUserId: (userId: number) => {
    localStorage.setItem(`${storagePrefix}userId`, JSON.stringify(userId));
  },
  clearUserId: () => {
    localStorage.removeItem(`${storagePrefix}userId`);
  },

  getUserRole: () => {
    return JSON.parse(
      localStorage.getItem(`${storagePrefix}userRole`) as string
    );
  },
  setUserRole: (userRole: string) => {
    localStorage.setItem(`${storagePrefix}userRole`, JSON.stringify(userRole));
  },
  clearUserRole: () => {
    localStorage.removeItem(`${storagePrefix}userRole`);
  },

  getHasTermsOfUse: () => {
    return JSON.parse(
      localStorage.getItem(`${storagePrefix}hasTermsOfUse`) as string
    );
  },
  setHasTermsOfUse: (hasTermsOfUse: boolean) => {
    localStorage.setItem(
      `${storagePrefix}hasTermsOfUse`,
      JSON.stringify(hasTermsOfUse)
    );
  },
  clearHasTermsOfUse: () => {
    localStorage.removeItem(`${storagePrefix}hasTermsOfUse`);
  },

  getNewPasswordRequired: () => {
    return JSON.parse(
      localStorage.getItem(`${storagePrefix}newPasswordRequired`) as string
    );
  },
  setNewPasswordRequired: (newPasswordRequired: boolean) => {
    localStorage.setItem(
      `${storagePrefix}newPasswordRequired`,
      JSON.stringify(newPasswordRequired)
    );
  },
  clearNewPasswordRequired: () => {
    localStorage.removeItem(`${storagePrefix}newPasswordRequired`);
  },
};

export const sessStorage = {
  getToken: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}token`) as string
    );
  },
  setToken: (token: string) => {
    sessionStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    sessionStorage.removeItem(`${storagePrefix}token`);
  },

  getUserId: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}userId`) as string
    );
  },
  setUserId: (userId: number) => {
    sessionStorage.setItem(`${storagePrefix}userId`, JSON.stringify(userId));
  },
  clearUserId: () => {
    sessionStorage.removeItem(`${storagePrefix}userId`);
  },

  getProfileId: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}profileId`) as string
    );
  },
  setProfileId: (profileId: number) => {
    sessionStorage.setItem(`${storagePrefix}profileId`, JSON.stringify(profileId));
  },
  clearProfileId: () => {
    sessionStorage.removeItem(`${storagePrefix}profileId`);
  },

  getUserRole: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}userRole`) as string
    );
  },
  setUserRole: (userRole: string) => {
    sessionStorage.setItem(
      `${storagePrefix}userRole`,
      JSON.stringify(userRole)
    );
  },
  clearUserRole: () => {
    sessionStorage.removeItem(`${storagePrefix}userRole`);
  },

  getHasTermsOfUse: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}hasTermsOfUse`) as string
    );
  },
  setHasTermsOfUse: (hasTermsOfUse: boolean) => {
    sessionStorage.setItem(
      `${storagePrefix}hasTermsOfUse`,
      JSON.stringify(hasTermsOfUse)
    );
  },
  clearHasTermsOfUse: () => {
    sessionStorage.removeItem(`${storagePrefix}hasTermsOfUse`);
  },

  getNewPasswordRequired: () => {
    return JSON.parse(
      sessionStorage.getItem(`${storagePrefix}newPasswordRequired`) as string
    );
  },
  setNewPasswordRequired: (newPasswordRequired: boolean) => {
    sessionStorage.setItem(
      `${storagePrefix}newPasswordRequired`,
      JSON.stringify(newPasswordRequired)
    );
  },
  clearNewPasswordRequired: () => {
    sessionStorage.removeItem(`${storagePrefix}newPasswordRequired`);
  },
};
