import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import LinkButton from "components/LinkButton";
import ModalComponent from "components/ModalComponent";
import { useRegister } from "providers/Register";
import { PatientRegisterAccessData } from "types/register";

import GeneralTerms from "../../../../../components/GeneralTerms";

const PatientAccessDataForm = () => {
  const navigate = useNavigate();
  const { isLoading, registerPatient } = useRegister();
  const [termsModalShow, setTermsModalShow] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido")
      .trim(),
    password: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*A senha deve ter pelo menos 8 caracteres")
      .matches(
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})(?=.*[!.@#$%^&*\"'¨()_+=`´{}~,<>;:?|\\]\\\\/[-])"
        ),
        "*Formato inválido"
      ),
    isAccepted: yup
      .boolean()
      .test(
        "termsValidator",
        "*É necessário concordar com os termos para completar o cadastro",
        (value) => !!value
      ),
    termsOfUse: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PatientRegisterAccessData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: PatientRegisterAccessData) => {
    delete data.isAccepted;
    registerPatient(data, navigate);
  };

  const handleTermsOfUseInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setValue("isAccepted", true);
      setValue("termsOfUse", moment().toISOString(true));
    } else {
      setValue("isAccepted", false);
    }
  };

  return (
    <>
      <form className="p-3 p-sm-4 p-xl-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-box p-3 p-sm-4 p-xl-5">
          <header className="row flex-nowrap pt-3 pb-4">
            <div className="col">
              <h5 className="pb-4">Dados de Acesso</h5>
            </div>
            <div className="col col-auto text-muted">
              <b className="text-primary">3</b>
              /3
            </div>
          </header>

          <div className="form-group">
            <Input
              label="E-mail:"
              placeholder="seu@email.com"
              type="text"
              register={register("email")}
              error={errors?.email?.message}
            />
            <small id="emailHelp" className="form-text text-muted">
              Nunca compartilharemos seu email.
            </small>
          </div>

          <div className="form-group">
            <Input
              label="Senha:"
              isPasswordInput
              register={register("password")}
              error={errors?.password?.message}
            />
            <small id="emailHelp" className="form-text text-muted">
              Utilize letras maiúsculas e minúsculas, números e caracteres
              especiais (*#.!@)
            </small>
          </div>

          <div className="form-group">
            <label
              htmlFor="acceptTerms"
              className="control control--checkbox mb-0"
            >
              <span className="caption">
                Ao prosseguir você concorda com os{" "}
                {/* TODO: adicionar Termos e Condições */}
                <LinkButton
                  className="align-baseline fs-6"
                  onClick={() => setTermsModalShow(true)}
                >
                  Termos Gerais
                </LinkButton>
                .
              </span>
              <input
                id="acceptTerms"
                type="checkbox"
                // defaultChecked={false}
                {...register("isAccepted")}
                onChange={handleTermsOfUseInput}
              />
              <div
                className={`control__indicator ${
                  !!errors?.isAccepted && "border border-danger"
                }`}
              ></div>
            </label>
            <div className="text-danger mb-3">
              <small>{!!errors?.isAccepted && errors.isAccepted.message}</small>
            </div>
          </div>
        </div>

        <div className="row no-gutters justify-content-between pt-3 pt-sm-4 pt-xl-5">
          <div className="col col-auto">
            <Button
              className=""
              type="button"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              Voltar
            </Button>
          </div>
          <div className="col col-auto">
            <Button className="btn-primary" type="submit" disabled={isLoading}>
              Finalizar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>
        </div>
      </form>

      {/* Modal de Termos */}
      <ModalComponent
        title="Termos Gerais"
        show={termsModalShow}
        onHide={() => setTermsModalShow(false)}
        size="lg"
      >
        <Modal.Body>
          <GeneralTerms />
        </Modal.Body>
      </ModalComponent>
    </>
  );
};

export default PatientAccessDataForm;
