import { Link } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";

import LoginForm from "./components/LoginForm";

const Login = () => {
  const { isLoading } = useAuth();

  return (
    <ContainerAnimation className="content container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6">
          <h1
            className="text-center aos-init"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-once
          >
            Login
          </h1>
          <p
            className="text-center mb-3 mb-sm-4 mb-xl-5 aos-init"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-once
            id="login-description"
          >
            Informe seu usuário e senha para entrar!
          </p>

          <LoginForm />

          <p
            className="text-center pt-3 pt-sm-4 pt-xl-5 aos-init"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once
            data-aos-anchor="#login-description"
          >
            Não possui login?{" "}
            <Link
              to="/cadastro"
              className="fw-bold"
              style={{ pointerEvents: isLoading ? "none" : undefined }}
            >
              Cadastre-se aqui
            </Link>
          </p>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Login;
