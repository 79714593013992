import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import Providers from "providers";

import App from "./App";

import "react-bootstrap/dist/react-bootstrap";
import "bootstrap/dist/css/bootstrap-utilities.min.css";
import "styles/bootstrap.min.css";

const root = ReactDOMClient.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Providers>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Providers>
  </React.StrictMode>
);
