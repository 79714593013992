import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import FileUploadCard from "components/FileUploadCard";
import { useQuoteDetails } from "providers/QuoteDetails";
import { api } from "services";
import { DatabaseFile, DocumentType } from "types/files";
import { toast } from "utils/toast";

interface FileData {
  fileTypes?: { value: string; fileName: string }[];
}

interface FileAddFormProps {
  quoteId: number;
  setDatabaseFiles: React.Dispatch<React.SetStateAction<DatabaseFile[]>>;
}

const FileAddForm = ({ quoteId, setDatabaseFiles }: FileAddFormProps) => {
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getQuotePendingItems } = useQuoteDetails();

  const schema = yup.object().shape({
    fileTypes: yup.array().of(
      yup.object().shape({
        value: newFiles.length
          ? yup.string().required("*Selecione o tipo do arquivo")
          : yup.string(),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FileData>({ resolver: yupResolver(schema) });

  const { fields, append, remove } = useFieldArray({
    name: "fileTypes",
    control,
  });

  const getFilesAttachment = async () => {
    try {
      const response = await api.get("/FileAttachment/find", {
        params: {
          idOrcamento: quoteId,
        },
      });

      setDatabaseFiles(response.data.listFiles);

      getQuotePendingItems(quoteId);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: FileData) => {
    setIsLoading(true);

    if (data.fileTypes) {
      try {
        for (let i = 0; i < newFiles.length; i++) {
          const formData = new FormData();

          formData.append("document", newFiles[i]);
          formData.append("idQuote", String(quoteId));
          formData.append("documentType", data.fileTypes[i].value);

          await api.post("/FileAttachment", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          setIsLoading(false);

          getFilesAttachment();
          remove();
          setNewFiles([]);

          toast.fire({
            icon: "success",
            title: "Arquivos anexados com sucesso!",
          });
        }
      } catch (error) {
        setIsLoading(false);

        toast.fire({
          icon: "error",
          title: "Erro ao anexar documentos.",
        });
      }
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const newFile = event.target.files[0];

      append({ value: "", fileName: newFile.name });

      setNewFiles([...newFiles, newFile]);
    }
  };

  const removeFile = (fieldIndex: number) => {
    remove(fieldIndex);
    setNewFiles(newFiles.filter((_, fileIndex) => fileIndex !== fieldIndex));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/FileAttachment/documenttypes");

        setDocumentTypes(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="custom-file mb-2">
        <input
          type="file"
          className="custom-file-input"
          onChange={handleFileSelect}
        />
        <label
          className="custom-file-label overflow-hidden"
          htmlFor="orcamento"
        >
          {!!newFiles.length && (
            <span>
              {newFiles.length} arquivo{newFiles.length > 1 && "s"}
            </span>
          )}

          {!newFiles.length && "Nenhum arquivo"}
        </label>
      </div>

      {fields.map((field, index) => (
        <FileUploadCard
          key={field.id}
          fileName={field.fileName}
          register={register(`fileTypes.${index}.value`)}
          documentTypes={documentTypes}
          index={index}
          error={errors.fileTypes?.[index]?.value?.message}
          removeFile={() => removeFile(index)}
        />
      ))}

      <div className="text-end pt-4">
        <Button
          type="submit"
          className="btn-sm btn-primary"
          disabled={!newFiles.length || isLoading}
        >
          Enviar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </div>
    </form>
  );
};

export default FileAddForm;
