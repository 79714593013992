import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const PatientUnconfirmedNewPassword = () => {
  return (
    <div className="modal-body text-body">
      <Modal.Body>
        <p>
          Você foi cadastrado(a) na plataforma DocttorPay com uma senha gerada
          automaticamente.
        </p>
        <p>
          Por motivos de segurança, você pode realizar a redefinição da sua
          senha{" "}
          <Link
            to="/meus-dados"
            state={"access-data"}
            className="align-baseline fs-6"
          >
            clicando aqui
          </Link>
          .
        </p>
      </Modal.Body>
    </div>
  );
};

export default PatientUnconfirmedNewPassword;
