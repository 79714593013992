import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { useEffect, useRef } from "react";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";

import ProfessionalQuoteReportCard from "../ProfessionalQuoteReportCard";

const ProfessionalQuoteReportList = () => {
  const professionalQuotesReportRef = useRef<HTMLDivElement>(null);
  const professionalQuotesReportListRef = useRef<HTMLDivElement>(null);

  const {
    professionalQuotesReport,
    professionalQuotesReportFound,
    professionalQuotesReportTotalPages,
    professionalQuotesReportFoundTotalPages,
    searchData,
    setSearchData,
    isLoadingProfessionalQuotesReport,
    professionalQuotesReportCurrentPage,
    professionalQuotesReportFoundCurrentPage,
    getProfessionalQuotesReport,
    searchProfessionalQuotesReport,
    setProfessionalQuotesReportCurrentPage,
    setProfessionalQuotesReportFoundCurrentPage,
    setProfessionalQuotesReportGlobalRef,
  } = useProfessionalQuote();

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setProfessionalQuotesReportCurrentPage(value);

    getProfessionalQuotesReport(value);

    professionalQuotesReportRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleSearchPagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setProfessionalQuotesReportFoundCurrentPage(value);

    searchProfessionalQuotesReport(searchData, value);

    professionalQuotesReportRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const notShowFilters = () => {
    if (!Object.keys(searchData).length) {
      return true;
    }

    const paymentMethodValidation =
      !searchData.paymentMethod ||
      !Object.keys(searchData.paymentMethod).length ||
      (!searchData.paymentMethod?.credit && !searchData.paymentMethod?.financing);

    const rangeValidation =
      !searchData.range ||
      (searchData.range[0] === 0 && searchData.range[1] === 100000);

    if (
      !searchData.beginDate &&
      !searchData.endDate &&
      !searchData.beginDateCreate &&
      !searchData.endDateCreate &&
      !searchData.chooseDate &&
      !searchData.patientName &&
      !searchData.idQuote &&
      (!searchData.companyId || searchData.companyId === -1) &&
      paymentMethodValidation &&
      rangeValidation
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getProfessionalQuotesReport(professionalQuotesReportCurrentPage);
    setTimeout(() => {
      setProfessionalQuotesReportGlobalRef(
        professionalQuotesReportListRef.current
      );
    }, 1000);
  }, []);

  useEffect(() => {
    if (!Object.keys(searchData).length) {
      return;
    }

    searchProfessionalQuotesReport(searchData);
  }, [searchData]);

  useEffect(() => {
    if (!!isLoadingProfessionalQuotesReport) {
      return;
    }

    setProfessionalQuotesReportGlobalRef(
      professionalQuotesReportListRef.current
    );
  }, [isLoadingProfessionalQuotesReport]);

  return (
    <>
      {notShowFilters() ? (
        <></>
      ) : (
        <div className="bg-white shadow-sm p-3 rounded-xl mb-3">
          <div className="row no-gutters">
            <div className="col col-auto pr-2 pt-1">
              <small className="text-uppercase">Filtros:</small>
            </div>

            {/* Filtros */}
            <div className="col">
              <div className="row g-2">
                {/* Paciente */}
                {!!Object.keys(searchData).length &&
                  !!searchData.patientName && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              patientName: "",
                            })
                          }
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Paciente:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.patientName}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Id Orçamento */}
                {!!Object.keys(searchData).length &&
                  !!searchData.idQuote && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              idQuote: "",
                            })
                          }
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Id do Orçamento:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.idQuote}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Company */}
                {!!Object.keys(searchData).length &&
                  searchData.companyId !== -1 && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              companyId: -1,
                              companyName: undefined,
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Empresa selecionada:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          {searchData.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Tipo de Pagamento */}
                {!!Object.keys(searchData).length &&
                  (searchData.paymentMethod?.credit ||
                    searchData.paymentMethod?.financing) && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              paymentMethod: {
                                credit: false,
                                financing: false,
                              },
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Tipo de Pagamento
                        </label>
                        {searchData.paymentMethod?.financing && (
                          <a
                            className="bg-dark rounded px-2 border text-white shadow-sm mx-1"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setSearchData({
                                ...searchData,
                                paymentMethod: {
                                  credit: searchData.paymentMethod?.credit,
                                  financing: false,
                                },
                              })
                            }
                          >
                            <i className="uil uil-credit-card"></i>{" "}
                            Financiamento
                          </a>
                        )}
                        {searchData.paymentMethod?.credit && (
                          <a
                            className="bg-primary rounded px-2 border text-white shadow-sm mx-1"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setSearchData({
                                ...searchData,
                                paymentMethod: {
                                  credit: false,
                                  financing:
                                    searchData.paymentMethod?.financing,
                                },
                              })
                            }
                          >
                            <i className="uil uil-file-landscape-alt"></i>{" "}
                            Parcelamento
                          </a>
                        )}
                      </div>
                    </div>
                  )}

                {/* Range Valor */}
                {!!searchData.range &&
                  searchData.range.toString() !==
                    [0, 100000].toString() && (
                    <div className="col col-auto">
                      <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                        <a
                          onClick={() =>
                            setSearchData({
                              ...searchData,
                              range: [0, 100000],
                            })
                          }
                          className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                        >
                          <i className="uil uil-times"></i>
                        </a>
                        <label className="small text-muted mb-0 mx-1">
                          Valor entre:
                        </label>
                        <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                          R$ {searchData.range[0]} - R${" "}
                          {searchData.range[1]}
                        </div>
                      </div>
                    </div>
                  )}

                {/* Data Criação de: */}
                {!!searchData.beginDateCreate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            beginDateCreate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data Criação de:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.beginDateCreate).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* Data Criação até: */}
                {!!searchData.endDateCreate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            endDateCreate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data Criação até:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.endDateCreate).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Data de: */}
                {!!searchData.beginDate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            beginDate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data de:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.beginDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                )}
                {/* Data até: */}
                {!!searchData.endDate && (
                  <div className="col col-auto">
                    <div className="bg-light rounded-lg border d-flex align-items-center p-1">
                      <a
                        onClick={() =>
                          setSearchData({
                            ...searchData,
                            endDate: null,
                          })
                        }
                        className="btn btn-sm btn-secondary px-1 py-0 mx-1"
                      >
                        <i className="uil uil-times"></i>
                      </a>
                      <label className="small text-muted mb-0 mx-1">
                        Data até:
                      </label>
                      <div className="bg-white rounded px-2 border text-dark shadow-sm mx-1">
                        {moment(searchData.endDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {!!professionalQuotesReport?.length && (
        <>
          {!!isLoadingProfessionalQuotesReport &&
            [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

          {/* Lista de orçamentos fnalizados */}
          {!Object.values(searchData).some(
            (currentValue) => !!currentValue
          ) && (
            <>
              {!isLoadingProfessionalQuotesReport && (
                <div ref={professionalQuotesReportListRef}>
                  {!!professionalQuotesReport.length &&
                    professionalQuotesReport?.map((quote) => (
                      <ProfessionalQuoteReportCard
                        key={quote.id}
                        quote={quote}
                      />
                    ))}
                </div>
              )}
              {!!professionalQuotesReport.length && (
                <Pagination
                  color="primary"
                  size="large"
                  hidePrevButton
                  hideNextButton
                  count={professionalQuotesReportTotalPages}
                  page={professionalQuotesReportCurrentPage}
                  onChange={handlePagination}
                  className="mt-4 d-flex justify-content-center"
                />
              )}
            </>
          )}
        </>
      )}
      {/* Lista de orçamentos pesquisados */}
      {!!Object.values(searchData).some((currentValue) => !!currentValue) && (
        <>
          {!isLoadingProfessionalQuotesReport && (
            <div ref={professionalQuotesReportListRef}>
              {!!professionalQuotesReportFound.length &&
                professionalQuotesReportFound?.map((quote) => (
                  <ProfessionalQuoteReportCard key={quote.id} quote={quote} />
                ))}

              {/* Lista de orçamentos vazia */}
              {!professionalQuotesReportFound.length && (
                <EmptyList
                  icon="uil-bill"
                  title="Nenhum orçamento encontrado"
                />
              )}
            </div>
          )}
          {professionalQuotesReportFoundTotalPages > 1 && (
            <Pagination
              color="primary"
              size="large"
              hidePrevButton
              hideNextButton
              count={professionalQuotesReportFoundTotalPages}
              page={professionalQuotesReportFoundCurrentPage}
              onChange={handleSearchPagination}
              className="mt-4 d-flex justify-content-center"
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfessionalQuoteReportList;
