export enum Role {
  Admin = 1,
  Professional,
  Secretary,
  Patient,
}

export enum RoleDescription {
  Admin = "Admin",
  Professional = "Doctor",
  Secretary = "Secretary",
  Patient = "Patient",
}

export enum Gender {
  Female = "F",
  Male = "M",
  Intersex = "I",
}

export enum GenderName {
  F = "Feminino",
  M = "Masculino",
  I = "Intersexo",
}

export enum MaritalStatus {
  Solteiro = 1,
  Casado,
  Separado,
  Divorciado,
  Viuvo,
  "NÃO INFORMADO",
}

export enum PaymentMethod {
  Free,
  Debit,
  Credit,
  Financing,
}

export enum TypePriceTable {
  Companies = 1,
  Tables,
}

export enum PaymentFeeOwner {
  Paciente = 1,
  Estabelecimento,
}

export enum PaymentTool {
  "Link de pagamento",
  "Máquina física"
}

export enum PaymentMethodDescription {
  Gratuito,
  "Cartão de Débito",
  Parcelamento,
  Financiamento,
}

export enum PaymentMethodClass {
  "success",
  "info",
  "dark",
  "light",
}

export enum PaymentMethodIcon {
  "uil-file-contract-dollar",
  "uil-credit-card",
  "uil-transaction",
  "uil-files-landscapes-alt",
}

export enum PaymentStatus {
  Open,
  Paid,
  Canceled,
  Awaiting,
  Denied,
  Refunded,
}

export enum QuoteStatus {
  Quote = 1,
  WaitingApproval,
  Analyzing,
  Approved,
  Schedule,
  Finished,
  Canceled,
}

export const quoteStatusTitle = new Map([
  [1, "Orçamento"],
  [2, "Pendente"],
  [3, "Pendente"],
  [4, "Aprovado"],
  [5, "Agendamento"],
  [6, "Concluído"],
  [7, "Cancelado"],
]);

export const quoteStatusColor = new Map([
  [1, "gray"],
  [2, "warning"],
  [3, "warning"],
  [4, "success"],
  [5, "dark"],
  [6, "info"],
  [7, "danger"],
]);

export enum QuoteStatusDescription {
  "Orçamento Cadastrado" = 1,
  "Aguardando Pagamento",
  "Em Análise",
  "Orçamento Aprovado",
  "Em Agendamento",
  "Orçamento Concluído",
  "Orçamento Cancelado",
}

export enum QuoteStatusWaitingDescriptionByPaymentMethod {
  "Aguardando Pagamento" = 2,
  "Aguardando Aprovação",
}

export enum QuoteActions {
  Create = 1,
  Edit,
  Delete,
  EditStatus,
  ModifyProcedureDateStatus,
  AddAttachment,
  DeleteAttachment,
  AgreeServiceContractProvision,
  AgreeGeneralUseContractProvision,
  ShareServiceContractProvisionLink,
  ModifyPaymentStatus,
  ModifyPaymentAdministrativeDetails,
  InteractQuoteChat,
  ViewQuoteChat,
  QuoteChatSuport,
  QuoteChatInstituition,
  AddExtraInfoForm,
}

export enum PaymentLinkAction {
  Copy = 1,
  CopyEdit,
  CopyPay,
  CopyEditPay,
}

export enum DocumentTypeDescription {
  Orçamento = 1,
  "Solicitação Médica",
  "Comprovante de Pagamento",
  "Comprovante de Residência",
  CPF,
  "Carteira de Habilitação",
  RG,
  Outros,
  "Comprovante Split de Pagamento" = 20,
  "Termo de Prestação de Serviço" = 100,
}

export enum FormTypeDescription {
  "Financiamento Avalista" = 1,
  "Financiamento Solicitante",
}

export enum ProcedureDateStatusDescription {
  Pendente,
  Solicitado,
  Confirmado,
}

export enum QuotePaymentStatusDescription {
  Aberto = 0,
  Pago = 1,
  Repasse = 6,
}

export enum ForeignKey {
  NotInformed = 0,
  Quote = 1,
  Payment = 2,
  Patient = 3,
  Professional = 4,
  Company = 5,
  User = 6,
  Perfil = 7,
  ProfessionalReach = 8,
  QuoteAttachment = 9,
  ProfessionalAttachment = 10,
  AuditEntity = 11,
  AuditEvent = 12,
  CheckList = 13,
  CheckListItem = 14,
  CompanySpecialty = 15,
  CompanyLocationAddress = 16,
  CompanyProfessional = 17,
  CompanyPriceTable = 18,
  CompanyUser = 19,
  Specialty = 20,
  SpecialtyProfessional = 21,
  Form = 22,
  Hospital = 23,
  LogTermsOfUse = 24,
  Message = 25,
  MessageRead = 26,
  QuoteCheckListItem = 27,
  ProfessionalTeam = 28,
  RecoverPassword = 29,
  PriceTable = 30,
  TypeAttachment = 31,
  TypeSpecialty = 32,
  TypeProfessional = 33,
  Notification = 34,
  QuoteUser = 35,
}

export enum ForeignKeyDescription {
  "Não Informado",
  "Orçamento",
  "Pagamento",
  "Paciente",
  "Profissional",
  "Empresa",
  "Usuario",
  "Perfil",
  "Alcada Profissional",
  "Anexo Orcamento",
  "Anexo Profissional",
  "AuditEntity",
  "AuditEvent",
  "CheckList",
  "CheckList Item",
  "Empresa Especialidade",
  "Empresa Local Endereco",
  "Empresa Profissional",
  "Empresa Tabela Preco",
  "Empresa Usuario",
  "Especialidade",
  "Especialidade Profissional",
  "Formulario",
  "Hospital",
  "Log Termos De Uso",
  "Mensagem",
  "Mensagem Lida",
  "Orcamento CheckList Item",
  "Profissional Equipe",
  "Recuperar Senha",
  "Tabela Preco",
  "Tipo Anexo",
  "Tipo Especialidade",
  "Tipo Profissional",
  "Notificacao",
  "Ocamento Usuario",
}

export enum ContractStatus {
  None = 0,
  Signed = 1,
  Rejected = 2,
  Negociating = 3,
}

export enum ContractStatusDescription {
  Pendente = 0,
  Assinado = 1,
  Rejeitado = 2,
  "Em Negociação" = 3,
}

export enum ContractType {
  Partnership = 0,
}

export enum PixType {
  "Email" = 0,
  "Telefone" = 1,
  "Chave aleatória" = 2,
  "CPF ou CNPJ" = 3,
}
