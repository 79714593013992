import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";
import { Modal } from "react-bootstrap";

import Button from "components/Button";

interface AlertModalProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onClickButton?: () => void;
  isLoading?: boolean;
  btnVariant?:
    | "primary"
    | "secondary"
    | "success"
    | "dark"
    | "info"
    | "danger"
    | "warning";
  show: boolean;
  onHide: () => void;
  size?: "sm" | "lg" | "xl";
}

const defaultTitle = "Tem certeza que deseja cancelar?";
const defaultButtonText = "Confirmar";

const AlertModal = ({
  title = defaultTitle,
  subtitle,
  buttonText = defaultButtonText,
  onClickButton,
  isLoading,
  btnVariant = "danger",
  ...rest
}: AlertModalProps) => {
  return (
    <Modal {...rest} backdrop="static" centered>
      <Modal.Header
        closeButton
        className="justify-content-end pb-0 border-0"
      ></Modal.Header>
      <Modal.Body className="text-body">
        <div className="text-center">
          <p>
            <i className="uil uil-exclamation-triangle display-4" />
          </p>

          <h4>{title}</h4>
          {!!subtitle && <p>{subtitle}</p>}

          <p className="my-3">
            <Button
              type="button"
              className={`btn-outline-${btnVariant}`}
              onClick={onClickButton}
              disabled={isLoading}
            >
              {buttonText}
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </p>
          {/* )} */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AlertModal;
