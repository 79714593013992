import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

import ModalComponent from "components/ModalComponent";

interface ContractDocuSignSuccessModalProps {
  docusignSuccessShow: boolean;
  logout: () => void;
}

const DELAY_SECONDS = 20;

const ContractDocuSignSuccessModal = ({
  docusignSuccessShow,
  logout,
}: ContractDocuSignSuccessModalProps) => {
  const [seconds, setSeconds] = useState(DELAY_SECONDS);

  const progressWidth = `${(1 - seconds / DELAY_SECONDS) * 100}%`;

  useEffect(() => {
    if (!docusignSuccessShow || seconds <= 0) {
      return;
    }

    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [docusignSuccessShow, seconds]);

  return (
    <ModalComponent
      title="Contrato"
      show={docusignSuccessShow}
      hasCloseButton={false}
      dialogClassName="modal-45w"
      size="lg"
    >
      <Modal.Body>
        <h1>Parabéns</h1>
        <p>
          O contrato foi assinado com sucesso. Um botão para sair será
          desbloqueado em alguns segundos, em seguida por favor entre novamente.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="btn-primary"
          onClick={logout}
          disabled={seconds !== 0}
          style={{ position: "relative", overflow: "hidden" }}
        >
          Sair{" "}
          {seconds > 0 && (
            <>
              <span>em {seconds}</span>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  top: 0,
                  width: progressWidth,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderTopLeftRadius: 999,
                  borderBottomLeftRadius: 999,
                  transition: "width 1s linear",
                }}
              ></div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </ModalComponent>
  );
};

export default ContractDocuSignSuccessModal;
