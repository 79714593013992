import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import LinkButton from "components/LinkButton";
import { pwdRecoveryStep1 } from "pages/Login/utils/pwdRecoveryPopUp";
import { useAuth } from "providers/Auth";
import { UserLoginData } from "types/auth";
import { cpfAndCnpjMask } from "utils/formMasks";
import { validateCpfAndCnpj } from "utils/formValidations";

const LoginForm = () => {
  const navigate = useNavigate();
  const { isLoading, login } = useAuth();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .when("isEmail", {
        is: true,
        then: yup.string().email("*Formato incorreto"),
        otherwise: yup
          .string()
          .test("cpfValidator", "*CPF/CNPJ inválido", validateCpfAndCnpj)
          .transform((value) => value.replace(/\D/g, "")), // Pode ser cpf ou cnpj
      }),
    password: yup.string().required("*Campo obrigatório"),
    isEmail: yup.boolean(),
    keepConnected: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserLoginData>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: UserLoginData) => {
    login(data, navigate);
  };

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.match("@")) {
      setValue("isEmail", true);
      setValue("email", inputValue);
    }
    if (!inputValue.match(/([a-z])/gi)) {
      setValue("isEmail", false);
      setValue("email", cpfAndCnpjMask(inputValue));
    }
  };

  const openPwdRecoveryPopUp = () => {
    // Step 1 - Identification
    pwdRecoveryStep1();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-once
    >
      <div className="form-box p-3 p-sm-4 p-xl-5">
        <div className="row">
          <div className="form-group col-12 mb-3">
            <Input
              placeholder="E-mail, CPF ou CNPJ"
              register={register("email")}
              error={errors?.email?.message}
              onChange={handleUserInput}
              type="text"
              autoFocus
            />
          </div>

          <div className="form-group col-12 mb-0">
            <Input
              placeholder="Senha"
              isPasswordInput
              register={register("password")}
              error={errors?.password?.message}
            />
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="row pt-3 pt-sm-4 pt-xl-5 pb-3 pb-sm-4 pb-xl-5">
          <div className="col-12 col-md-6 text-center text-md-left">
            {/* <label
              htmlFor="keepConnected"
              className="control control--checkbox mb-0"
            >
              <span className="caption">Manter Conectado</span>
              <input
                id="keepConnected"
                // defaultChecked
                type="checkbox"
                {...register("keepConnected")}
              />
              <div className="control__indicator"></div>
            </label> */}
          </div>

          <div className="col-12 col-md-6 mb-3 text-center text-md-right">
            <LinkButton
              className="fs-6"
              onClick={openPwdRecoveryPopUp}
              disabled={isLoading}
            >
              Esqueci a senha
            </LinkButton>
          </div>
        </div>

        <Button type="submit" className="btn btn-primary" disabled={isLoading}>
          Entrar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
