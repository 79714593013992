import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import ModalComponent from "components/ModalComponent";

import SecretaryCreateForm from "../Forms/SecretaryCreateForm";

// import SecretaryCreateForm from "../Forms/SecretaryCreateForm";

interface SecretarySearchProps {
  professionalId: number;
  setSearchData: (searchData: string) => void;
  handleGetSecretaries: () => Promise<void>;
}

interface SecretarySearchData {
  secretaryName: string;
}

const SecretarySearch = ({
  professionalId,
  setSearchData,
  handleGetSecretaries,
}: SecretarySearchProps) => {
  const [modalShow, setModalShow] = useState(false);

  const schema = yup.object().shape({
    secretaryName: yup.string(),
  });

  const { register, handleSubmit } = useForm<SecretarySearchData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: SecretarySearchData) => {
    setSearchData(data.secretaryName);
  };

  return (
    <>
      <div className="col col-md-6 col-xl-auto pb-2 text-end">
        {professionalId !== -1 && (
          <Button
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}
          >
            <i className="uil uil-plus mr-2" />
            Cadastrar Secretária
          </Button>
        )}
      </div>

      <div className="col-12 col-md-4 col-xl pb-2">
        <form
          className="row g-0 border rounded-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col border-right">
            <Input
              type="search"
              placeholder="Nome da secretária"
              register={register("secretaryName")}
            />
          </div>

          <div className="col col-auto">
            <Button
              className="btn btn-outline-primary border-0 rounded-0 py-2 px-3 h-100"
              type="submit"
            >
              <i className="uil uil-search fs-6" />
            </Button>
          </div>
        </form>
      </div>

      <ModalComponent
        title="Adicionar Secretária"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          handleGetSecretaries();
        }}
        size="lg"
      >
        <SecretaryCreateForm
          professionalId={professionalId}
          onHide={() => {
            setModalShow(false);
            handleGetSecretaries();
          }}
        />
      </ModalComponent>
    </>
  );
};

export default SecretarySearch;
