import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import SelectInput from "components/SelectInput";
import { useUser } from "providers/User";
import { Gender, MaritalStatus } from "types/enums";
import { BrazilianState } from "types/ibge";
import { User, UserAdminEditData } from "types/user";
import { getBrazilianStates } from "utils/brazilianStates";
import checkCep from "utils/checkCep";
import { cnpjMask, mobilePhoneMask, phoneMask } from "utils/formMasks";
import { validateBirthDate, validateCpfAndCnpj } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCepInput,
  handleComercialPhoneInput,
  handleCpfOrCnpjInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";

interface UserEditFormProps {
  onHide: () => void;
  user: User;
}

const UserEditForm = ({ onHide, user }: UserEditFormProps) => {
  const [inputCep, setInputCep] = useState("");
  const [isSearchingCep, setIsSearchingCep] = useState(false);
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);

  const { editAnotherUserData, isLoading } = useUser();

  const schema = yup.object().shape({
    id: yup.number().default(user.id),
    name: yup.string().required("*Campo obrigatório").trim(),
    socialName: yup.string().uppercase().trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfOrCnpjValidator", "*CPF ou CNPJ inválido", validateCpfAndCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    rg: yup.string().required("*Campo obrigatório").uppercase().trim(),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*E-mail inválido")
      .trim(),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
    comercialPhone: yup.string(),
    maritalStatus: yup.string(),
    sex: yup.string(),

    addressCEP: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*Deve ter 8 dígitos"),
    addressRoad: yup.string().required("*Campo obrigatório").uppercase().trim(),
    addressNumber: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressComplement: yup.string().uppercase().trim(),
    addressDistrict: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressState: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
    addressCity: yup
      .string()
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    formState: { errors },
  } = useForm<UserAdminEditData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: UserAdminEditData) => {
    editAnotherUserData(data, user.perfilId as number, onHide);
  };

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-6">
            <h5 className="mb-4">Dados do Usuário</h5>
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="Nome Completo:"
                placeholder="Seu Nome Aqui"
                type="text"
                register={register("name")}
                error={errors?.name?.message}
                defaultValue={user.name}
              />
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <Input
                  className="text-uppercase"
                  label="Nome Social:"
                  placeholder="Como prefere ser chamado?"
                  type="text"
                  register={register("socialName")}
                  error={errors?.socialName?.message}
                  defaultValue={user.socialName}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  label="Data de Nascimento:"
                  placeholder="dd/mm/aaaa"
                  type="tel"
                  maxLength={10}
                  register={register("birthDate")}
                  error={errors?.birthDate?.message}
                  onChange={(e) =>
                    handleBirthDateInput(e, setValue, "birthDate")
                  }
                  defaultValue={moment(user.birthDate).format("DD/MM/YYYY")}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <Input
                  label="CPF / CNPJ:"
                  placeholder="CPF ou CNPJ"
                  type="text"
                  maxLength={18}
                  register={register("cpf")}
                  error={errors?.cpf?.message}
                  onChange={(e) => handleCpfOrCnpjInput(e, setValue, "cpf")}
                  defaultValue={cnpjMask(user.cpf)}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  className="text-uppercase"
                  label="RG:"
                  placeholder="9999999-9"
                  type="tel"
                  register={register("rg")}
                  defaultValue={user.rg}
                  error={errors?.rg?.message}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                  <Input
                    type="text"
                    label="E-mail:"
                    placeholder="instituicao@email.com"
                    register={register("email")}
                    error={errors?.email?.message}
                    defaultValue={user.email}
                  />
                </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <Input
                  label="Celular:"
                  type="tel"
                  maxLength={15}
                  register={register("mobilePhone")}
                  error={errors?.mobilePhone?.message}
                  onChange={(e) =>
                    handlePhoneOrMobilePhoneInput(e, setValue, "mobilePhone")
                  }
                  defaultValue={mobilePhoneMask(
                    `${user.dddMobilePhone}${user.mobilePhone}`
                  )}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  label="Celular Comercial:"
                  type="tel"
                  maxLength={15}
                  register={register("comercialPhone")}
                  error={errors?.comercialPhone?.message}
                  onChange={(e) =>
                    handleComercialPhoneInput(e, setValue, "comercialPhone")
                  }
                  defaultValue={phoneMask(
                    `${user.dddComercialPhone}${user.comercialPhone}`
                  )}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <div className="form-group">
                  <SelectInput
                    label="Estado civil:"
                    placeholder="Estado Civil"
                    control={control}
                    name="maritalStatus"
                    defaultValue={
                      user.maritalStatus ?? MaritalStatus["NÃO INFORMADO"]
                    }
                    error={errors?.maritalStatus?.message}
                  >
                    <MenuItem value={MaritalStatus.Solteiro}>SOLTEIRO</MenuItem>
                    <MenuItem value={MaritalStatus.Casado}>CASADO</MenuItem>
                    <MenuItem value={MaritalStatus.Separado}>SEPARADO</MenuItem>
                    <MenuItem value={MaritalStatus.Divorciado}>
                      DIVORCIADO
                    </MenuItem>
                    <MenuItem value={MaritalStatus.Viuvo}>VIÚVO</MenuItem>
                    <MenuItem value={MaritalStatus["NÃO INFORMADO"]}>
                      NÃO INFORMADO
                    </MenuItem>
                  </SelectInput>
                </div>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="select">Sexo</label>
                <div className="row">
                  <div className="col col-auto">
                    <RadioInput
                      value="M"
                      label="Masculino"
                      register={register("sex")}
                      defaultChecked={user?.sex === Gender.Male}
                    />
                  </div>
                  <div className="col col-auto">
                    <RadioInput
                      value="F"
                      label="Feminino"
                      register={register("sex")}
                      defaultChecked={user?.sex === Gender.Female}
                    />
                  </div>
                  <div className="col col-auto">
                    <RadioInput
                      value="I"
                      label="Intersexo"
                      register={register("sex")}
                      defaultChecked={user?.sex === Gender.Intersex}
                    />
                  </div>
                </div>
                <div className="text-danger mb-3">
                  <small>{!!errors?.sex && "*Selecione uma opção"}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <h5 className="mt-2 mt-xl-0 mb-4">Endereço</h5>

            <div className="form-group">
              <div className="row flex-column gap-2 flex-sm-row gap-md-0 align-items-center">
                <div className="col">
                  <Input
                    label="Consulte pelo CEP:"
                    placeholder="99999-999"
                    type="text"
                    register={register("addressCEP")}
                    error={errors?.addressCEP?.message}
                    onChange={(e) =>
                      handleCepInput(e, setValue, "addressCEP", setInputCep)
                    }
                    defaultValue={user.addressCEP}
                  />
                  <div className="col-12 col-md-auto small ps-0">
                    <a
                      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Não sei meu CEP!
                    </a>
                  </div>
                </div>

                <div className="col col-auto">
                  <Button
                    type="button"
                    className="btn-sm btn-dark mt-3"
                    onClick={() =>
                      checkCep(inputCep, setIsSearchingCep, setValue, setFocus)
                    }
                    disabled={isSearchingCep}
                  >
                    Consultar
                    {!!isSearchingCep && (
                      <CircularProgress
                        color="inherit"
                        size={12}
                        className="align-text-top ms-2"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8">
                <div className="form-group">
                  <Input
                    label="Endereço:"
                    placeholder="Nome da Rua"
                    type="text"
                    register={register("addressRoad")}
                    error={errors?.addressRoad?.message}
                    defaultValue={user.addressRoad}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <Input
                    label="Número:"
                    placeholder="nº"
                    type="text"
                    register={register("addressNumber")}
                    error={errors?.addressNumber?.message}
                    defaultValue={user.addressNumber}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <Input
                    label="Complemento:"
                    placeholder="Casa, apto, bloco"
                    type="text"
                    register={register("addressComplement")}
                    error={errors?.addressComplement?.message}
                    defaultValue={user.addressComplement}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <Input
                  label="Bairro:"
                  placeholder="Nome do Bairro"
                  type="text"
                  register={register("addressDistrict")}
                  error={errors?.addressDistrict?.message}
                  defaultValue={user.addressDistrict}
                />
              </div>

              <div className="col-sm-6">
                <SelectInput
                  name="addressState"
                  label="Estado:"
                  placeholder="Selecione"
                  control={control}
                  error={errors?.addressState?.message}
                  defaultValue={user.addressState ?? "selecione"}
                >
                  <MenuItem value="selecione" disabled>
                    Selecione
                  </MenuItem>
                  {brazilianStates?.map((brazilianState) => (
                    <MenuItem
                      key={brazilianState.id}
                      value={brazilianState.sigla}
                    >
                      {brazilianState.sigla}
                    </MenuItem>
                  ))}
                </SelectInput>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <Input
                    label="Cidade:"
                    placeholder="Nome da Cidade"
                    type="text"
                    register={register("addressCity")}
                    error={errors?.addressCity?.message}
                    defaultValue={user.addressCity}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Salvar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default UserEditForm;
