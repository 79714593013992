import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import { useRegister } from "providers/Register";

import UserTypeCard from "../UserTypeCard";

interface TypeRegisterData {
  registerType: string;
}

const UserTypeForm = () => {
  const navigate = useNavigate();
  const { setRegisterType } = useRegister();
  const schema = yup.object().shape({
    registerType: yup.string().required("Escolha uma das opções."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TypeRegisterData>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = (data: TypeRegisterData) => {
    const { registerType } = data;
    setRegisterType(registerType);

    return navigate(
      `/cadastro/${registerType}${registerType !== "instituicao" ? "-1" : ""}`
    );
  };

  return (
    <form
      className="p-3 p-sm-4 p-xl-5 aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-once
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset className="checkbox-group justify-content-center">
        <UserTypeCard
          register={register("registerType")}
          label="Profissional"
          value="profissional"
          icon="uil-medkit"
          aosDelay="300"
        />

        <UserTypeCard
          register={register("registerType")}
          label="Cliente"
          value="cliente"
          icon="uil-user"
          aosDelay="400"
        />

        <UserTypeCard
          register={register("registerType")}
          label="Instituição"
          value="instituicao"
          icon="uil-hospital"
          aosDelay="500"
        />
      </fieldset>
      {!!Object.keys(errors).length && (
        <div className="text-center text-danger m-0">
          <small>*Escolha uma das opções</small>
        </div>
      )}

      <div className="text-center pt-4">
        <Button type="submit" className="btn btn-primary">
          Prosseguir
        </Button>
      </div>
    </form>
  );
};

export default UserTypeForm;
