import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { ContractUrlResponse, SessionEndEvent } from "types/auth";
import { ContractType, RoleDescription } from "types/enums";

import ContractDocuSignSuccessModal from "./components/ContractDocuSignSuccessModal";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { loadDocuSign } from "../../../temp/bundles/dev-docusign-bundle";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { loadDocuSign } from "../../bundles/prod-docusign-bundle";

const ContractDocuSignIntegration = () => {
  const [docusignSuccessShow, setDocusignSuccessShow] = useState(false);

  const { userRole, logout } = useAuth();

  const loadDocuSignEmbeddedSigning = async () => {
    const { data }: AxiosResponse<ContractUrlResponse> = await api.get(
      "/Contract/docusign/generateRedirectUrl",
      {
        params: {
          contractType: ContractType.Partnership,
        },
      }
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const docusignLoaded: any = await window.DocuSign.loadDocuSign(
      data.envelopId
    );

    const signing = docusignLoaded.signing({
      url: data.redirectUrl,
      displayFormat: "focused",
      style: {
        /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
        branding: {
          primaryButton: {
            /** Background color of primary button */
            backgroundColor: "#333",
            /** Text color of primary button */
            color: "#fff",
          },
        },

        /** High-level components we allow specific overrides for */
        signingNavigationButton: {
          finishText: "Assinar",
          /** 'bottom-left'|'bottom-center'|'bottom-right',  default: bottom-right */
          position: "bottom-right",
        },
      },
    });

    /** Event handlers **/
    signing.on("ready", () => {
      console.log("UI is rendered");
    });
    signing.on("sessionEnd", async (event: SessionEndEvent) => {
      /** The event here denotes what caused the sessionEnd to trigger, such as signing_complete, ttl_expired etc../ */

      switch (event.sessionEndType) {
        case "signing_complete":
          setDocusignSuccessShow(true);
          break;

        case "decline":
          logout();
          break;

        default:
          return;
      }
    });

    signing.mount("#agreement");
  };

  useEffect(() => {
    loadDocuSignEmbeddedSigning();
  }, []);

  return userRole !== RoleDescription.Patient ? (
    <>
      <div
        style={{
          width: "100%",
          height: "90vh",
        }}
        className="docusign-agreement p-3 border rounded"
        id="agreement"
      ></div>
      <ContractDocuSignSuccessModal
        docusignSuccessShow={docusignSuccessShow}
        logout={logout}
      />
    </>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default ContractDocuSignIntegration;
