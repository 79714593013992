import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";

import Button from "components/Button";
import CancellationModal from "components/CancellationModal";
import ModalBodyComponentStepper from "components/ModalBodyComponentStepper";
import ModalComponent from "components/ModalComponent";
import { useQuoteDetails } from "providers/QuoteDetails";
import { FormTypeDescription } from "types/enums";
import { ActionFormQuote, FormActions, FormQuote } from "types/formQuote";
import { toast } from "utils/toast";

import Step1PersonalInfoForm from "./components/Form/Step1PersonalInfoForm";
import Step2AddessInfoForm from "./components/Form/Step2AdressInfoForm";
import Step3ProfessionalInfoForm from "./components/Form/Step3ProfessionalInfoForm";
import Step4OthersInfoForm from "./components/Form/Step4OthersInfoForm";
import FormQuoteView from "./components/FormQuoteView";
import { RedCircle } from "./styles";

interface FileCardProps {
  formQuote: FormQuote;
  quoteId: number;
}

const FormQuoteCard = ({ formQuote, quoteId }: FileCardProps) => {
  const [formQuoteViewModalShow, setFormQuoteViewModalShow] = useState(false);
  const [formQuoteEditModalShow, setFormQuoteEditModalShow] = useState(false);
  const [cancellationModalShow, setCancellationModalShow] = useState(false);
  const [editFormQuote, setEditFormQuote] = useState<FormQuote>(
    {} as FormQuote
  );
  const [activeStep, setActiveStep] = useState(0);

  const { setSaveFormAsDraft, removeQuoteForm, isLoading } = useQuoteDetails();

  const closeFormQuoteStepper = () => {
    if (!formQuote.form.isDraft) {
      setFormQuoteEditModalShow(false);
      setActiveStep(0);
      return;
    }

    Swal.fire({
      title: "Deseja salvar como rascunho?",
      icon: "warning",
      position: "center",
      showCloseButton: true,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Salvar",
      denyButtonText: "Não Salvar",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-outline-success ml-2",
        denyButton: "btn btn-outline-danger",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        setSaveFormAsDraft(true);
        setFormQuoteEditModalShow(false);

        toast.fire("Formulário foi salvo como rascunho!", "", "success");
      } else if (res.isDenied) {
        setSaveFormAsDraft(false);
        setFormQuoteEditModalShow(false);
        setActiveStep(0);

        toast.fire("Formulário não foi salvo como rascunho!", "", "info");
      } else {
        return;
      }
    });
  };

  const removeForm = () => {
    removeQuoteForm(formQuote.id, quoteId);
    setCancellationModalShow(false);
  };

  useEffect(() => {
    setEditFormQuote(formQuote);
  }, [formQuote]);

  return (
    <>
      <div className="row align-items-center py-2 border-bottom">
        <div className="col-9">
          <span className="text-body d-flex col lh-1 align-items-center">
            {!formQuote.form.isDraft ? (
              <div className="icone-notes me-2 text-primary">
                <i className="uil uil-notes mb-0 display-5"></i>
              </div>
            ) : (
              <div className="d-flex align-items-center icone-notes me-2 text-muted">
                <RedCircle></RedCircle>
                <i className="uil uil-notes mb-0 display-5"></i>
              </div>
            )}

            <div className="text-truncate">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{formQuote.info}</Tooltip>}
              >
                <span className="m-0">{formQuote.description}</span>
              </OverlayTrigger>
              <br />
            </div>
          </span>
        </div>

        <div className="col-3 d-flex justify-content-end align-items-center gap-2">
          {formQuote.actions?.includes(FormActions.ViewExtraInfoForm) &&
            !formQuote.form.isDraft && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="show">Visualizar Formulário</Tooltip>
                }
              >
                <Button
                  className="btn-outline-primary btn-sm btn-icon"
                  onClick={() => setFormQuoteViewModalShow(true)}
                >
                  <i className="uil uil-search-plus fs-6"></i>
                </Button>
              </OverlayTrigger>
            )}
          {formQuote.actions?.includes(FormActions.EditExtraInfoForm) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="show">Editar Formulário</Tooltip>}
            >
              <Button
                className="btn-outline-warning btn-sm btn-icon"
                onClick={() => setFormQuoteEditModalShow(true)}
              >
                <i className="uil uil-edit-alt fs-6"></i>
              </Button>
            </OverlayTrigger>
          )}
          {formQuote.actions?.includes(FormActions.DeleteExtraInfoForm) && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="show">Excluir Formulário</Tooltip>}
            >
              <Button
                className="btn-outline-danger btn-sm btn-icon"
                onClick={() => setCancellationModalShow(true)}
              >
                <i className="uil uil-trash fs-6"></i>
              </Button>
            </OverlayTrigger>
          )}
        </div>
      </div>

      {/* Modal de Cancelamento */}
      <CancellationModal
        show={cancellationModalShow}
        title="Tem certeza que deseja excluir esse formulário?"
        onClickButton={removeForm}
        isLoading={isLoading}
        onHide={() => setCancellationModalShow(false)}
      />

      {/* Editar Formulário */}
      <ModalComponent
        title={`Editar ${
          !formQuote.form.isDraft ? "" : "Rascunho do"
        } Formulário do ${FormTypeDescription[formQuote.typeForm]}`}
        show={formQuoteEditModalShow}
        onHide={() => closeFormQuoteStepper()}
        size="xl"
      >
        <ModalBodyComponentStepper
          activeStep={activeStep}
          steps={[
            {
              label: "Informações Pessoais",
              component: (
                <Step1PersonalInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={editFormQuote}
                  setFormQuote={setEditFormQuote}
                  onHide={() => setFormQuoteEditModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Edit}
                />
              ),
            },
            {
              label: "Endereço",
              component: (
                <Step2AddessInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={editFormQuote}
                  setFormQuote={setEditFormQuote}
                  onHide={() => setFormQuoteEditModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Edit}
                />
              ),
            },
            {
              label: "Dados Profissionais",
              component: (
                <Step3ProfessionalInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={editFormQuote}
                  setFormQuote={setEditFormQuote}
                  onHide={() => setFormQuoteEditModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Edit}
                />
              ),
            },
            {
              label: "Outros",
              component: (
                <Step4OthersInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={editFormQuote}
                  setFormQuote={setEditFormQuote}
                  onHide={() => setFormQuoteEditModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Edit}
                />
              ),
            },
          ]}
        />
      </ModalComponent>

      {/* Visualizar Formulário */}
      {!(
        formQuote.form.personalReference1 ||
        formQuote.form.personalReference2 ||
        formQuote.form.spouse
      ) ? (
        <ModalComponent
          title="Visualizar formulário"
          show={formQuoteViewModalShow}
          onHide={() => setFormQuoteViewModalShow(false)}
          size="xl"
        >
          <FormQuoteView form={formQuote.form} />
        </ModalComponent>
      ) : (
        <ModalComponent
          title="Visualizar formulário"
          show={formQuoteViewModalShow}
          onHide={() => setFormQuoteViewModalShow(false)}
          dialogClassName="modal-90w"
        >
          <FormQuoteView form={formQuote.form} />
        </ModalComponent>
      )}
    </>
  );
};

export default FormQuoteCard;
