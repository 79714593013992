import { Navigate, Outlet } from "react-router-dom";

import RegisterContainer from "pages/Register/components/RegisterContainer";
import { useRegister } from "providers/Register";

const ProfessionalRegister = () => {
  const { registerType } = useRegister();

  if (!registerType) {
    return <Navigate to="/cadastro" />;
  }

  return (
    <RegisterContainer title="Cadastro - Profissional">
      <Outlet />
    </RegisterContainer>
  );
};

export default ProfessionalRegister;
