function getInitials(name: string) {
  // Split the name into an array of words
  const words = name.trim().split(" ");

  // Get the first word as the first name initial
  const firstNameInitial = words[0][0].toUpperCase();

  // Get the last word as the last name initial
  const lastNameInitial = words[words.length - 1][0].toUpperCase();

  // Return the initials
  return `${firstNameInitial}${lastNameInitial}`;
}

export default getInitials;
