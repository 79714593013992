import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";

import Button from "components/Button";
import ModalComponent from "components/ModalComponent";
import { useAuth } from "providers/Auth";
import { api } from "services";
import { ContractStatus, ContractType, RoleDescription } from "types/enums";
import { toast } from "utils/toast";

import ContractNegotiationModal from "./components/ContractNegotiationModal";
import EmbeddedContractModal from "./components/EmbeddedContractModal";

interface ContractModalsProps {
  contractShow: boolean;
  setContractShow: Dispatch<SetStateAction<boolean>>;
  onHide?: () => void;
}

const ContractModals = ({
  contractShow,
  setContractShow,
  onHide,
}: ContractModalsProps) => {
  const { userRole, logout, refreshToken, userId } = useAuth();
  const navigate = useNavigate();

  const [contractNegotiationShow, setContractNegotiationShow] = useState(false);
  const [embeddedContractShow, setEmbeddedContractShow] = useState(false);

  const RejectContract = async () => {
    setContractShow(false);

    try {
      const request = {
        contractStatus: ContractStatus.Rejected,
        contractType: ContractType.Partnership,
      };

      await api.post("Contract/reject", request);

      toast.fire({
        icon: "success",
        title: "Contrato rejeitado com sucesso!",
      });

      await refreshToken(userId);
    } catch (error: any) {
      console.error(error);

      toast.fire({
        icon: "error",
        title: error.message,
      });
    }

    logout();
  };

  const NegotiateContract = () => {
    setContractShow(false);
    setContractNegotiationShow(true);
  };

  const SignContract = () => {
    setContractShow(false);
    navigate("/contract/docusign");
  };

  return userRole !== RoleDescription.Patient ? (
    <>
      <ModalComponent
        title="Contrato"
        show={contractShow}
        onHide={() => {
          setContractShow(false);
          if (onHide) onHide();
        }}
        dialogClassName="modal-45w"
        size="lg"
      >
        <Modal.Body>
          <div className="modal-body text-body">
            Para utilizar a solução Docttorpay, é necessário assinar o contrato
            de relação profissional.{" "}
            <span
              onClick={() => setEmbeddedContractShow(true)}
              className="span-btn"
            >
              Clique aqui para visualizar o contrato
            </span>{" "}
            ou clique nos botões abaixo para tomar alguma ação.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn-danger" onClick={RejectContract}>
            Recusar
          </Button>

          <Button
            type="button"
            className="btn-warning"
            onClick={NegotiateContract}
          >
            Negociar
          </Button>

          <Button type="button" className="btn-primary" onClick={SignContract}>
            Prosseguir
          </Button>
        </Modal.Footer>
      </ModalComponent>
      <ContractNegotiationModal
        show={contractNegotiationShow}
        onHide={() => {
          setContractNegotiationShow(false);
          setContractShow(true);
        }}
      />
      <EmbeddedContractModal
        signed={false}
        show={embeddedContractShow}
        onHide={() => setEmbeddedContractShow(false)}
      />
    </>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default ContractModals;
