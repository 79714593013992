import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode } from "react";
import { Controller } from "react-hook-form";

interface SelectInputProps {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  defaultValue?: number | string;
  children?: ReactNode;
  customOnChange?: () => void;
  changeOptionSelected?: (event: SelectChangeEvent<any>) => void;
  isDisabled?: boolean;
}

const SelectInput = (props: SelectInputProps) => {
  const customStyles = {
    "& .MuiInputBase-root": {
      borderRadius: "7px",
      height: 48,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: props.error ? "var(--danger)" : "var(--border)",
    },
  };

  return (
    <>
      <label>{props.label}</label>

      <FormControl fullWidth sx={customStyles}>
        <InputLabel>{props.placeholder}</InputLabel>

        <Controller
          name={props.name}
          control={props.control}
          defaultValue={props.defaultValue}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(event) => {
                onChange(event);

                if (props.changeOptionSelected) {
                  props.changeOptionSelected(event);
                }

                props.customOnChange?.();
              }}
              label={props.placeholder}
              value={value}
              disabled={props.isDisabled}
            >
              {props.children}
            </Select>
          )}
        />
      </FormControl>

      {!!props.error && (
        <div className="text-start text-danger">
          <small>{props.error}</small>
        </div>
      )}
    </>
  );
};

export default SelectInput;
