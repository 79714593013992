import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import SelectDropdown from "components/SelectDropdown";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import { usePatientQuote } from "providers/Quote/PatientQuote";
import { useQuoteDetails } from "providers/QuoteDetails";
import { api } from "services";
import { PaymentMethod, PaymentMethodDescription } from "types/enums";
import { Quote, QuoteCreateData } from "types/quotes";
import { validateProcedureDate } from "utils/formValidations";
import { getCompanyOptions, getProfessionalOptions } from "utils/getOptions";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import {
  creditParcelOptions,
  financingParcelOptions,
  paymentMethodOptions,
} from "utils/selectOptions";

interface PatientQuoteEditFormProps {
  onHide: () => void;
  quote: Quote;
  setQuote: React.Dispatch<React.SetStateAction<Quote>>;
}

const PatientQuoteEditForm = ({
  onHide,
  quote,
  setQuote,
}: PatientQuoteEditFormProps) => {
  const { isLoading, editPatientQuote } = usePatientQuote();
  const { getAuditLogs } = useQuoteDetails();

  const schema = yup.object().shape({
    company: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      }),
    professional: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string().uppercase(),
      })
      .nullable(),
    patient: yup.object().default({
      id: quote.patient.id,
      name: quote.patient.name.toLocaleUpperCase(),
    }),
    description: yup
      .string()
      .min(4, "*Informe um procedimento com pelo menos 4 caracteres")
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    date: yup
      .date()
      .typeError("*Data inválida")
      .nullable()
      .required("*Campo obrigatório")
      .test("procedureDateValidator", "*Data inválida", (value) =>
        validateProcedureDate(value, companyMinLimitDateProcedure)
      ),
    priceValue: yup.string().required("*Campo obrigatório"),
    paymentMethod: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    parcelQtd: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    control,
    formState: { errors },
  } = useForm<Omit<QuoteCreateData, "fileTypes">>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      company: {
        value: quote.company.id,
        label: quote.company.name,
      },
      professional: {
        value: quote.professional?.id,
        label: quote.professional?.name,
      },
      description: quote?.description,
      date: moment(quote.date).format("YYYY-MM-DD"),
      priceValue: quote?.payment.value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      }),
      paymentMethod: {
        value: quote.payment.method,
        label: PaymentMethodDescription[quote.payment.method],
      },
      parcelQtd: {
        value: quote.payment.parcelQtd,
        label: `${quote.payment.parcelQtd}x`,
      },
    },
  });

  const [companyId, setCompanyId] = useState<number>(quote.company.id);

  const [companyMinLimitDateProcedure, setCompanyMinLimitDateProcedure] =
    useState(0);

  useEffect(() => {
    async function getCompanyMinLimitDateProcedure() {
      if (companyId === -1) return;

      try {
        const { data } = await api.get(
          `/Company/${companyId}/data-procedimento`
        );

        setCompanyMinLimitDateProcedure(data);
      } catch (error) {
        console.error(error);
      }
    }

    getCompanyMinLimitDateProcedure();
  }, [companyId]);

  const onSubmit = async (data: Omit<QuoteCreateData, "fileTypes">) => {
    await editPatientQuote(data, quote.id, onHide, setQuote);

    getAuditLogs(quote.id);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-group">
            <SelectSearch
              label="Instituição:"
              placeholder="Buscar Instituição"
              url={`${API_URL}Company/search`}
              control={control}
              name="company"
              get={getCompanyOptions}
              isClearable
              error={errors.company?.label?.message}
              handleChange={(companyId: number) => {
                setCompanyId(companyId);
              }}
            />
          </div>

          <div className="form-group">
            <SelectSearch
              label="Profissional:"
              placeholder="Buscar Profissional"
              url={`${API_URL}Company/${companyId}/professionals`}
              control={control}
              name="professional"
              get={getProfessionalOptions}
              isClearable
              error={errors.professional?.label?.message}
            />
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-7">
              <Input
                className="text-uppercase"
                type="text"
                label="Procedimento:"
                placeholder="Nome do procedimento"
                register={register("description")}
                error={errors?.description?.message}
              />
            </div>

            <div className="form-group col-12 col-md-5">
              <DatePicker
                label="Data do Procedimento:"
                control={control}
                name="date"
                minDate={moment().add(companyMinLimitDateProcedure, "d")}
                error={errors?.date?.message}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="label" htmlFor="valor">
              Valor:
            </label>
            <div className="row align-items-center no-gutters flex-nowrap">
              <div className="col col-auto pr-2">R$</div>
              <div className="col">
                <input
                  type="tel"
                  placeholder="0,00"
                  {...register("priceValue")}
                  onChange={(e) =>
                    handleTwoDecimalPlacesInput(e, setValue, "priceValue")
                  }
                  className={`form-control ${
                    !!errors.priceValue && "border-danger"
                  }`}
                />
              </div>
            </div>
            {!!errors.priceValue && (
              <div className="text-start text-danger">
                <small>{errors.priceValue?.message}</small>
              </div>
            )}
          </div>

          <div className="row">
            <div className="form-group col-12 col-md-7">
              <SelectDropdown
                options={paymentMethodOptions}
                label="Método de Pagamento"
                name="paymentMethod"
                placeholder="Selecione"
                control={control}
                error={errors.paymentMethod?.label?.message}
                changeOptionSelected={() =>
                  resetField("parcelQtd", {
                    defaultValue: {},
                  })
                }
                isSearchable={false}
              />
            </div>

            <div className="form-group col-md-5">
              <SelectDropdown
                options={
                  watch("paymentMethod")?.value === PaymentMethod.Credit
                    ? creditParcelOptions
                    : watch("paymentMethod")?.value === PaymentMethod.Financing
                    ? financingParcelOptions
                    : undefined
                }
                label="Parcelas:"
                name="parcelQtd"
                placeholder="Selecione"
                control={control}
                error={errors.parcelQtd?.label?.message}
                isSearchable={false}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            className="btn-light"
            onClick={onHide}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" className="btn-primary" disabled={isLoading}>
            <i className="uil uil-check mr-2" />
            Salvar
            {!!isLoading && (
              <CircularProgress
                color="inherit"
                size={12}
                className="align-text-top ms-2"
              />
            )}
          </Button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default PatientQuoteEditForm;
