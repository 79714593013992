import { useEffect, useState } from "react";

import SelectDropdown from "components/SelectDropdown";
import { API_URL } from "config";
import { api } from "services";
import { PaymentMethod } from "types/enums";
import { PriceTable } from "types/priceTable";

interface PriceTableSelectProps {
  withCardMachines?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customOnChange?: () => void;
}

export default function PriceTableSelect({
  withCardMachines,
  control,
  customOnChange,
}: PriceTableSelectProps) {
  const [priceTables, setPriceTables] = useState<PriceTable[]>([]);
  const [isLoadingPriceTables, setIsLoadingPriceTables] = useState(true);

  useEffect(() => {
    const getPriceTables = async () => {
      setIsLoadingPriceTables(true);

      try {
        const response = await api.get(`${API_URL}PriceTable/search`, {
          params: {
            tipo: PaymentMethod.Credit,
            active: true,
          },
        });

        const response2 = await api.get("/Quote/TerminalPDQ");

        let priceTables = [];

        if (withCardMachines) {
          priceTables = [...response.data, ...response2.data];
        } else {
          priceTables = response.data;
        }

        setPriceTables(priceTables);

        setIsLoadingPriceTables(false);
      } catch (error) {
        console.error(error);
      }
    };

    getPriceTables();
  }, []);

  return (
    <div className="form-group flex-fill">
      <SelectDropdown
        name="priceTable"
        options={priceTables.map((priceTable) => ({
          value: priceTable.id,
          label: priceTable.description,
        }))}
        control={control}
        placeholder="Buscar Tabela Preço Parcelamento"
        isClearable={true}
        isDisabled={isLoadingPriceTables}
        customOnChange={customOnChange}
      />
    </div>
  );
}
