import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import { useRegister } from "providers/Register";
import { ProfessionalRegisterPersonalData } from "types/register";
import { validateBirthDate, validateCpfAndCnpj } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCpfOrCnpjInput,
  handleMobilePhoneInput,
} from "utils/handleInput";

const ProfessionalPersonalDataForm = () => {
  const navigate = useNavigate();
  const { registerType, setProfessionalPersonalData } = useRegister();

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfOrCnpjValidator", "*CPF ou CNPJ inválido", validateCpfAndCnpj)
      .transform((value) => value.replace(/\D/g, "")), // Pode ser cpf ou cnpj
    sex: yup.string(),
    socialName: yup.string().uppercase().trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfessionalRegisterPersonalData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ProfessionalRegisterPersonalData) => {
    setProfessionalPersonalData(data);
    navigate(`/cadastro/${registerType}-2`);
  };

  return (
    <form className="p-3 p-sm-4 p-xl-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-box p-3 p-sm-4 p-xl-5">
        <header className="row flex-nowrap pt-3 pb-4">
          <div className="col">
            <h5>Dados Pessoais</h5>
          </div>
          <div className="col col-auto text-muted">
            <b className="text-primary">1</b>
            /3
          </div>
        </header>
        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome Completo:"
            placeholder="Seu Nome Aqui"
            type="text"
            register={register("name")}
            error={errors?.name?.message}
          />
        </div>

        <div className="form-group">
          <Input
            label="CPF / CNPJ:"
            placeholder="CPF ou CNPJ"
            type="text"
            maxLength={18}
            register={register("cpf")}
            error={errors?.cpf?.message}
            onChange={(e) => handleCpfOrCnpjInput(e, setValue, "cpf")}
          />
        </div>

        <label htmlFor="select">Sexo:</label>
        <div className="row">
          <div className="col col-auto">
            <RadioInput
              label="Masculino"
              value="M"
              register={register("sex")}
            />
          </div>
          <div className="col col-auto">
            <RadioInput label="Feminino" value="F" register={register("sex")} />
          </div>
          <div className="col col-auto">
            <RadioInput
              label="Intersexo"
              value="I"
              register={register("sex")}
            />
          </div>
        </div>
        <div className="text-danger mb-3">
          <small>{!!errors?.sex && "*Selecione uma opção"}</small>
        </div>

        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome Social:"
            placeholder="Como prefere ser chamado?"
            type="text"
            register={register("socialName")}
            error={errors?.socialName?.message}
          />
        </div>

        <div className="form-group">
          <Input
            label="Data de Nascimento:"
            placeholder="dd/mm/aaaa"
            type="tel"
            maxLength={10}
            register={register("birthDate")}
            error={errors?.birthDate?.message}
            onChange={(e) => handleBirthDateInput(e, setValue, "birthDate")}
          />
        </div>

        <div className="form-group">
          <Input
            label="Celular:"
            placeholder="(99) 99999-9999"
            type="tel"
            maxLength={15}
            register={register("mobilePhone")}
            error={errors?.mobilePhone?.message}
            onChange={(e) => handleMobilePhoneInput(e, setValue, "mobilePhone")}
          />
        </div>
      </div>

      <div className="row no-gutters justify-content-between pt-3 pt-sm-4 pt-xl-5">
        <div className="col col-auto">
          <Button
            className="btn-light"
            type="button"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
        <div className="col col-auto">
          <Button className="btn-primary" type="submit">
            Prosseguir
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ProfessionalPersonalDataForm;
