import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import EmbeddedContractModal from "components/ContractModals/components/EmbeddedContractModal";
import { useProfessional } from "providers/Professional";

import ActiveProfessionalList from "./components/ActiveProfessionalList";
import InactiveProfessionalList from "./components/InactiveProfessionalList";
import ProfessionalSearch from "./components/ProfessionalSearch";

const Professionals = () => {
  const {
    inactiveProfessionalsTotal,
    inactiveProfessionalsFoundTotal,
    activeProfessionalsTotal,
    activeProfessionalsFoundTotal,
    getActiveProfessionals,
    searchActiveProfessionals,
    getInactiveProfessionals,
    searchInactiveProfessionals,
    searchData,
  } = useProfessional();
  const [selectedNavTab, setSelectedNavTab] = useState("active-data");
  const [embeddedContractShow, setEmbeddedContractShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    undefined
  );

  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };
  // TODO: verificar se secretária pode visualizar aba profissionais
  // const { professionalCompanies, isLoadingProfessionalCompanies } = useCompany();
  // useEffect(() => {
  //   if (userData.id && userRole === RoleDescription.Secretary) {
  //     getProfessionalCompanies(userData.id as number);
  //   }
  // }, [userData]);

  const showProfessionalEmbeddedContractModal = (userId: number) => {
    setSelectedUserId(userId);
    setEmbeddedContractShow(true);
  };
  
  useEffect(() => {
    getInactiveProfessionals(searchData.companyId, 1, searchData.search, searchData.contract);
    getActiveProfessionals(searchData.companyId, 1, searchData.search, searchData.contract);
    searchInactiveProfessionals(searchData);
    searchActiveProfessionals(searchData);
  }, [searchData]);

  return (
    <>
      <ContainerAnimation className="content">
        <div className="bg-white">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col col-auto">
                <h1>Profissionais</h1>
              </div>
              <div className="col col-auto">
                <ProfessionalSearch />
              </div>
              <div className="col-12">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <span
                      id="active-data"
                      className={`nav-link link-primary ${
                        selectedNavTab === "active-data" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Ativos
                      <Badge bg="primary ml-1 text-white">
                        {!Object.values(searchData).some(
                          (currentValue) => !!currentValue
                        )
                          ? activeProfessionalsTotal
                          : activeProfessionalsFoundTotal}
                      </Badge>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      id="inative-data"
                      className={`nav-link link-primary ${
                        selectedNavTab === "inative-data" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Inativos
                      <Badge bg="dark ml-1 text-white">
                        {!Object.values(searchData).some(
                          (currentValue) => !!currentValue
                        )
                          ? inactiveProfessionalsTotal
                          : inactiveProfessionalsFoundTotal}
                      </Badge>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tab-content">
            {selectedNavTab === "active-data" && (
              <ActiveProfessionalList
                showProfessionalEmbeddedContractModal={
                  showProfessionalEmbeddedContractModal
                }
              />
            )}
            {selectedNavTab === "inative-data" && (
              <InactiveProfessionalList
                showProfessionalEmbeddedContractModal={
                  showProfessionalEmbeddedContractModal
                }
              />
            )}
          </div>
        </div>
      </ContainerAnimation>
      <EmbeddedContractModal
        userId={selectedUserId}
        signed={true}
        show={embeddedContractShow}
        onHide={() => setEmbeddedContractShow(false)}
      />
    </>
  );
};

export default Professionals;
