import { ReactNode } from "react";
import { Link } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useRegister } from "providers/Register";

interface RegisterContainerProps {
  title: string;
  children: ReactNode;
}

const RegisterContainer = ({ title, children }: RegisterContainerProps) => {
  const { registerType, isLoading } = useRegister();

  return (
    <ContainerAnimation className="content container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6">
          <h1
            className="text-center aos-init"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {title}
          </h1>
          {registerType === "instituicao" && (
            <>
              <p
                className="text-center aos-init"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Preencha seus dados para que possamos entrar em contato.
              </p>
              <p
                className="mb-4 text-center aos-init"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Temos planos e condições incrívieis!
              </p>
            </>
          )}

          <div className="aos-init" data-aos="fade-up" data-aos-delay="200">
            {children}
            <p className="text-center pt-4">
              Já possui login?{" "}
              <Link
                to="/login"
                className="fw-bold"
                style={{ pointerEvents: isLoading ? "none" : undefined }}
              >
                Faça login aqui!
              </Link>
            </p>
          </div>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default RegisterContainer;
