const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <p>
            {`Copyright © ${new Date().getFullYear()}. Todos os direitos reservados. — `}
            <a
              href="https://docttorpay.com.br/"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="text-decoration-none"
            >
              DocttorPay
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
