import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

export const PrivateRoute = () => {
  const { token } = useAuth();

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoute = () => {
  const { token } = useAuth();

  return token ? <Navigate to="/" /> : <Outlet />;
};

export const ProfessionalRoute = () => {
  const { userRole, userContract } = useAuth();

  if (!userContract && userRole == RoleDescription.Professional) {
    return <Navigate to="/contract" />;
  }

  return userRole !== RoleDescription.Patient ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export const PatientRoute = () => {
  const { userRole } = useAuth();

  return userRole === RoleDescription.Patient ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export const AdminRoute = () => {
  const { userRole } = useAuth();

  return userRole === RoleDescription.Admin ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
