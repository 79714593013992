import moment from "moment";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Button from "components/Button";
import CancellationModal from "components/CancellationModal";
import { useQuoteDetails } from "providers/QuoteDetails";
import { api } from "services";
import { DocumentTypeDescription, QuoteActions } from "types/enums";
import { DatabaseFile } from "types/files";
import { toast } from "utils/toast";

interface FileCardProps {
  file: DatabaseFile;
  setDatabaseFiles: React.Dispatch<React.SetStateAction<DatabaseFile[]>>;
  quoteId: number;
  quoteActions: number[];
}

const FileCard = ({
  file,
  setDatabaseFiles,
  quoteId,
  quoteActions,
}: FileCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cancellationModalShow, setCancellationModalShow] = useState(false);
  const { getQuotePendingItems } = useQuoteDetails();

  const getFilesAttachment = async () => {
    try {
      const response = await api.get("/FileAttachment/find", {
        params: {
          idOrcamento: quoteId,
        },
      });

      setDatabaseFiles(response.data.listFiles);
      getQuotePendingItems(quoteId);
    } catch (error) {
      console.error(error);
    }
  };

  const removeFile = async () => {
    setIsLoading(true);

    try {
      await api.delete(`/FileAttachment/${file.id}`);

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: "Arquivo removido com sucesso!",
      });

      getFilesAttachment();

      setCancellationModalShow(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  return (
    <>
      <div className="row align-items-center py-2 border-bottom">
        <div className="col-9">
          <a
            href={file.url}
            className="arquivo text-body d-flex col lh-1 align-items-center"
            download
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Baixar Arquivo</Tooltip>}
            >
              <div className="icone-file me-2 text-primary">
                <span>.{file.name.split(".").pop()}</span>
                <i className="uil uil-file mb-0 display-5"></i>
              </div>
            </OverlayTrigger>

            <div className="text-truncate">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {DocumentTypeDescription[file.documentType]}
                    <br />({moment(file.uploadDate).format("DD-MM-YYYY")})
                  </Tooltip>
                }
              >
                <span className="m-0">
                  {DocumentTypeDescription[file.documentType]}{" "}
                  <small>
                    ({moment(file.uploadDate).format("DD-MM-YYYY")})
                  </small>
                </span>
              </OverlayTrigger>

              <br />

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{file.name}</Tooltip>}
              >
                <span className="m-0 small">{file.name}</span>
              </OverlayTrigger>
            </div>
          </a>
        </div>

        {quoteActions?.includes(QuoteActions.DeleteAttachment) && (
          <div className="text-end col-3">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="show">Excluir Arquivo</Tooltip>}
            >
              <Button
                className="btn-outline-danger btn-sm btn-icon"
                onClick={() => setCancellationModalShow(true)}
              >
                <i className="uil uil-trash fs-6"></i>
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </div>

      {/* Modal de Cancelamento */}
      <CancellationModal
        show={cancellationModalShow}
        title="Tem certeza que deseja excluir esse arquivo?"
        onClickButton={removeFile}
        isLoading={isLoading}
        onHide={() => setCancellationModalShow(false)}
      />
    </>
  );
};

export default FileCard;
