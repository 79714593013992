import { Outlet } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";

const Page = () => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Page;
