import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { usePatient } from "providers/Patient";
import { useUser } from "providers/User";
import { RoleDescription } from "types/enums";

import ActivePatientList from "./components/ActivePatientList";
import CompanySelect from "./components/CompanySelect";
import InactivePatientList from "./components/InactivePatientList";
import PatientSearch from "./components/PatientSearch";

const Patients = () => {
  const [companyId, setCompanyId] = useState<number>(-1);
  const [searchData, setSearchData] = useState<string>("");

  const { userRole } = useAuth();
  const { userData } = useUser();

  const {
    professionalCompanies,
    isLoadingProfessionalCompanies,
    getProfessionalCompanies,
  } = useCompany();

  const {
    activePatientsTotal,
    inactivePatientsTotal,
    getActivePatients,
    getInactivePatients,
  } = usePatient();

  const [selectedNavTab, setSelectedNavTab] = useState("active-data");
  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  useEffect(() => {
    if (
      userData.id &&
      (userRole === RoleDescription.Professional ||
        userRole === RoleDescription.Secretary)
    ) {
      getProfessionalCompanies(userData.id as number);
    }
    
    getActivePatients(companyId, 1, searchData);
    getInactivePatients(companyId, 1, searchData);
  }, [userData, companyId, searchData]);

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center gx-2">
            <div className="col-12 col-md-4 col-lg-6 pb-2">
              <h1 className="mb-0">Pacientes</h1>
            </div>
            <div className="col-12 col-sm-6 col-md pb-2">
              <CompanySelect
                isLoadingProfessionalCompanies={isLoadingProfessionalCompanies}
                professionalCompanies={professionalCompanies}
                setCompanyId={setCompanyId}
              />
            </div>
            <div className="col-12 col-sm-6 col-md pb-2">
              <PatientSearch setSearchData={setSearchData} />
            </div>
            
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    id="active-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "active-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Ativos
                    <Badge bg="primary ml-1 text-white">
                      {activePatientsTotal}
                    </Badge>
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    id="inative-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "inative-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Inativos
                    <Badge bg="dark ml-1 text-white">
                      {inactivePatientsTotal}
                    </Badge>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "active-data" && (
            <ActivePatientList companyId={companyId} searchData={searchData} />
          )}
          {selectedNavTab === "inative-data" && (
            <InactivePatientList companyId={companyId} searchData={searchData} />
          )}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Patients;
