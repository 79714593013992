import moment from "moment";
import { Modal } from "react-bootstrap";

import {
  DocumentTypeDescription,
  GenderName,
  MaritalStatus,
} from "types/enums";
import {
  Form,
  ProofIncome,
  ResidenceTypeDescription,
  TimeAddress,
  UnionTypeDescription,
} from "types/formQuote";
import { checkString, formatPrice } from "utils/formats";
import {
  cepMask,
  cnpjMask,
  cpfMask,
  mobilePhoneMask,
  phoneOrMobilePhoneMask,
} from "utils/formMasks";

interface FormQuoteViewProps {
  form: Form;
}

const FormQuoteView = ({ form }: FormQuoteViewProps) => {
  return (
    <div className="modal-body text-body">
      <Modal.Body>
        <div className="row">
          <div className="col">
            <h5>Dados do {form.type === 1 ? "Avalista" : "Solicitante"}</h5>
            <div className="row rounded-3 border p-3 mb-4 mr-1">
              <div className="form-group col-md-6">
                <strong>Nome Proponente:</strong> <br />
                {checkString(form.proponentName)}
              </div>
              <div className="form-group col-md-6">
                <strong>Email:</strong> <br />
                {checkString(form.email)}
              </div>

              <div className="form-group col-md-6">
                <strong>Documento de Indentificacao:</strong> <br />
                {DocumentTypeDescription[form.documentType]} <br />
              </div>

              <div className="form-group col-md-6">
                <strong>Data Nascimento:</strong> <br />
                {checkString(moment(form.birthDate).format("DD/MM/YYYY"))}
              </div>

              {!form.rg ? (
                <div className="form-group col-md-12">
                  <strong>RG:</strong> <br />
                  {checkString(form.rg)} <br />
                </div>
              ) : (
                <>
                  <div className="form-group col-md-3">
                    <strong>RG:</strong> <br />
                    {form.rg ?? "-"} <br />
                  </div>

                  <div className="form-group col-md-3">
                    <strong>UF do RG:</strong> <br />
                    {form.ufrg ?? "-"} <br />
                  </div>

                  <div className="form-group col-md-6">
                    <strong>Orgao Emissor:</strong> <br />
                    {form.issuingOrganization ?? "-"}
                  </div>
                </>
              )}

              <div className="form-group col-md-6">
                <strong>CPF:</strong> <br />
                {cpfMask(form.cpf)}
              </div>

              <div className="form-group col-md-6">
                <strong>Sexo:</strong> <br />
                {GenderName[form.sex]}
              </div>

              <div className="form-group col-md-6">
                <strong>Nome da Mae:</strong> <br />
                {checkString(form.motherName)}
              </div>

              <div className="form-group col-md-6">
                <strong>Nome do Pai:</strong> <br />
                {checkString(form.fatherName)}
              </div>
            </div>

            <h5 className="mt-2">Dados Profissionais</h5>
            <div className="row rounded-3 border p-3 mb-4 mr-1">
              <div className="form-group col-sm-6">
                <strong>Empresa:</strong> <br />
                {checkString(form.professionalData.companyName)}
              </div>
              <div className="form-group col-sm-6">
                <strong>CNPJ:</strong> <br />
                {checkString(cnpjMask(form.professionalData.cnpj))}
              </div>
              <div className="form-group col-sm-6">
                <strong>Estado do Trabalho:</strong> <br />
                {checkString(form.professionalData.companyAddressState)}
              </div>
              <div className="form-group col-sm-6">
                <strong>Cidade do Trabalho:</strong> <br />
                {checkString(form.professionalData.companyAddressCity)}
              </div>
              <div className="form-group col-sm-6">
                <strong>Inicio das Atividades:</strong> <br />
                {checkString(
                  moment(form.professionalData.startActivities).format(
                    "DD/MM/YYYY"
                  )
                )}
              </div>
              <div className="form-group col-sm-6">
                <strong>Telefone:</strong> <br />
                {checkString(
                  phoneOrMobilePhoneMask(form.professionalData.phone)
                )}
              </div>
              <div className="form-group col-sm-6">
                <strong>Cargo da Profissão:</strong> <br />
                {checkString(form.professionalData.jobTitle)}
              </div>
              <div className="form-group col-sm-6">
                <strong>Tempo de Serviço:</strong> <br />
                {checkString(form.professionalData.serviceTime)}
              </div>
              <div className="form-group col-sm-6">
                <strong>Tipo de Comprovante de Renda:</strong> <br />
                {ProofIncome[form.professionalData.typeIncomeProof]}
              </div>
              <div className="form-group col-sm-6">
                <strong>Renda:</strong> <br />
                {checkString(formatPrice(+form.professionalData.rent))}
              </div>
            </div>
          </div>

          {!!(
            form.personalReference1 ||
            form.personalReference2 ||
            form.spouse
          ) && (
            <>
              <div className="col">
                {!!(
                  form.personalReference1 ||
                  form.personalReference2 ||
                  form.spouse
                ) && (
                  <>
                    <h5>Dados de Contato</h5>
                    <div className="row rounded-3 border p-3 mb-4 mr-1">
                      <div className="form-group col-md-6">
                        <strong>Celular:</strong> <br />
                        {checkString(
                          mobilePhoneMask(`${form.ddd} ${form.phone}`)
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <strong>Estado Civil:</strong> <br />
                        {MaritalStatus[form.maritalStatus]}
                      </div>
                      <div className="form-group col-md-6">
                        <strong>Nacionalidade:</strong> <br />
                        {checkString(form.nationality)}
                      </div>
                      <div className="form-group col-md-6">
                        <strong>Cidade de Nascimento:</strong> <br />
                        {checkString(form.birthCity)}
                      </div>
                      <div className="form-group col-md-6">
                        <strong>Pessoa com Deficiência?</strong> <br />
                        {form.personWithDisability ? "Sim" : "Não"}
                      </div>

                      <div className="form-group col-md-6">
                        <strong>Pessoa Publica?</strong> <br />
                        {form.publicPerson ? "Sim" : "Não"}
                      </div>
                    </div>
                  </>
                )}
                {!!form.personalReference1 && (
                  <>
                    <h5 className="mt-2">Referencia Pessoais 1</h5>
                    <div className="form-group">
                      <div className="row rounded-3 border p-3 mb-4 mr-1">
                        <div className="form-group col-sm-6">
                          <strong>Nome:</strong> <br />
                          {checkString(form.personalReference1.name)}
                        </div>
                        <div className="form-group col-sm-6">
                          <strong>Relacionamento:</strong> <br />
                          {checkString(form.personalReference1.relationship)}
                        </div>
                        <div className="form-group col-sm-6">
                          <strong>Telefone:</strong> <br />
                          {checkString(
                            phoneOrMobilePhoneMask(
                              `${form.personalReference1.ddd} ${form.personalReference1.phone}`
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {!!form.personalReference2 && (
                  <>
                    <h5 className="mt-2">Referencia Pessoais 2</h5>
                    <div className="form-group">
                      <div className="row rounded-3 border p-3 mb-4 mr-1">
                        <div className="form-group col-sm-6">
                          <strong>Nome:</strong> <br />
                          {checkString(form.personalReference2.name)}
                        </div>
                        <div className="form-group col-sm-6">
                          <strong>Relacionamento:</strong> <br />
                          {checkString(form.personalReference2.relationship)}
                        </div>
                        <div className="form-group col-sm-6">
                          <strong>Telefone:</strong> <br />
                          {checkString(
                            phoneOrMobilePhoneMask(
                              `${form.personalReference2.ddd} ${form.personalReference2.phone}`
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {!!form.spouse && (
                  <>
                    <h5 className="mt-2">Conjuge</h5>
                    <div className="form-group">
                      <div className="row rounded-3 border p-3 mb-4 mr-1">
                        <div className="form-group col-sm-6">
                          <strong>Nome:</strong> <br />
                          {checkString(form.spouse.name)}
                        </div>
                        <div className="form-group col-sm-6">
                          <strong>CPF:</strong> <br />
                          {checkString(cpfMask(form.spouse.cpf))}
                        </div>
                        <div className="col-sm-12">
                          <strong>Tipo de Uniao:</strong> <br />
                          {UnionTypeDescription[form.spouse.unionType]}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div className="col">
            {!(
              form.personalReference1 ||
              form.personalReference2 ||
              form.spouse
            ) && (
              <>
                <h5>Dados do Contato</h5>
                <div className="row rounded-3 border p-3 mb-4 mr-1">
                  <div className="form-group col-md-6">
                    <strong>Celular:</strong> <br />
                    {checkString(mobilePhoneMask(`${form.ddd} ${form.phone}`))}
                  </div>
                  <div className="form-group col-md-6">
                    <strong>Nacionalidade:</strong> <br />
                    {checkString(form.nationality)}
                  </div>
                  <div className="form-group col-md-6">
                    <strong>Estado Civil:</strong> <br />
                    {MaritalStatus[form.maritalStatus]}
                  </div>
                  <div className="form-group col-md-6">
                    <strong>Cidade de Nascimento:</strong> <br />
                    {checkString(form.birthCity)}
                  </div>
                  <div className="form-group col-md-6">
                    <strong>Pessoa com Deficiência:</strong> <br />
                    {form.personWithDisability ? "Sim" : "Não"}
                  </div>

                  <div className="form-group col-md-6">
                    <strong>Pessoa Publica:</strong> <br />
                    {form.publicPerson ? "Sim" : "Não"}
                  </div>
                </div>
              </>
            )}
            <h5>Endereço da Instituição</h5>
            <div className="row rounded-3 border p-3 mb-4 ">
              <div className="form-group col-sm-8">
                <strong>Cep:</strong> <br />
                {checkString(cepMask(form.address.addressCEP))}
              </div>
              <div className="form-group col-sm-6">
                <strong>Rua:</strong> <br />
                {checkString(form.address.addressRoad)}
              </div>

              <div className="form-group col-sm-6">
                <strong>Numero:</strong> <br />
                {checkString(form.address.addressNumber)}
              </div>

              <div className="form-group col-sm-6">
                <strong>Complemento:</strong> <br />
                {form.address.addressComplement
                  ? form.address.addressComplement
                  : "-"}
              </div>

              <div className="form-group col-sm-6">
                <strong>Bairro:</strong> <br />
                {checkString(form.address.addressDistrict)}
              </div>

              <div className="form-group col-sm-6">
                <strong>Cidade:</strong> <br />
                {checkString(form.address.addressCity)}
              </div>

              <div className="form-group col-sm-6">
                <strong>Estado:</strong> <br />
                {checkString(form.address.addressState)}
              </div>

              <div className="form-group col-sm-6">
                <strong>Tempo No Endereco:</strong> <br />
                {TimeAddress[form.address.timeAtAddress]}
              </div>
              <div className="form-group col-sm-6">
                <strong>Tipo da Residencia:</strong> <br />
                {ResidenceTypeDescription[form.address.residenceType]}
              </div>
              {form.address.residenceType ==
                ResidenceTypeDescription["ALUGADO"] && (
                <div className="form-group col-sm-6">
                  <strong>Valor do Aluguel:</strong> <br />
                  {checkString(formatPrice(+form.address.RentValue))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default FormQuoteView;
