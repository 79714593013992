import styled from "styled-components";

export const Container = styled.div`
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--primary);
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}
`;
