import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useCompany } from "providers/Company";

import ActiveCompanyList from "./components/ActiveCompanyList";
import CompanySearch from "./components/CompanySearch";
import InactiveCompanyList from "./components/InactiveCompanyList";

const Companies = () => {
  const [searchData, setSearchData] = useState<string>("");
  const {
    inactiveCompaniesTotal,
    activeCompaniesTotal,
    getActiveCompanies,
    getInactiveCompanies,
  } = useCompany();

  const [selectedNavTab, setSelectedNavTab] = useState("active-data");
  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  useEffect(() => {
    getInactiveCompanies(1, searchData);
    getActiveCompanies(1, searchData);
  }, [searchData]);
  
  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center gx-2">
            <div className="col col-auto col-lg-6 pb-2">
              <h1 className="mb-0">Instituições</h1>
            </div>
            <CompanySearch setSearchData={setSearchData} />
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    id="active-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "active-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Ativas
                    <Badge bg="primary ml-1 text-white">
                      {activeCompaniesTotal}
                    </Badge>
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    id="inative-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "inative-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Inativas
                    <Badge bg="dark ml-1 text-white">
                      {inactiveCompaniesTotal}
                    </Badge>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "active-data" && (
            <ActiveCompanyList searchData={searchData} />
          )}
          {selectedNavTab === "inative-data" && (
            <InactiveCompanyList searchData={searchData} />
          )}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Companies;
