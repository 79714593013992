import { EventContentArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { useUser } from "providers/User";
import { api } from "services";
import { RoleDescription } from "types/enums";
import { formatPrice } from "utils/formats";

import CompanySelect from "./components/CompanySelect";
import ProfessionalSelect from "./components/ProfessionalSelect";

const Schedule = () => {
  const { userRole } = useAuth();
  const { userData } = useUser();
  const {
    professionalCompanies,
    isLoadingProfessionalCompanies,
    getProfessionalCompanies,
  } = useCompany();

  useEffect(() => {
    if (
      userData.id &&
      (userRole === RoleDescription.Professional ||
        userRole === RoleDescription.Secretary)
    ) {
      getProfessionalCompanies(userData.id as number);
    }
  }, [userData]);

  const [companyId, setCompanyId] = useState<number>(-1);
  const [professionalId, setProfessionalId] = useState<number>(-1);

  function renderEventContent(eventInfo: EventContentArg) {
    return (
      <Link
        to="/agenda/agenda-detalhes"
        state={{
          date: eventInfo.event.start,
          totalValue:
            eventInfo.event._def.extendedProps.totalPaidValue +
            eventInfo.event._def.extendedProps.totalForecastValue,
          companyId,
          professionalId,
        }}
        className="overflow-hidden px-2 py-4">
        <div>
          {eventInfo.event._def.extendedProps.totalPaidValue !== 0 && (
            <p className="text-primary mb-1">
              <strong>
                {formatPrice(eventInfo.event._def.extendedProps.totalPaidValue)}
              </strong>{" "}
              pago
            </p>
          )}
          {eventInfo.event._def.extendedProps.totalForecastValue !== 0 && (
            <p className="text-primary mb-0">
              <strong>
                {formatPrice(
                  eventInfo.event._def.extendedProps.totalForecastValue
                )}
              </strong>{" "}
              previsto
            </p>
          )}
        </div>
      </Link>
    );
  }

  return (
    <ContainerAnimation className="content">
      <div className="bg-white mb-0">
        <div className="container">
          <div className="row justify-content-between align-items-center gx-2 pb-4">
            <div className="col-12 col-md-4 col-lg-6 pb-2">
              <h1 className="mb-0">Calendário</h1>
            </div>
            <div className="col-12 col-sm-6 col-md pb-2">
              <CompanySelect
                isLoadingProfessionalCompanies={isLoadingProfessionalCompanies}
                professionalCompanies={professionalCompanies}
                setCompanyId={setCompanyId}
                setProfessionalId={setProfessionalId}
              />
            </div>
            {userRole === RoleDescription.Secretary ||
            userRole === RoleDescription.Admin ? (
              <div className="col-12 col-sm-6 col-md pb-2">
                <ProfessionalSelect
                  companyId={companyId}
                  setProfessionalId={setProfessionalId}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="container">
        <FullCalendar
          plugins={[dayGridPlugin]}
          locale={"pt-br"}
          timeZone="UTC"
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          buttonText={{
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
          buttonHints={{
            prev: "Anterior",
            next: "Próximo",
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
          height="auto"
          events={async (info, successCallback, failureCallback) => {
            try {
              const { data } = await api.post("/Payment/Forecasting", {
                startDate: info.startStr,
                endDate: info.endStr,
                companyId: companyId === -1 ? undefined : companyId,
                professionalId: professionalId === -1 ? undefined : professionalId,
              });

              successCallback(
                data.map((item: any) => {
                  return {
                    start: item.date,
                    extendedProps: {
                      totalPaidValue: item.totalPaidValue,
                      totalForecastValue: item.totalForecastValue,
                    },
                  };
                })
              );
            } catch (error) {
              failureCallback(error as Error);
            }
          }}
          eventContent={renderEventContent}
          eventColor="transparent"
        />
      </div>
    </ContainerAnimation>
  );
};

export default Schedule;
