import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import SelectDropdown from "components/SelectDropdown";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import { useCompany } from "providers/Company";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { useQuoteDetails } from "providers/QuoteDetails";
import { useUser } from "providers/User";
import { api } from "services";
import { PaymentMethod, PaymentMethodDescription } from "types/enums";
import { Quote, QuoteCreateData } from "types/quotes";
import { validateProcedureDate } from "utils/formValidations";
import { getPatientOptions } from "utils/getOptions";
import { handleTwoDecimalPlacesInput } from "utils/handleInput";
import {
  creditParcelOptions,
  financingParcelOptions,
  paymentMethodOptions,
} from "utils/selectOptions";

interface ProfessionalQuoteEditFormProps {
  onHide: () => void;
  quote: Quote;
  setQuote: React.Dispatch<React.SetStateAction<Quote>>;
}

const ProfessionalQuoteEditForm = ({
  onHide,
  quote,
  setQuote,
}: ProfessionalQuoteEditFormProps) => {
  const { userData } = useUser();
  const { isLoading, editProfessionalQuote } = useProfessionalQuote();
  const { getAuditLogs } = useQuoteDetails();
  const {
    professionalCompanies,
    isLoadingProfessionalCompanies,
    getProfessionalCompanies,
  } = useCompany();

  const patientInputRef = useRef(null);

  const schema = yup.object().shape({
    company: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      })
      .nullable(),
    patient: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório").uppercase(),
      })
      .nullable(),
    professional: yup.object().default({
      id: quote.professional.id,
      name: quote.professional.name.toLocaleUpperCase(),
    }),
    description: yup
      .string()
      .required("*Campo obrigatório")
      .min(4, "*Informe um procedimento com pelo menos 4 caracteres")
      .uppercase()
      .trim(),
    date: yup
      .date()
      .typeError("*Data inválida")
      .nullable()
      .required("*Campo obrigatório")
      .test("procedureDateValidator", "*Data inválida", (value) =>
        validateProcedureDate(value, companyMinLimitDateProcedure)
      ),
    priceValue: yup.string().required("*Campo obrigatório"),
    paymentMethod: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
    parcelQtd: yup
      .object()
      .required("*Selecione uma opção")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm<Omit<QuoteCreateData, "fileTypes">>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      company: {
        value: quote.company.id,
        label: quote.company.name,
      },
      patient: {
        value: quote.patient.id,
        label: quote.patient.name,
      },
      description: quote?.description,
      date: moment(quote.date).format("YYYY-MM-DD"),
      priceValue: quote?.payment.value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      }),
      paymentMethod: {
        value: quote.payment.method,
        label: PaymentMethodDescription[quote.payment.method],
      },
      parcelQtd: {
        value: quote.payment.parcelQtd,
        label: `${quote.payment.parcelQtd}x`,
      },
    },
  });

  useEffect(() => {
    getProfessionalCompanies(userData.id as number);
  }, []);

  const [companyId, setCompanyId] = useState<number>(quote.company.id);

  const [companyMinLimitDateProcedure, setCompanyMinLimitDateProcedure] =
    useState(0);

  useEffect(() => {
    async function getCompanyMinLimitDateProcedure() {
      if (companyId === -1) return;

      try {
        const { data } = await api.get(
          `/Company/${companyId}/data-procedimento`
        );

        setCompanyMinLimitDateProcedure(data);
      } catch (error) {
        console.error(error);
      }
    }

    getCompanyMinLimitDateProcedure();
  }, [companyId]);

  const onSubmit = async (data: Omit<QuoteCreateData, "fileTypes">) => {
    await editProfessionalQuote(data, quote.id, onHide, setQuote);

    getAuditLogs(quote.id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <SelectDropdown
            options={(function () {
              return professionalCompanies.map((company) => ({
                value: company.id,
                label: company.name,
              }));
            })()}
            label="Instituição:"
            name="company"
            placeholder="Selecione"
            control={control}
            error={errors.company?.label?.message}
            changeOptionSelected={() => {
              setCompanyId(getValues("company").value);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              patientInputRef.current?.clearValue();

              // A lib do react hook form não suporta resetar um campo para undefined, e por algum motivo o valor
              // null não dispara a mensagem de campo obrigatório, no entanto espera-se que o usuário perceba os
              // campos em branco e seu instinto seja de preencher novamente, por isso a melhor alternativa foi
              // resetar para null mesmo.
              resetField("patient", { defaultValue: null });
              resetField("date", { defaultValue: null });
            }}
            isSearchable={false}
            isDisabled={isLoadingProfessionalCompanies}
          />
        </div>

        <div className="form-group position-relative">
          <SelectSearch
            label="CPF do Cliente:"
            placeholder="Buscar Cliente pelo CPF"
            url={`${API_URL}Company/${companyId}/patients`}
            control={control}
            name="patient"
            get={getPatientOptions}
            isClearable
            error={errors.patient?.label?.message}
            isCpf
            isDisabled={companyId === -1}
            ref={patientInputRef}
          />
        </div>

        <div className="row">
          <div className="form-group col-12 col-md-7">
            <Input
              className="text-uppercase"
              type="text"
              label="Procedimento:"
              placeholder="Nome do Procedimento"
              register={register("description")}
              error={errors?.description?.message}
            />
          </div>

          <div className="form-group col-12 col-md-5">
            <DatePicker
              label="Data do Procedimento:"
              control={control}
              name="date"
              defaultValue={null}
              minDate={moment().add(companyMinLimitDateProcedure, "d")}
              error={errors?.date?.message}
              disabled={companyId === -1}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="label" htmlFor="valor">
            Valor:
          </label>
          <div className="row align-items-center no-gutters flex-nowrap">
            <div className="col col-auto pr-2">R$</div>
            <div className="col">
              <input
                type="tel"
                placeholder="0,00"
                {...register("priceValue")}
                onChange={(e) =>
                  handleTwoDecimalPlacesInput(e, setValue, "priceValue")
                }
                className={`form-control ${
                  !!errors.priceValue && "border-danger"
                }`}
              />
            </div>
          </div>
          {!!errors.priceValue && (
            <div className="text-start text-danger">
              <small>{errors.priceValue?.message}</small>
            </div>
          )}
        </div>

        <div className="row">
          <div className="form-group col-12 col-md-7">
            <SelectDropdown
              options={paymentMethodOptions}
              label="Método de Pagamento"
              name="paymentMethod"
              placeholder="Selecione"
              control={control}
              error={errors.paymentMethod?.label?.message}
              changeOptionSelected={() =>
                resetField("parcelQtd", {
                  defaultValue: {},
                })
              }
              isSearchable={false}
            />
          </div>

          <div className="form-group col-md-5">
            <SelectDropdown
              options={
                watch("paymentMethod")?.value === PaymentMethod.Credit
                  ? creditParcelOptions
                  : watch("paymentMethod")?.value === PaymentMethod.Financing
                  ? financingParcelOptions
                  : undefined
              }
              label="Parcelas:"
              name="parcelQtd"
              placeholder="Selecione"
              control={control}
              error={errors.parcelQtd?.label?.message}
              isSearchable={false}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Salvar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default ProfessionalQuoteEditForm;
