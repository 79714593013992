import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";

interface UserSearchProps {
  setSearchData: (searchData: string) => void;
}

interface UserSearchData {
  userName: string;
}

const UserSearch = ({ setSearchData }: UserSearchProps) => {
  const schema = yup.object().shape({
    userName: yup.string(),
  });

  const { register, handleSubmit } = useForm<UserSearchData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<UserSearchData> = async (data) => {
    setSearchData(data.userName);
  };

  return (
    <form
      className="row g-0 border rounded-3"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="col border-right">
        <Input
          type="search"
          placeholder="Nome do usuário"
          register={register("userName")}
        />
      </div>

      <div className="col col-auto">
        <Button
          className="btn btn-outline-primary border-0 rounded-0 py-2 px-3 h-100"
          type="submit">
          <i className="uil uil-search fs-6" />
        </Button>
      </div>
    </form>
  );
};

export default UserSearch;
