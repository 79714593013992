import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import SelectInput from "components/SelectInput";
import { useQuoteDetails } from "providers/QuoteDetails";
import { MaritalStatus, DocumentTypeDescription } from "types/enums";
import {
  ActionFormQuote,
  Form,
  FormPersonalInfo,
  FormQuote,
} from "types/formQuote";
import { cpfMask, rgMask } from "utils/formMasks";
import { validateBirthDate, validateCpfAndCnpj } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCpfInput,
  handleMobilePhoneInput,
  handleRgInput,
} from "utils/handleInput";

interface Step1PersonalInfoFormProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formQuote: FormQuote;
  setFormQuote: React.Dispatch<React.SetStateAction<FormQuote>>;
  onHide: () => void;
  quoteId: number;
  action: ActionFormQuote;
}

const Step1PersonalInfoForm = ({
  setActiveStep,
  formQuote,
  setFormQuote,
  onHide,
  quoteId,
  action,
}: Step1PersonalInfoFormProps) => {
  const {
    createFormQuote,
    saveFormAsDraft,
    setSaveFormAsDraft,
    editFormQuote,
  } = useQuoteDetails();

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const schema = yup.object().shape({
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfValidator", "*CPF inválido", validateCpfAndCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    proponentName: yup.string().required("*Campo obrigatório").trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    sex: yup.string().required("*Campo obrigatório"),
    documentType: yup.string().required("*Campo obrigatório"),
    rg: yup.string().required("*Campo obrigatório").uppercase().trim(),
    issuingOrganization: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    ufrg: yup.string().required("*Campo obrigatório").uppercase().trim(),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*E-mail inválido")
      .trim(),
    birthCity: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
    nationality: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
    phone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
    maritalStatus: yup.number().required("*Campo obrigatório"),
    personWithDisability: yup.string().required("*Campo obrigatório"),
    publicPerson: yup.string().required("*Campo obrigatório"),
    motherName: yup.string().required("*Campo obrigatório"),
    fatherName: yup.string().notRequired(),
  });

  const onSubmit = (data: FormPersonalInfo) => {
    setFormQuote({
      ...formQuote,
      form: {
        ...formQuote?.form,
        cpf: data.cpf,
        proponentName: data.proponentName,
        birthDate: moment(data.birthDate, "DD/MM/YYYY").toISOString(),
        sex: data.sex,
        documentType: data.documentType,
        rg: data.rg,
        issuingOrganization: data.issuingOrganization,
        ufrg: data.ufrg,
        email: data.email,
        birthCity: data.birthCity,
        nationality: data.nationality,
        phone: data.phone,
        ddd: data.phone.slice(1, 3),
        maritalStatus: data.maritalStatus,
        personWithDisability: Boolean(+data.personWithDisability),
        publicPerson: Boolean(+data.publicPerson),
        motherName: data.motherName,
        fatherName: data.fatherName,
      } as Form,
    } as FormQuote);

    handleNext();
  };

  useEffect(() => {
    setSaveFormAsDraft(false);
  }, []);

  useEffect(() => {
    if (!saveFormAsDraft) {
      return;
    }

    if (!formQuote) {
      return;
    }

    const data = getValues() as FormPersonalInfo;

    const cache = {
      ...formQuote,
      form: {
        ...formQuote?.form,
        cpf: data.cpf ? data.cpf : "",
        proponentName: data.proponentName ? data.proponentName : "",
        birthDate: data.birthDate
          ? moment(data.birthDate, "DD/MM/YYYY").toISOString()
          : moment().toISOString(),
        sex: data.sex ? data.sex : "",
        documentType: data.documentType ? data.documentType : "",
        rg: data.rg ? data.rg : "",
        issuingOrganization: data.issuingOrganization
          ? data.issuingOrganization
          : "",
        ufrg: data.ufrg ? data.ufrg : "",
        email: data.email ? data.email : "",
        birthCity: data.birthCity ? data.birthCity : "",
        nationality: data.nationality ? data.nationality : "",
        phone: data.phone ? data.phone : "",
        ddd: data.phone ? data.phone.slice(1, 3) : "",
        maritalStatus: data.maritalStatus ? data.maritalStatus : "",
        personWithDisability: data.personWithDisability
          ? Boolean(+data.personWithDisability)
          : false,
        publicPerson: data.publicPerson ? Boolean(+data.publicPerson) : false,
        motherName: data.motherName ? data.motherName : "",
        fatherName: data.fatherName ? data.fatherName : "",
        isDraft: true,
      } as Form,
    } as FormQuote;

    if (action === ActionFormQuote.Create) {
      createFormQuote(cache, quoteId, onHide);
    }

    if (action === ActionFormQuote.Edit) {
      editFormQuote(cache, quoteId, onHide);
    }

    setSaveFormAsDraft(false);
    setActiveStep(0);
  }, [saveFormAsDraft]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormPersonalInfo>({ resolver: yupResolver(schema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="pt-0">
        <div className="p-xl-2">
          <header className="row py-4">
            <div className="col">
              <h5>Dados Pessoais</h5>
            </div>
          </header>

          <div className="row">
            <div className="form-group col-md-6">
              <Input
                className="text-uppercase"
                label="Nome Completo:"
                placeholder="Seu Nome Aqui"
                type="text"
                register={register("proponentName")}
                error={errors?.proponentName?.message}
                defaultValue={formQuote?.form?.proponentName ?? ""}
              />
            </div>

            <div className="form-group col-md-3">
              <Input
                label="Data de Nascimento:"
                placeholder="dd/mm/aaaa"
                type="tel"
                maxLength={10}
                register={register("birthDate")}
                error={errors?.birthDate?.message}
                onChange={(e) => handleBirthDateInput(e, setValue, "birthDate")}
                defaultValue={
                  formQuote?.form?.birthDate
                    ? moment(formQuote?.form?.birthDate).format("DD/MM/YYYY")
                    : ""
                }
              />
            </div>

            <div className="form-group col-md-3">
              <SelectInput
                label="Estado civil:"
                placeholder="Estado Civil"
                control={control}
                name="maritalStatus"
                defaultValue={
                  formQuote?.form?.maritalStatus ??
                  MaritalStatus["NÃO INFORMADO"]
                }
                error={errors?.maritalStatus?.message}
              >
                <MenuItem value={MaritalStatus.Solteiro}>SOLTEIRO</MenuItem>
                <MenuItem value={MaritalStatus.Casado}>CASADO</MenuItem>
                <MenuItem value={MaritalStatus.Separado}>SEPARADO</MenuItem>
                <MenuItem value={MaritalStatus.Divorciado}>DIVORCIADO</MenuItem>
                <MenuItem value={MaritalStatus.Viuvo}>VIÚVO</MenuItem>
                <MenuItem value={MaritalStatus["NÃO INFORMADO"]}>
                  NÃO INFORMADO
                </MenuItem>
              </SelectInput>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <Input
                type="text"
                label="E-mail:"
                placeholder="seu@email.com"
                register={register("email")}
                error={errors?.email?.message}
                defaultValue={formQuote?.form?.email ?? ""}
              />
            </div>

            <div className="form-group col-md-3">
              <SelectInput
                label="Documento de Indentificação:"
                placeholder="Seu Documento Aqui"
                control={control}
                name="documentType"
                defaultValue={
                  formQuote?.form?.documentType ??
                  DocumentTypeDescription.Outros
                }
                error={errors?.documentType?.message}
              >
                <MenuItem value={DocumentTypeDescription.CPF}>CPF</MenuItem>
                <MenuItem
                  value={DocumentTypeDescription["Carteira de Habilitação"]}
                >
                  Carteira de Habilitação
                </MenuItem>
                <MenuItem
                  value={
                    DocumentTypeDescription["Comprovante Split de Pagamento"]
                  }
                >
                  Comprovante Split de Pagamento
                </MenuItem>
                <MenuItem
                  value={DocumentTypeDescription["Comprovante de Pagamento"]}
                >
                  Comprovante de Pagamento
                </MenuItem>
                <MenuItem
                  value={DocumentTypeDescription["Comprovante de Residência"]}
                >
                  Comprovante de Residência
                </MenuItem>
                <MenuItem value={DocumentTypeDescription.Orçamento}>
                  Orçamento
                </MenuItem>
                <MenuItem value={DocumentTypeDescription.Outros}>
                  Outros
                </MenuItem>
                <MenuItem value={DocumentTypeDescription.RG}>RG</MenuItem>
                <MenuItem value={DocumentTypeDescription["Solicitação Médica"]}>
                  Solicitação Médica
                </MenuItem>
                <MenuItem
                  value={
                    DocumentTypeDescription["Termo de Prestação de Serviço"]
                  }
                >
                  Termo de Prestação de Serviço
                </MenuItem>
              </SelectInput>
            </div>

            <div className="form-group col-md-3">
              <Input
                label="Celular:"
                placeholder="(99) 99999-9999"
                type="tel"
                maxLength={15}
                register={register("phone")}
                error={errors?.phone?.message}
                onChange={(e) => handleMobilePhoneInput(e, setValue, "phone")}
                defaultValue={formQuote?.form?.phone ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <Input
                label="CPF:"
                placeholder="999.999.999-99"
                type="tel"
                maxLength={14}
                register={register("cpf")}
                error={errors?.cpf?.message}
                onChange={(e) => handleCpfInput(e, setValue, "cpf")}
                defaultValue={
                  formQuote?.form?.cpf ? cpfMask(formQuote.form.cpf) : ""
                }
              />
            </div>

            <div className="form-group col-md-2">
              <Input
                className="text-uppercase"
                label="RG:"
                placeholder="9999999-9"
                type="tel"
                register={register("rg")}
                error={errors?.rg?.message}
                onChange={(e) => handleRgInput(e, setValue, "rg")}
                defaultValue={
                  formQuote?.form?.rg ? rgMask(formQuote.form.rg) : ""
                }
              />
            </div>

            <div className="form-group col-md-2">
              <Input
                className="text-uppercase"
                label="Orgao Emissor:"
                placeholder="SSP"
                register={register("issuingOrganization")}
                error={errors?.issuingOrganization?.message}
                defaultValue={formQuote?.form?.issuingOrganization ?? ""}
              />
            </div>

            <div className="form-group col-md-2">
              <Input
                className="text-uppercase"
                label="UF do RG:"
                placeholder="SP"
                register={register("ufrg")}
                error={errors?.ufrg?.message}
                defaultValue={formQuote?.form?.ufrg ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label className="" htmlFor="select">
                Sexo:
              </label>

              <div className="row">
                <div className="col col-auto">
                  <RadioInput
                    label="Masculino"
                    value="M"
                    register={register("sex")}
                    defaultChecked={formQuote?.form?.sex == "M"}
                  />
                </div>
                <div className="col col-auto">
                  <RadioInput
                    label="Feminino"
                    value="F"
                    register={register("sex")}
                    defaultChecked={formQuote?.form?.sex == "F"}
                  />
                </div>
                <div className="col col-auto">
                  <RadioInput
                    label="Intersexo"
                    value="I"
                    register={register("sex")}
                    defaultChecked={formQuote?.form?.sex == "I"}
                  />
                </div>
              </div>

              <div className="text-danger mb-3">
                <small>{!!errors?.sex && "*Selecione uma opção"}</small>
              </div>
            </div>

            <div className="form-group col-md-3">
              <Input
                className="text-uppercase"
                type="text"
                label="Nacionalidade:"
                placeholder="Brasileiro"
                register={register("nationality")}
                error={errors?.nationality?.message}
                defaultValue={formQuote?.form?.nationality ?? ""}
              />
            </div>

            <div className="form-group col-md-3">
              <Input
                className="text-uppercase"
                type="text"
                label="Cidade de Nascimento:"
                placeholder="IPORANGA"
                register={register("birthCity")}
                error={errors?.birthCity?.message}
                defaultValue={formQuote?.form?.birthCity ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <SelectInput
                label="Pessoa Com Deficiencia?"
                placeholder="Sim/Não"
                control={control}
                name="personWithDisability"
                defaultValue={formQuote?.form?.personWithDisability ? 1 : 0}
                error={errors?.personWithDisability?.message}
              >
                <MenuItem value={0}>NÃO</MenuItem>
                <MenuItem value={1}>SIM</MenuItem>
              </SelectInput>
            </div>

            <div className="form-group col-md-6">
              <SelectInput
                label="Pessoa Publica?"
                placeholder="Sim/Não"
                control={control}
                name="publicPerson"
                defaultValue={formQuote?.form?.publicPerson ? 1 : 0}
                error={errors?.publicPerson?.message}
              >
                <MenuItem value={0}>NÃO</MenuItem>
                <MenuItem value={1}>SIM</MenuItem>
              </SelectInput>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <Input
                className="text-uppercase"
                type="text"
                label="Nome da Mãe:"
                placeholder="Nome da Sua Mãe Aqui"
                register={register("motherName")}
                error={errors?.motherName?.message}
                defaultValue={formQuote?.form?.motherName ?? ""}
              />
            </div>

            <div className="form-group col-md-6">
              <Input
                className="text-uppercase"
                type="text"
                label="Nome do Pai (opcional):"
                placeholder="Nome do Seu Pai Aqui"
                register={register("fatherName")}
                error={errors?.fatherName?.message}
                defaultValue={formQuote?.form?.fatherName ?? ""}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={handleBack}
          disabled={true}
        >
          Voltar
        </Button>

        <Button className="btn-primary" type="submit">
          <i className="uil uil-check mr-2" />
          Próximo
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Step1PersonalInfoForm;
