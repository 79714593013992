import { CircularProgress } from "@mui/material";

import Logo from "assets/logo.png";

const LoadingScreen = () => {
  return (
    <div
      className="w-100 h-100 content"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <div
        className="position-absolute text-center top-50 start-50"
        style={{ width: "270px", marginLeft: "-135px", marginTop: "-50px" }}
      >
        <img
          src={Logo}
          alt="logo docttorpay"
          className="animate__animated animate__pulse animate__infinite	infinite mb-3"
        />
        <h3>
          <CircularProgress size={25} className="me-2" color="primary" />
          Carregando{" "}
          <span className="d-inline-block animate__animated animate__flash animate__infinite	infinite animate__slower">
            ...
          </span>
        </h3>
        <strong>Por favor aguarde</strong>
      </div>
    </div>
  );
};

export default LoadingScreen;
