import { Navigate } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import NavCard from "components/NavCard";
import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

const Reports = () => {
  const { userRole } = useAuth();

  return (
    <>
      <ContainerAnimation className="content container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <h1>Relatórios</h1>

            <p className="m-0">
              Tenha acesso aos relatorios de orçamentos dos seus clientes.
            </p>

            <p>
              Acompanhe em tempo real os orçamento de seu cliente atraves dos
              relatorios.
            </p>

            <div className="row no-gutters mx-n2 pt-5">
              <NavCard
                title="Orçamentos"
                description="Relatório de Orçamentos finalizados"
                icon="uil-bill"
                to={
                  userRole === RoleDescription.Patient
                    ? "/relatorios-orcamentos-paciente"
                    : "/relatorios-orcamentos-profissional"
                }
              />
            </div>
          </div>
        </div>
      </ContainerAnimation>
    </>
  );
};

export default Reports;
