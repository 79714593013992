import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UseFormSetFocus, UseFormSetValue, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import { useQuoteDetails } from "providers/QuoteDetails";
import {
  ActionFormQuote,
  Address,
  Form,
  FormQuote,
  ResidenceTypeDescription,
  TimeAddress,
} from "types/formQuote";
import { brazilianStates } from "utils/brazilianStates";
import checkCep from "utils/checkCep";
import { twoDecimalPlacesMask } from "utils/formMasks";
import { handleCepInput, handleTwoDecimalPlacesInput } from "utils/handleInput";

interface Step2AddessInfoFormProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formQuote?: FormQuote;
  setFormQuote: React.Dispatch<React.SetStateAction<FormQuote>>;
  onHide: () => void;
  quoteId: number;
  action: ActionFormQuote;
}

const Step2AddessInfoForm = ({
  setActiveStep,
  formQuote,
  setFormQuote,
  onHide,
  quoteId,
  action,
}: Step2AddessInfoFormProps) => {
  const [inputCep, setInputCep] = useState("");
  const [isSearchingCep, setIsSearchingCep] = useState(false);
  const {
    saveFormAsDraft,
    setSaveFormAsDraft,
    createFormQuote,
    editFormQuote,
  } = useQuoteDetails();

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const schema = yup.object().shape({
    addressCEP: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*Deve ter 8 dígitos"),
    addressRoad: yup.string().required("*Campo obrigatório").uppercase().trim(),
    addressNumber: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressComplement: yup.string().uppercase().trim(),
    addressDistrict: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressState: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
    addressCity: yup
      .string()
      .required("*Campo obrigatório")
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
    timeAtAddress: yup.number().required("*Campo obrigatório"),
    residenceType: yup.number().required("*Campo obrigatório"),
    RentValue: yup.string().required("*Campo obrigatório"),
  });

  const onSubmit = (data: Address) => {
    setFormQuote({
      ...formQuote,
      form: {
        ...formQuote?.form,
        address: {
          addressCEP: data.addressCEP,
          addressRoad: data.addressRoad,
          addressNumber: data.addressNumber,
          addressDistrict: data.addressDistrict,
          addressCity: data.addressCity,
          addressState: data.addressState,
          addressComplement: data.addressComplement ?? null,
          timeAtAddress: data.timeAtAddress,
          residenceType: data.residenceType,
          RentValue: data.RentValue,
        },
      } as Form,
    } as FormQuote);

    handleNext();
  };

  useEffect(() => {
    setSaveFormAsDraft(false);
  }, []);

  useEffect(() => {
    if (!saveFormAsDraft) {
      return;
    }

    if (!formQuote) {
      return;
    }

    const data = getValues() as Address;

    const cache = {
      ...formQuote,
      form: {
        ...formQuote?.form,
        address: {
          ...formQuote?.form?.address,
          addressCEP: data.addressCEP ? data.addressCEP : "",
          addressRoad: data.addressRoad ? data.addressRoad : "",
          addressNumber: data.addressNumber ? data.addressNumber : "",
          addressDistrict: data.addressDistrict ? data.addressDistrict : "",
          addressCity: data.addressCity ? data.addressCity : "",
          addressState: data.addressState ? data.addressState : "",
          addressComplement: data.addressComplement ?? null,
          timeAtAddress: data.timeAtAddress
            ? data.timeAtAddress
            : TimeAddress["NÃO INFORMADO"],
          residenceType: data.residenceType
            ? data.residenceType
            : ResidenceTypeDescription["NÃO INFORMADO"],
          RentValue: data.RentValue ? data.RentValue : "",
          isDraft: true,
        },
      } as Form,
    } as FormQuote;

    if (action === ActionFormQuote.Create) {
      createFormQuote(cache, quoteId, onHide);
    }

    if (action === ActionFormQuote.Edit) {
      editFormQuote(cache, quoteId, onHide);
    }

    setSaveFormAsDraft(false);
    setActiveStep(0);
  }, [saveFormAsDraft]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    control,
    formState: { errors },
  } = useForm<Address>({ resolver: yupResolver(schema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="pt-0">
        <div className="p-xl-2">
          <header className="row py-4">
            <div className="col">
              <h5>Endereço da Instituição</h5>
            </div>
          </header>

          <div className="row flex-column gap-2 flex-sm-row gap-md-0 align-items-center">
            <div className="col">
              <Input
                label="Consulte pelo CEP:"
                placeholder="99999-999"
                type="text"
                register={register("addressCEP")}
                error={errors?.addressCEP?.message}
                onChange={(e) =>
                  handleCepInput(e, setValue, "cep", setInputCep)
                }
                defaultValue={formQuote?.form?.address?.addressCEP ?? ""}
              />
              <div className="col-12 col-md-auto small ps-0">
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Não sei meu CEP!
                </a>
              </div>
            </div>

            <div className="col col-auto">
              <Button
                type="button"
                className="btn-sm btn-dark mt-3"
                onClick={() =>
                  checkCep(inputCep, setIsSearchingCep, setValue, setFocus)
                }
                disabled={isSearchingCep}
              >
                Consultar
                {!!isSearchingCep && (
                  <CircularProgress
                    color="inherit"
                    size={12}
                    className="align-text-top ms-2"
                  />
                )}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Endereço:"
                  placeholder="Nome da Rua"
                  type="text"
                  register={register("addressRoad")}
                  error={errors?.addressRoad?.message}
                  defaultValue={formQuote?.form?.address?.addressRoad ?? ""}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Número:"
                  placeholder="nº"
                  type="text"
                  register={register("addressNumber")}
                  error={errors?.addressNumber?.message}
                  defaultValue={formQuote?.form?.address?.addressNumber ?? ""}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Complemento:"
                  placeholder="Casa, apto, bloco"
                  type="text"
                  register={register("addressComplement")}
                  error={errors?.addressComplement?.message}
                  defaultValue={
                    formQuote?.form?.address?.addressComplement ?? ""
                  }
                />
              </div>
            </div>

            <div className="col-sm-6">
              <Input
                className="text-uppercase"
                label="Bairro:"
                placeholder="Nome do Bairro"
                type="text"
                register={register("addressDistrict")}
                error={errors?.addressDistrict?.message}
                defaultValue={formQuote?.form?.address?.addressDistrict ?? ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                name="addressState"
                label="Estado:"
                placeholder="Selecione"
                control={control}
                error={errors?.addressState?.message}
                defaultValue={
                  formQuote?.form?.address?.addressState ?? "selecione"
                }
              >
                <MenuItem value="selecione" disabled>
                  Selecione
                </MenuItem>
                {brazilianStates.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </SelectInput>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Input
                  className="text-uppercase"
                  label="Cidade:"
                  placeholder="Nome da Cidade"
                  type="text"
                  register={register("addressCity")}
                  error={errors?.addressCity?.message}
                  defaultValue={formQuote?.form?.address?.addressCity ?? ""}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <SelectInput
                label="Tipo da Residência:"
                placeholder="Tipo da Residência"
                control={control}
                name="residenceType"
                defaultValue={
                  formQuote?.form?.address?.residenceType ??
                  ResidenceTypeDescription["NÃO INFORMADO"]
                }
                error={errors?.residenceType?.message}
              >
                <MenuItem value={ResidenceTypeDescription["NÃO INFORMADO"]}>
                  NÃO INFORMADO
                </MenuItem>
                <MenuItem value={ResidenceTypeDescription["PROPRIO"]}>
                  PROPRIO
                </MenuItem>
                <MenuItem value={ResidenceTypeDescription["COM OS PAIS"]}>
                  COM OS PAIS
                </MenuItem>
                <MenuItem value={ResidenceTypeDescription["FUNCIONAL"]}>
                  FUNCIONAL
                </MenuItem>
                <MenuItem value={ResidenceTypeDescription["ALUGADO"]}>
                  ALUGADO
                </MenuItem>
              </SelectInput>
            </div>

            <div className="col-sm-4">
              <SelectInput
                label="Tempo No Endereco:"
                placeholder="Tempo No Endereco"
                control={control}
                name="timeAtAddress"
                defaultValue={
                  formQuote?.form?.address?.timeAtAddress ??
                  TimeAddress["NÃO INFORMADO"]
                }
                error={errors?.timeAtAddress?.message}
              >
                <MenuItem value={TimeAddress["NÃO INFORMADO"]}>
                  NÃO INFORMADO
                </MenuItem>
                <MenuItem value={TimeAddress["MENOR QUE 1 ANO"]}>
                  MENOR QUE 1 ANO
                </MenuItem>
                <MenuItem value={TimeAddress["ENTRE 1 E 3 ANOS"]}>
                  ENTRE 1 E 3 ANOS
                </MenuItem>
                <MenuItem value={TimeAddress["ENTRE 3 E 5 ANOS"]}>
                  ENTRE 3 E 5 ANOS
                </MenuItem>
                <MenuItem value={TimeAddress["MAIS QUE 5 ANOS"]}>
                  MAIS QUE 5 ANOS
                </MenuItem>
              </SelectInput>
            </div>

            <div className="col-sm-4">
              <Input
                type="tel"
                label="Valor do Aluguel:"
                placeholder="0,00"
                isPriceField
                register={register("RentValue")}
                error={errors?.RentValue?.message}
                onChange={(e) =>
                  handleTwoDecimalPlacesInput(e, setValue, "RentValue")
                }
                defaultValue={
                  formQuote?.form?.address
                    ? twoDecimalPlacesMask(
                        formQuote?.form?.address?.RentValue + ""
                      )
                    : 0
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" className="btn-light" onClick={handleBack}>
          Voltar
        </Button>

        <Button className="btn-primary" type="submit">
          <i className="uil uil-check mr-2" />
          Próximo
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Step2AddessInfoForm;
