import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import { useRegister } from "providers/Register";
import { CompanyRegisterAsLeadData } from "types/register";
import { validateCnpj } from "utils/formValidations";
import { handleCnpjInput, handleMobilePhoneInput } from "utils/handleInput";

const CompanyRegisterForm = () => {
  const navigate = useNavigate();
  const { isLoading, registerCompanyAsLead } = useRegister();

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    cnpj: yup
      .string()
      .required("*Campo obrigatório")
      .test("cnpjValidator", "*CNPJ inválido", validateCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    contactName: yup.string().required("*Campo obrigatório").uppercase().trim(),
    contactJobPosition: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido"),
    enableCheckList: yup.boolean().required("*Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CompanyRegisterAsLeadData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: CompanyRegisterAsLeadData) => {
    registerCompanyAsLead(data);
  };

  return (
    <form
      className="p-3 p-sm-4 p-xl-5 aos-init"
      data-aos="fade-up"
      data-aos-delay="200"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-box p-3 p-sm-4 p-xl-5">
        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome da Instituição:"
            placeholder="Nome da Empresa, Clínica, Hospital..."
            type="text"
            register={register("name")}
            error={errors?.name?.message}
          />
        </div>

        <div className="form-group">
          <Input
            label="CNPJ:"
            placeholder="CNPJ da instituição"
            type="tel"
            register={register("cnpj")}
            error={errors?.cnpj?.message}
            onChange={(e) => handleCnpjInput(e, setValue, "cnpj")}
          />
        </div>

        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome Completo:"
            placeholder="Seu Nome Aqui"
            type="text"
            register={register("contactName")}
            error={errors?.contactName?.message}
          />
        </div>

        <div className="row align-items-end">
          <div className="col-md-6">
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="Cargo:"
                placeholder="CEO, Administrativo, Comercial"
                type="text"
                register={register("contactJobPosition")}
                error={errors?.contactJobPosition?.message}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <Input
                label="Telefone para Contato:"
                placeholder="(99) 99999-9999"
                type="tel"
                register={register("mobilePhone")}
                error={errors?.mobilePhone?.message}
                onChange={(e) =>
                  handleMobilePhoneInput(e, setValue, "mobilePhone")
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <Input
            label="E-mail:"
            placeholder="seu@email.com"
            type="text"
            register={register("email")}
            error={errors?.email?.message}
          />
          <small id="emailHelp" className="form-text text-muted">
            Nunca compartilharemos seu email.
          </small>
        </div>

        <div className="form-check">
          <label
            className="control control--checkbox mb-2"
            htmlFor="enableCheckList"
          >
            <span className="caption">Habilitar Checklist</span>
            <input
              className="form-check-input"
              type="checkbox"
              id="enableCheckList"
              {...register("enableCheckList")}
            />
            <div className="control__indicator"></div>
          </label>
        </div>
      </div>

      <div className="row no-gutters justify-content-between pt-3 pt-sm-4 pt-xl-5">
        <div className="col col-auto">
          <Button
            className="btn-light"
            type="button"
            disabled={isLoading}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
        <div className="col col-auto">
          <Button className="btn-primary" type="submit" disabled={isLoading}>
            Finalizar
            {!!isLoading && (
              <CircularProgress
                color="inherit"
                size={12}
                className="align-text-top ms-2"
              />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CompanyRegisterForm;
