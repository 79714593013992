import { Pagination } from "@mui/material";
import { useEffect, useRef } from "react";
import { Badge } from "react-bootstrap";

import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import { usePatient } from "providers/Patient";

import PatientCard from "../PatientCard";

interface ActivePatientList {
  companyId: number;
  searchData: string;
}

const ActivePatientList = ({ companyId, searchData }: ActivePatientList) => {
  const activePatientsRef = useRef<HTMLDivElement>(null);

  const {
    activePatients,
    activePatientsTotal,
    activePatientsTotalPages,
    isLoadingActivePatients,
    activePatientsCurrentPage,
    getActivePatients,
    setActivePatientsCurrentPage,
  } = usePatient();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setActivePatientsCurrentPage(value);

    getActivePatients(companyId, value, searchData);

    activePatientsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getActivePatients(companyId, 1, searchData);
  }, [companyId, searchData]);

  return (
    <>
      <div ref={activePatientsRef}></div>

      {!!isLoadingActivePatients &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de Pacientes ativos */}
      {!isLoadingActivePatients && (
        <>
          {!!activePatients.length &&
            activePatients?.map((activePatient) => (
              <PatientCard
                key={activePatient.id}
                patient={activePatient}
                companyId={companyId}
                isActive={true}
              />
            ))}

            {/* Lista de pacientes inativos vazia */}
            {companyId === -1 ? (
              <EmptyList
                icon="uil-user-md"
                title="Por favor, selecione uma empresa acima"
              />
            ) : !activePatients.length ? (
              <EmptyList icon="uil-user-md" title="Nenhum paciente inativo" />
            ) : null}
        </>
      )}

      {activePatientsTotalPages > 1 && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={activePatientsTotalPages}
          page={activePatientsCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}
    </>
  );
};

export default ActivePatientList;
