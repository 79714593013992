import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import PaymentInfo from "components/PaymentInfo";
import QuoteStatusInfo from "components/QuoteStatusInfo";
import { useQuoteDetails } from "providers/QuoteDetails";
import { PaymentMethod } from "types/enums";
import { Quote } from "types/quotes";
import { formatPrice } from "utils/formats";

interface PatientQuoteCardProps {
  quote: Quote;
}

const PatientQuoteCard = ({ quote }: PatientQuoteCardProps) => {
  const { pendingItems } = useQuoteDetails();

  return (
    <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(quote.payment?.method === PaymentMethod.Financing ? 'border-dark' : 'border-primary')}>
      
      <div className="row align-items-center">
        {/* Informações do orçamento  */}
        <div className="col">
          <div className="row align-items-center no-gutters">
            <div className="col col-auto pb-3 pr-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  quote.payment?.method === PaymentMethod.Financing ? (
                    <Tooltip>Financiamento</Tooltip>
                  ) : (
                    <Tooltip>Parcelamento</Tooltip>
                  )
                }
              >
                {quote.payment?.method === PaymentMethod.Financing ? (
                  <div className="badge badge-dark rounded-circle py-2 fs-5 text-center">
                    <i className="uil uil-file-landscape-alt"></i>
                  </div>
                ) : (
                  <div className="badge badge-primary rounded-circle py-2 fs-5 text-center">
                    <i className="uil uil-credit-card"></i>
                  </div>
                )}
              </OverlayTrigger>
            </div>
            {/* Nome do médico */}
            <div className="col col-sm-10 col-md-6 col-lg-7 col-xl pb-3 px-1">
              <p className="small opacity-75 mb-0">Profissional</p>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{quote.professional?.name}</Tooltip>}
              >
                <b className="d-block text-truncate">
                  {quote.professional?.name}
                </b>
              </OverlayTrigger>
            </div>

            {/* Data do procedimento */}
            <div className="col-12 col-sm-6 col-md-2 pb-3">
              <p className="small opacity-75 mb-0 text-truncate">
                Data de Criação
              </p>
              <b>{moment(quote.creationDate).format("DD/MM/YYYY")}</b>
            </div>

            {/* Dara do procedimento */}
            <div className="col-12 col-sm-6 col-md-4 col-xl-2 pb-3 px-1 ml-auto">
              <p className="small opacity-75 mb-0">Data do Procedimento</p>
              <b>{moment(quote.date).format("DD/MM/YYYY")}</b>
            </div>

            {/* Data limite */}
            <div className="col-12 col-sm-6 col-md-4 col-xl-2 pb-3 px-1">
              <p className="small opacity-75 mb-0">Data Limite</p>
              <OverlayTrigger
                placement="top"
                overlay={
                  quote.hasLimitDateAlert ? (
                    <Tooltip>Orçamento está prestes a expirar</Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <b
                  className={quote.hasLimitDateAlert ? "text-danger" : ""}
                  style={{
                    cursor: quote.hasLimitDateAlert ? "default" : undefined,
                  }}
                >
                  {moment(quote.limitDate).format("DD/MM/YYYY")}
                </b>
              </OverlayTrigger>
            </div>

            {/* Valor do orçamento */}
            <div className="col-12 col-sm-6 col-md-4 col-xl-2 pb-3 text-nowrap px-1">
              <p className="small opacity-75 mb-0">
                Valor <PaymentInfo payment={quote.payment} />
              </p>
              <b>{formatPrice(quote.payment.value)}</b>
            </div>

            {/* Status do orçamento */}
            <div className="col-12 col-sm-6 col-md-4 col-xl-1 pb-3 text-nowrap px-1">
              <p className="small opacity-75 mb-0">Status</p>
              <QuoteStatusInfo
                quoteStatus={quote.quoteStatus}
                paymentMethod={quote.payment.method}
              />
            </div>
          </div>
        </div>

        {/* Botão de detalhes */}
        <div className="col col-auto pb-3">
          <div className="col col-auto pl-2 position-relative">
            <Link
              to={"/orcamentos-paciente/orcamento-detalhes"}
              className="btn btn-light btn-sm"
              state={quote.id}
            >
              Detalhes
            </Link>

            {!!pendingItems.length && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Ver Pendências</Tooltip>}
              >
                <i className="animate__animated animate__pulse animate__infinite uil uil-exclamation-circle text-white bg-danger px-1 rounded-circle position-absolute start-0 mt-n1"></i>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientQuoteCard;
