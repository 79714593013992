import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AlertModal from "components/AlertModal";
import ModalComponent from "components/ModalComponent";
import { useCompany } from "providers/Company";
import { api } from "services";
import { Company } from "types/company";
import { cnpjMask, mobilePhoneMask } from "utils/formMasks";
import { toast } from "utils/toast";

import CompanyEditForm from "../Forms/CompanyEditForm";

interface CompanyCardProps {
  company: Company;
  isActive?: boolean;
}

const CompanyCard = ({ company, isActive = true }: CompanyCardProps) => {
  const navigate = useNavigate();
  const [companyEditModalShow, setCompanyEditModalShow] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setInactiveCompaniesCurrentPage,
    setActiveCompaniesCurrentPage,
    getInactiveCompanies,
    getActiveCompanies,
  } = useCompany();

  const handleActivateCompany = async () => {
    setIsLoading(true);

    try {
      await api.patch(
        `/Company/${company.id}/${isActive ? "disable" : "enable"}`
      );

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: `Instituição ${isActive ? "des" : ""}ativada com sucesso!`,
      });

      setInactiveCompaniesCurrentPage(1);
      setActiveCompaniesCurrentPage(1);

      getInactiveCompanies();
      getActiveCompanies();

      setAlertModalShow(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  return (
    <>
      <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(isActive ? 'border-primary' : 'border-dark')}>
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do instituição */}
              <div className="col-12 col-sm-6 col-lg-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Instituição</p>
                <b>{company.name}</b>
              </div>

              {/* CNPJ da instituição */}
              <div className="col-12 col-sm-6 col-lg-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">CNPJ</p>
                <b className="text-break">{cnpjMask(company.cnpj)}</b>
              </div>

              {/* Endereço da instituição */}
              <div className="col-12 col-sm-6 col-lg-4 pb-3 px-1">
                <p className="small opacity-75 mb-0">Endereço</p>
                <b>
                  {!company.addressCEP && "Sem endereço cadastrado"}

                  {!!company.addressCEP && (
                    <>
                      {company.addressRoad}, {company.addressNumber} -{" "}
                      {company.addressComplement &&
                        `${company.addressComplement},`}{" "}
                      {company.addressDistrict}, {company.addressCity} -{" "}
                      {company.addressState}
                    </>
                  )}
                </b>
              </div>

              {/* Contato */}
              <div className="col-12 col-sm-6 col-lg-4 pb-3 px-1">
                <p className="small opacity-75 mb-0">Contato</p>
                <b>
                  {company.contactName} - {company.contactJobPosition}
                </b>
                <br />
                <b className="text-break">{company.email}</b>
                <br />
                <b>
                  {mobilePhoneMask(
                    `${company.dddMobilePhone}${company.mobilePhone}`
                  )}
                </b>
              </div>
            </div>
          </div>

          {/* Botão*/}
          <div className="col col-auto pb-3">
            <Dropdown>
              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => setAlertModalShow(true)}
                  className={isActive ? "text-danger" : "text-success"}
                >
                  <i
                    className={`uil uil-${
                      isActive ? "times" : "check"
                    }-circle mr-2`}
                  />
                  {isActive ? "Desativar" : "Ativar"}
                </Dropdown.Item>

                {isActive && (
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate("/instituicoes/instituicao-profissionais", {
                        state: { company },
                      })
                    }
                  >
                    <i className="uil uil-medkit mr-2" />
                    Profissionais
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  as="button"
                  onClick={() => setCompanyEditModalShow(true)}
                >
                  <i className="uil uil-edit-alt mr-2" />
                  Editar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Modal de confirmar ativação de instituição */}
      <AlertModal
        show={alertModalShow}
        onHide={() => setAlertModalShow(false)}
        title={`Tem certeza que deseja ${
          isActive ? "des" : ""
        }ativar essa instituição?`}
        onClickButton={handleActivateCompany}
        btnVariant={isActive ? "danger" : "success"}
        isLoading={isLoading}
      />

      {/* Modal de editar instituição */}
      <ModalComponent
        title="Editar Instituição"
        show={companyEditModalShow}
        onHide={() => setCompanyEditModalShow(false)}
        size="xl"
      >
        <CompanyEditForm
          onHide={() => setCompanyEditModalShow(false)}
          company={company}
        />
      </ModalComponent>
    </>
  );
};

export default CompanyCard;
