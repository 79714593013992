import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { NavigateFunction } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { api } from "services";
import {
  Quote,
  SearchDataPatient,
  QuoteReport,
  QuoteCreateData,
} from "types/quotes";
import { toast } from "utils/toast";

interface PatientQuoteProviderProps {
  children: ReactNode;
}

interface PatientQuoteProviderData {
  patientQuotes: Quote[];
  patientQuotesTotal: number;
  patientQuotesFound: Quote[];
  patientQuotesFoundTotal: number;
  patientQuotesTotalPages: number;
  patientQuotesFoundTotalPages: number;
  patientQuotesCurrentPage: number;
  patientQuotesFoundCurrentPage: number;

  getPatientQuotes: (currentPage?: number) => Promise<void>;
  searchPatientQuotes: (
    data: Omit<SearchDataPatient, "patientName">,
    currentPage?: number
  ) => Promise<void>;
  setPatientQuotesFound: React.Dispatch<React.SetStateAction<Quote[]>>;
  setPatientQuotesCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPatientQuotesFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  patientQuotesReport: QuoteReport[];
  patientQuotesReportTotal: number;
  patientQuotesReportTotalPages: number;
  patientQuotesReportCurrentPage: number;
  patientQuotesReportFound: QuoteReport[];
  patientQuotesReportFoundTotal: number;
  patientQuotesReportFoundTotalPages: number;
  patientQuotesReportFoundCurrentPage: number;

  getPatientQuotesReport: (currentPage?: number) => Promise<void>;
  searchPatientQuotesReport: (
    data: Omit<SearchDataPatient, "patientName">,
    currentPage?: number
  ) => Promise<void>;
  setPatientQuotesReportFound: React.Dispatch<
    React.SetStateAction<QuoteReport[]>
  >;
  setPatientQuotesReportCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setPatientQuotesReportFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  createPatientQuote: (
    data: QuoteCreateData,
    files: File[],
    onHide: () => void,
    navigate: NavigateFunction
  ) => Promise<void>;
  editPatientQuote: (
    data: Omit<QuoteCreateData, "fileTypes">,
    quoteId: number,
    onHide: () => void,
    setQuote: React.Dispatch<React.SetStateAction<Quote>>
  ) => Promise<void>;
  removePatientQuote: (
    quoteId: number,
    navigate: NavigateFunction
  ) => Promise<void>;

  searchData: Omit<SearchDataPatient, "patientName">;
  setSearchData: React.Dispatch<
    React.SetStateAction<Omit<SearchDataPatient, "patientName">>
  >;

  isLoadingPatientQuotesReport: boolean;
  isLoading: boolean;

  patientQuotesReportGlobalRef: any;
  setPatientQuotesReportGlobalRef: React.Dispatch<any>;
}

export const PatientQuoteContext = createContext<PatientQuoteProviderData>(
  {} as PatientQuoteProviderData
);

export const PatientQuoteProvider = ({
  children,
}: PatientQuoteProviderProps) => {
  const patientQuotesLimit = 10;

  const { token } = useAuth();

  const [searchData, setSearchData] = useState(
    {} as Omit<SearchDataPatient, "patientName">
  );

  //#region provider states patientQuotes
  const [patientQuotes, setPatientQuotes] = useState<Quote[]>([]);
  const [patientQuotesTotal, setPatientQuotesTotal] = useState(0);

  const [patientQuotesTotalPages, setPatientQuotesTotalPages] = useState(0);

  const [patientQuotesFound, setPatientQuotesFound] = useState<Quote[]>([]);

  const [patientQuotesCurrentPage, setPatientQuotesCurrentPage] = useState(1);

  const [patientQuotesFoundCurrentPage, setPatientQuotesFoundCurrentPage] =
    useState(1);

  const [patientQuotesFoundTotalPages, setPatientQuotesFoundTotalPages] =
    useState(0);

  const [patientQuotesFoundTotal, setPatientQuotesFoundTotal] = useState(0);
  //#endregion

  //#region provider states patientQuotesReport
  const [patientQuotesReport, setPatientQuotesReport] = useState<QuoteReport[]>(
    []
  );

  const [patientQuotesReportTotal, setPatientQuotesReportTotal] = useState(0);

  const [patientQuotesReportTotalPages, setPatientQuotesReportTotalPages] =
    useState(0);

  const [patientQuotesReportCurrentPage, setPatientQuotesReportCurrentPage] =
    useState(1);

  const [patientQuotesReportFound, setPatientQuotesReportFound] = useState<
    QuoteReport[]
  >([]);

  const [patientQuotesReportFoundTotal, setPatientQuotesReportFoundTotal] =
    useState(0);

  const [
    patientQuotesReportFoundTotalPages,
    setPatientQuotesReportFoundTotalPages,
  ] = useState(0);

  const [
    patientQuotesReportFoundCurrentPage,
    setPatientQuotesReportFoundCurrentPage,
  ] = useState(1);
  //#endregion

  const [isLoadingPatientQuotesReport, setIsLoadingPatientQuotesReport] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [patientQuotesReportGlobalRef, setPatientQuotesReportGlobalRef] =
    useState(null);

  const getPatientQuotesReport = async (currentPage = 1) => {
    setIsLoadingPatientQuotesReport(true);

    try {
      const response = await api.get("Quote/report", {
        params: {
          page: currentPage,
          size: patientQuotesLimit,
          onlyOpened: false,
        },
      });

      setIsLoadingPatientQuotesReport(false);
      setPatientQuotesReport(response.data);
      setPatientQuotesReportTotal(Number(response.headers["x-total-count"]));

      setPatientQuotesReportTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / patientQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingPatientQuotesReport(false);
    }
  };

  const searchPatientQuotesReport = async (
    data: Omit<SearchDataPatient, "patientName">,
    currentPage = 1
  ) => {
    setIsLoadingPatientQuotesReport(true);
    setPatientQuotesReportFoundCurrentPage(currentPage);

    const {
      professionalName,
      beginDateCreate,
      endDateCreate,
      beginDate,
      endDate,
      chooseDate,
      range,
      paymentMethod,
      idQuote,
    } = data;

    try {
      const response = await api.get("Quote/report", {
        params: {
          idQuote: idQuote,
          page: currentPage,
          size: patientQuotesLimit,
          doctorName: professionalName ? professionalName : undefined,
          beginDate: beginDate
            ? moment(beginDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          beginDateCreate: beginDateCreate
            ? moment(beginDateCreate).format("YYYY-MM-DD")
            : undefined,
          endDateCreate: endDateCreate
            ? moment(endDateCreate).format("YYYY-MM-DD")
            : undefined,
          minPrice: range ? range[0] : 0,
          maxPrice: range ? range[1] : 100000,
          onlyOpened: false,
          chooseDate: chooseDate,
          creditMethod: paymentMethod ? paymentMethod.credit : undefined,
          financingMethod: paymentMethod ? paymentMethod.financing : undefined,
        },
      });

      setIsLoadingPatientQuotesReport(false);
      setPatientQuotesReportFound(response.data);

      setPatientQuotesReportFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setPatientQuotesReportFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / patientQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingPatientQuotesReport(false);
    }
  };

  const getPatientQuotes = async (currentPage = 1) => {
    setIsLoading(true);

    try {
      const response = await api.get("Quote/search", {
        params: {
          page: currentPage,
          size: patientQuotesLimit,
        },
      });

      setIsLoading(false);
      setPatientQuotes(response.data);
      setPatientQuotesTotal(Number(response.headers["x-total-count"]));

      setPatientQuotesTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / patientQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const searchPatientQuotes = async (
    data: Omit<SearchDataPatient, "patientName">,
    currentPage = 1
  ) => {
    setIsLoading(true);
    setPatientQuotesFoundCurrentPage(currentPage);

    const {
      professionalName,
      beginDateCreate,
      endDateCreate,
      beginDate,
      endDate,
      chooseDate,
      range,
      paymentMethod,
    } = data;

    try {
      const response = await api.get("Quote/search", {
        params: {
          page: currentPage,
          size: patientQuotesLimit,
          doctorName: professionalName ? professionalName : undefined,
          beginDate: beginDate
            ? moment(beginDate).format("YYYY-MM-DD")
            : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          beginDateCreate: beginDateCreate
            ? moment(beginDateCreate).format("YYYY-MM-DD")
            : undefined,
          endDateCreate: endDateCreate
            ? moment(endDateCreate).format("YYYY-MM-DD")
            : undefined,
          minPrice: range ? range[0] : 0,
          maxPrice: range ? range[1] : 100000,
          onlyOpened: true,
          chooseDate: chooseDate,
          creditMethod: paymentMethod ? paymentMethod.credit : undefined,
          financingMethod: paymentMethod ? paymentMethod.financing : undefined,
        },
      });

      setIsLoading(false);
      setPatientQuotesFound(response.data);

      setPatientQuotesFoundTotal(Number(response.headers["x-total-count"]));

      setPatientQuotesFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / patientQuotesLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const createPatientQuote = async (
    data: QuoteCreateData,
    files: File[],
    onHide: () => void,
    navigate: NavigateFunction
  ) => {
    const body = {
      ...data,
      company: {
        id: data.company.value,
        name: data.company.label,
      },
      professional: {
        id: data.professional?.value,
        name: data.professional?.label,
      },
      date: moment(data.date).toISOString(true),
      files: [],
      priceTable: undefined,
      payment: {
        value: Number(data.priceValue?.replace(/\./g, "").replace(",", ".")),
        method: data.paymentMethod?.value,
        parcelQtd: data.parcelQtd?.value,
        paymentFeeOwner: data.paymentFeeOwner ?? undefined,
        priceTable: data.priceTable,
      },
    };

    if (!data.professional?.value && !data.professional?.label) {
      // @ts-expect-error Profissional não é obrigatório
      delete body.professional;
    }

    delete body.priceValue;
    delete body.paymentMethod;
    delete body.parcelQtd;
    delete body.paymentFeeOwner;

    console.log(body);

    try {
      const response = await api.post("/Quote", body);

      if (files.length && body.fileTypes?.length) {
        try {
          for (let i = 0; i < files.length; i++) {
            const formData = new FormData();

            formData.append("document", files[i]);
            formData.append("idQuote", response.data.id);
            formData.append("documentType", body.fileTypes[i].value);

            await api.post("/FileAttachment", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }

          await toast.fire({
            icon: "success",
            title: "Arquivos anexados com sucesso!",
          });
        } catch (error) {
          toast.fire({
            icon: "error",
            title: "Erro ao anexar documentos.",
          });
        }
      }

      onHide();

      toast.fire({
        icon: "success",
        title: "Orçamento cadastrado com sucesso!",
      });

      navigate("/orcamentos-paciente/orcamento-detalhes", {
        state: response.data.id,
      });
    } catch (error: any) {
      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const editPatientQuote = async (
    data: Omit<QuoteCreateData, "fileTypes">,
    quoteId: number,
    onHide: () => void,
    setQuote: React.Dispatch<React.SetStateAction<Quote>>
  ) => {
    setIsLoading(true);

    const patientQuoteEditData = {
      ...data,
      company: {
        id: data.company.value,
        name: data.company.label,
      },
      professional: {
        id: data.professional?.value,
        name: data.professional?.label,
      },
      date: moment(data.date).toISOString(true),
      files: [],
      payment: {
        value: Number(data.priceValue?.replace(/\./g, "").replace(",", ".")),
        method: data.paymentMethod?.value,
        parcelQtd: data.parcelQtd?.value,
      },
    };

    if (!data.professional?.value && !data.professional?.label) {
      // @ts-expect-error Profissional não é obrigatório
      delete body.professional;
    }

    delete patientQuoteEditData.priceValue;
    delete patientQuoteEditData.paymentMethod;
    delete patientQuoteEditData.parcelQtd;

    console.log(patientQuoteEditData);

    try {
      await api.put(`/Quote/${quoteId}`, patientQuoteEditData);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      const response = await api.get(`/Quote/${quoteId}`);
      setQuote(response.data);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const removePatientQuote = async (
    quoteId: number,
    navigate: NavigateFunction
  ) => {
    try {
      await api.delete(`/Quote/${quoteId}`);

      toast.fire({
        icon: "success",
        title: "Orçamento deletado com sucesso",
      });

      navigate("/orcamentos-paciente");
    } catch (error) {
      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  useEffect(() => {
    setSearchData({} as Omit<SearchDataPatient, "patientName">);
  }, [token]);

  return (
    <PatientQuoteContext.Provider
      value={{
        patientQuotes,
        patientQuotesTotal,
        patientQuotesTotalPages,
        patientQuotesCurrentPage,
        patientQuotesFound,
        patientQuotesFoundTotal,
        patientQuotesFoundTotalPages,
        patientQuotesFoundCurrentPage,

        patientQuotesReport,
        patientQuotesReportTotal,
        patientQuotesReportTotalPages,
        patientQuotesReportCurrentPage,
        patientQuotesReportFound,
        patientQuotesReportFoundTotal,
        patientQuotesReportFoundTotalPages,
        patientQuotesReportFoundCurrentPage,

        searchData,
        isLoadingPatientQuotesReport,
        isLoading,

        getPatientQuotes,
        getPatientQuotesReport,
        searchPatientQuotes,
        searchPatientQuotesReport,
        createPatientQuote,
        editPatientQuote,
        removePatientQuote,

        setPatientQuotesFound,
        setPatientQuotesCurrentPage,
        setPatientQuotesFoundCurrentPage,
        setPatientQuotesReportCurrentPage,
        setPatientQuotesReportFoundCurrentPage,
        setPatientQuotesReportFound,

        setSearchData,

        patientQuotesReportGlobalRef,
        setPatientQuotesReportGlobalRef,
      }}
    >
      {children}
    </PatientQuoteContext.Provider>
  );
};

export const usePatientQuote = () => useContext(PatientQuoteContext);
