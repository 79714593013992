import styled from "styled-components";

export const Container = styled.div`
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--primary);
  }
  
}
`;
