/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormSetValue } from "react-hook-form";

import {
  cardExpiryMask,
  cepMask,
  cnpjMask,
  cpfAndCnpjMask,
  cpfMask,
  twoDecimalPlacesMask,
  dateMask,
  digitMask,
  mobilePhoneMask,
  phoneMask,
  phoneOrMobilePhoneMask,
  rgMask,
  accountMask,
  ieMask,
} from "./formMasks";

export const handleRgInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, rgMask(inputValue));
};

export const handleCpfInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, cpfMask(inputValue));
};

export const handleCnpjInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, cnpjMask(inputValue));
};

export const handleCpfOrCnpjInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, cpfAndCnpjMask(inputValue));
};

export const handleBirthDateInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, dateMask(inputValue));
};

export const handleDateInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, dateMask(inputValue));
};

export const handlePhoneInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, phoneMask(inputValue));
};

export const handleComercialPhoneInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, phoneOrMobilePhoneMask(inputValue));
};

export const handleMobilePhoneInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, mobilePhoneMask(inputValue));
};

export const handlePhoneOrMobilePhoneInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, phoneOrMobilePhoneMask(inputValue));
};

export const handleTwoDecimalPlacesInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, twoDecimalPlacesMask(inputValue));
};

export const handleDigitInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, digitMask(inputValue));
};

export const handleCepInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string,
  setInputCep?: React.Dispatch<React.SetStateAction<string>>
) => {
  const inputValue = event.target.value;

  if (setInputCep) {
    setInputCep(inputValue);
  }

  setValue(field, cepMask(inputValue));
};

export const handleCardExpiryInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;
  setValue(field, cardExpiryMask(inputValue));
};

export const handleIeInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;

  setValue(field, ieMask(inputValue));
};

export const handleAccountInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<any>,
  field: string
) => {
  const inputValue = event.target.value;

  setValue(field, accountMask(inputValue));
};
