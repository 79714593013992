import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import moment from "moment";
import Payment from "payment";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Cards, { Focused } from "react-credit-cards";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { api } from "services/index";
import { brazilianStates } from "utils/brazilianStates";
import checkCep from "utils/checkCep";
import isCreditCard from "utils/creditCardValidation/isCreditCard";
import { formatPrice } from "utils/formats";
import {
  amexCardNumberMask,
  cepMask,
  cpfAndCnpjMask,
  creditCardNumberMask,
  mobilePhoneMask,
} from "utils/formMasks";
import { validateCpfAndCnpj, validateExpiryDate } from "utils/formValidations";
import {
  handleCardExpiryInput,
  handleCepInput,
  handleCpfOrCnpjInput,
  handleDigitInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";
import { toast } from "utils/toast";

import CardOption from "./components";

import "react-credit-cards/es/styles-compiled.css";

interface PaymentData {
  quoteDescription: string;
  professionalName: string;
  parcelValue: number;
  parcelQtd: number;
  finalValue: number;
  dateValid: string;
  paid: boolean;
}

interface CheckoutForm {
  cpfOrCnpj: string;
  phoneOrMobilePhone: string;
  patientName: string;
  patientLastName: string;
  email: string;

  addressCEP: string;
  addressRoad: string;
  addressNumber: string;
  addressDistrict: string;
  addressCity: string;
  addressState: string;

  number: string;
  name: string;
  expiry: string;
  cvc: string;
  brand: string;
}

const cardBrands = ["mastercard", "visa", "elo", "hipercard", "amex"];

const Checkout = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { userData } = useUser();
  const { token } = useAuth();

  const [focused, setFocused] = useState<Focused>("number");
  const [selectedCardBrand, setSelectedCardBrand] = useState("");
  const [disableButtonPayment, setDisableButtonPayment] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentData>(
    {} as PaymentData
  );
  const [isLoadingPaymentData, setIsLoadingPaymentData] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isSearchingCep, setIsSearchingCep] = useState(false);

  const schema = yup.object().shape({
    cpfOrCnpj: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfOrCnpjValidator", "*CPF ou CNPJ inválido", validateCpfAndCnpj)
      .transform((value) => value.replace(/\D/g, "")),
    phoneOrMobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(14, "*Deve possuir no mínimo 10 dígitos"),
    patientName: yup.string().required("*Campo obrigatório").trim(),
    patientLastName: yup.string().required("*Campo obrigatório").trim(),
    email: yup.string().email().required("*Campo obrigatório").trim(),

    addressCEP: yup
      .string()
      .required("*Campo obrigatório")
      .min(9, "*Deve possuir 8 dígitos")
      .trim(),
    addressRoad: yup.string().required("*Campo obrigatório").trim(),
    addressNumber: yup.string().required("*Campo obrigatório").trim(),
    addressDistrict: yup.string().required("*Campo obrigatório").trim(),
    addressCity: yup.string().required("*Campo obrigatório").trim(),
    addressState: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "selecione" ? false : true
      ),

    number: yup
      .string()
      .required("*Campo obrigatório")
      .when("brand", {
        is: "amex",
        then: yup.string().min(17, "Deve possuir 15 dígitos"),
        otherwise: yup.string().min(19, "Deve possuir 16 dígitos"),
      })
      .test("Número do cartão válido", function (value) {
        const { path, createError } = this;

        if (isCreditCard(value)) {
          return true;
        } else {
          return createError({
            path,
            message: "Número do cartão inválido",
          });
        }
      }),
    name: yup.string().required("*Campo obrigatório").trim(),
    expiry: yup
      .string()
      .required("*Campo obrigatório")
      .test("expiryDateValidator", "*Data inválida", validateExpiryDate),
    cvc: yup
      .string()
      .required("*Campo obrigatório")
      .when("brand", {
        is: "amex",
        then: yup.string().min(4, "Deve possuir 4 dígitos"),
        otherwise: yup.string().min(3, "Deve possuir 3 dígitos"),
      }),
    brand: yup
      .string()
      .nullable()
      .required(
        "*Verifique seu número do cartão de crédito e certifique-se de utilizar uma das bandeiras listadas acima"
      ),
  });

  const {
    register,
    watch,
    getValues,
    setFocus,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckoutForm>({ resolver: yupResolver(schema) });

  useEffect(() => {
    async function getPaymentData() {
      try {
        const { data }: AxiosResponse<PaymentData> = await api.get(
          `/Payment/${params.id}`
        );

        setPaymentData(data);
        setDisableButtonPayment(data.paid);

        setIsLoadingPaymentData(false);

        if (data.paid) {
          Swal.fire({
            title: "Pagamento já foi realizado!",
            icon: "success",
            position: "center",
            showConfirmButton: token ? true : false,
            confirmButtonText: "Ok",
            buttonsStyling: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-outline-success ml-2",
            },
          }).then((res) => {
            if (res.isConfirmed) {
              if (token) {
                navigate(-1);
              }
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    getPaymentData();
  }, []);

  const onSubmit = async (data: CheckoutForm) => {
    setDisableButtonPayment(true);
    setIsLoadingPayment(true);

    try {
      await api.post("/Payment/checkout", {
        hash_code: params.id,
        brand: data.brand,
        document: data.cpfOrCnpj,
        phone: data.phoneOrMobilePhone,
        first_name: data.patientName,
        last_name: data.patientLastName,
        email: data.email,
        zipcode: data.addressCEP,
        address: data.addressRoad,
        neighborhood: data.addressDistrict,
        address_number: data.addressNumber,
        city: data.addressCity,
        state: data.addressState,
        district: data.addressState,
        nome_titular: data.name,
        cartao: data.number,
        cod_seguranca: data.cvc,
        validade_mes: data.expiry.split("/")[0],
        validade_ano: data.expiry.split("/")[1],
      });

      setIsLoadingPayment(false);

      Swal.fire({
        title: "Pagamento realizado com sucesso!",
        icon: "success",
        position: "center",
        showConfirmButton: token ? true : false,
        confirmButtonText: "Ok",
        buttonsStyling: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-outline-success ml-2",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          if (token) {
            navigate(-1);
          }

          toast.fire({
            icon: "success",
            title: "Pagamento realizado com sucesso!",
          });
        }
      });
    } catch (error) {
      
      setIsLoadingPayment(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });

      setDisableButtonPayment(false);
    }
  };

  const changeFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(event.currentTarget.name as Focused);
  };

  const handleCardNumberInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const number = event.currentTarget.value;

    const brand = Payment.fns.cardType(number);

    if (brand === "amex") {
      setValue("number", amexCardNumberMask(number));
    } else {
      setValue("number", creditCardNumberMask(number));
    }

    setSelectedCardBrand(brand);
    setValue("brand", brand);
  };

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col col-auto pb-4">
              <h1>Checkout</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-4 mb-3 order-md-2">
              <div className="sticky top-0">
                <div className="form-box p-3 p-sm-4 p-xl-5">
                  <h5 className="mb-4 text-center">Resumo do pedido</h5>
                  <div className="row">
                    <div className="col text-center">
                      <h6>DOCTTORPAY</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between justify-content-xl-between">
                    <span>Procedimento:</span>
                    <strong className="text-xl-right">
                      {paymentData.quoteDescription}
                    </strong>
                  </div>

                  <hr />

                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>Profissional:</span>
                    <strong className="text-xl-right">
                      {paymentData.professionalName}
                    </strong>
                  </div>

                  <hr />

                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>Razão Social:</span>
                    <strong className="text-xl-right">
                      Docttorpay Cobranca Ltda
                    </strong>
                  </div>
                  <hr />
                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>CNPJ:</span>
                    <strong className="text-xl-right">
                      43.921.058/0001-80
                    </strong>
                  </div>
                  <hr />
                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>Pagamento:</span>
                    <strong className="text-xl-right">
                      {paymentData.parcelQtd}x de{" "}
                      {formatPrice(paymentData.parcelValue)}
                    </strong>
                  </div>
                  <hr />
                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>Link válido até:</span>
                    <strong className="text-xl-right">
                      {moment(paymentData.dateValid).format("DD/MM/YYYY HH:mm")}
                    </strong>
                  </div>
                  <hr />
                  <div className="d-flex flex-column align-items-start flex-xl-row justify-content-xl-between">
                    <span>Total:</span>
                    <strong className="text-xl-right">
                      {formatPrice(paymentData.finalValue)}
                    </strong>
                  </div>
                </div>

                <div className="zig-zag-bottom mt-n2"></div>
              </div>
            </div>

            <div className="col-md-8 mb-3 order-md-1">
              <div className="form-box p-3 p-sm-4 p-xl-5 mb-3">
                <h5 className="mb-4">Seus dados</h5>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <Input
                      label="CPF/CNPJ"
                      type="text"
                      placeholder="CPF ou CNPJ"
                      autoComplete="new-off"
                      maxLength={18}
                      register={register("cpfOrCnpj", {
                        onChange: (event) =>
                          handleCpfOrCnpjInput(event, setValue, "cpfOrCnpj"),
                      })}
                      error={errors?.cpfOrCnpj?.message}
                      defaultValue={
                        userData?.cpf ? cpfAndCnpjMask(userData?.cpf) : ""
                      }
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          CPF/CNPJ do responsável pelo pagamento
                        </Tooltip>
                      }
                    >
                      <i
                        className="uil uil-question-circle position-absolute top-0"
                        style={{ left: "87px" }}
                      ></i>
                    </OverlayTrigger>
                  </div>
                  <div className="form-group col-md-6">
                    <Input
                      label="Telefone"
                      type="text"
                      placeholder="(99) 99999-9999"
                      autoComplete="new-off"
                      minLength={14}
                      maxLength={15}
                      register={register("phoneOrMobilePhone", {
                        onChange: (event) =>
                          handlePhoneOrMobilePhoneInput(
                            event,
                            setValue,
                            "phoneOrMobilePhone"
                          ),
                      })}
                      error={errors?.phoneOrMobilePhone?.message}
                      defaultValue={
                        userData.mobilePhone
                          ? mobilePhoneMask(
                              `${userData?.dddMobilePhone} ${userData?.mobilePhone}`
                            )
                          : ""
                      }
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <Input
                      label="Nome"
                      type="text"
                      placeholder="Seu Nome Aqui"
                      autoComplete="new-off"
                      minLength={2}
                      maxLength={255}
                      register={register("patientName")}
                      error={errors?.patientName?.message}
                      defaultValue={
                        userData?.name ? userData?.name.split(" ")[0] : ""
                      }
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <Input
                      label="Sobrenome"
                      type="text"
                      placeholder="Seu Sobrenome Aqui"
                      autoComplete="new-off"
                      minLength={2}
                      maxLength={255}
                      register={register("patientLastName")}
                      error={errors?.patientLastName?.message}
                      defaultValue={
                        userData?.name ? userData?.name.split(" ").at(-1) : ""
                      }
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <Input
                      label="E-mail"
                      type="email"
                      placeholder="seu@email.com"
                      autoComplete="new-off"
                      maxLength={255}
                      register={register("email")}
                      error={errors?.email?.message}
                      defaultValue={userData?.email ? userData?.email : ""}
                    />
                  </div>
                </div>
              </div>

              <div className="form-box p-3 p-sm-4 p-xl-5 mb-3">
                <h5 className="mb-4">Endereço de cobrança</h5>
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-12 col-md-5 col-xl-4">
                      <Input
                        label="CEP"
                        type="text"
                        placeholder="99999-999"
                        autoComplete="new-off"
                        minLength={9}
                        maxLength={9}
                        register={register("addressCEP", {
                          onChange: (event) =>
                            handleCepInput(event, setValue, "addressCEP"),
                        })}
                        error={errors?.addressCEP?.message}
                        defaultValue={
                          userData?.addressCEP
                            ? cepMask(userData?.addressCEP)
                            : ""
                        }
                      />
                      <a
                        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="position-absolute top-0"
                        style={{ left: "41px" }}
                      >
                        | Buscar CEP
                      </a>
                    </div>
                    <div className="col col-auto pl-0 pr-md-0 align-self-center mt-2">
                      <Button
                        type="button"
                        className="btn-sm btn-dark"
                        onClick={() =>
                          checkCep(
                            getValues("addressCEP"),
                            setIsSearchingCep,
                            setValue,
                            setFocus
                          )
                        }
                        disabled={isLoadingPaymentData || isSearchingCep}
                      >
                        Consultar
                        {!!isSearchingCep && (
                          <CircularProgress
                            color="inherit"
                            size={12}
                            className="align-text-top ms-2"
                          />
                        )}
                      </Button>
                    </div>

                    <div className="form-group col-12 col-md-7 col-xl-8">
                      <Input
                        label="Endereço"
                        type="text"
                        placeholder="Rua, avenida, travessa..."
                        autoComplete="new-off"
                        minLength={5}
                        maxLength={255}
                        register={register("addressRoad")}
                        error={errors?.addressRoad?.message}
                        defaultValue={userData?.addressRoad || ""}
                      />
                    </div>

                    <div className="form-group col-4 col-md-2">
                      <Input
                        label="Número"
                        type="text"
                        placeholder="999"
                        autoComplete="new-off"
                        minLength={1}
                        maxLength={10}
                        register={register("addressNumber")}
                        error={errors?.addressNumber?.message}
                        defaultValue={userData?.addressNumber || ""}
                      />
                    </div>

                    <div className="form-group col-20 col-md-10">
                      <Input
                        label="Bairro"
                        type="text"
                        placeholder="Bairro"
                        autoComplete="new-off"
                        minLength={2}
                        maxLength={255}
                        register={register("addressDistrict")}
                        error={errors?.addressDistrict?.message}
                        defaultValue={userData?.addressDistrict || ""}
                      />
                    </div>

                    <div className="form-group col-12 col-md-6">
                      <Input
                        label="Cidade"
                        type="text"
                        placeholder="Cidade"
                        autoComplete="new-off"
                        minLength={2}
                        maxLength={255}
                        register={register("addressCity")}
                        error={errors?.addressCity?.message}
                        defaultValue={userData?.addressCity || ""}
                      />
                    </div>

                    <div className="form-group col-12 col-md-6">
                      <label className="label" htmlFor="Estado">
                        Estado:
                      </label>
                      <select
                        id="Estado"
                        className={`form-control required ${
                          errors?.addressState && "border-danger"
                        }`}
                        {...register("addressState")}
                        defaultValue={userData?.addressState || "selecione"}
                      >
                        <option value="selecione" disabled>
                          Selecione
                        </option>
                        {brazilianStates.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">
                        <small>
                          {!!errors?.addressState && "*Selecione uma opção"}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-box p-3 p-sm-4 p-xl-5 mb-3">
                <h5 className="mb-4">Dados do pagamento</h5>
                <div className="form-row">
                  <div className="form-group col-12 d-none d-md-block">
                    <div className="card-wrapper mb-3">
                      <Cards
                        number={watch("number") || ""}
                        name={watch("name") || ""}
                        expiry={watch("expiry") || ""}
                        cvc={watch("cvc") || ""}
                        focused={focused}
                        placeholders={{ name: "Nome do Titular" }}
                        locale={{ valid: "Validade" }}
                        acceptedCards={cardBrands}
                      />
                    </div>
                  </div>

                  <div className="form-group col-12">
                    <Input
                      label="Número do Cartão"
                      type="text"
                      placeholder="**** **** **** ****"
                      autoComplete="new-off"
                      maxLength={19}
                      onFocus={changeFocus}
                      register={register("number", {
                        onChange: handleCardNumberInput,
                      })}
                      error={errors?.number?.message}
                    />
                  </div>

                  <div className="form-group col-12">
                    <Input
                      label="Nome do Titular"
                      type="text"
                      placeholder="Nome Impresso no Cartão"
                      autoComplete="new-off"
                      maxLength={255}
                      register={register("name")}
                      onFocus={changeFocus}
                      className="text-uppercase"
                      error={errors?.name?.message}
                    />
                  </div>

                  <div className="form-group col-7 col-md-4">
                    <Input
                      label="Validade"
                      type="text"
                      placeholder="mm/aaaa"
                      autoComplete="new-off"
                      minLength={7}
                      maxLength={7}
                      onFocus={changeFocus}
                      register={register("expiry", {
                        onChange: (event) =>
                          handleCardExpiryInput(event, setValue, "expiry"),
                      })}
                      error={errors?.expiry?.message}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Informe a validade do cartão no formato mês/ano
                          (mm/aaaa)
                        </Tooltip>
                      }
                    >
                      <i
                        className="uil uil-question-circle position-absolute top-0"
                        style={{ left: "74px" }}
                      ></i>
                    </OverlayTrigger>
                  </div>

                  <div className="form-group col-5 col-md-3">
                    <Input
                      label="CVV"
                      type="text"
                      placeholder="CVV"
                      autoComplete="new-off"
                      maxLength={watch("brand") === "amex" ? 4 : 3}
                      onFocus={changeFocus}
                      register={register("cvc", {
                        onChange: (event) =>
                          handleDigitInput(event, setValue, "cvc"),
                      })}
                      error={errors?.cvc?.message}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Cartões American Express: 4 dígitos impressos na face
                          do Cartão; Cartões das demais bandeiras: 3 dígitos
                          impressos no verso do Cartão
                        </Tooltip>
                      }
                    >
                      <i
                        className="uil uil-question-circle position-absolute top-0"
                        style={{ left: "43px" }}
                      ></i>
                    </OverlayTrigger>
                  </div>

                  <div className="form-group col-12">
                    <div id="card-brand-group">
                      <label htmlFor="radio-brands-group">
                        Bandeira do Cartão
                      </label>
                      <fieldset className="checkbox-group">
                        {cardBrands.map((cardBrand) => (
                          <CardOption
                            key={cardBrand}
                            brand={cardBrand}
                            register={register("brand")}
                            checked={selectedCardBrand === cardBrand}
                            error={!!errors?.brand}
                          />
                        ))}
                      </fieldset>

                      {errors?.brand && (
                        <span className="text-danger">
                          <strong>{errors.brand.message}</strong>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-sm-12">
                    <label>Opção de Pagamento</label>
                    <h5 className="text-primary">
                      {formatPrice(paymentData.finalValue)} em{" "}
                      {paymentData.parcelQtd} parcelas de{" "}
                      {formatPrice(paymentData.parcelValue)}
                    </h5>
                  </div>
                </div>

                <hr />

                <div className="pt-4 text-right">
                  <Button
                    disabled={disableButtonPayment}
                    className="btn-primary"
                    type="submit"
                  >
                    Pagar
                    {!!isLoadingPayment && (
                      <CircularProgress
                        color="inherit"
                        size={12}
                        className="align-text-top ms-2"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ContainerAnimation>
  );
};

export default Checkout;
