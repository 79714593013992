import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import ModalComponent from "components/ModalComponent";
import { useBankAccount } from "providers/BankAccount";

import BankAccountCard from "../BankAccountCard";
import BankAccountForm from "../BankAccountForm";

const BankAccountsList = () => {
  const bankAccountsRef = useRef<HTMLDivElement>(null);
  const [modalShow, setModalShow] = useState(false);

  const {
    bankAccounts,
    bankAccountsTotal,
    bankAccountsTotalPages,
    isLoadingBankAccounts,
    getBankAccounts,
  } = useBankAccount();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getBankAccounts(value);

    bankAccountsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getBankAccounts();
  }, []);

  return (
    <>
      {/* Contas Bancárias */}
      <div ref={bankAccountsRef} className="row mb-4">
        <div className="col">
          <h5>
            Conta Bancária
            <Badge bg="primary ml-1 text-white">{bankAccountsTotal}</Badge>
          </h5>
        </div>
        <div className="col col-auto">
          <Button
            type="button"
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}
          >
            <i className="uil uil-plus mr-2" />
            Adicionar conta
          </Button>
        </div>
      </div>

      {!!isLoadingBankAccounts &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de contas bancárias */}
      {!isLoadingBankAccounts && (
        <>
          {!!bankAccounts.length &&
            bankAccounts?.map((account) => (
              <BankAccountCard key={account.id} bankAccount={account} />
            ))}

          {/* Lista de contas bancárias vazia */}
          {!bankAccounts.length && (
            <EmptyList
              icon="uil-money-withdrawal"
              title="Nenhuma conta bancária cadastrada"
              subtitle="Clique abaixo e adicione uma conta bancária para realizar saques"
              buttonTitle="Adicionar conta"
              onClickButton={() => setModalShow(true)}
            />
          )}
        </>
      )}
      {!!bankAccounts.length && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={bankAccountsTotalPages}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}

      {/* Modal de adicionar conta bancária */}
      <ModalComponent
        title="Adicionar Conta"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
      >
        <BankAccountForm onHide={() => setModalShow(false)} />
      </ModalComponent>
    </>
  );
};

export default BankAccountsList;
