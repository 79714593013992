import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface NavTabItemProps {
  title: string;
  id: string;
  selectedNavTab: string;
  handleTabClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  newPasswordRequired?: boolean;
}

const NavTabItem = (props: NavTabItemProps) => {
  return (
    <li className="nav-item">
      <span
        id={props.id}
        className={`nav-link link-primary ${
          props.selectedNavTab === props.id && "active"
        }`}
        onClick={props.handleTabClick}
      >
        {!!props.newPasswordRequired && props.id === "access-data" && (
          <OverlayTrigger overlay={<Tooltip>Alterar Senha</Tooltip>}>
            <i className="d-inline-block animate__animated animate__pulse animate__infinite uil uil-exclamation-triangle display-6 me-1 text-warning"></i>
          </OverlayTrigger>
        )}
        {props.title}
      </span>
    </li>
  );
};

export default NavTabItem;
