/* eslint-disable import/order */
import { Badge } from "react-bootstrap";
import { SecretaryBody } from "types/secrataries";
import { ButtonEdit } from "../ButtonGroup";

interface SecretaryCardInterface {
  setModal: () => void;
  disableSecretary: (id: number) => void;
  secretary: SecretaryBody;
}

const SecretaryCard = ({ secretary, setModal, disableSecretary }: SecretaryCardInterface) => {
  const {
    id,
    name,
    socialName,
    cpf,
    email,
    phone,
    addressRoad,
    addressDistrict,
    addressCity,
    addressComplement,
    addressNumber,
    addressState,
    mobilePhone,
  } = secretary;

  const deleteFunc = () => {
    disableSecretary(id);
  };

  return (
    <div className="row-item mb-2 px-3 pt-3">
      <div className="row align-items-center">
        <div className="col">
          <div className="row no-gutters">
            {/* Nome da secretaria */}
            <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
              <p className="small opacity-75 mb-0">Secretaria</p>
              <b>{socialName && socialName.length ? socialName + ` (${name})` : name}</b>
            </div>

            {/* CPF da secretaria */}
            <div className="col-12 col-sm-6 col-lg-2 pb-3 px-1">
              <p className="small opacity-75 mb-0">CPF</p>
              <b>{cpf}</b>
            </div>

            {/* Contatos */}
            <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
              <p className="small opacity-75 mb-0">Contatos</p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <b>{email}</b>

                <b>{phone}</b>

                {mobilePhone && <b>{mobilePhone}</b>}
              </div>
            </div>

            {/* Endereço da secretaria */}
            <div className="col-12 col-sm-6 col-lg-3 pb-3 px-1">
                  <p className="small opacity-75 mb-0">Endereço</p>
                  <b>
                    {addressRoad + ", "} {addressNumber + " - "} {addressComplement + ", "} {addressDistrict + ", "} {addressCity + " - "}{" "}
                    {addressState}
                  </b>
            </div>

            {/* Status */}
            <div className="col-12 col-sm-12 col-lg-1 pb-3 text-nowrap px-1">
              <p className="small opacity-75 mb-0">Status</p>
              <Badge bg="success text-white">Ativo</Badge>
              {/* <Badge bg="danger text-white">Inativo</Badge> */}
            </div>
          </div>
        </div>

        {/* Botão*/}
        <div className="col col-auto pb-3">
          <ButtonEdit deleteFunc={deleteFunc} editFunc={setModal} />
        </div>
      </div>
    </div>
  );
};

export default SecretaryCard;
