import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactSelect, { SingleValue } from "react-select";

import FormatGroupLabel from "components/FormatGroupLabel";
import PaymentInfo from "components/PaymentInfo";
import QuoteStatusInfo from "components/QuoteStatusInfo";
import { useAuth } from "providers/Auth";
import { useQuoteDetails } from "providers/QuoteDetails";
import { api } from "services";
import {
  PaymentMethod,
  QuoteActions,
  QuoteStatus,
  quoteStatusColor,
  quoteStatusTitle,
  RoleDescription,
} from "types/enums";
import { GroupedOption, Quote, StatusOption } from "types/quotes";
import { formatPrice } from "utils/formats";
import { toast } from "utils/toast";

interface ProfessionalQuoteCardProps {
  quote: Quote;
}

const ProfessionalQuoteCard = ({ quote }: ProfessionalQuoteCardProps) => {
  const { userRole } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { pendingItems } = useQuoteDetails();

  const [prevStepOptions, setPrevStepOtions] = useState(
    quote?.previusStep?.map((step) => {
      return {
        value: step,
        label: quoteStatusTitle.get(step) as string,
        statusColor: `var(--${quoteStatusColor.get(step)})`,
      };
    })
  );

  const [nextStepOptions, setNextStepOtions] = useState(
    quote?.nextStep?.map((step) => {
      return {
        value: step,
        label: quoteStatusTitle.get(step) as string,
        statusColor: `var(--${quoteStatusColor.get(step)})`,
      };
    })
  );

  const [selected, setSelected] = useState<StatusOption>({
    value: quote.quoteStatus,
    label: quoteStatusTitle.get(quote.quoteStatus) as string,
    statusColor: `var(--${quoteStatusColor.get(quote.quoteStatus)})`,
  });

  const customStyles = (value: StatusOption) => ({
    container: (base: Record<string, unknown>) => ({
      ...base,
      padding: 0,
    }),
    control: (base: Record<string, unknown>) => ({
      ...base,
      minHeight: 18,
      background: value?.statusColor,
      border: value?.statusColor,
      transition: "all 0.3s",
      boxShadow: "none",
      cursor: value.value === QuoteStatus.Finished ? "default" : "pointer",
      "&:hover": {
        filter:
          value.value === QuoteStatus.Finished ? "none" : "brightness(1.15)",
      },
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      width: 120,
      background: "var(--white)",
      color: "var(--text)",
      border: "1px solid var(--border)",
    }),
    option: (base: Record<string, unknown>, state: any) => ({
      ...base,
      cursor: "pointer",
      fontWeight: "bold",
      color: state.data.statusColor,
      backgroundColor: state.isSelected ? "var(--primary)" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--primary)" : "var(--light)",
      },
    }),
    valueContainer: (base: Record<string, unknown>) => ({
      ...base,
      padding: "none",
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      fontWeight: "bold",
      color: "var(--white)",
      padding: "3px 4.8px",
    }),
    indicatorsContainer: (base: Record<string, unknown>) => ({
      ...base,
      display: value.value === QuoteStatus.Finished ? "none" : "flex",
    }),
    dropdownIndicator: (base: Record<string, unknown>) => ({
      ...base,
      padding: "0 2px",
      "& svg": {
        width: 15,
        color: "var(--white)",
      },
    }),
  });

  const changeStatus = async (
    newStatus: SingleValue<{
      value: number;
      label: string;
      statusColor: string;
    }>
  ) => {
    setIsLoading(true);

    setSelected(newStatus as StatusOption);

    try {
      await api.patch(`/Quote/${quote.id}/status`, null, {
        params: {
          newStatus: newStatus?.value,
        },
      });

      const response = await api.get(`/Quote/${quote.id}`);

      setIsLoading(false);

      setPrevStepOtions(
        response.data.previusStep?.map((step: number) => {
          return {
            value: step,
            label: quoteStatusTitle.get(step),
            statusColor: `var(--${quoteStatusColor.get(step)})`,
          };
        })
      );

      setNextStepOtions(
        response.data.nextStep?.map((step: number) => {
          return {
            value: step,
            label: quoteStatusTitle.get(step),
            statusColor: `var(--${quoteStatusColor.get(step)})`,
          };
        })
      );

      toast.fire({
        icon: "success",
        title: "Status atualizado com sucesso!",
      });
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: "Retornar para:",
      options: prevStepOptions,
    },
    {
      label: "Avançar para:",
      options: nextStepOptions,
    },
  ];

  return (
    <>
      <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(quote.payment?.method === PaymentMethod.Financing ? 'border-dark' : 'border-primary')}>
        <div className="row align-items-center">
          {/* Informações do orçamento */}
          <div className="col">
            <div className="row align-items-center no-gutters">
              <div className="col col-auto d-flex align-items-center flex-column pb-3 pr-3">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    quote.payment?.method === PaymentMethod.Financing ? (
                      <Tooltip>Financiamento</Tooltip>
                    ) : (
                      <Tooltip>Parcelamento</Tooltip>
                    )
                  }
                >
                  {quote.payment?.method === PaymentMethod.Financing ? (
                    <div className="badge badge-dark rounded-circle py-2 fs-5 text-center">
                      <i className="uil uil-file-landscape-alt"></i>
                    </div>
                  ) : (
                    <div className="badge badge-primary rounded-circle py-2 fs-5 text-center">
                      <i className="uil uil-credit-card"></i>
                    </div>
                  )}
                </OverlayTrigger>
                <div>
                  <b>#{quote.id}</b>
                </div>
              </div>

              {/* Nome do cliente */}
              <div className="col col-md-5 col-xxl pb-3 px-1">
                <p className="small opacity-75 mb-0">Cliente</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{quote.patient.name}</Tooltip>}
                >
                  <b className="d-block text-md-truncate">
                    {quote.patient.name}
                  </b>
                </OverlayTrigger>
              </div>

              {/* Nome do médico */}
              <div className="col-12 col-md-4 col-xxl-2 pb-3 px-1 ml-auto">
                <p className="small opacity-75 mb-0">Profissional</p>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {quote.professional?.name ??
                        "PROFISSIONAL NÃO INFORMADO..."}
                    </Tooltip>
                  }
                >
                  <b
                    className={
                      "d-block text-sm-truncate " +
                      (quote.professional?.name ??
                        "opacity-50 user-select-none")
                    }
                  >
                    {quote.professional?.name ?? "NÃO INFORMADO..."}
                  </b>
                </OverlayTrigger>
                <small>{quote.company?.name}</small>
              </div>

              <div className="col-12 col-xl-8 col-xxl-6">
                <div className="row">
                  {/* Data do procedimento */}
                  <div className="col-12 col-sm-6 col-md-2 pb-3">
                    <p className="small opacity-75 mb-0 text-truncate">
                      Data de Criação
                    </p>
                    <b>{moment(quote.creationDate).format("DD/MM/YYYY")}</b>
                  </div>

                  {/* Data do procedimento */}
                  <div className="col-12 col-sm-6 col-md-2 pb-3">
                    <p className="small opacity-75 mb-0 text-truncate">
                      Data do Procedimento
                    </p>
                    <b>{moment(quote.date).format("DD/MM/YYYY")}</b>
                  </div>

                  {/* Data limite */}
                  <div className="col-12 col-sm-6 col-md-2 pb-3">
                    <p className="small opacity-75 mb-0">Data Limite</p>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        quote.hasLimitDateAlert ? (
                          <Tooltip>Orçamento está prestes a expirar</Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <b
                        className={quote.hasLimitDateAlert ? "text-danger" : ""}
                      >
                        {moment(quote.limitDate).format("DD/MM/YYYY")}
                      </b>
                    </OverlayTrigger>
                  </div>

                  {/* Valor do orçamento */}
                  <div className="col-6 col-md-2 pb-3">
                    <p className="small opacity-75 mb-0">
                      Valor <PaymentInfo payment={quote.payment} />
                    </p>
                    <b>{formatPrice(quote.payment.value)}</b>
                  </div>
                  {/* ID pagamento */}
                  {userRole === RoleDescription.Admin &&
                    !!quote.payment.orderId && (
                      <div className="col-6 col-md-3 pb-3">
                        <p className="small opacity-75 mb-0">Pagamento</p>
                        <b className="d-block">#{quote.payment.orderId}</b>
                      </div>
                    )}
                </div>
              </div>

              {/* Status do orçamento */}
              <div className="col-12 col-sm-6 col-md-12 col-xl-4 col-xxl pb-3 text-nowrap px-1">
                <p className="small opacity-75 mb-0">
                  Status{" "}
                  {isLoading && <CircularProgress size={12} color="inherit" />}
                </p>
                <div>
                  {quote.actions.includes(QuoteActions.EditStatus) ? (
                    <ReactSelect
                      styles={customStyles(selected)}
                      options={groupedOptions}
                      value={selected}
                      onChange={changeStatus}
                      isSearchable={false}
                      className="badge text-start"
                      formatGroupLabel={FormatGroupLabel}
                      noOptionsMessage={() => null}
                    />
                  ) : (
                    <QuoteStatusInfo
                      quoteStatus={quote.quoteStatus}
                      paymentMethod={quote.payment.method}
                    />
                  )}
                </div>
                <div className="display-6">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      quote.hasTerm ? (
                        <Tooltip>Termo Assinado</Tooltip>
                      ) : (
                        <Tooltip>Termo Não Assinado</Tooltip>
                      )
                    }
                  >
                    <i
                      className={`uil uil-file-edit-alt ${
                        !quote.hasTerm ? "opacity-25" : "text-success"
                      }`}
                    ></i>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      quote.hasAllDocumention ? (
                        <Tooltip>Documentos Aprovados</Tooltip>
                      ) : (
                        <Tooltip>Documentos Pendentes</Tooltip>
                      )
                    }
                  >
                    <i
                      className={`uil uil-file-upload ${
                        !quote.hasAllDocumention ? "opacity-25" : "text-success"
                      }`}
                    ></i>
                  </OverlayTrigger>

                  {quote.hasCheckList && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        quote.doneCheckList ? (
                          <Tooltip>Checklist completo</Tooltip>
                        ) : (
                          <Tooltip>Checklist incompleto</Tooltip>
                        )
                      }
                    >
                      <i
                        className={`uil uil-check-circle ${
                          !quote.doneCheckList ? "opacity-25" : "text-success"
                        }`}
                      ></i>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Botão de "Detalhes" */}
          <div className="col-12 col-sm-auto pb-3 position-relative">
            <Link
              to={"/orcamentos-profissional/orcamento-detalhes"}
              className="btn btn-light btn-sm"
              state={quote.id}
            >
              Detalhes
            </Link>

            {!!pendingItems.length && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Ver Pendências</Tooltip>}
              >
                <Badge className="animate__animated animate__pulse animate__infinite bg-danger rounded-circle position-absolute start-0 mt-n1 ms-2 p-1">
                  <i className="animate__animated animate__pulse animate__infinite uil uil-exclamation-circle text-white"></i>
                </Badge>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalQuoteCard;
