import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Button from "components/Button";
import ModalComponent from "components/ModalComponent";
import PatientQuoteCreateForm from "pages/Quotes/PatientQuote/components/Forms/PatientQuoteCreateForm";
import ProfessionalQuoteCreateForm from "pages/Quotes/ProfessionalQuote/components/Forms/ProfessionalQuoteCreateForm";
import QuoteCreateForm from "pages/Quotes/ProfessionalQuote/components/Forms/QuoteCreateForm";
import SecretaryQuoteCreateForm from "pages/Quotes/ProfessionalQuote/components/Forms/SecretaryQuoteCreateForm";
import { useAuth } from "providers/Auth";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { PaymentFeeOwner, PaymentTool, RoleDescription } from "types/enums";
import { SimulatorParcel } from "types/quotes";
import { formatAsPercent, formatPrice } from "utils/formats";

interface SimulatorParcelCardProps {
  simulatorParcel: SimulatorParcel;
  company: { value: number; label: string };
  showAddQuote: boolean;
  paymentToolAndMachine?: {
    paymentTool: PaymentTool | null;
    cardMachine: {
      value: number;
      label: string;
    };
  };
}

const SimulatorParcelCard = ({
  simulatorParcel,
  company,
  showAddQuote,
  paymentToolAndMachine,
}: SimulatorParcelCardProps) => {
  const { userRole } = useAuth();
  const [addQuoteModalShow, setAddQuoteModalShow] = useState(false);
  const { simulatedQuotePayment, setSimulatedQuotePayment } =
    useProfessionalQuote();

  const createQuote = () => {
    setSimulatedQuotePayment({
      ...simulatedQuotePayment,
      ...simulatorParcel,
    });
    setAddQuoteModalShow(true);
  };

  return (
    <>
      <div className="row-item mb-2 px-3 pt-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              {/* Quantidade de Parcelas */}
              <div className="col-12 col-sm-6 col-lg-2 print-col pb-3 px-1">
                <p className="small opacity-75 mb-0">Parcelas</p>
                <b>{simulatorParcel.parcelQtd}x</b>
              </div>

              {/* Valor da Parcela */}
              <div className="col-12 col-sm-6 col-lg-3 print-col pb-3 px-1">
                <p className="small opacity-75 mb-0">Valor da Parcela</p>
                <b>{formatPrice(simulatorParcel.parcelValue)}</b>
              </div>

              {/* Taxa Mensal  */}
              {simulatedQuotePayment.paymentFeeOwner ===
                PaymentFeeOwner.Paciente && (
                <>
                  {!!simulatorParcel.feeMonthly && (
                    <div className="col-12 col-sm-12 col-lg-3 print-col pb-3 px-1">
                      <p className="small opacity-75 mb-0">
                        Taxa Mensal
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              <b>Taxa Anual</b>
                              <br />
                              {formatAsPercent(simulatorParcel.feeYearly)}
                            </Tooltip>
                          }
                        >
                          <i className="uil uil-info-circle ml-1" />
                        </OverlayTrigger>
                      </p>
                      <b>{formatAsPercent(simulatorParcel.feeMonthly)}</b>
                    </div>
                  )}
                </>
              )}

              {/* Valor Total */}
              <div className="col-12 col-sm-12 col-lg-3 print-col pb-3 text-nowrap px-1">
                <p className="small opacity-75 mb-0">Valor Total</p>
                <b>{formatPrice(simulatorParcel.totalValue)}</b>
              </div>

              {simulatedQuotePayment.paymentFeeOwner ===
                PaymentFeeOwner.Estabelecimento && (
                <div className="col-12 col-sm-12 col-lg-3 print-col pb-3 text-nowrap px-1">
                  <p className="small opacity-75 mb-0">Valor Compensação</p>
                  <div className="bg-light rounded compensation-value">
                    <b>{formatPrice(simulatorParcel.compensationValue)}</b>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Botão */}
          {!!showAddQuote && (
            <div className="col col-auto pb-3">
              <a className=" btn btn-primary btn-sm" onClick={createQuote}>
                <i className="uil uil-plus mr-2" />
                Adicionar Orçamento
              </a>
            </div>
          )}
        </div>
      </div>

      {/* Modal de adicionar orçamento */}
      {!!showAddQuote && (
        <ModalComponent
          title="Adicionar Orçamento"
          show={addQuoteModalShow}
          onHide={() => setAddQuoteModalShow(false)}
        >
          {userRole === RoleDescription.Professional && (
            <ProfessionalQuoteCreateForm
              companyFromSimulator={company}
              paymentToolAndMachineFromSimulator={paymentToolAndMachine}
              onHide={() => setAddQuoteModalShow(false)}
            />
          )}

          {userRole === RoleDescription.Admin && (
            <QuoteCreateForm
              companyFromSimulator={company}
              paymentToolAndMachineFromSimulator={paymentToolAndMachine}
              onHide={() => setAddQuoteModalShow(false)}
            />
          )}

          {userRole === RoleDescription.Secretary && (
            <SecretaryQuoteCreateForm
              companyFromSimulator={company}
              paymentToolAndMachineFromSimulator={paymentToolAndMachine}
              onHide={() => setAddQuoteModalShow(false)}
            />
          )}

          {userRole === RoleDescription.Patient && (
            <PatientQuoteCreateForm
              companyFromSimulator={company}
              onHide={() => setAddQuoteModalShow(false)}
            />
          )}
        </ModalComponent>
      )}
    </>
  );
};

export default SimulatorParcelCard;
