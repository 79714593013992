import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...rest }: ButtonProps, ref) => (
    <button {...rest} className={`btn ${rest.className}`} ref={ref}>
      {children}
    </button>
  )
);

Button.displayName = "Button";

export default Button;
