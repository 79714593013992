import moment, { updateLocale } from "moment";
import { useEffect } from "react";
import { Badge, Dropdown } from "react-bootstrap";

import { useNotify } from "providers/Notify";
import { ForeignKeyDescription } from "types/enums";

import * as C from "./styles";

const NotificationAlert = () => {
  const {
    getAllNotifications,
    getCountUnreadNotifications,
    changeAllNotificationToRead,
    isLoadingNotify,
    messages,
    notifyCount,
  } = useNotify();

  const formatAsRelativeTime = (value: string) => {
    updateLocale("pt-br", null);
    return moment(value).fromNow();
  };

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        await getAllNotifications(false);
        await getCountUnreadNotifications();
      })();
    }, 1000);
  }, []);

  return (
    <C.Container className="col col-auto ms-auto p-0 z-3">
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          className="btn-icon fs-5"
          variant="primary"
          size="sm"
        >
          <i className="uil uil-bell"></i>
          {notifyCount.countUnread > 0 && (
            <Badge bg="success" className="fw-normal">
              <small className="text-pure-white">
                {notifyCount.countUnread < 99 ? notifyCount.countUnread : "99+"}
              </small>
            </Badge>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="pt-0"
          style={{ maxHeight: "40rem", maxWidth: "35rem", zIndex: 3 }}
        >
          <Dropdown.Header className="lh-1 border-bottom py-3">
            <h1>Notificações</h1>
          </Dropdown.Header>

          {!isLoadingNotify && (
            <>
              <Dropdown.ItemText className="w-full d-flex justify-content-between align-items-center border-bottom sticky-top top-0 bg-white">
                <span className="opacity-50">Mais Recentes</span>
                <a
                  className={`fw-light ${
                    !messages.length && "opacity-50"
                  }`}
                  onClick={() => {
                    if (!messages.length) {
                      return;
                    }
                    changeAllNotificationToRead();
                  }}
                >
                  <small>Marcar todas como lidas</small>
                </a>
              </Dropdown.ItemText>
              {!messages.length ? (
                <div className="d-flex justify-content-center align-items-center mt-4 mb-3">
                  Você não tem nenhuma notificação não lida...
                </div>
              ) : (
                <>
                  {messages?.map((el, index) => (
                    <Dropdown.Item as="a" className="border-bottom" key={index}>
                      <div className="d-flex d-grid align-items-center justify-content-between">
                        <div>
                          <span className="col col-auto mr-2 w-full text-break text-wrap fw-bolder">
                            {el.title}
                          </span>{" "}
                          <label className="col small col-auto opacity-50">
                            {formatAsRelativeTime(el.date)}
                          </label>{" "}
                        </div>
                        {!el.read ? (
                          <span
                            className="col col-auto"
                            style={{
                              background: "var(--primary)",
                              borderRadius: "50%",
                              height: ".5rem",
                              width: ".5rem",
                            }}
                          ></span>
                        ) : (
                          <div className="col col-1"></div>
                        )}
                      </div>
                      <label className="w-full text-break text-wrap">
                        {el.message}
                      </label>
                      <small className="d-block opacity-50">
                        {ForeignKeyDescription[el.typeExternal]}
                      </small>
                    </Dropdown.Item>
                  ))}
                </>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </C.Container>
  );
};

export default NotificationAlert;
