import { useState } from "react";
import { Badge } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";

import ProfessionalQuoteReportList from "./ProfessionalQuoteReportList";
import ProfessionalQuoteReportSearch from "./ProfessionalQuoteReportSearch";

const ProfessionalQuoteReport = () => {
  const {
    professionalQuotesReportTotal,
    professionalQuotesReportFoundTotal,
    searchData,
  } = useProfessionalQuote();

  const [selectedNavTab, setSelectedNavTab] = useState("quote-data");

  const handleTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setSelectedNavTab(e.currentTarget.id);
  };

  return (
    <ContainerAnimation className="content">
      {/* Título e Pesquisa */}
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-sm-auto">
              <h1>Relatório Dos Orçamentos</h1>
            </div>
            <div className="col-12 col-sm-auto">
              <ProfessionalQuoteReportSearch />
            </div>
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <span
                    id="quote-data"
                    className={`nav-link link-primary ${
                      selectedNavTab === "quote-data" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Orçamentos Finalizados
                    <Badge bg="primary ml-1 text-white">
                      {!Object.values(searchData).some(
                        (currentValue) => !!currentValue
                      )
                        ? professionalQuotesReportTotal
                        : professionalQuotesReportFoundTotal}
                    </Badge>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="tab-content">
          {selectedNavTab === "quote-data" && <ProfessionalQuoteReportList />}
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default ProfessionalQuoteReport;
