import { useLocation } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { Company } from "types/company";

import CompanyProfessionalList from "./components/CompanyProfessionalList";

const CompanyProfessionals = () => {
  const location = useLocation();

  const company = (location?.state as { company: Company })?.company;

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col col-auto pb-4">
              <h1>{company?.name}</h1>
            </div>

            <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-auto pb-4">
              {/* <CompanySearch /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <CompanyProfessionalList companyId={company?.id} />
      </div>
    </ContainerAnimation>
  );
};

export default CompanyProfessionals;
