import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { QuoteReport } from "types/quotes";
import { formatPrice } from "utils/formats";

interface PatientQuoteCardProps {
  quote: QuoteReport;
}

const PatientQuoteReportCard = ({ quote }: PatientQuoteCardProps) => {
  const validateDate = (value: string) =>
    value === "0001-01-01T00:00:00" ? null : value;

  return (
    <>
      <div className="row-item mb-2 px-3 pt-3">
        <div className="row align-items-center no-gutters">
          {/* Id do Orçamento */}
          <div className="col col-auto d-flex align-items-center flex-column pb-3 pr-3">
            <div>
              <p className="small opacity-75 mb-0">Id Orçamento</p>
              <b>#{quote.id}</b>
            </div>
          </div>

          {/* Nome do Prestador */}
          <div className="col col-xxl pb-3 px-1">
            <p className="small opacity-75 mb-0">Nome Prestador</p>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{quote.providerName}</Tooltip>}
            >
              <b className="d-block text-md-truncate">{quote.providerName}</b>
            </OverlayTrigger>
          </div>

          <div className="col-12 col-md-8 col-xl-8 col-xxl-6">
            <div className="row">
              {/* Valor Total */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0 text-truncate">
                  Valor Total
                </p>
                <b>{formatPrice(quote.totalValue)}</b>
              </div>

              {/* Qtd Parcela */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0 text-truncate">Parcelas</p>
                <b>{quote.parcelQtd}x</b>
              </div>

              {/* Data de Pagamento */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0 text-truncate">
                  Data do Pagamento
                </p>
                {!!validateDate(quote.paymentDate) ? (
                  <b>{moment(quote.paymentDate).format("DD/MM/YYYY")}</b>
                ) : (
                  <p className="opacity-75 mb-0 text-truncate">
                    ---------------
                  </p>
                )}
              </div>

              {/* Valor do Repasse */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0 text-truncate">
                  Valor de Repasse
                </p>
                {!!quote.compensationValue ? (
                  <b>{formatPrice(quote.compensationValue)}</b>
                ) : (
                  <p className="opacity-75 mb-0 text-truncate">
                    --------------
                  </p>
                )}
              </div>

              {/* Data de Repasse */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0 text-truncate">
                  Data de Repasse
                </p>
                {!!validateDate(quote.compensationDate) ? (
                  <b>{moment(quote.compensationDate).format("DD/MM/YYYY")}</b>
                ) : (
                  <p className="opacity-75 mb-0 text-truncate">
                    ---------------
                  </p>
                )}
              </div>

              {/* PaymentCode */}
              <div className="col-12 col-sm-2 col-md-2 pb-3">
                <p className="small opacity-75 mb-0">Pagamento</p>
                {!!quote.paymentCode ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{quote.paymentCode}</Tooltip>}
                  >
                    <b
                      className={
                        "d-block text-sm-truncate " + quote.paymentCode
                      }
                    >
                      #{quote.paymentCode}
                    </b>
                  </OverlayTrigger>
                ) : (
                  <span className="opacity-75">---------</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientQuoteReportCard;
