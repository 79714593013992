import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem, SelectChangeEvent } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import { useRegister } from "providers/Register";
import { BrazilianCity, BrazilianState } from "types/ibge";
import { AddressRegisterData } from "types/register";
import { getBrazilianStates } from "utils/brazilianStates";
import { handleCepInput } from "utils/handleInput";
import { toast } from "utils/toast";

const AddressForm = () => {
  const navigate = useNavigate();
  const { registerType, setAddressData } = useRegister();
  const [inputCep, setInputCep] = useState("");
  const [isSearchingCep, setIsSearchingCep] = useState(false);
  const [brazilianStates, setBrazilianStates] = useState<BrazilianState[]>([]);
  const [brazilianCities, setBrazilianCities] = useState<BrazilianCity[]>([]);

  const schema = yup.object().shape({
    addressCEP: yup
      .string()
      .required("*Campo obrigatório")
      .min(8, "*Deve ter 8 dígitos"),
    addressRoad: yup.string().required("*Campo obrigatório").uppercase().trim(),
    addressNumber: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressComplement: yup.string().uppercase().trim(),
    addressDistrict: yup
      .string()
      .required("*Campo obrigatório")
      .uppercase()
      .trim(),
    addressCity: yup
      .string()
      .test("selectCity", "*Selecione uma Cidade", (inputValue) =>
        inputValue === "" ? false : true
      )
      .transform((value) => value.toUpperCase()),
    addressState: yup
      .string()
      .test("selectState", "*Selecione um Estado", (inputValue) =>
        inputValue === "" ? false : true
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    watch,
    control,
    formState: { errors },
  } = useForm<AddressRegisterData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: AddressRegisterData) => {
    setAddressData(data);
    navigate(`/cadastro/${registerType}-3`);
  };

  const checkCep = async () => {
    setIsSearchingCep(true);

    try {
      const cepResponse = await axios.get(
        `https://viacep.com.br/ws/${inputCep}/json/`
      );

      setIsSearchingCep(false);

      setValue("addressRoad", cepResponse.data.logradouro);
      setValue("addressDistrict", cepResponse.data.bairro);
      setValue("addressState", cepResponse.data.uf);

      const citiesResponse = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cepResponse.data.uf}/municipios?orderBy=nome`
      );

      setBrazilianCities(citiesResponse.data);

      setValue("addressCity", cepResponse.data.localidade);

      if (!cepResponse.data.logradouro.length) {
        return setFocus("addressRoad");
      }
      setFocus("addressNumber");
    } catch (error) {
      setIsSearchingCep(false);

      toast.fire({
        icon: "error",
        title: "O CEP consultado não foi encontrado.",
      });
    }
  };

  const handleSelectBrazilianState = async (event: SelectChangeEvent<any>) => {
    const brazilianState = event.target.value;

    setValue("addressCity", "");

    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${brazilianState}/municipios?orderBy=nome`
    );

    setBrazilianCities(response.data);
  };

  useEffect(() => {
    getBrazilianStates(setBrazilianStates);
  }, []);

  return (
    <form className="p-3 p-sm-4 p-xl-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-box p-3 p-sm-4 p-xl-5">
        <header className="row flex-nowrap pt-3 pb-4">
          <div className="col">
            <h5>Endereço</h5>
          </div>
          <div className="col col-auto text-muted">
            <b className="text-primary">2</b>
            /3
          </div>
        </header>

        <div className="form-group">
          <div className="row flex-column gap-2 flex-sm-row gap-md-0 align-items-center">
            <div className="col">
              <Input
                label="Consulte pelo CEP:"
                placeholder="99999-999"
                type="text"
                register={register("addressCEP")}
                error={errors?.addressCEP?.message}
                onChange={(e) =>
                  handleCepInput(e, setValue, "addressCEP", setInputCep)
                }
              />
              <div className="col-12 col-md-auto small ps-0">
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  target="_blank"
                  rel="noreferrer noopener">
                  Não sei meu CEP!
                </a>
              </div>
            </div>

            <div className="col col-auto">
              <Button
                type="button"
                className="btn-sm btn-dark mt-3"
                onClick={checkCep}
                disabled={isSearchingCep}>
                Consultar
                {!!isSearchingCep && (
                  <CircularProgress
                    color="inherit"
                    size={12}
                    className="align-text-top ms-2"
                  />
                )}
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8">
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="Endereço:"
                placeholder="Nome da Rua"
                type="text"
                register={register("addressRoad")}
                error={errors?.addressRoad?.message}
              />
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="Número:"
                placeholder="nº"
                type="text"
                register={register("addressNumber")}
                error={errors?.addressNumber?.message}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="Complemento:"
                placeholder="Casa, apto, bloco"
                type="text"
                register={register("addressComplement")}
                error={errors?.addressComplement?.message}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <Input
              className="text-uppercase"
              label="Bairro:"
              placeholder="Nome do Bairro"
              type="text"
              register={register("addressDistrict")}
              error={errors?.addressDistrict?.message}
            />
          </div>

          <OverlayTrigger
            placement="bottom"
            overlay={
              !watch("addressState") ? (
                <Tooltip>Selecione primeiro o Estado</Tooltip>
              ) : (
                <></>
              )
            }>
            <div className="col-sm-6">
              <SelectInput
                name="addressCity"
                label="Cidade:"
                placeholder="Selecione"
                control={control}
                error={errors?.addressCity?.message}
                isDisabled={!brazilianCities.length}
                defaultValue="">
                <MenuItem value="selecione" disabled>
                  Selecione
                </MenuItem>
                {brazilianCities?.map((brazilianCity) => (
                  <MenuItem key={brazilianCity.id} value={brazilianCity.nome}>
                    {brazilianCity.nome.toUpperCase()}
                  </MenuItem>
                ))}
              </SelectInput>
            </div>
          </OverlayTrigger>

          <div className="col-sm-6">
            <SelectInput
              name="addressState"
              label="Estado:"
              placeholder="Selecione"
              control={control}
              error={errors?.addressState?.message}
              changeOptionSelected={handleSelectBrazilianState}
              defaultValue="">
              <MenuItem value="" disabled>
                Selecione
              </MenuItem>
              {brazilianStates?.map((brazilianState) => (
                <MenuItem key={brazilianState.id} value={brazilianState.sigla}>
                  {brazilianState.sigla}
                </MenuItem>
              ))}
            </SelectInput>
          </div>
        </div>
      </div>

      <div className="row no-gutters justify-content-between pt-3 pt-sm-4 pt-xl-5">
        <div className="col col-auto">
          <Button
            className="btn-light"
            type="button"
            onClick={() => navigate(-1)}
            disabled={isSearchingCep}>
            Voltar
          </Button>
        </div>
        <div className="col col-auto">
          <Button
            className="btn-primary"
            type="submit"
            disabled={isSearchingCep}>
            Prosseguir
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddressForm;
