import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { StylesConfig, Theme } from "react-select";
import { SelectFetch } from "react-select-fetch";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
    * multiValue(remove)/color:hover
    */
    danger: "var(--danger)",
    /*
    * multiValue(remove)/backgroundColor(focused)
    * multiValue(remove)/backgroundColor:hover
    */
    dangerLight: "var(--danger-light)",
    /*
    * control/backgroundColor
    * menu/backgroundColor
    * option/color(selected)
    */
    neutral0: "var(--neutral-0)",
    /*
    * control/backgroundColor(disabled)
    */
    neutral5: "var(--neutral-5)",
    /*
    * control/borderColor(disabled)
    * multiValue/backgroundColor
    * indicators(separator)/backgroundColor(disabled)
    */
    neutral10: "var(--neutral-10)",
    /*
    * control/borderColor
    * option/color(disabled)
    * indicators/color
    * indicators(separator)/backgroundColor
    * indicators(loading)/color
    */
    neutral20: "var(--neutral-20)",
    /*
    * control/borderColor(focused)
    * control/borderColor:hover
    */
    neutral30: "var(--neutral-30)",
    /*
    * menu(notice)/color
    * singleValue/color(disabled)
    * indicators/color:hover
    */
    neutral40: "var(--neutral-40)",
    /*
    * placeholder/color
    */
    neutral50: "var(--neutral-50)",
    /*
    * indicators/color(focused)
    * indicators(loading)/color(focused)
    */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
    * input/color
    * multiValue(label)/color
    * singleValue/color
    * indicators/color(focused)
    * indicators/color:hover(focused)
    */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
    * control/boxShadow(focused)
    * control/borderColor(focused)
    * control/borderColor:hover(focused)
    * option/backgroundColor(selected)
    * option/backgroundColor:active(selected)
    */
    primary: "var(--primary)",
    /*
    * option/backgroundColor(focused)
    */
    primary25: "var(--primary-25)",
    /*
    * option/backgroundColor:active
    */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

interface SelectSearchProps {
  url: string;
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  error?: string;
  isCpf?: boolean;
  isDisabled?: boolean;
  handleChange?: (value: number) => void;
  get: (
    url: string,
    params: {
      [key: string]: any;
    }
  ) => Promise<{
    options: any;
    hasMore: boolean;
  }>;
}

const SelectSearch = ({
  label,
  name,
  control,
  error,
  isCpf,
  isDisabled,
  handleChange,
  ...rest
}: SelectSearchProps, ref: any) => {
  const customStyles: StylesConfig = {
    control: (base: Record<string, unknown>) => ({
      ...base,
      minHeight: 48,
      borderColor: `${error ? "var(--danger)" : "var(--border)"}`,
      cursor: "pointer",
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      textTransform: "uppercase",
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      cursor: "pointer",
      textTransform: "uppercase",
    }),
  };

  return (
    <>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectFetch
            {...rest}
            loadingMessage={() => "Carregando..."}
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? isCpf
                  ? "Nenhum resultado encontrado. Clique em Adicionar Cliente"
                  : "Nenhum resultado encontrado"
                : null
            }
            theme={customTheme}
            queryParams={{
              limit: 10,
            }}
            styles={customStyles}
            onChange={(e) => {
              field.onChange(e);

              if (handleChange) {
                if (e) {
                  handleChange(e.value);
                } else {
                  handleChange(-1);
                }
              }
            }}
            value={field.value}
            isDisabled={isDisabled}
            selectRef={ref}
          />
        )}
      />

      {!!error && (
        <div className="text-start text-danger">
          <small>{error}</small>
        </div>
      )}
    </>
  );
};

export default forwardRef(SelectSearch);
