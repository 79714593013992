import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import SelectSearch from "components/SelectSearch";
import { API_URL } from "config";
import { useCompany } from "providers/Company";
import { getProfessionalOptions } from "utils/getOptions";

interface CompanyProfessionalFormProps {
  companyId: number;
  onHide: () => void;
}

const CompanyProfessionalForm = ({
  companyId,
  onHide,
}: CompanyProfessionalFormProps) => {
  const { isLoading, addProfessionalToCompany } = useCompany();

  const schema = yup.object().shape({
    professional: yup
      .object()
      .required("*Campo obrigatório")
      .shape({
        value: yup.number().required("*Campo obrigatório"),
        label: yup.string().required("*Campo obrigatório"),
      })
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    professional: { value: number; label: string };
  }>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: {
    professional: { value: number; label: string };
  }) => {
    addProfessionalToCompany(companyId, data.professional.value, onHide);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <SelectSearch
            label="Nome do Profissional:"
            placeholder="Buscar Profissional"
            url={`${API_URL}Professional/search`}
            control={control}
            name={"professional"}
            get={getProfessionalOptions}
            isClearable
            error={errors.professional?.label?.message}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}>
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Adicionar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default CompanyProfessionalForm;
