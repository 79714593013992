import { Link } from "react-router-dom";

interface NavCardProps {
  title: string;
  description: string;
  icon: string;
  to: string;
  isInactive?: boolean;
}

const NavCard = (props: NavCardProps) => {
  return (
    <div className="col-12 col-lg-6 px-2 pb-3">
      <Link
        to={props.to}
        className="checkbox-tile py-3 h-100"
        style={{ pointerEvents: props.isInactive ? "none" : "auto" }}
      >
        <div className="row align-items-center no-gutters w-100">
          <div className="col col-auto pr-3">
            <span className="checkbox-icon">
              <i className={`uil ${props.icon}`} />
            </span>
          </div>
          <div className="col">
            <h6>{props.title}</h6>
            <p className="mb-0 lh-sm">{props.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NavCard;
