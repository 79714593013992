export const cpfAndCnpjMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCpfOrCnpj = inputValueSubstrings.slice(0, 14);
  const size = formattedCpfOrCnpj.length;

  if (size > 12) {
    return formattedCpfOrCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,})$/g, "$1.$2.$3/$4-$5");
  } else if (size > 9) {
    return formattedCpfOrCnpj
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3-$4");
  } else if (size > 8) {
    return formattedCpfOrCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3/$4");
  } else if (size > 6) {
    return formattedCpfOrCnpj
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 5) {
    return formattedCpfOrCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 3) {
    return formattedCpfOrCnpj.join("").replace(/^(\d{3})(\d{1,})$/g, "$1.$2");
  } else if (size > 2) {
    return formattedCpfOrCnpj.join("").replace(/^(\d{2})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCpfOrCnpj.join("");
  }
};

export const dateMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedDate = inputValueSubstrings.slice(0, 8);
  const size = formattedDate.length;

  if (size > 4) {
    return formattedDate
      .join("")
      .replace(/^(\d{2})(\d{2})(\d{1,})$/g, "$1/$2/$3");
  } else if (size > 2) {
    return formattedDate.join("").replace(/^(\d{2})(\d{1,})$/g, "$1/$2");
  } else {
    return formattedDate.join("");
  }
};

export const phoneMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedPhone = inputValueSubstrings.slice(0, 10);
  const size = formattedPhone.length;

  if (size > 7) {
    return formattedPhone
      .join("")
      .replace(/^(\d{2})(\d{4})(\d{1,})$/g, "($1) $2-$3");
  } else if (size > 2) {
    return formattedPhone.join("").replace(/^(\d{2})(\d{1,})$/g, "($1) $2");
  } else {
    return formattedPhone.join("");
  }
};

export const mobilePhoneMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedPhone = inputValueSubstrings.slice(0, 11);
  const size = formattedPhone.length;

  if (size > 7) {
    return formattedPhone
      .join("")
      .replace(/^(\d{2})(\d{5})(\d{1,})$/g, "($1) $2-$3");
  } else if (size > 2) {
    return formattedPhone.join("").replace(/^(\d{2})(\d{1,})$/g, "($1) $2");
  } else {
    return formattedPhone.join("");
  }
};

export const phoneOrMobilePhoneMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedPhone = inputValueSubstrings.slice(0, 11);
  const size = formattedPhone.length;

  if (size > 7) {
    if (size === 10) {
      return formattedPhone
        .join("")
        .replace(/^(\d{2})(\d{4})(\d{1,})$/g, "($1) $2-$3");
    }
    return formattedPhone
      .join("")
      .replace(/^(\d{2})(\d{5})(\d{1,})$/g, "($1) $2-$3");
  } else if (size > 2) {
    return formattedPhone.join("").replace(/^(\d{2})(\d{1,})$/g, "($1) $2");
  } else {
    return formattedPhone.join("");
  }
};

export const cepMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCep = inputValueSubstrings.slice(0, 8);
  const size = formattedCep.length;

  if (size > 5) {
    return formattedCep.join("").replace(/^(\d{5})(\d{1,})$/g, "$1-$2");
  } else {
    return formattedCep.join("");
  }
};

export const cpfMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const cpfSubstringsList = inputValue.split("");
  const formattedCpf = cpfSubstringsList.slice(0, 11);
  const size = formattedCpf.length;

  if (size > 9) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3-$4");
  } else if (size > 6) {
    return formattedCpf
      .join("")
      .replace(/^(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 3) {
    return formattedCpf.join("").replace(/^(\d{3})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCpf.join("");
  }
};

export const rgMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "").substring(0, 9);

  if (inputValue.length === 0) {
    return "";
  }

  return inputValue.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
};

export const cnpjMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCnpj = inputValueSubstrings.slice(0, 14);
  const size = formattedCnpj.length;

  if (size > 12) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,})$/g, "$1.$2.$3/$4-$5");
  } else if (size > 8) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{1,})$/g, "$1.$2.$3/$4");
  } else if (size > 5) {
    return formattedCnpj
      .join("")
      .replace(/^(\d{2})(\d{3})(\d{1,})$/g, "$1.$2.$3");
  } else if (size > 2) {
    return formattedCnpj.join("").replace(/^(\d{2})(\d{1,})$/g, "$1.$2");
  } else {
    return formattedCnpj.join("");
  }
};

export const twoDecimalPlacesMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");
  inputValue = inputValue.replace(/(\d)(\d{2})$/, "$1,$2");
  inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return inputValue;
};

export const digitMask = (inputValue: string) => {
  return inputValue.replace(/\D/g, "");
};

export const cardExpiryMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedDate = inputValueSubstrings.slice(0, 7);
  const size = formattedDate.length;

  if (size > 2) {
    return formattedDate.join("").replace(/^(\d{2})(\d{1,})$/g, "$1/$2");
  } else {
    return formattedDate.join("");
  }
};

export const amexCardNumberMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCardNumber = inputValueSubstrings.slice(0, 15);
  const size = formattedCardNumber.length;

  if (size > 12) {
    return formattedCardNumber
      .join("")
      .replace(/^(\d{4})(\d{6})(\d{1,})$/g, "$1 $2 $3");
  } else if (size > 4) {
    return formattedCardNumber.join("").replace(/^(\d{4})(\d{1,})$/g, "$1 $2");
  } else {
    return formattedCardNumber.join("");
  }
};

export const creditCardNumberMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const inputValueSubstrings = inputValue.split("");
  const formattedCardNumber = inputValueSubstrings.slice(0, 16);
  const size = formattedCardNumber.length;

  if (size > 12) {
    return formattedCardNumber
      .join("")
      .replace(/^(\d{4})(\d{4})(\d{4})(\d{1,})$/g, "$1 $2 $3 $4");
  } else if (size > 8) {
    return formattedCardNumber
      .join("")
      .replace(/^(\d{4})(\d{4})(\d{1,})$/g, "$1 $2 $3");
  } else if (size > 4) {
    return formattedCardNumber.join("").replace(/^(\d{4})(\d{1,})$/g, "$1 $2");
  } else {
    return formattedCardNumber.join("");
  }
};

export const ieMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const formattedIe = inputValue.replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, "$1.");

  return formattedIe;
};

export const accountMask = (inputValue: string) => {
  inputValue = inputValue.replace(/\D/g, "");

  const formattedAccount = inputValue.replace(/(\d+)(\d{3})$/, "$1-$2");

  return formattedAccount;
};
