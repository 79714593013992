import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Slider, Switch } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select, { Theme, StylesConfig } from "react-select";
import { SelectFetch } from "react-select-fetch";
import { useReactToPrint } from "react-to-print";
import * as yup from "yup";

import Button from "components/Button";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import ModalComponent from "components/ModalComponent";
import Simulator from "components/Simulator";
import { API_URL } from "config";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { useProfessionalQuote } from "providers/Quote/ProfessionalQuote";
import { RoleDescription } from "types/enums";
import { SearchDataQuoteProfessional } from "types/quotes";
import { getCompanyOptions } from "utils/getOptions";

import * as C from "./styles";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
    * multiValue(remove)/color:hover
    */
    danger: "var(--danger)",
    /*
    * multiValue(remove)/backgroundColor(focused)
    * multiValue(remove)/backgroundColor:hover
    */
    dangerLight: "var(--danger-light)",
    /*
    * control/backgroundColor
    * menu/backgroundColor
    * option/color(selected)
    */
    neutral0: "var(--neutral-0)",
    /*
    * control/backgroundColor(disabled)
    */
    neutral5: "var(--neutral-5)",
    /*
    * control/borderColor(disabled)
    * multiValue/backgroundColor
    * indicators(separator)/backgroundColor(disabled)
    */
    neutral10: "var(--neutral-10)",
    /*
    * control/borderColor
    * option/color(disabled)
    * indicators/color
    * indicators(separator)/backgroundColor
    * indicators(loading)/color
    */
    neutral20: "var(--neutral-20)",
    /*
    * control/borderColor(focused)
    * control/borderColor:hover
    */
    neutral30: "var(--neutral-30)",
    /*
    * menu(notice)/color
    * singleValue/color(disabled)
    * indicators/color:hover
    */
    neutral40: "var(--neutral-40)",
    /*
    * placeholder/color
    */
    neutral50: "var(--neutral-50)",
    /*
    * indicators/color(focused)
    * indicators(loading)/color(focused)
    */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
    * input/color
    * multiValue(label)/color
    * singleValue/color
    * indicators/color(focused)
    * indicators/color:hover(focused)
    */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
    * control/boxShadow(focused)
    * control/borderColor(focused)
    * control/borderColor:hover(focused)
    * option/backgroundColor(selected)
    * option/backgroundColor:active(selected)
    */
    primary: "var(--primary)",
    /*
    * option/backgroundColor(focused)
    */
    primary25: "var(--primary-25)",
    /*
    * option/backgroundColor:active
    */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

const ProfessionalQuoteReportSearch = () => {
  const { userRole } = useAuth();
  const { isLoadingProfessionalCompanies, professionalCompanies } =
    useCompany();
  const {
    searchData,
    setSearchData,
    searchProfessionalQuotesReport,
    professionalQuotesReportGlobalRef,
  } = useProfessionalQuote();

  const [openDropdown, setOpenDropdown] = useState(false);

  const [rangeValue, setRangeValue] = useState<number[]>([0, 10000]);
  const [changeDate, setChangeDate] = useState(true);
  const [checkFinanciamento, setCheckFinanciamento] = useState(false);
  const [checkParcelamento, setCheckParcelamento] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const componentRef = useRef(
    professionalQuotesReportGlobalRef?.current ?? null
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatório dos Orçamentos Finalizados",
  });

  const customStyles: StylesConfig = {
    control: (base: Record<string, unknown>) => ({
      ...base,
      height: 48,
      borderColor: "var(--border)",
      cursor: "pointer",
      "&:hover": {},
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      textTransform: "uppercase",
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      cursor: "pointer",
      textTransform: "uppercase",
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      position: "relative",
      zIndex: 5,
    }),
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChangeDate(true);
    } else {
      setChangeDate(false);
    }
  };

  function valueRangeText(value: number) {
    return `R$ ${value}`;
  }

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue("patientName", inputValue);

    if (!inputValue) {
      setSearchData({
        ...searchData,
        patientName: "",
      } as SearchDataQuoteProfessional);
    }
  };

  const handleFinanciamentoInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setCheckFinanciamento(true);
    } else {
      setCheckFinanciamento(false);
    }
  };

  const handleParcelamentoInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setCheckParcelamento(true);
    } else {
      setCheckParcelamento(false);
    }
  };

  const handleIdQuoteInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue("idQuote", inputValue);

    if (!inputValue) {
      setSearchData({
        ...searchData,
        idQuote: null,
      } as SearchDataQuoteProfessional);
    }
  };

  const handleDateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (!inputValue) {
      setSearchData({
        ...searchData,
        beginDate: null,
        endDate: null,
      } as SearchDataQuoteProfessional);
    }
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setRangeValue(newValue as number[]);
  };

  const handleCompanyChange = (newValue: { value: number; label: string }) => {
    if (!newValue) {
      setSelectedCompany({ value: -1, label: "" });
      return;
    }

    setSelectedCompany(newValue);
  };

  const clearFilters = () => {
    reset();
    setSelectedCompany({ value: -1, label: "" });
    setChangeDate(false);
    setCheckFinanciamento(false);
    setCheckParcelamento(false);
    setRangeValue([0, 10000] as number[]);
    setSearchData(getValues());
  };

  const schema = yup.object().shape({
    patientName: yup.string(),
    idQuote: yup.string(),
    range: yup.array(),
    beginDate: yup.date().typeError("*Data inválida").nullable(),
    endDate: yup.date().typeError("*Data inválida").nullable(),
    beginDateCreate: yup.date().typeError("*Data inválida").nullable(),
    endDateCreate: yup.date().typeError("*Data inválida").nullable(),
    chooseDate: yup.number(),
    paymentMethod: yup.object(),
    companyId: yup.number(),
  });

  const { register, handleSubmit, control, setValue, reset, getValues } =
    useForm<SearchDataQuoteProfessional>({
      resolver: yupResolver(schema),
      defaultValues: {
        beginDate: searchData.beginDate
          ? moment(searchData.beginDate).format("YYYY-MM-DD")
          : null,
        endDate: searchData.endDate
          ? moment(searchData.endDate).format("YYYY-MM-DD")
          : null,
        beginDateCreate: searchData.beginDateCreate
          ? moment(searchData.beginDateCreate).format("YYYY-MM-DD")
          : null,
        endDateCreate: searchData.endDateCreate
          ? moment(searchData.endDateCreate).format("YYYY-MM-DD")
          : null,
        paymentMethod: searchData.paymentMethod ?? {
          financing: false,
          credit: false,
        },
        range: searchData.range ?? [0, 10000],
        chooseDate: searchData.chooseDate ?? 0,
        companyId: searchData.companyId ?? -1,
      },
    });

  const onSubmit = async (data: SearchDataQuoteProfessional) => {
    const result = {
      ...data,
      companyId: selectedCompany?.value,
      companyName: selectedCompany?.label,
    };

    setSearchData(result);
    searchProfessionalQuotesReport(data);
    setOpenDropdown(false);
  };

  useEffect(() => {
    if (userRole === RoleDescription.Admin) {
      setSelectedCompany(null);

      setSearchData({
        ...searchData,
        companyId: undefined,
        companyName: "",
      } as SearchDataQuoteProfessional);
    } else if (professionalCompanies.length > 0) {
      setSelectedCompany({
        value: professionalCompanies[0].id,
        label: professionalCompanies[0].name,
      });

      setSearchData({
        ...searchData,
        companyId: professionalCompanies[0].id,
        companyName: professionalCompanies[0].name,
      } as SearchDataQuoteProfessional);
    }
  }, [professionalCompanies]);

  useEffect(() => {
    if (!Object.keys(searchData).length) {
      return;
    }

    setSelectedCompany({
      value: searchData.companyId ?? -1,
      label: searchData.companyName ?? "",
    });

    setCheckFinanciamento(searchData.paymentMethod?.financing);
    setCheckParcelamento(searchData.paymentMethod?.credit);
    setRangeValue(searchData.range);
  }, [searchData]);

  useEffect(() => {
    componentRef.current = professionalQuotesReportGlobalRef;
  }, [professionalQuotesReportGlobalRef]);

  return (
    <div className="row gx-2">
      <div className="col col-auto pb-2">
        <Button
          className="btn-sm btn-outline-primary"
          onClick={() => {
            componentRef.current = professionalQuotesReportGlobalRef;
            handlePrint();
          }}
        >
          <i className="uil uil-print mr-2" />
          Imprimir Orçamento
        </Button>
      </div>

      <form className="col col-auto pb-2" onSubmit={handleSubmit(onSubmit)}>
        <C.Container>
          <Dropdown autoClose={false} show={openDropdown}>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="dark"
              size="sm"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              FILTROS
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                width: "90vw",
                maxHeight: "29rem",
                maxWidth: "22rem",
                overflowY: "auto",
                zIndex: 3,
                paddingBottom:0
              }}
            >
              <Dropdown.Header className="lh-1">FILTROS</Dropdown.Header>

              <Dropdown.Divider />

              <Dropdown.ItemText>
                <div className="row">
                  <div className="form-group col-12 w-full">
                    <Input
                      label="Nome"
                      labelClassName="small text-muted mb-0"
                      type="text"
                      placeholder="Nome do Cliente"
                      register={register("patientName")}
                      onChange={handleTextInput}
                      defaultValue={searchData.patientName}
                    />
                  </div>

                  <div className="form-group col-12 w-full flex-fill">
                    {userRole === RoleDescription.Secretary ? (
                      <>
                        <label className="small text-muted mb-0">
                          Selecione a Empresa
                        </label>
                        <Controller
                          name="companyId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              options={professionalCompanies.map(
                                (company) => ({
                                  value: company.id,
                                  label: company.name,
                                })
                              )}
                              onChange={(newValue) => {
                                const company = newValue as {
                                  value: number;
                                  label: string;
                                };

                                field.onChange(company?.value ?? -1);

                                handleCompanyChange(
                                  newValue as { value: number; label: string }
                                );
                              }}
                              placeholder="Buscar Instituição"
                              isClearable={true}
                              value={selectedCompany}
                              theme={customTheme}
                              styles={customStyles}
                              isDisabled={isLoadingProfessionalCompanies}
                            />
                          )}
                        ></Controller>
                      </>
                    ) : userRole === RoleDescription.Admin ? (
                      <>
                        <label className="small text-muted mb-0">
                          Selecione a Empresa
                        </label>
                        <Controller
                          name="companyId"
                          control={control}
                          render={({ field }) => (
                            <SelectFetch
                              loadingMessage={() => "Carregando..."}
                              noOptionsMessage={() =>
                                "Nenhum resultado encontrado"
                              }
                              theme={customTheme}
                              queryParams={{
                                limit: 10,
                              }}
                              placeholder="Buscar Instituição"
                              url={`${API_URL}Company/search`}
                              get={getCompanyOptions}
                              isClearable
                              styles={customStyles}
                              onChange={(newValue) => {
                                const company = newValue as {
                                  value: number;
                                  label: string;
                                };

                                field.onChange(company?.value ?? -1);

                                handleCompanyChange(company);
                              }}
                              value={selectedCompany}
                            />
                          )}
                        ></Controller>
                      </>
                    ) : null}
                  </div>

                  <div className="form-group col-12 w-full">
                    <Input
                      label="Id do Orçamento"
                      labelClassName="small text-muted mb-0"
                      type="number"
                      placeholder="Id do Orçamento"
                      register={register("idQuote")}
                      onChange={handleIdQuoteInput}
                      defaultValue={searchData.idQuote ?? ""}
                    />
                  </div>

                  <div className="form-group col-12 w-full">
                    <Controller
                      name="paymentMethod"
                      control={control}
                      render={({ field }) => (
                        <>
                          <label className="small text-muted mb-0">
                            Tipo de Pagamento
                          </label>
                          <fieldset className="checkbox-group justify-content-center">
                            <label>
                              <input
                                type="checkbox"
                                name="tipo-usuario"
                                checked={checkFinanciamento}
                                className="checkbox-input"
                                onChange={(event) => {
                                  field.onChange({
                                    financing: event.target.checked,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    credit: field.value?.credit,
                                  });
                                  handleFinanciamentoInput(event);
                                }}
                              />
                              <span className="checkbox-tile">
                                <span className="d-flex align-items-center checkbox-label">
                                  <div
                                    className="badge badge-dark text-white rounded-circle p-2 text-center mr-2"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Parcelamento"
                                  >
                                    <i className="uil uil-credit-card"></i>
                                  </div>
                                  <span>Financiamento</span>
                                </span>
                              </span>
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="tipo-usuario"
                                checked={checkParcelamento}
                                className="checkbox-input"
                                onChange={(event) => {
                                  field.onChange({
                                    credit: event.target.checked,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    financing: field.value?.financing,
                                  });
                                  handleParcelamentoInput(event);
                                }}
                              />
                              <span className="checkbox-tile">
                                <span className="d-flex align-items-center checkbox-label">
                                  <div
                                    className="badge badge-primary text-white rounded-circle p-2 text-center mr-2"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Parcelamento"
                                  >
                                    <i className="uil uil-file-landscape-alt"></i>
                                  </div>
                                  <span>Parcelamento</span>
                                </span>
                              </span>
                            </label>
                          </fieldset>
                        </>
                      )}
                    ></Controller>
                  </div>

                  <div className="form-group col-11 w-full">
                    <div>
                      <Controller
                        name="range"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Typography
                              id="range-slider"
                              gutterBottom
                              className="small text-muted mb-0"
                            >
                              Valor entre
                            </Typography>
                            <Slider
                              value={rangeValue}
                              max={10000}
                              min={0}
                              onChange={(event) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                field.onChange(event.target?.value);
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                handleChange(event, event.target?.value);
                              }}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              valueLabelFormat={valueRangeText}
                              getAriaValueText={valueRangeText}
                            />
                          </>
                        )}
                      ></Controller>
                    </div>
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data Criação de"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="beginDateCreate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data Criação até"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="endDateCreate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="chooseDate"
                      className="small text-muted mb-0"
                    >
                      Data
                    </label>
                    <Controller
                      name="chooseDate"
                      control={control}
                      render={({ field }) => (
                        <div className="row w-full justify-content-center align-items-center">
                          <Typography className="col-5 w-full">
                            Procedimento
                          </Typography>
                          <Switch
                            className="w-full"
                            onChange={(event) => {
                              field.onChange(!event.target.checked ? 0 : 1);
                              handleChangeDate(event);
                            }}
                            color="primary"
                          />
                          <Typography className="col-4 w-full">
                            Limite
                          </Typography>
                        </div>
                      )}
                    ></Controller>
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data de"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="beginDate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>

                  <div className="form-group col-6 w-full">
                    <DatePicker
                      label="Data até"
                      labelClassName="small text-muted mb-0"
                      control={control}
                      name="endDate"
                      defaultValue={null}
                      onInputChange={handleDateInput}
                    />
                  </div>
                </div>
              </Dropdown.ItemText>
              <Dropdown.ItemText
                className="position-sticky bg-white py-2 border-top"
                style={{ bottom: 0 }}
              >
                <div className="row justify-content-between">
                  <div className="col col-auto">
                    <a
                      className="btn btn-light btn-sm text-uppercase"
                      onClick={clearFilters}
                    >
                      <i className="uil uil-times mr-2" />
                      Limpar Filtros
                    </a>
                  </div>
                  <div className="col col-auto">
                    <Button
                      type="submit"
                      className="btn-primary btn-sm text-uppercase"
                    >
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </C.Container>
      </form>
    </div>
  );
};

export default ProfessionalQuoteReportSearch;
