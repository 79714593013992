export const calculateFees = (parcelQtd: number) => {
  let calculatedFeeMonthly = "";
  let calculatedFeeAnnual = "";

  if (parcelQtd === 12) {
    calculatedFeeMonthly = "2,66";
    calculatedFeeAnnual = "37,03";
  } else if (parcelQtd === 24) {
    calculatedFeeMonthly = "2,81";
    calculatedFeeAnnual = "39,45";
  } else if (parcelQtd === 36) {
    calculatedFeeMonthly = "2,94";
    calculatedFeeAnnual = "41,58";
  } else if (parcelQtd === 48) {
    calculatedFeeMonthly = "3,06";
    calculatedFeeAnnual = "43,58";
  }

  return { calculatedFeeMonthly, calculatedFeeAnnual };
};

export const calculateFinalValue = (parcelQtd: number, parcelValue: number) => {
  const calculatedFinalValue = parcelQtd * parcelValue;

  return calculatedFinalValue.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
};
