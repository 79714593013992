import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { Role } from "types/enums";
import { Professional, SearchDataProfessional } from "types/professional";
import { ProfessionalEditData } from "types/register";
import { toast } from "utils/toast";

interface ProfessionalProviderProps {
  children: ReactNode;
}

interface ProfessionalProviderData {
  inactiveProfessionals: Professional[];
  inactiveProfessionalsTotal: number;
  inactiveProfessionalsTotalPages: number;
  inactiveProfessionalsCurrentPage: number;
  inactiveProfessionalsFound: Professional[];
  inactiveProfessionalsFoundTotal: number;
  inactiveProfessionalsFoundTotalPages: number;
  inactiveProfessionalsFoundCurrentPage: number;

  getInactiveProfessionals: (
    companyId?: number,
    currentPage?: number,
    search?: string,
    contract?: number
  ) => Promise<void>;
  searchInactiveProfessionals: (
    data: SearchDataProfessional,
    currentPage?: number
  ) => Promise<void>;

  searchData: SearchDataProfessional;
  setSearchData: React.Dispatch<React.SetStateAction<SearchDataProfessional>>;

  setInactiveProfessionalsFound: React.Dispatch<
    React.SetStateAction<Professional[]>
  >;
  setInactiveProfessionalsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setInactiveProfessionalsFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  activeProfessionals: Professional[];
  activeProfessionalsTotal: number;
  activeProfessionalsTotalPages: number;
  activeProfessionalsCurrentPage: number;
  activeProfessionalsFound: Professional[];
  activeProfessionalsFoundTotal: number;
  activeProfessionalsFoundTotalPages: number;
  activeProfessionalsFoundCurrentPage: number;

  getActiveProfessionals: (
    companyId?: number,
    currentPage?: number,
    search?: string,
    contract?: number
  ) => Promise<void>;
  searchActiveProfessionals: (
    data: SearchDataProfessional,
    currentPage?: number
  ) => Promise<void>;

  setActiveProfessionalsFound: React.Dispatch<
    React.SetStateAction<Professional[]>
  >;
  setActiveProfessionalsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  setActiveProfessionalsFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  editProfessional: (
    data: ProfessionalEditData,
    professionalId: number,
    onHide: () => void,
    companyId?: number
  ) => Promise<void>;

  isLoadingInactiveProfessionals: boolean;
  isLoadingActiveProfessionals: boolean;
  isLoading: boolean;
}

export const ProfessionalContext = createContext<ProfessionalProviderData>(
  {} as ProfessionalProviderData
);

export const ProfessionalProvider = ({
  children,
}: ProfessionalProviderProps) => {
  const { token } = useAuth();

  const professionalsLimit = 10;

  //#region provider states inactiveProfessionals
  const [inactiveProfessionals, setInactiveProfessionals] = useState<
    Professional[]
  >([]);

  const [inactiveProfessionalsTotal, setInactiveProfessionalsTotal] =
    useState(0);

  const [inactiveProfessionalsTotalPages, setInactiveProfessionalsTotalPages] =
    useState(0);

  const [
    inactiveProfessionalsCurrentPage,
    setInactiveProfessionalsCurrentPage,
  ] = useState(1);

  const [inactiveProfessionalsFound, setInactiveProfessionalsFound] = useState<
    Professional[]
  >([]);

  const [inactiveProfessionalsFoundTotal, setInactiveProfessionalsFoundTotal] =
    useState(0);

  const [
    inactiveProfessionalsFoundTotalPages,
    setInactiveProfessionalsFoundTotalPages,
  ] = useState(0);

  const [
    inactiveProfessionalsFoundCurrentPage,
    setInactiveProfessionalsFoundCurrentPage,
  ] = useState(1);

  //#endregion

  //#region provider states inactiveProfessionals
  const [activeProfessionals, setActiveProfessionals] = useState<
    Professional[]
  >([]);

  const [activeProfessionalsTotal, setActiveProfessionalsTotal] = useState(0);

  const [activeProfessionalsTotalPages, setActiveProfessionalsTotalPages] =
    useState(0);

  const [activeProfessionalsCurrentPage, setActiveProfessionalsCurrentPage] =
    useState(1);

  const [activeProfessionalsFound, setActiveProfessionalsFound] = useState<
    Professional[]
  >([]);

  const [activeProfessionalsFoundTotal, setActiveProfessionalsFoundTotal] =
    useState(0);

  const [
    activeProfessionalsFoundTotalPages,
    setActiveProfessionalsFoundTotalPages,
  ] = useState(0);

  const [
    activeProfessionalsFoundCurrentPage,
    setActiveProfessionalsFoundCurrentPage,
  ] = useState(1);
  //#endregion

  const [searchData, setSearchData] = useState({} as SearchDataProfessional);

  const [isLoadingInactiveProfessionals, setIsLoadingInactiveProfessionals] =
    useState(false);

  const [isLoadingActiveProfessionals, setIsLoadingActiveProfessionals] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const searchInactiveProfessionals = async (
    data: SearchDataProfessional,
    currentPage = 1
  ) => {
    setIsLoadingInactiveProfessionals(true);

    try {
      const response = await api.get("/Professional/search", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          search: data.search,
          professionalType: Role.Professional,
          active: false,
          companyId: data.companyId === -1 ? undefined : data.companyId,
          contract: data.contract === -1 ? undefined : data.contract,
        },
      });
      setIsLoadingInactiveProfessionals(false);
      setInactiveProfessionalsFound(response.data);

      setInactiveProfessionalsFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setInactiveProfessionalsFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalsLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingInactiveProfessionals(false);
    }
  };

  const searchActiveProfessionals = async (
    data: SearchDataProfessional,
    currentPage = 1
  ) => {
    setIsLoadingActiveProfessionals(true);

    try {
      const response = await api.get("/Professional/search", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          search: data.search,
          professionalType: Role.Professional,
          active: true,
          companyId: data.companyId === -1 ? undefined : data.companyId,
          contract: data.contract === -1 ? undefined : data.contract,
        },
      });
      setIsLoadingActiveProfessionals(false);
      setActiveProfessionalsFound(response.data);

      setActiveProfessionalsFoundTotal(
        Number(response.headers["x-total-count"])
      );

      setActiveProfessionalsFoundTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalsLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingActiveProfessionals(false);
    }
  };

  const getInactiveProfessionals = async (
    companyId?: number,
    currentPage = 1,
    search = "",
    contract?: number
  ) => {
    setIsLoadingInactiveProfessionals(true);

    try {
      const response = await api.get("/Professional/search", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          professionalType: Role.Professional,
          active: false,
          companyId: companyId === -1 ? undefined : companyId,
          search: search,
          contract: contract === -1 ? undefined : contract,
        },
      });

      setIsLoadingInactiveProfessionals(false);
      setInactiveProfessionals(response.data);
      setInactiveProfessionalsTotal(Number(response.headers["x-total-count"]));

      setInactiveProfessionalsTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalsLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingInactiveProfessionals(false);
    }
  };

  const getActiveProfessionals = async (
    companyId?: number,
    currentPage = 1,
    search = "",
    contract?: number
  ) => {
    setIsLoadingActiveProfessionals(true);

    try {
      const response = await api.get("/Professional/search", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          professionalType: Role.Professional,
          active: true,
          companyId: companyId === -1 ? undefined : companyId,
          search: search,
          contract: contract === -1 ? undefined : contract,
        },
      });

      setIsLoadingActiveProfessionals(false);
      setActiveProfessionals(response.data);
      setActiveProfessionalsTotal(Number(response.headers["x-total-count"]));

      setActiveProfessionalsTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalsLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingActiveProfessionals(false);
    }
  };

  const editProfessional = async (
    data: ProfessionalEditData,
    professionalId: number,
    onHide: () => void,
    companyId?: number
  ) => {
    setIsLoading(true);

    const body = {
      ...data,
      birthDate: moment(data.birthDate, "DD/MM/YYYY").toISOString(),
      dddMobilePhone: data.mobilePhone.slice(1, 3),
      mobilePhone: data?.mobilePhone?.replace("-", "").slice(5),
      dddComercialPhone: data.comercialPhone.slice(1, 3),
      comercialPhone: data?.comercialPhone?.replace("-", "").slice(5),
      role: Role.Professional,
    };

    try {
      await api.put(`/Professional/${professionalId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getInactiveProfessionals(companyId, inactiveProfessionalsCurrentPage);
      getActiveProfessionals(companyId, activeProfessionalsCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  useEffect(() => {
    setSearchData({} as SearchDataProfessional);
  }, [token]);

  return (
    <ProfessionalContext.Provider
      value={{
        inactiveProfessionals,
        inactiveProfessionalsTotal,
        inactiveProfessionalsTotalPages,
        inactiveProfessionalsCurrentPage,
        inactiveProfessionalsFound,
        inactiveProfessionalsFoundTotal,
        inactiveProfessionalsFoundTotalPages,
        inactiveProfessionalsFoundCurrentPage,

        getInactiveProfessionals,
        searchInactiveProfessionals,

        searchData,
        setSearchData,

        activeProfessionals,
        activeProfessionalsTotal,
        activeProfessionalsTotalPages,
        activeProfessionalsCurrentPage,
        activeProfessionalsFound,
        activeProfessionalsFoundTotal,
        activeProfessionalsFoundTotalPages,
        activeProfessionalsFoundCurrentPage,

        getActiveProfessionals,
        searchActiveProfessionals,

        setInactiveProfessionalsCurrentPage,
        setInactiveProfessionalsFoundCurrentPage,
        setInactiveProfessionalsFound,

        setActiveProfessionalsCurrentPage,
        setActiveProfessionalsFoundCurrentPage,
        setActiveProfessionalsFound,

        isLoadingInactiveProfessionals,
        isLoadingActiveProfessionals,
        isLoading,

        editProfessional,
      }}
    >
      {children}
    </ProfessionalContext.Provider>
  );
};

export const useProfessional = () => useContext(ProfessionalContext);
