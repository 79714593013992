import { Placeholder } from "react-bootstrap";

const ListCardSkeleton = () => {
  return (
    <div className="row-item mb-2 px-3 pt-3">
      <div className="row align-items-center">
        <div className="col">
          <div className="row no-gutters">
            <div className="col-12 col-sm-6 col-xl-3 pb-3">
              <Placeholder
                as="p"
                animation="glow"
                className="small opacity-75 mb-0"
              >
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </div>

            <div className="col-12 col-sm-6 col-xl-3 pb-3">
              <Placeholder
                as="p"
                animation="glow"
                className="small opacity-75 mb-0"
              >
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </div>

            <div className="col-12 col-sm-6 col-xl-3 pb-3">
              <Placeholder
                as="p"
                animation="glow"
                className="small opacity-75 mb-0"
              >
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </div>

            <div className="col-12 col-sm-6 col-xl-3 pb-3">
              <Placeholder
                as="p"
                animation="glow"
                className="small opacity-75 mb-0"
              >
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
            </div>
          </div>
        </div>

        <Placeholder as="p" className="col col-auto pb-3" animation="glow">
          <Placeholder.Button style={{ width: "8rem", height: "2.5rem" }} />
        </Placeholder>
      </div>
    </div>
  );
};

export default ListCardSkeleton;
