import {DocumentTypeDescription, FormTypeDescription, Gender, MaritalStatus} from "./enums"

export interface FormQuote {
  id: number
  idQuote: number
  description: string
  info: string
  form: Form
  typeForm: FormTypeDescription
  status: FormStatus
  actions: FormActions[]
}

export interface FormPersonalInfo {
  cpf: string;
  proponentName: string;
  birthDate: string;
  sex: Gender;
  documentType: DocumentTypeDescription;
  rg: string;
  issuingOrganization: string;
  ufrg: string;
  email: string;
  birthCity: string;
  nationality: string;
  phone: string;
  ddd: string;
  maritalStatus: MaritalStatus;
  personWithDisability: boolean;
  publicPerson: boolean;
  motherName: string;
  fatherName: string;
}

export interface Form {
  cpf: string;
  proponentName: string;
  birthDate: string;
  sex: Gender;
  documentType: DocumentTypeDescription;
  rg: string;
  issuingOrganization: string;
  ufrg: string;
  email: string;
  birthCity: string;
  nationality: string;
  phone: string;
  ddd: string;
  maritalStatus: MaritalStatus;
  personWithDisability: boolean;
  publicPerson: boolean;
  motherName: string;
  fatherName: string;
  address: Address;
  personalReference1: PersonalReferences;
  personalReference2: PersonalReferences;
  professionalData: ProfessionalData;
  spouse: Spouse;
  type: FormTypeDescription;
  isDraft: boolean;
}

export interface Address {
  addressCEP: string;
  addressRoad: string;
  addressNumber: string;
  addressDistrict: string;
  addressCity: string;
  addressState: string;
  addressComplement: string;
  timeAtAddress: TimeAddress;
  residenceType: ResidenceTypeDescription;
  RentValue: string;
}

export interface PersonalReferences {
  name: string;
  relationship: string;
  ddd: string;
  phone: string;
}

export interface ProfessionalData {
  companyName: string;
  phone: string;
  startActivities: string;
  rent: string;
  typeIncomeProof: ProofIncome;
  jobTitle: string;
  cnpj: string;
  companyAddressCity: string;
  companyAddressState: string;
  serviceTime: string;
}

export interface Spouse {
  name: string;
  cpf: string;
  unionType: UnionTypeDescription;
}

export enum FormActions {
  ViewExtraInfoForm = 1,
  EditExtraInfoForm,
  DeleteExtraInfoForm
}

export enum FormStatus {
  NotInformed = 0,
  Pending,
  Submitted,
  Approved,
  Rejected
}

export enum TimeAddress {
  "NÃO INFORMADO" = 0,
  "MENOR QUE 1 ANO",
  "ENTRE 1 E 3 ANOS",
  "ENTRE 3 E 5 ANOS",
  "MAIS QUE 5 ANOS"
}

export enum ResidenceTypeDescription {
  "NÃO INFORMADO" = 0,
  "PROPRIO",
  "COM OS PAIS",
  "FUNCIONAL",
  "ALUGADO"
}

export enum ProofIncome {
  "NÃO INFORMADO" = 0,
  "EXTRATO BANCÁRIO",
  "HOLERITE",
  "IMPOSTO DE RENDA"
}

export enum UnionTypeDescription {
  "NÃO INFORMADO" = 0,
  "COMUNHÃO PARCIAL DE BENS",
  "COMUNHÃO UNIVERSAL DE BENS",
  "PARTICIPAÇÃO FINAL NOS AQUESTOS",
  "SEPARAÇÃO DE BENS",
  "SEPARAÇÃO OBRIGATÓRIA DE BENS"
}

export enum ActionFormQuote {
  Create,
  Edit
}