import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import ModalComponent from "components/ModalComponent";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";

import PasswordChangeForm from "./components/PasswordChangeForm";

const EditAccessDataForm = () => {
  const { newPasswordRequired } = useAuth();
  const { userData, editUserData, isLoading } = useUser();
  const [modalShow, setModalShow] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email?: string }>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: { email?: string }) => {
    editUserData(data);
  };

  // const openPwdEditPopUp = () => {
  //   // Step 1 - Identification
  //   pwdEditStep1();
  // };

  return (
    <>
      <ContainerAnimation>
        <div className="tab-pane fade active show" id="dados-acesso">
          <form
            className="form-box p-3 p-sm-4 p-xl-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h5 className="mb-4">Dados de Acesso</h5>
            <div className="form-group">
              <Input
                type="text"
                label="E-mail"
                placeholder="seu@email.com"
                register={register("email")}
                error={errors?.email?.message}
                defaultValue={userData?.email}
              />
            </div>

            <div className="form-group">
              <label>Senha</label>
              <div>
                <Button
                  type="button"
                  className={
                    newPasswordRequired
                      ? "animate__animated animate__pulse animate__infinite btn-warning"
                      : "btn-light"
                  }
                  onClick={() => setModalShow(true)}
                >
                  Alterar senha
                </Button>
              </div>
            </div>

            <div className="text-center pt-4">
              <Button
                type="submit"
                className="btn-primary"
                disabled={isLoading}
              >
                Salvar
                {!!isLoading && (
                  <CircularProgress
                    color="inherit"
                    size={12}
                    className="align-text-top ms-2"
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </ContainerAnimation>

      {/* Modal de Alterar Senha */}
      <ModalComponent
        title="Alterar Senha"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <PasswordChangeForm onHide={() => setModalShow(false)} />
      </ModalComponent>
    </>
  );
};

export default EditAccessDataForm;
