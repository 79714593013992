/* eslint-disable import/order */
import ContainerAnimation from "components/ContainerAnimation";
import SecretariesList from "./components/SecretaryList";

const ProfessionalSecretaries = () => {
  return (
    <ContainerAnimation>
      <div className="tab-pane fade active show" id="dados-secretarias">
        <SecretariesList />
      </div>
    </ContainerAnimation>
  );
};

export default ProfessionalSecretaries