import { useState } from "react";
import { Navigate } from "react-router-dom";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import ContractModals from "components/ContractModals";
import { useAuth } from "providers/Auth";
import { RoleDescription } from "types/enums";

const Contract = () => {
  const { userRole } = useAuth();

  const [contractShow, setContractShow] = useState(true);

  return userRole !== RoleDescription.Patient ? (
    <>
      <ContainerAnimation className="content container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <h1>Contrato</h1>
            <p>Caro Profissional, assine o contrato para prosseguir!</p>

            <div className="row no-gutters mx-n2 pt-5">
              <div className="col-12 col-lg-6 px-2 pb-3">
                <Button
                  className="checkbox-tile py-3 h-100"
                  style={{ pointerEvents: "auto" }}
                  onClick={() => setContractShow(true)}
                >
                  <div className="row align-items-center no-gutters w-100">
                    <div className="col col-auto pr-3">
                      <span className="checkbox-icon">
                        <i className="uil uil-file" />
                      </span>
                    </div>
                    <div className="col text-left">
                      <h6>Contrato</h6>
                      <p className="mb-0 lh-sm">Contrato com a Docttorpay</p>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ContainerAnimation>
      <ContractModals
        contractShow={contractShow}
        setContractShow={setContractShow}
      />
    </>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default Contract;
