/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import {
  handleCepInput,
  handleCpfInput,
  handleMobilePhoneInput,
  handlePhoneInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";
import { SecretaryBody } from "types/secrataries";
import { api } from "services";
import SelectSearch from "components/SelectSearch";
import RadioInput from "components/RadioInput";
import checkCep from "utils/checkCep";
import { brazilianStates } from "utils/brazilianStates";
import { useAuth } from "providers/Auth";
import { MaritalStatus } from "types/enums";

interface SecretaryForm {
  onHide: () => void;
  secretary: SecretaryBody;
  isEditing: boolean;
}

const SecretariesForm = ({ onHide, secretary, isEditing }: SecretaryForm) => {
  const [registerNewSecretary, setRegisterNewSecretary] = useState(
    secretary.id ? true : false
  );
  const [registerChoice, setRegisterChoice] = useState(
    secretary.id ? false : true
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSearchingCep, setIsSearchingCep] = useState(false);
  const [inputCep, setInputCep] = useState("");

  const { userId } = useAuth();

  const schema = yup.object().shape({
    secretarie: yup
      .object()
      .shape({
        value: yup.number(),
        label: yup.string(),
      })
      .nullable(),

    name: yup.string().required("Campo obrigatório").uppercase().trim(),
    cpf: yup
      .string()
      .required("Campo obrigatório")
      .typeError("Campo obrigatório"),
    birthDate: yup
      .date()
      .required("Campo obrigatório")
      .typeError("Campo obrigatório"),
    sex: yup
      .string()
      .required("Campo obrigatório")
      .typeError("Campo obrigatório"),
    phone: yup
      .string()
      .required("Campo obrigatório")
      .typeError("Campo obrigatório"),
    email: yup
      .string()
      .required("Campo obrigatório")
      .typeError("Campo obrigatório"),
    socialName: yup.string().uppercase().trim(),
    addressRoad: yup.string().uppercase().trim(),
    addressNumber: yup.string().uppercase().trim(),
    addressComplement: yup.string().uppercase().trim(),
    addressCity: yup.string().uppercase().trim(),
    addressDistrict: yup.string().uppercase().trim(),
    addressState: yup.string(),
    addressCEP: yup.string(),
    mobilePhone: yup.string(),
    comercialPhone: yup.string(),
    rg: yup.string().uppercase().trim(),
    maritalStatus: yup.string().required(),
    image: yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    setFocus,
  } = useForm<SecretaryBody>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleOnSubmit = async (secretary: SecretaryBody) => {
    try {
      setIsLoading(true);
      const { phone, mobilePhone, comercialPhone } = secretary;

      const ddd = phone.slice(1, 3) || "";
      const dddMobilePhone = mobilePhone?.slice(1, 3) || "";
      const dddComercialPhone = comercialPhone?.slice(1, 3) || "";
      const birthDate = new Date(secretary.birthDate).toISOString();

      if (!secretary.secretarie?.label) {
        delete secretary.secretarie;
      }

      const body = {
        ...secretary,
        ddd,
        dddMobilePhone,
        dddComercialPhone,
        birthDate,
        image: "",
      };

      const { data } = await api.post(`Professional/${userId}/secretary`, body);

      await api.post(`Professional/${data.id}/scope/${userId}`);

      setIsLoading(false);
      onHide();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleAddSecretaryToDoctor = async () => {
    try {
      setIsLoading(true);
      const professionalID =
        localStorage.getItem("@docttorpay:userId") || userId;

      const secretarie = getValues("secretarie");
      if (!secretarie) {
        setIsLoading(false);
        return;
      }

      const secretaryId = secretarie.value;

      await api.post(`Professional/${secretaryId}/scope/${professionalID}`);

      setIsLoading(false);
      onHide();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleGetSecretaries = async (
    url: string,
    params: {
      [key: string]: any;
    }
  ) => {
    const { search, limit, offset } = params;

    let filteredSecretaries = [];
    let hasMore = false;
    if (search) {
      try {
        const response = await api.get(url, {
          params: {
            search,
            professionalType: 3,
          },
        });

        const Secretary = response.data;

        filteredSecretaries = Secretary.map((secretary: SecretaryBody) => {
          return {
            value: secretary.id,
            label: secretary.socialName
              ? `${secretary.socialName} (${secretary.name})`
              : secretary.name,
          };
        });

        hasMore = response.headers["x-total-count"] > offset + limit;
      } catch (error) {
        console.error(error);
      }
    }

    return {
      options: filteredSecretaries,
      hasMore,
    };
  };

  const handleEditSecretary = async (dataToEdit: SecretaryBody) => {
    try {
      setIsLoading(true);
      const { phone, mobilePhone, comercialPhone } = dataToEdit;

      const ddd = phone.slice(1, 3) || "";
      const dddMobilePhone = mobilePhone?.slice(1, 3) || "";
      const dddComercialPhone = comercialPhone?.slice(1, 3) || "";
      const birthDate = new Date(dataToEdit.birthDate).toISOString();

      if (!dataToEdit.secretarie?.label) {
        delete dataToEdit.secretarie;
      }

      const body = {
        ...dataToEdit,
        ddd,
        dddMobilePhone,
        dddComercialPhone,
        birthDate,
        image: "",
        maritalStatus: secretary.maritalStatus,
      };

      await api.put(`Professional/secretary/${secretary.id}`, body);

      setIsLoading(false);
      onHide();
    } catch (error) {
      console.error(error);
      onHide();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(isEditing ? handleEditSecretary : handleOnSubmit)}
    >
      <Modal.Body>
        {registerChoice && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="mt-3"
            >
              <h5>Escolha um método para prosseguir</h5>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              className="mt-5 mb-5"
            >
              <Button
                onClick={() => {
                  setRegisterChoice(false);
                  setRegisterNewSecretary(true);
                }}
                className="btn-primary mr-2"
              >
                Criar nova secretária
              </Button>
              <Button
                onClick={() => {
                  setRegisterChoice(false);
                  setRegisterNewSecretary(false);
                }}
                className="btn-primary ml-2"
              >
                Usar secretária existente
              </Button>
            </div>
          </>
        )}

        {!registerChoice && registerNewSecretary && (
          <div
            style={{
              maxHeight: "750px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="row">
              <div className="col-xl-12 mb-2">
                <h5 className="mb-4">Dados da secretaria</h5>
                <div className="row">
                  <div className="col-6">
                    <Input
                      className="text-uppercase"
                      label="Nome da Secretaria: "
                      placeholder="Nome completo da secretaria"
                      register={register("name")}
                      error={errors.name?.message}
                      defaultValue={!!secretary ? secretary.name : ""}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      className="text-uppercase"
                      label="Nome social: "
                      placeholder="Como deseja ser chamada?"
                      register={register("socialName")}
                      error={errors.socialName?.message}
                      defaultValue={!!secretary ? secretary.socialName : ""}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mb-2">
                <div className="row">
                  <div className="col-12">
                    <Input
                      label="Email: "
                      placeholder="secretaria@email.com"
                      register={register("email")}
                      error={errors.email?.message}
                      defaultValue={!!secretary ? secretary.email : ""}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <Input
                        label="CPF: "
                        placeholder="000.000.000-00"
                        register={register("cpf")}
                        error={errors.cpf?.message}
                        defaultValue={!!secretary ? secretary.cpf : ""}
                        onChange={(e) => handleCpfInput(e, setValue, "cpf")}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="RG: "
                        placeholder="00.000.000-0"
                        register={register("rg")}
                        error={errors.rg?.message}
                        defaultValue={!!secretary ? secretary.rg : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <SelectInput
                        label="Estado civil:"
                        placeholder="Estado Civil"
                        control={control}
                        name="maritalStatus"
                        defaultValue={
                          !!secretary.id
                            ? secretary.maritalStatus
                            : MaritalStatus["NÃO INFORMADO"]
                        }
                        error={errors?.maritalStatus?.message}
                      >
                        <MenuItem value={"1"}>Solteiro</MenuItem>
                        <MenuItem value={"2"}>Casado</MenuItem>
                        <MenuItem value={"3"}>Separado</MenuItem>
                        <MenuItem value={"4"}>Divorciado </MenuItem>
                        <MenuItem value={"5"}>Viúvo</MenuItem>
                        <MenuItem value={"6"}>Não informado</MenuItem>
                      </SelectInput>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <Input
                        type="date"
                        label="Data de nascimento: "
                        placeholder="Digite a data de nascimento da secretária"
                        register={register("birthDate")}
                        error={errors.birthDate?.message}
                        defaultValue={
                          !!secretary && secretary.birthDate
                            ? secretary.birthDate.toString().split("T")[0]
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="select">Sexo:</label>
                    <div className="row mt-2">
                      <div className="col col-auto">
                        <RadioInput
                          label="Masculino"
                          value={1}
                          defaultChecked={!!secretary && secretary.sex === "1"}
                          register={register("sex")}
                        />
                      </div>
                      <div className="col col-auto">
                        <RadioInput
                          label="Feminino"
                          value={0}
                          defaultChecked={!!secretary && secretary.sex === "0"}
                          register={register("sex")}
                        />
                      </div>
                      <div className="col col-auto">
                        <RadioInput
                          label="Intersexo"
                          value={2}
                          defaultChecked={!!secretary && secretary.sex === "2"}
                          register={register("sex")}
                        />
                      </div>
                    </div>
                    <div className="text-danger mb-3">
                      <small>{!!errors?.sex && "*Campo obrigatório"}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <h5 className="mb-4">Dados de contato</h5>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <Input
                        label="Telefone fixo: "
                        error={errors.phone?.message}
                        placeholder="Telefone fixo"
                        register={register("phone")}
                        onChange={(e) => handlePhoneInput(e, setValue, "phone")}
                        defaultValue={!!secretary ? secretary.phone : ""}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <Input
                        label="Telefone celular: "
                        placeholder="Telefone celular"
                        register={register("mobilePhone")}
                        error={errors.mobilePhone?.message}
                        defaultValue={!!secretary ? secretary.mobilePhone : ""}
                        onChange={(e) =>
                          handleMobilePhoneInput(e, setValue, "mobilePhone")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <Input
                        label="Telefone comercial: "
                        error={errors.comercialPhone?.message}
                        defaultValue={
                          !!secretary ? secretary.comercialPhone : ""
                        }
                        placeholder="Telefone comercial"
                        register={register("comercialPhone")}
                        onChange={(e) =>
                          handlePhoneOrMobilePhoneInput(
                            e,
                            setValue,
                            "comercialPhone"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-xl-12">
                <h5 className="mb-4">Endereço</h5>
                <div className="form-group">
                  <div className="row flex-column gap-2 flex-sm-row gap-md-0 align-items-center">
                    <div className="col">
                      <Input
                        label="Consulte pelo CEP:"
                        placeholder="99999-999"
                        type="text"
                        register={register("addressCEP")}
                        error={errors?.addressCEP?.message}
                        onChange={(e) =>
                          handleCepInput(e, setValue, "addressCEP", setInputCep)
                        }
                        defaultValue={!!secretary ? secretary.addressCEP : ""}
                      />
                      <div className="col-12 col-md-auto small ps-0">
                        <a
                          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Não sei meu CEP!
                        </a>
                      </div>
                    </div>

                    <div className="col col-auto">
                      <Button
                        type="button"
                        className="btn-sm btn-dark mt-3"
                        onClick={() =>
                          checkCep(
                            inputCep,
                            setIsSearchingCep,
                            setValue,
                            setFocus
                          )
                        }
                        disabled={isSearchingCep}
                      >
                        Consultar
                        {!!isSearchingCep && (
                          <CircularProgress
                            color="inherit"
                            size={12}
                            className="align-text-top ms-2"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="Endereço: "
                        placeholder="Nome da rua"
                        register={register("addressRoad")}
                        error={errors.addressRoad?.message}
                        defaultValue={!!secretary ? secretary.addressRoad : ""}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="Numero: "
                        error={errors.addressNumber?.message}
                        placeholder="Numero da casa"
                        register={register("addressNumber")}
                        defaultValue={
                          !!secretary ? secretary.addressNumber : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="Complemento: "
                        placeholder="Complemento"
                        register={register("addressComplement")}
                        error={errors.addressComplement?.message}
                        defaultValue={
                          !!secretary ? secretary.addressComplement : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="Distrito: "
                        error={errors.addressDistrict?.message}
                        placeholder="Distrito"
                        register={register("addressDistrict")}
                        defaultValue={
                          !!secretary ? secretary.addressDistrict : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <Input
                        className="text-uppercase"
                        label="Cidade: "
                        error={errors.addressCity?.message}
                        placeholder="Cidade"
                        register={register("addressCity")}
                        defaultValue={!!secretary ? secretary.addressCity : ""}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <SelectInput
                        name="addressState"
                        label="Estado:"
                        placeholder="Selecione"
                        control={control}
                        error={errors?.addressState?.message}
                        defaultValue={
                          !!secretary.id ? String(secretary.addressState) : ""
                        }
                      >
                        <MenuItem value="" disabled>
                          Selecione
                        </MenuItem>
                        {brazilianStates.map((state, index) => (
                          <MenuItem key={index} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!registerChoice && !registerNewSecretary && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <SelectSearch
                    label="Nome da Secretaria:"
                    placeholder="Nome completo da secretaria"
                    url={`https://api-dev.docttorpay.com.br/api/Professional/search`}
                    control={control}
                    name={"secretarie"}
                    get={handleGetSecretaries}
                    isClearable
                    error={errors.secretarie?.label?.message}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>

      {!registerChoice && (
        <Modal.Footer>
          {!registerChoice && registerNewSecretary && (
            <p>A senha da secretária será enviada para o email registrado.</p>
          )}
          <Button type="button" className="btn-danger" onClick={onHide}>
            Cancelar
          </Button>
          {!registerChoice && !registerNewSecretary ? (
            <Button
              disabled={isLoading}
              type="button"
              onClick={handleAddSecretaryToDoctor}
              className="btn-primary"
            >
              <i className="uil uil-check mr-2" />
              Adicionar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          ) : (
            <Button disabled={isLoading} type="submit" className="btn-primary">
              <i className="uil uil-check mr-2" />
              {isEditing ? "Atualizar" : "Adicionar"}{" "}
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          )}
        </Modal.Footer>
      )}
    </form>
  );
};

export default SecretariesForm;
