import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import ModalComponent from "components/ModalComponent";

import CompanyCreateForm from "../Forms/CompanyCreateForm";

interface CompanySearchProps {
  setSearchData: (searchData: string) => void;
}

interface CompanySearchData {
  companyName: string;
}

const CompanySearch = ({ setSearchData }: CompanySearchProps) => {
  const [modalShow, setModalShow] = useState(false);

  const schema = yup.object().shape({
    companyName: yup.string(),
  });

  const { register, handleSubmit } = useForm<CompanySearchData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: CompanySearchData) => {
    setSearchData(data.companyName);
    // searchProfessionalPendingQuotes(data);
  };

  return (
    <>
      <div className="col col-auto pb-2">
        <Button
          className="btn-sm btn-primary"
          onClick={() => setModalShow(true)}
        >
          <i className="uil uil-plus mr-2" />
          Cadastrar instituição
        </Button>
      </div>

      <div className="col-12 col-md pb-2">
        <form
          className="row g-0 border rounded-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col">
            <div className="row g-0 border-right m-0">
              <div className="col-12 col-sm-6 col-md col-xl-auto">
                <Input
                  type="search"
                  placeholder="Nome da instituição"
                  register={register("companyName")}
                />
              </div>
            </div>
          </div>

          <div className="col col-auto">
            <Button
              className="btn btn-outline-primary border-0 rounded-0 py-2 px-3 h-100"
              type="submit"
            >
              <i className="uil uil-search fs-6" />
            </Button>
          </div>
        </form>
      </div>

      <ModalComponent
        title="Cadastrar Instituição"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
      >
        <CompanyCreateForm onHide={() => setModalShow(false)} />
      </ModalComponent>
    </>
  );
};

export default CompanySearch;
