import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { Modal } from "react-bootstrap";
import { UseFormSetValue, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import { useRegister } from "providers/Register";
import { Gender, Role } from "types/enums";
import { QuoteCreateData } from "types/quotes";
import { PatientUnconfirmedRegisterData } from "types/register";
import { validateBirthDate, validateCpf } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCpfInput,
  handleMobilePhoneInput,
} from "utils/handleInput";

interface PatientUnconfirmedRegisterFormProps {
  companyId: number;
  onHide: () => void;
  setQuoteValue: UseFormSetValue<QuoteCreateData>;
}

const PatientUnconfirmedRegisterForm = ({
  companyId,
  onHide,
  setQuoteValue,
}: PatientUnconfirmedRegisterFormProps) => {
  const { registerPatientUnconfirmed, isLoading } = useRegister();

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido")
      .trim(),
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test("cpfValidator", "*CPF inválido", validateCpf)
      .transform((value) => value.replace(/\D/g, "")),
    rg: yup.string().required("*Campo obrigatório").uppercase().trim(),
    sex: yup.string(),
    socialName: yup.string().uppercase().trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    mobilePhone: yup.string().required("*Campo obrigatório"),
    role: yup.number().default(Role.Patient),
    companyId: yup.number().default(companyId),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PatientUnconfirmedRegisterData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: PatientUnconfirmedRegisterData) => {
    registerPatientUnconfirmed(data, setQuoteValue, onHide);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome Completo:"
            placeholder="Nome do Cliente"
            type="text"
            register={register("name")}
            error={errors?.name?.message}
          />
        </div>

        <div className="form-group">
          <Input
            label="E-mail:"
            placeholder="cliente@email.com"
            type="text"
            register={register("email")}
            error={errors?.email?.message}
          />
        </div>

        <div className="row align-items-end">
          <div className="col-md-6">
            <div className="form-group">
              <Input
                label="CPF:"
                placeholder="999.999.999-99"
                type="tel"
                maxLength={14}
                register={register("cpf")}
                error={errors?.cpf?.message}
                onChange={(e) => handleCpfInput(e, setValue, "cpf")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <Input
                className="text-uppercase"
                label="RG:"
                placeholder="9999999-9"
                type="tel"
                register={register("rg")}
                error={errors?.rg?.message}
              />
            </div>
          </div>
        </div>

        <label className="" htmlFor="select">
          Sexo:
        </label>
        <div className="row">
          <div className="col col-auto">
            <RadioInput
              label="Masculino"
              value={Gender.Male}
              register={register("sex")}
            />
          </div>
          <div className="col col-auto">
            <RadioInput
              label="Feminino"
              value={Gender.Female}
              register={register("sex")}
            />
          </div>
          <div className="col col-auto">
            <RadioInput
              label="Intersexo"
              value={Gender.Intersex}
              register={register("sex")}
            />
          </div>
        </div>
        <div className="text-danger mb-3">
          <small>{!!errors?.sex && "*Selecione uma opção"}</small>
        </div>

        <div className="form-group">
          <Input
            className="text-uppercase"
            label="Nome Social:"
            placeholder="Como prefere ser chamado?"
            type="text"
            register={register("socialName")}
            error={errors?.socialName?.message}
          />
        </div>

        <div className="row align-items-end">
          <div className="col-md-6">
            <div className="form-group">
              <Input
                label="Data de Nascimento:"
                placeholder="dd/mm/aaaa"
                type="tel"
                maxLength={10}
                register={register("birthDate")}
                error={errors?.birthDate?.message}
                onChange={(e) => handleBirthDateInput(e, setValue, "birthDate")}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <Input
                label="Celular:"
                placeholder="(99) 99999-9999"
                type="tel"
                maxLength={15}
                register={register("mobilePhone")}
                error={errors?.mobilePhone?.message}
                onChange={(e) =>
                  handleMobilePhoneInput(e, setValue, "mobilePhone")
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          className="btn-light"
          onClick={onHide}
          disabled={isLoading}>
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Adicionar
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default PatientUnconfirmedRegisterForm;
