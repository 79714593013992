/* eslint-disable import/order */
import ContainerAnimation from "components/ContainerAnimation";
import SecretariesList from "./components/SecretaryList";
import { useEffect, useState } from "react";
import SecretarySearch from "./components/SecretarySearch";
import CompanySelect from "./components/CompanySelect";
import ProfessionalSelect from "./components/ProfessionalSelect";
import { SecretaryBody } from "types/secrataries";
import { api } from "services";

const AdminSecretaries = () => {
  const [secretaries, setSecretaries] = useState<SecretaryBody[]>([]);
  const [isLoadingSecretaries, setIsLoadingSecretaries] = useState(false);

  const [searchData, setSearchData] = useState<string>("");

  const [companyId, setCompanyId] = useState<number>(-1);
  const [professionalId, setProfessionalId] = useState<number>(-1);

  const [selectedProfessional, setSelectedProfessional] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const handleGetSecretaries = async () => {
    try {
      setIsLoadingSecretaries(true);
      const { data } = await api.get(
        `Professional/${professionalId}/targetby`,
        {
          params: {
            search: searchData,
          },
        }
      );
      setSecretaries(data);
      setIsLoadingSecretaries(false);
    } catch (error) {
      console.error(error);
      setIsLoadingSecretaries(false);
    }
  };

  useEffect(() => {
    if (professionalId !== -1) handleGetSecretaries();
    else setSecretaries([]);
  }, [professionalId, searchData]);

  return (
    <ContainerAnimation className="content">
      <div className="bg-white">
        <div className="container">
          <div className="row align-items-center pb-4 gx-2">
            <div className="col col-auto col-md-6 col-xl-3 pb-2">
              <h1 className="mb-0">Secretárias</h1>
            </div>
            <SecretarySearch
              professionalId={professionalId}
              setSearchData={setSearchData}
              handleGetSecretaries={handleGetSecretaries}
            />
            <div className="col-12 col-sm-6 col-md-4 col-xl pb-2">
              <CompanySelect
                setCompanyId={setCompanyId}
                setProfessionalId={setProfessionalId}
                setSelectedProfessional={setSelectedProfessional}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl pb-2">
              <ProfessionalSelect
                companyId={companyId}
                setProfessionalId={setProfessionalId}
                selectedProfessional={selectedProfessional}
                setSelectedProfessional={setSelectedProfessional}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <SecretariesList
          secretaries={secretaries}
          searchData={searchData}
          companyId={companyId}
          professionalId={professionalId}
          handleGetSecretaries={handleGetSecretaries}
          isLoadingSecretaries={isLoadingSecretaries}
        />
      </div>
    </ContainerAnimation>
  );
};

export default AdminSecretaries;
