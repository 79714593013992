import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Badge } from "react-bootstrap";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ListCardSkeleton from "components/ListCardSkeleton";
import ModalComponent from "components/ModalComponent";
import { useCompany } from "providers/Company";

import CompanyProfessionalCard from "../CompanyProfessionalCard";
import CompanyProfessionalForm from "../CompanyProfessionalForm";

interface CompanyProfessionalListProps {
  companyId: number;
}

const CompanyProfessionalList = ({
  companyId,
}: CompanyProfessionalListProps) => {
  const companyProfessionalsRef = useRef<HTMLDivElement>(null);
  const [modalShow, setModalShow] = useState(false);

  const {
    companyProfessionals,
    companyProfessionalsTotal,
    companyProfessionalsTotalPages,
    isLoadingCompanyProfessionals,
    companyProfessionalsCurrentPage,
    getCompanyProfessionals,
    setCompanyProfessionalsCurrentPage,
  } = useCompany();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCompanyProfessionalsCurrentPage(value);

    getCompanyProfessionals(companyId, value);

    companyProfessionalsRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getCompanyProfessionals(companyId);
  }, []);

  return (
    <>
      <div ref={companyProfessionalsRef} className="row mb-4">
        <div className="col">
          <h5>
            Profissionais
            <Badge bg="primary ml-1 text-white">
              {companyProfessionalsTotal}
            </Badge>
          </h5>
        </div>

        <div className="col col-auto">
          <Button
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}>
            <i className="uil uil-plus mr-2" />
            Adicionar profissional
          </Button>
        </div>
      </div>

      {/* Carregando lista de profissionais da empresa */}
      {!!isLoadingCompanyProfessionals &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de profissionais da empresa */}
      {!isLoadingCompanyProfessionals && (
        <>
          {!!companyProfessionals.length &&
            companyProfessionals?.map((companyProfessional) => (
              <CompanyProfessionalCard
                key={companyProfessional.id}
                companyId={companyId}
                companyProfessional={companyProfessional}
              />
            ))}

          {/* Lista de instituições inativas vazia */}
          {!companyProfessionals.length && (
            <EmptyList
              icon="uil-medkit"
              title="Nenhum profissional encontrado"
              subtitle="Clique abaixo e adicione um profissional à essa instituição"
              buttonTitle="Adicionar profissional"
              onClickButton={() => setModalShow(true)}
            />
          )}
        </>
      )}

      {!!companyProfessionals.length && (
        <Pagination
          color="primary"
          size="large"
          hidePrevButton
          hideNextButton
          count={companyProfessionalsTotalPages}
          page={companyProfessionalsCurrentPage}
          onChange={handleChangePage}
          className="mt-4 d-flex justify-content-center"
        />
      )}

      {/* Modal de vicular cliente à instituição */}
      <ModalComponent
        title="Adicionar Profissional"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg">
        <CompanyProfessionalForm
          companyId={companyId}
          onHide={() => setModalShow(false)}
        />
      </ModalComponent>
    </>
  );
};

export default CompanyProfessionalList;
