import styled from "styled-components";

export const RedCircle = styled.span`
  position: initial !important;
  display: inline-block !important;
  width: .8rem !important;
  height: .8rem !important;
  background-color: red !important;
  border-radius: 50% !important;
`;
