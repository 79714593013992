import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import ModalComponent from "components/ModalComponent";
import { api } from "services";
import { ContractStatus, ContractType } from "types/enums";
import { handlePhoneOrMobilePhoneInput } from "utils/handleInput";
import { toast } from "utils/toast";

import EmbeddedContractModal from "../EmbeddedContractModal";
import SolicitationSendedModal from "../SolicitationSendedModal";

interface ContractNegotiationData {
  contract: string;
  contractType: ContractType;
  negociateData: NegociateForm;
}

interface NegociateForm {
  phone: string;
  email: string;
  suggestionRequested: string;
}

interface ContractNegotiationModalProps {
  show: boolean;
  onHide: () => void;
}

const ContractNegotiationModal = ({
  show,
  onHide,
}: ContractNegotiationModalProps) => {
  const phone = "(41) 98787-1851";

  const [isLoading, setIsLoading] = useState(false);
  const [embeddedContractShow, setEmbeddedContractShow] = useState(false);
  const [solicitationShow, setSolicitationShow] = useState(false);

  const schema = yup.object().shape({
    negociateData: yup.object().shape({
      phone: yup
        .string()
        .required("*Campo obrigatório")
        .min(14, "*Deve possuir no mínimo 10 dígitos"),
      email: yup.string().email().required("*Campo obrigatório").trim(),
      suggestionRequested: yup.string().trim(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContractNegotiationData>({
    resolver: yupResolver(schema),
    defaultValues: {
      contractType: ContractType.Partnership,
      negociateData: {
        phone: "",
        email: "",
        suggestionRequested: "",
      },
    },
  });

  const onSubmit = async (negociateData: ContractNegotiationData) => {
    setIsLoading(true);
    try {
      await api.post("Contract/negociate", negociateData);

      toast.fire({
        icon: "success",
        title: "Negociação iniciada com sucesso!",
      });
      setSolicitationShow(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  return (
    <>
      <ModalComponent
        title="Negociação de Contrato"
        show={show}
        onHide={onHide}
        size="lg"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="modal-body text-body">
              <div
                style={{ maxHeight: "50vh", overflowY: "auto" }}
                className="p-3 border rounded"
              >
                <p>
                  Caso tenha questionamento ou solicitação de alteração a ser
                  realizada no contrato, informe no campo abaixo que entraremos
                  em contato.
                </p>

                <div className="mb-4">
                  <Input
                    label="Telefone"
                    type="text"
                    placeholder="(99) 99999-9999"
                    autoComplete="new-off"
                    minLength={14}
                    maxLength={15}
                    register={register("negociateData.phone", {
                      onChange: (event) =>
                        handlePhoneOrMobilePhoneInput(
                          event,
                          setValue,
                          "negociateData.phone"
                        ),
                    })}
                    error={errors?.negociateData?.phone?.message}
                    // defaultValue={
                    //   userData.mobilePhone
                    //     ? mobilePhoneMask(
                    //         `${userData?.dddMobilePhone} ${userData?.mobilePhone}`
                    //       )
                    //     : ""
                    // }
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label="E-mail"
                    type="email"
                    placeholder="seu@email.com"
                    autoComplete="new-off"
                    maxLength={255}
                    register={register("negociateData.email")}
                    error={errors?.negociateData?.email?.message}
                    // defaultValue={userData?.email ? userData?.email : ""}
                  />
                </div>
                <label htmlFor="suggestionRequested">
                  Alterações solicitadas
                </label>
                <TextareaAutosize
                  className="form-control mb-4"
                  id="suggestionRequested"
                  placeholder="Alterar cláusula x, em função de y..."
                  minRows={4}
                  {...register("negociateData.suggestionRequested")}
                ></TextareaAutosize>
                <p>
                  Caso tenha alguma dúvida, por favor entre em contato por meio
                  do telefone/WhatsApp: {phone}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn-light"
              onClick={() => {
                onHide();
              }}
              disabled={isLoading}
            >
              Voltar
            </Button>
            <Button
              type="button"
              className="btn-warning"
              onClick={() => setEmbeddedContractShow(true)}
              disabled={isLoading}
            >
              Visualizar contrato original
            </Button>
            <Button type="submit" className="btn-primary" disabled={isLoading}>
              Enviar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </Modal.Footer>
        </form>
      </ModalComponent>
      <EmbeddedContractModal
        signed={false}
        show={embeddedContractShow}
        onHide={() => setEmbeddedContractShow(false)}
      />
      <SolicitationSendedModal
        show={solicitationShow}
        onHide={() => setSolicitationShow(false)}
      />
    </>
  );
};

export default ContractNegotiationModal;
