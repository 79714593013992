import { UseFormRegisterReturn } from "react-hook-form";

interface UserTypeCardProps {
  register: UseFormRegisterReturn;
  label: string;
  value: string;
  icon: string;
  aosDelay?: string;
}

const UserTypeCard = ({
  register,
  label,
  value,
  icon,
  aosDelay,
}: UserTypeCardProps) => {
  return (
    <label
      // className="aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay={aosDelay}
      data-aos-once
      style={{ minWidth: 125 }}
    >
      <input
        type="radio"
        className="checkbox-input"
        value={value}
        {...register}
      />
      <span className="checkbox-tile">
        <span className="checkbox-icon">
          <i className={`uil ${icon}`} />
        </span>
        <span className="checkbox-label">{label}</span>
      </span>
    </label>
  );
};

export default UserTypeCard;
