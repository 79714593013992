import { useEffect, useState } from "react";

import ContainerAnimation from "components/ContainerAnimation";
import LoadingScreen from "components/LoadingScreen";
import { useAuth } from "providers/Auth";
import { api } from "services";
import { RoleDescription } from "types/enums";

interface Checkbox {
  id: number;
  idCheckListItem: number;
  idQuote: number;
  description: string;
  info: string;
  value: boolean;
}

interface ChecklistProps {
  quoteId: number;
}

export default function Checklist({ quoteId }: ChecklistProps) {
  const { userRole } = useAuth();

  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCheckboxes();
  }, []);

  const loadCheckboxes = async () => {
    try {
      const { data } = await api.get(`/Checklist/orcamento/${quoteId}`);

      setCheckboxes(data);

      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      await api.post(`/Checklist/orcamento/${quoteId}`, data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="position-relative">
      <div className="px-3 px-sm-4 px-xl-5 mx-n3 mx-sm-n4 mx-xl-n5">
        {!!isLoading && (
          <ContainerAnimation className="content">
            <LoadingScreen />
          </ContainerAnimation>
        )}
        {!isLoading && (
          <>
            {checkboxes.map((checkbox, i) => (
              <div className="form-check" key={checkbox.id}>
                <label
                  className="control control--checkbox mb-2"
                  htmlFor={checkbox.id.toString()}>
                  <span className="caption">{checkbox.description}</span>
                  <input
                  className="form-check-input"
                  type="checkbox"
                  id={checkbox.id.toString()}
                  disabled={userRole !== RoleDescription.Admin}
                  checked={checkbox.value}
                  onChange={(e) => {
                    handleSubmit([
                      {
                        idChecklistItem: checkbox.idCheckListItem,
                        value: e.target.checked,
                      },
                    ]);

                    setCheckboxes((prev) => {
                      const newCheckboxes = [...prev];
                      newCheckboxes[i].value = e.target.checked;
                      return newCheckboxes;
                    });
                  }}
                />
                  <div className="control__indicator"></div>
                  
                </label>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
