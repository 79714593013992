import { ReactNode } from "react";

import { AuthProvider } from "./Auth";
import { BankAccountProvider } from "./BankAccount";
import { CompanyProvider } from "./Company";
import { DarkModeProvider } from "./DarkMode";
import { NotifyProvider } from "./Notify";
import { PatientProvider } from "./Patient";
import { ProfessionalProvider } from "./Professional";
import { PatientQuoteProvider } from "./Quote/PatientQuote";
import { ProfessionalQuoteProvider } from "./Quote/ProfessionalQuote";
import { QuoteDetailsProvider } from "./QuoteDetails";
import { RegisterProvider } from "./Register";
import { TeamProvider } from "./Team";
import { UserProvider } from "./User";

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <AuthProvider>
      <NotifyProvider>
        <RegisterProvider>
          <DarkModeProvider>
            <UserProvider>
              <CompanyProvider>
                <ProfessionalProvider>
                  <PatientProvider>
                    <ProfessionalQuoteProvider>
                      <PatientQuoteProvider>
                        <QuoteDetailsProvider>
                          <BankAccountProvider>
                            <TeamProvider>{children}</TeamProvider>
                          </BankAccountProvider>
                        </QuoteDetailsProvider>
                      </PatientQuoteProvider>
                    </ProfessionalQuoteProvider>
                  </PatientProvider>
                </ProfessionalProvider>
              </CompanyProvider>
            </UserProvider>
          </DarkModeProvider>
        </RegisterProvider>
      </NotifyProvider>
    </AuthProvider>
  );
};

export default Providers;
