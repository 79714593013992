import {
  Autocomplete,
  AutocompleteInputChangeReason,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";

import { ComboBoxOption, ComboBoxQuoteOption } from "types";

interface ComboBoxProps {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  options: any[];
  searchPlaceholder?: string;
  defaultValue?: ComboBoxOption | ComboBoxQuoteOption;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
}

const ComboBox = (props: ComboBoxProps) => {
  const customStyles = {
    "& .MuiInputBase-root": {
      borderRadius: "7px",
      height: 48,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: props.error ? "var(--danger)" : "var(--border)",
    },
  };

  return (
    <>
      <label>{props.label}</label>

      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            onChange={(_, value) => field.onChange(value)}
            onInputChange={props.onInputChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            value={field.value || null}
            options={props.options}
            noOptionsText="Nenhum resultado encontrado"
            getOptionLabel={(option) => option.name}
            sx={customStyles}
            // defaultValue={field.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.placeholder}
                placeholder={props.searchPlaceholder}
              />
            )}
          />
        )}
      />

      {!!props.error && (
        <div className="text-start text-danger">
          <small>{props.error}</small>
        </div>
      )}
    </>
  );
};

export default ComboBox;
