/* eslint-disable import/order */
import { useEffect, useRef, useState } from "react";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ModalComponent from "components/ModalComponent";
import ListCardSkeleton from "components/ListCardSkeleton";
import SecretaryCard from "../SecretaryCard";
import SecretariesForm from "../SecretaryForm";
import { SecretaryBody } from "types/secrataries";
import { api } from "services";
import { useAuth } from "providers/Auth";

const SecretariesList = () => {
  const secretaryRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [isLoadingSecretaries, setIsLoadingSecretaries] = useState(false);
  const [secretaries, setSecretaries] = useState<SecretaryBody[]>([]);
  const [secretaryToEdit, setSecretaryToEdit] = useState<SecretaryBody>(
    {} as SecretaryBody
  );
  const { userId } = useAuth();

  const handleGetSecretaries = async () => {
    try {
      setIsLoadingSecretaries(true);
      const { data } = await api.get(`Professional/${userId}/targetby`);
      setSecretaries(data);
      setIsLoadingSecretaries(false);
    } catch (error) {
      console.error(error);
      setIsLoadingSecretaries(false);
    }
  };

  const editSecretary = (secretary: SecretaryBody) => {
    setSecretaryToEdit(secretary);
    setModalShow(true);
  };

  const handleHideModal = () => {
    setModalShow(false);
    setSecretaryToEdit({} as SecretaryBody);
    handleGetSecretaries();
    return;
  };

  const disableSecretary = async (id: number) => {
    try {
      await api.delete(`Professional/${id}/scope/${userId}`);

      handleGetSecretaries();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetSecretaries();
  }, []);

  return (
    <>
      {/* Secretárias */}
      <div ref={secretaryRef} className="row mb-4">
        <div className="col">
          <h5>Secretarias</h5>
        </div>
        <div className="col col-auto">
          <Button
            type="button"
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}
          >
            <i className="uil uil-plus mr-2" />
            Adicionar Secretaria
          </Button>
        </div>
      </div>

      {!!isLoadingSecretaries &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de secretarias */}
      {!isLoadingSecretaries && (
        <>
          {!!secretaries.length &&
            secretaries?.map((secretary, key) => (
              <SecretaryCard
                disableSecretary={disableSecretary}
                secretary={secretary}
                setModal={() => editSecretary(secretary)}
                key={key}
              />
            ))}

          {/* Lista de secretarias vazia */}
          {!secretaries.length && (
            <EmptyList
              icon="uil-user"
              title="Nenhuma secretária cadastrada"
              subtitle="Clique abaixo e adicione uma secretária"
              buttonTitle="Adicionar secretária"
              onClickButton={() => setModalShow(true)}
            />
          )}
        </>
      )}

      {/* Modal de adicionar secretaria */}
      <ModalComponent
        title="Adicionar Secretária"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setSecretaryToEdit({} as SecretaryBody);
        }}
        size="lg"
      >
        <SecretariesForm
          isEditing={secretaryToEdit.id ? true : false}
          secretary={secretaryToEdit}
          onHide={handleHideModal}
        />
      </ModalComponent>
    </>
  );
};

export default SecretariesList;
