/* eslint-disable import/order */
import { useEffect, useRef, useState } from "react";

import Button from "components/Button";
import EmptyList from "components/EmptyList";
import ModalComponent from "components/ModalComponent";
import ListCardSkeleton from "components/ListCardSkeleton";
import SecretaryCard from "../SecretaryCard";
import SecretaryEditForm from "../Forms/SecretaryEditForm";
import { SecretaryBody } from "types/secrataries";
import { api } from "services";

type SecretaryListProps = {
  secretaries: SecretaryBody[];
  searchData: string;
  companyId: number;
  professionalId: number;
  handleGetSecretaries: () => Promise<void>;
  isLoadingSecretaries: boolean;
};

const SecretaryList = ({
  secretaries,
  searchData,
  companyId,
  professionalId,
  handleGetSecretaries,
  isLoadingSecretaries,
}: SecretaryListProps) => {
  const secretaryRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [secretaryToEdit, setSecretaryToEdit] = useState<SecretaryBody>(
    {} as SecretaryBody
  );

  const editSecretary = (secretary: SecretaryBody) => {
    setSecretaryToEdit(secretary);
    setModalShow(true);
  };

  const handleHideModal = () => {
    setModalShow(false);
    setSecretaryToEdit({} as SecretaryBody);
    handleGetSecretaries();
    return;
  };

  const disableSecretary = async (id: number) => {
    try {
      await api.delete(`Professional/${id}/scope/${professionalId}`);

      handleGetSecretaries();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* Secretárias */}
      {/* <div ref={secretaryRef} className="row mb-4">
        <div className="col">
          <h5>Secretarias</h5>
        </div>
        <div className="col col-auto">
          <Button
            type="button"
            className="btn-sm btn-primary"
            onClick={() => setModalShow(true)}
          >
            <i className="uil uil-plus mr-2" />
            Adicionar Secretaria
          </Button>
        </div>
      </div> */}

      {!!isLoadingSecretaries &&
        [...Array(3)].map((_, index) => <ListCardSkeleton key={index} />)}

      {/* Lista de secretarias */}
      {!isLoadingSecretaries && (
        <>
          {!!secretaries.length &&
            secretaries?.map((secretary, key) => (
              <SecretaryCard
                disableSecretary={disableSecretary}
                secretary={secretary}
                setModal={() => editSecretary(secretary)}
                key={key}
              />
            ))}

          {/* Lista de secretarias vazia */}
          {/* {!secretaries.length && (
            <EmptyList
              icon="uil-user"
              title="Nenhuma secretária cadastrada"
              subtitle="Clique abaixo e adicione uma secretária"
              buttonTitle="Adicionar secretária"
              onClickButton={() => setModalShow(true)}
            />
          )} */}

          {companyId === -1 ? (
            <EmptyList
              icon="uil-user-md"
              title="Por favor, selecione uma empresa e um profissional acima"
            />
          ) : professionalId === -1 ? (
            <EmptyList
              icon="uil-user-md"
              title="Por favor, selecione um profissional acima"
            />
          ) : !secretaries.length ? (
            <EmptyList
              icon="uil-user-md"
              title="Nenhuma secretária cadastrada"
            />
          ) : null}
        </>
      )}

      <ModalComponent
        title="Atualizar Secretária"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setSecretaryToEdit({} as SecretaryBody);
        }}
        size="lg"
      >
        <SecretaryEditForm
          secretary={secretaryToEdit}
          onHide={handleHideModal}
        />
      </ModalComponent>
    </>
  );
};

export default SecretaryList;
