import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ComboBox from "components/ComboBox";
import Input from "components/Input";
import RadioInput from "components/RadioInput";
import SelectInput from "components/SelectInput";
import { useTeam } from "providers/Team";
import { BankCode, ITeamMemberForm, ComboBoxOption, TeamMember } from "types";
import { validateBirthDate } from "utils/formValidations";
import {
  handleBirthDateInput,
  handleCpfOrCnpjInput,
  handleDigitInput,
  handleMobilePhoneInput,
} from "utils/handleInput";

interface TeamMemberFormProps {
  onHide: () => void;
  teamMember?: TeamMember;
}

const TeamMemberForm = ({ onHide, teamMember }: TeamMemberFormProps) => {
  const [banks, setBanks] = useState<BankCode[]>([]);
  const { createTeamMember, editTeamMember } = useTeam();

  let bank = {} as ComboBoxOption;

  if (!!teamMember) {
    bank = {
      id: teamMember.bankCode,
      label: `${teamMember.bankCode} - ${teamMember.bankName}`,
    };
  }

  const schema = yup.object().shape({
    id: yup.number().default(teamMember?.id),
    category: yup.number().required("*Selecione uma opção"),
    name: yup.string().required("*Campo obrigatório").trim(),
    cpfOrCnpj: yup.string().required("*Campo obrigatório"),
    corporateName: yup.string().trim(),
    sex: yup.string(),
    socialName: yup.string().trim(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .transform((_, originalValue) =>
        moment(originalValue, "DD/MM/YYYY").format("YYYY-MM-DD")
      )
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    phone: yup.string().required("*Campo obrigatório"),
    bank: yup
      .object()
      .shape({
        label: yup.string().required("*Selecione uma opção"),
        id: yup.string().required("*Selecione uma opção"),
      })
      .nullable()
      .required("*Selecione uma opção"),
    accountType: yup.number().required("*Selecione uma opção"),
    branch: yup.string().required("*Campo obrigatório"),
    branchDigit: yup.string(),
    account: yup.string().required("*Campo obrigatório"),
    accountDigit: yup.string().required("*Campo obrigatório"),
    pixKey: yup.string().required("*Campo obrigatório").trim(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ITeamMemberForm>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = (data: ITeamMemberForm) => {
    console.log(data);
    // if (!!teamMember) {
    //   editTeamMember(data, onHide);
    // } else {
    //   createTeamMember(data, onHide);
    // }
  };

  const getBanks = async () => {
    try {
      const response = await axios.get("/banco_codigo.json");
      setBanks(response.data.banks);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-xl-6">
            <h5 className="mb-4">Dados Pessoais</h5>
            <div className="form-group">
              <SelectInput
                label="Categoria:"
                placeholder="Selecione"
                control={control}
                name="category"
                defaultValue={!!teamMember ? teamMember.category : ""}
                error={errors?.category?.message}
              >
                <MenuItem value={0}>Anestesista</MenuItem>
                <MenuItem value={1}>Auxiliar</MenuItem>
                <MenuItem value={2}>Cirurgião</MenuItem>
                <MenuItem value={3}>Hospital/Clínica</MenuItem>
                <MenuItem value={4}>Instrumentador</MenuItem>
                <MenuItem value={5}>Secretária</MenuItem>
              </SelectInput>
            </div>

            <div className="form-group">
              <Input
                type="text"
                label="Nome Completo:"
                placeholder="Nome completo do membro"
                register={register("name")}
                error={errors?.name?.message}
                defaultValue={teamMember?.name}
              />
            </div>

            <div className="form-group">
              <Input
                type="tel"
                label="CPF / CNPJ:"
                placeholder="CPF ou CNPJ do membro"
                register={register("cpfOrCnpj")}
                error={errors?.cpfOrCnpj?.message}
                onChange={(e) => handleCpfOrCnpjInput(e, setValue, "cpfOrCnpj")}
                defaultValue={teamMember?.cpfOrCnpj}
              />
            </div>

            <div className="form-group">
              <Input
                type="text"
                label="Razão Social:"
                placeholder="Nome Completo do membro"
                register={register("corporateName")}
                error={errors?.corporateName?.message}
                defaultValue={teamMember?.corporateName}
              />
            </div>

            <label htmlFor="select">Sexo:</label>
            <div className="row">
              <div className="col col-auto">
                <RadioInput
                  label="Masculino"
                  value={1}
                  register={register("sex")}
                  defaultChecked={teamMember?.sex === 1}
                />
              </div>
              <div className="col col-auto">
                <RadioInput
                  label="Feminino"
                  value={0}
                  register={register("sex")}
                  defaultChecked={teamMember?.sex === 0}
                />
              </div>
              <div className="col col-auto">
                <RadioInput
                  label="Intersexo"
                  value={2}
                  register={register("sex")}
                  defaultChecked={teamMember?.sex === 2}
                />
              </div>
            </div>
            <div className="text-danger mb-3">
              <small>{!!errors?.sex && "*Selecione uma opção"}</small>
            </div>

            <div className="form-group">
              <Input
                type="texts"
                label="Nome Social:"
                placeholder="Como prefere ser chamado?"
                register={register("socialName")}
                error={errors?.socialName?.message}
                defaultValue={teamMember?.socialName}
              />
            </div>

            <div className="row align-items-end">
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Data de Nascimento:"
                    placeholder="dd/mm/aaaa"
                    register={register("birthDate")}
                    error={errors?.birthDate?.message}
                    onChange={(e) =>
                      handleBirthDateInput(e, setValue, "birthDate")
                    }
                    defaultValue={
                      !!teamMember
                        ? moment(teamMember.birthDate).format("DD/MM/YYYY")
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Input
                    type="tel"
                    label="Telefone:"
                    placeholder="(99) 99999-9999"
                    register={register("phone")}
                    error={errors?.phone?.message}
                    onChange={(e) =>
                      handleMobilePhoneInput(e, setValue, "phone")
                    }
                    defaultValue={teamMember?.phone}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-xl-none">
            <hr className="mt-4 mb-5" />
          </div>
          <div className="col-12 col-xl-6">
            <h5 className="mb-4">Dados Financeiros</h5>
            <div className="row">
              <div className="col-sm-8">
                <div className="form-group">
                  <ComboBox
                    label="Código do Banco:"
                    control={control}
                    name="bank"
                    placeholder="Selecione"
                    searchPlaceholder="Buscar Banco"
                    options={banks}
                    defaultValue={!!teamMember ? bank : undefined}
                    error={errors.bank?.label?.message}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <SelectInput
                    label="Tipo de Conta:"
                    placeholder="Selecione"
                    control={control}
                    name="accountType"
                    defaultValue={!!teamMember ? teamMember.accountType : ""}
                    error={errors?.accountType?.message}
                  >
                    <MenuItem value={0}>Corrente</MenuItem>
                    <MenuItem value={1}>Poupança</MenuItem>
                  </SelectInput>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-8">
                    <div className="form-group">
                      <Input
                        type="tel"
                        label="Agência:"
                        placeholder="Nº da Agência"
                        register={register("branch")}
                        error={errors?.branch?.message}
                        onChange={(e) =>
                          handleDigitInput(e, setValue, "branch")
                        }
                        defaultValue={teamMember?.branch}
                      />
                    </div>
                  </div>

                  <div className="col-4 pl-0">
                    <div className="form-group">
                      <Input
                        type="tel"
                        label="Dígito:"
                        placeholder="Dígito"
                        register={register("branchDigit")}
                        onChange={(e) =>
                          handleDigitInput(e, setValue, "branchDigit")
                        }
                        defaultValue={teamMember?.branchDigit}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-8">
                    <div className="form-group">
                      <Input
                        type="tel"
                        label="Conta:"
                        placeholder="Nº da Conta"
                        register={register("account")}
                        error={errors?.account?.message}
                        onChange={(e) =>
                          handleDigitInput(e, setValue, "account")
                        }
                        defaultValue={teamMember?.account}
                      />
                    </div>
                  </div>

                  <div className="col-4 pl-0">
                    <div className="form-group">
                      <Input
                        type="tel"
                        label="Dígito:"
                        placeholder="Dígito"
                        register={register("accountDigit")}
                        error={errors?.accountDigit?.message}
                        onChange={(e) =>
                          handleDigitInput(e, setValue, "accountDigit")
                        }
                        defaultValue={teamMember?.accountDigit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <Input
                type="text"
                label="Chave PIX:"
                placeholder="Chave PIX da conta"
                register={register("pixKey")}
                error={errors?.pixKey?.message}
                defaultValue={teamMember?.pixKey}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" className="btn-light" onClick={onHide}>
          Cancelar
        </Button>
        <Button type="submit" className="btn-primary">
          <i className="uil uil-check mr-2" />
          Adicionar
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default TeamMemberForm;
