import axios from "axios";

import { BrazilianState } from "types/ibge";

export const brazilianStates = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "RR",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RS",
  "RO",
  "TO",
  "SC",
  "SP",
  "SE",
];

export const getBrazilianStates = async (
  setBrazilianStates: React.Dispatch<React.SetStateAction<BrazilianState[]>>
) => {
  const response = await axios.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
  );

  setBrazilianStates(response.data);
};
