import { ReactElement, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import AlertModal from "components/AlertModal";
import ModalComponent from "components/ModalComponent";
import { useProfessional } from "providers/Professional";
import { api } from "services";
import { ContractStatus, ContractStatusDescription } from "types/enums";
import { Professional } from "types/professional";
import { cpfAndCnpjMask, mobilePhoneMask } from "utils/formMasks";
import { toast } from "utils/toast";

import ProfessionalEditForm from "../Forms/ProfessionalEditForm";

interface ProfessionalCardProps {
  professional: Professional;
  companyId?: number;
  isActive?: boolean;
  showProfessionalEmbeddedContractModal: (userId: number) => void;
}

const renderContractStatusBadge = (status: ContractStatus) => {
  switch (status) {
    case 0:
      return (
        <div className="badge badge-light rounded-circle py-2 fs-5 text-center">
          <i className="uil uil-file-question-alt"></i>
        </div>
      );
    case 1:
      return (
        <div className="badge badge-success rounded-circle py-2 fs-5 text-center">
          <i className="uil uil-file-check-alt"></i>
        </div>
      );
    case 2:
      return (
        <div className="badge badge-danger rounded-circle py-2 fs-5 text-center">
          <i className="uil uil-file-times-alt "></i>
        </div>
      );
    case 3:
      return (
        <div className="badge badge-warning rounded-circle py-2 fs-5 text-center">
          <i className="uil uil-file-search-alt"></i>
        </div>
      );
  }
};

const ProfessionalCard = ({
  professional,
  companyId,
  showProfessionalEmbeddedContractModal,
  isActive = false,
}: ProfessionalCardProps) => {
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [professionalEditModalShow, setProfessionalEditModalShow] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    setInactiveProfessionalsCurrentPage,
    setActiveProfessionalsCurrentPage,
    getInactiveProfessionals,
    getActiveProfessionals,
  } = useProfessional();

  const handleActivateProfessional = async () => {
    setIsLoading(true);

    try {
      await api.patch(
        `/Professional/${professional.id}/${isActive ? "disable" : "enable"}`
      );

      setIsLoading(false);

      toast.fire({
        icon: "success",
        title: `Profissional ${isActive ? "des" : ""}ativado com sucesso!`,
      });

      setInactiveProfessionalsCurrentPage(1);
      setActiveProfessionalsCurrentPage(1);

      getInactiveProfessionals(companyId);
      getActiveProfessionals(companyId);

      setAlertModalShow(false);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const professionalName = professional.socialName
    ? `${professional.socialName} (${professional.name})`
    : professional.name;

  return (
    <>
      <div className={"row-item mb-2 px-3 pt-3 border-left border-4 "+(isActive ? 'border-primary' : 'border-dark')}>
        <div className="row align-items-center">
          <div className="col">
            <div className="row no-gutters">
              <div className="col col-auto d-flex align-items-center flex-column pr-2">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Contrato{" "}
                      {ContractStatusDescription[professional.contractStatus]}
                    </Tooltip>
                  }
                >
                  {
                    renderContractStatusBadge(
                      professional.contractStatus
                    ) as ReactElement
                  }
                </OverlayTrigger>
              </div>
              {/* Nome do profissional */}
              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Profissional</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{professionalName}</Tooltip>}
                >
                  <b className="d-block text-sm-truncate">{professionalName}</b>
                </OverlayTrigger>
              </div>

              {/* CPF/CNPJ do profissional */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">CPF / CNPJ</p>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>{cpfAndCnpjMask(professional.cpf)}</Tooltip>
                  }
                >
                  <b className="d-block text-sm-truncate">
                    {cpfAndCnpjMask(professional.cpf)}
                  </b>
                </OverlayTrigger>
              </div>

              {/* Telefone */}
              <div className="col-12 col-sm-6 col-lg-3 col-xl-2 pb-3 px-1">
                <p className="small opacity-75 mb-0">Telefone</p>
                <b>
                  {mobilePhoneMask(
                    `${professional.dddMobilePhone}${professional.mobilePhone}`
                  )}
                </b>
              </div>

              {/* E-mail */}

              <div className="col-12 col-sm-6 col-xl-2 pb-3 px-1 text-sm-truncate">
                <p className="small opacity-75 mb-0">E-mail</p>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{professional.email}</Tooltip>}
                >
                  <b className="d-block text-sm-truncate">
                    {professional.email}
                  </b>
                </OverlayTrigger>
              </div>

              {/* Endereço do profissional */}
              <div className="col-12 col-lg-6 col-xl-3 pb-3 px-1">
                <p className="small opacity-75 mb-0">Endereço</p>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    !professional.addressCEP ? (
                      <Tooltip>Sem endereço cadastrado</Tooltip>
                    ) : (
                      <Tooltip>
                        {professional.addressRoad}, {professional.addressNumber}{" "}
                        -{" "}
                        {professional.addressComplement &&
                          `${professional.addressComplement},`}{" "}
                        {professional.addressDistrict},{" "}
                        {professional.addressCity} - {professional.addressState}
                      </Tooltip>
                    )
                  }
                >
                  <b className="d-block text-lg-truncate">
                    {!professional.addressCEP && "Sem endereço cadastrado"}

                    {!!professional.addressCEP && (
                      <>
                        {professional.addressRoad}, {professional.addressNumber}{" "}
                        -{" "}
                        {professional.addressComplement &&
                          `${professional.addressComplement},`}{" "}
                        {professional.addressDistrict},{" "}
                        {professional.addressCity} - {professional.addressState}
                      </>
                    )}
                  </b>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          {/* Botão*/}
          <div className="col-12 col-sm-auto pb-3">
            <Dropdown>
              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => setAlertModalShow(true)}
                  className={isActive ? "text-danger" : "text-success"}
                >
                  <i
                    className={`uil uil-${
                      isActive ? "times" : "check"
                    }-circle mr-2`}
                  />
                  {isActive ? "Desativar" : "Ativar"}
                </Dropdown.Item>

                <Dropdown.Item
                  as="button"
                  onClick={() => setProfessionalEditModalShow(true)}
                >
                  <i className="uil uil-edit-alt mr-2" />
                  Editar
                </Dropdown.Item>

                {professional.contractStatus === 1 && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      showProfessionalEmbeddedContractModal(
                        professional.userId
                      );
                    }}
                  >
                    <i className="uil uil-file-alt mr-2" />
                    Ver contrato
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Modal de confirmar ativação/desativação de profssional */}
      <AlertModal
        show={alertModalShow}
        onHide={() => setAlertModalShow(false)}
        title={`Tem certeza que deseja ${
          isActive ? "des" : ""
        }ativar esse profissional?`}
        onClickButton={handleActivateProfessional}
        btnVariant={isActive ? "danger" : "success"}
        isLoading={isLoading}
      />

      <ModalComponent
        title="Editar Profissional"
        show={professionalEditModalShow}
        onHide={() => setProfessionalEditModalShow(false)}
        size="xl"
      >
        <ProfessionalEditForm
          onHide={() => setProfessionalEditModalShow(false)}
          professional={professional}
          companyId={companyId}
        />
      </ModalComponent>
    </>
  );
};

export default ProfessionalCard;
