import { yupResolver } from "@hookform/resolvers/yup";
import { TextareaAutosize } from "@mui/material";
import { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { useUser } from "providers/User";
import { api } from "services";
import { QuoteActions } from "types/enums";
import { ChatQuote, Message } from "types/quotes";
import getInitials from "utils/getInitials";

interface MessageData {
  text: string;
}

enum ContextEnum {
  Clinic = 1,
  Support,
}

interface ChatProps {
  quoteActions: QuoteActions[];
  quoteId: number;
}

export default function Chat({ quoteId, quoteActions }: ChatProps) {
  const [contextType, setContextType] = useState<ContextEnum>(
    ContextEnum.Clinic
  );
  const [clinicMessages, setClinicMessages] = useState<Message[]>([]);
  const [supportMessages, setSupportMessages] = useState<Message[]>([]);

  const { userData } = useUser();

  const chatRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    loadMessages();
  }, []);

  useEffect(() => {
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [clinicMessages, supportMessages, contextType]);

  const schema = yup.object().shape({
    text: yup.string().required("Não é possível enviar uma mensagem vazia"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MessageData>({
    resolver: yupResolver(schema),
  });

  const loadMessages = async () => {
    if (quoteActions.includes(QuoteActions.QuoteChatInstituition)) {
      setContextType(ContextEnum.Clinic);
    } else if (quoteActions.includes(QuoteActions.QuoteChatSuport)) {
      setContextType(ContextEnum.Support);
    }

    const {
      data: { clinicMessage, supportMessage },
    }: AxiosResponse<ChatQuote> = await api.get(`/Chat/quote/${quoteId}`);

    setClinicMessages(clinicMessage);
    setSupportMessages(supportMessage);
  };

  const onSubmit: SubmitHandler<MessageData> = async (data) => {
    if (contextType === ContextEnum.Clinic) {
      setClinicMessages([
        ...clinicMessages,
        {
          text: data.text,
          date: moment().toISOString(),
          author: {
            name: userData.name,
          },
        },
      ]);
    }

    if (contextType === ContextEnum.Support) {
      setSupportMessages([
        ...supportMessages,
        {
          text: data.text,
          date: moment().toISOString(),
          author: {
            name: userData.name,
          },
        },
      ]);
    }

    await api.post("/Chat/message", {
      text: data.text,
      quoteId,
      contextType,
    });

    formRef.current?.reset();
  };

  return (
    <>
      <ul
        className="chat-nav nav nav-tabs"
        style={{
          position: "relative",
          marginTop: "-44px",
          zIndex: 5,
          right: 0,
          float: "right",
          marginRight: "3rem",
        }}
      >
        {quoteActions?.includes(QuoteActions.QuoteChatInstituition) && (
          <li
            className="nav-item"
            onClick={() => setContextType(ContextEnum.Clinic)}
          >
            <span
              id="personal-data"
              className={`nav-link link-primary ${
                contextType === ContextEnum.Clinic ? "active" : "false"
              }`}
            >
              Clínica
            </span>
          </li>
        )}
        {quoteActions?.includes(QuoteActions.QuoteChatSuport) && (
          <li
            className="nav-item"
            onClick={() => setContextType(ContextEnum.Support)}
          >
            <span
              id="access-data"
              className={`nav-link link-primary ${
                contextType === ContextEnum.Support ? "active" : "false"
              }`}
            >
              Suporte
            </span>
          </li>
        )}
      </ul>
      <div className="form-box position-relative px-3 pb-3">
        <div
          className="chat bg-light pt-3 pb-4 px-3 px-sm-4 px-xl-5 mx-n3"
          ref={chatRef}
        >
          {contextType === ContextEnum.Clinic && (
            <>
              {clinicMessages.map((message, index) => {
                if (message.author.name == userData.name) {
                  return (
                    <div className="message pt-3" key={index}>
                      <div className="row justify-content-end">
                        <div className="col col-auto pt-3 pl-0 order-1">
                          <div className="user-image bg-primary">
                            {getInitials(message.author.name)}
                          </div>
                        </div>
                        <div className="col col-sm-10">
                          <div className="content p-3 bg-white">
                            <div className="row g-0">
                              <div className="col pl-0">
                                <h6 className="mb-2">{message.author.name}</h6>
                              </div>
                              <div className="col col-auto px-0 text-right">
                                <small className="text-muted">
                                  {moment(message.date).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </small>
                              </div>
                            </div>
                            <p className="text-gray text-pre line-height">
                              {message.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="message pt-3" key={index}>
                      <div className="row">
                        <div className="col col-auto pt-3 pr-0">
                          <div className="user-image bg-dark">
                            {getInitials(message.author.name)}
                          </div>
                        </div>
                        <div className="col col-sm-10">
                          <div className="content p-3 bg-white">
                            <div className="row g-0">
                              <div className="col pl-0">
                                <h6 className="mb-2">{message.author.name}</h6>
                              </div>
                              <div className="col col-auto px-0 text-right">
                                <small className="text-muted">
                                  {moment(message.date).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </small>
                              </div>
                            </div>
                            <p className="text-gray text-pre line-height">
                              {message.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}
          {contextType === ContextEnum.Support && (
            <>
              {supportMessages.map((message, index) => {
                if (message.author.name === userData.name) {
                  return (
                    <div className="message pt-3" key={index}>
                      <div className="row">
                        <div className="col col-auto pt-3 pl-0 order-1">
                          <div className="user-image bg-primary">
                            {getInitials(message.author.name)}
                          </div>
                        </div>
                        <div className="col col-sm-10">
                          <div className="content p-3 bg-white">
                            <div className="row g-0">
                              <div className="col pl-0">
                                <h6 className="mb-2">{message.author.name}</h6>
                              </div>
                              <div className="col col-auto px-0 text-right">
                                <small className="text-muted">
                                  {moment(message.date).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </small>
                              </div>
                            </div>
                            <p className="text-gray text-pre line-height">
                              {message.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="message pt-3" key={index}>
                      <div className="row justify-content-end">
                        <div className="col col-auto pt-3 pr-0">
                          <div className="user-image bg-dark">
                            {getInitials(message.author.name)}
                          </div>
                        </div>
                        <div className="col col-sm-10">
                          <div className="content p-3 bg-white">
                            <div className="row g-0">
                              <div className="col pl-0">
                                <h6 className="mb-2">{message.author.name}</h6>
                              </div>
                              <div className="col col-auto px-0 text-right">
                                <small className="text-muted">
                                  {moment(message.date).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </small>
                              </div>
                            </div>
                            <p className="text-gray text-pre line-height">
                              {message.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
        {quoteActions?.includes(QuoteActions.InteractQuoteChat) && (
          <div className="pt-3 px-3 px-sm-4 px-xl-5 mx-n3 mx-sm-n4 mx-xl-n5">
            <form
              className="row align-items-end"
              onSubmit={handleSubmit(onSubmit)}
              ref={formRef}
            >
              <div className="col">
                <TextareaAutosize
                  className="form-control"
                  id="texto_mensagem"
                  placeholder="Digite a mensagem para responder..."
                  {...register("text")}
                ></TextareaAutosize>
              </div>
              <div className="col col-auto pl-0">
                <button
                  className="btn btn-primary btn-sm btn-icon fs-6"
                  type="submit"
                  title="Enviar"
                >
                  <i className="uil uil-telegram-alt"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}
