import {FormTypeDescription, PaymentMethodDescription, QuotePaymentStatusDescription, ProcedureDateStatusDescription} from "types/enums";

export const paymentMethodOptions = [
  {
    value: PaymentMethodDescription.Parcelamento,
    label: PaymentMethodDescription[2],
  },
  {
    value: PaymentMethodDescription.Financiamento,
    label: PaymentMethodDescription[3],
  },
];

const creditParcelLimit = 12;
export const creditParcelOptions = [...Array(creditParcelLimit)].map(
  (_, index) => ({
    value: index + 1,
    label: `${index + 1}x`,
  })
);

export const financingParcelOptions = [
  {
    value: 12,
    label: "12x",
  },
  {
    value: 24,
    label: "24x",
  },
  {
    value: 36,
    label: "36x",
  },
  {
    value: 48,
    label: "48x",
  },
];
export interface financingOptionsInterface {
  value: string;
  label: string
}
export const financingAmountOptions: financingOptionsInterface[] = [
  {value: "1.000,00", label: "1.000,00"},
  {value: "1.500,00", label: "1.500,00"},
  {value: "2.000,00", label: "2.000,00"},
  {value: "2.500,00", label: "2.500,00"},
  {value: "3.000,00", label: "3.000,00"},
  {value: "3.500,00", label: "3.500,00"},
  {value: "4.000,00", label: "4.000,00"},
  {value: "4.500,00", label: "4.500,00"},
  {value: "5.000,00", label: "5.000,00"},
  {value: "6.000,00", label: "6.000,00"},
  {value: "7.000,00", label: "7.000,00"},
  {value: "8.000,00", label: "8.000,00"},
  {value: "9.000,00", label: "9.000,00"},
  {value: "10.000,00", label: "10.000,00"},
  {value: "11.000,00", label: "11.000,00"},
  {value: "12.000,00", label: "12.000,00"},
  {value: "13.000,00", label: "13.000,00"},
  {value: "14.000,00", label: "14.000,00"},
  {value: "15.000,00", label: "15.000,00"},
  {value: "16.000,00", label: "16.000,00"},
  {value: "17.000,00", label: "17.000,00"},
  {value: "18.000,00", label: "18.000,00"},
  {value: "19.000,00", label: "19.000,00"},
  {value: "20.000,00", label: "20.000,00"},
  {value: "21.000,00", label: "21.000,00"},
  {value: "22.000,00", label: "22.000,00"},
  {value: "23.000,00", label: "23.000,00"},
  {value: "24.000,00", label: "24.000,00"},
  {value: "25.000,00", label: "25.000,00"},
];

export const dateStatusOptions = [
  {value: 0, label: ProcedureDateStatusDescription[0]},
  {value: 1, label: ProcedureDateStatusDescription[1]},
  {value: 2, label: ProcedureDateStatusDescription[2]},
];

export const quotePaymentStatusOptions = [
  {value: 0, label: QuotePaymentStatusDescription[0]},
  {value: 1, label: QuotePaymentStatusDescription[1]},
];

export interface formTypeOptionsInterface {
  value: FormTypeDescription,
  label: string
}

export const formTypeOptions: formTypeOptionsInterface[] = [
  {
    value: FormTypeDescription["Financiamento Avalista"],
    label: FormTypeDescription[1],
  },
  {
    value: FormTypeDescription["Financiamento Solicitante"],
    label: FormTypeDescription[2],
  },
];

export const defaultFormTypeOption: formTypeOptionsInterface =
{
  value: FormTypeDescription["Financiamento Avalista"],
  label: FormTypeDescription[1],
}
