import { Step, StepLabel, Stepper } from "@mui/material";
import { Modal } from "react-bootstrap";

interface ComponentStepperProps {
  activeStep: number;
  steps: { label: string; component: any }[];
}

const ModalBodyComponentStepper = ({
  activeStep,
  steps,
}: ComponentStepperProps) => {
  return (
    <>
      <Modal.Body className="pb-0">
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            const stepProps: { completed?: boolean } = {};

            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Modal.Body>

      {steps[activeStep].component}
    </>
  );
};

export default ModalBodyComponentStepper;
