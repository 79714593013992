import { useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

import ModalComponent from "components/ModalComponent";
import { useBankAccount } from "providers/BankAccount";
import { BankAccount } from "types";

import BankAccountForm from "../BankAccountForm";

interface BankAccountProps {
  bankAccount: BankAccount;
}

const BankAccountCard = ({ bankAccount }: BankAccountProps) => {
  const [modalShow, setModalShow] = useState(false);
  const { removeBankAccount } = useBankAccount();

  return (
    <>
      <div className="row-item mb-2 px-3 pt-3">
        <div className="row align-items-center">
          {/* Informações da conta bancária */}
          <div className="col">
            <div className="row no-gutters">
              {/* Nome do titular */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">Titular</p>
                <b className="pe-2">{bankAccount.holder}</b>
              </div>

              {/* Nome do banco */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">Banco</p>
                <b>{bankAccount.bankName}</b>
              </div>

              {/* Agência e Conta */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">Agência/Conta</p>
                <b>{bankAccount.branch}</b> |{" "}
                <b>{`${bankAccount.account}-${bankAccount.accountDigit}`}</b>
              </div>

              {/* Chave PIX */}
              <div className="col-12 col-sm-6 col-xl-3 pb-3">
                <p className="small opacity-75 mb-0">PIX</p>
                <b>{bankAccount.pixKey}</b>
              </div>
            </div>
          </div>

          {/* Botão "Editar" */}
          <div className="col col-auto pb-3">
            <Dropdown as={ButtonGroup}>
              <Button
                variant="light"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                Editar
              </Button>

              <Dropdown.Toggle
                split
                variant="light"
                id="dropdown-split-basic"
                size="sm"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  className="text-danger"
                  onClick={() => removeBankAccount(bankAccount.id)}
                >
                  <i className="uil uil-trash mr-2" />
                  Excluir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <ModalComponent
        title="Editar Conta Bancária"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <BankAccountForm
          onHide={() => setModalShow(false)}
          bankAccount={bankAccount}
        />
      </ModalComponent>
    </>
  );
};

export default BankAccountCard;
