const GeneralTerms = () => {
  return (
    <div className="modal-body text-body">
      <div
        style={{ maxHeight: "50vh", overflowY: "auto" }}
        className="p-3 border rounded"
      >
        <h2>Bem-vindo à Docttorpay!</h2>
        <p>
          Somos gratos por usar nossos produtos e serviços (“Serviços
          Docttorpay”). Os Serviços serão fornecidos pela Docttorpay, empresa
          brasileira, com sede em Curitiba no Paraná. Oferecemos diversos tipos
          de Serviços, portanto, às vezes, podem-se aplicar a contratos e termos
          adicionais. Estes Termos Adicionais estarão disponíveis com os
          Serviços relevantes e esses Termos Adicionais se tornarão parte de
          nosso contrato com você, caso você use estes Serviços. Recomendamos a
          você a ler atentamente este contrato e os Termos Adicionais antes de
          utilizar nossos Serviços.
        </p>
        <h3>Aceitação</h3>
        <p>
          Para utilizar os Serviços Docttorpay, o Usuário Profissional deverá
          primeiro concordar com o presente Contrato. Não é permitido o uso dos
          Serviços por parte do usuário se ele não aceitar as condições do
          presente documento.
        </p>
        <p>Para aceitar o Contrato o usuário deverá:</p>
        <ul>
          <li className="list-style-type-l-alpha">
            Clicar para criar uma conta, aceitar ou concordar, no caso de essa
            opção ser disponibilizada pela DOCTTORPAY na interface do usuário
            dos Serviços, Instituição usuária de Serviços ou como usuário
            Paciente, abaixo tratados somente por “usuário”; ou
          </li>
          <li className="list-style-type-l-alpha">
            Utilizar efetivamente os Serviços. Nesse caso, o usuário compreende
            e aceita que a DOCTTORPAY considerará o seu uso dos Serviços como
            uma aceitação do Termo de Uso e Contratos a partir desse momento.
          </li>
        </ul>
        <br />
        <h5>
          CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE GESTÃO DE PAGAMENTO E OUTRAS
          AVENÇAS (DISPOSIÇÕES APLICADAS AO USUÁRIO PROFISSIONAL)
        </h5>
        <p>
          <strong>CONTRATADA: DOCTTORPAY COBRANÇA LTDA.</strong>, pessoa
          jurídica de direito privado, inscrita no CNPJ nº 43.921.058/0001-80,
          com sede na Av. João Gualberto, nº 1342, conj. 1105, Alto da Glória,
          CEP 80030-001, Curitiba/PR, doravante denominada DOCTTORPAY;
        </p>
        <p>
          <strong>CONTRATANTE:</strong> PESSOA FÍSICA OU JURÍDICA que preencheu
          e enviou à DOCTTORPAY o formulário de identificação e qualificação, em
          conformidade com as boas práticas de mercado de qualificação de
          clientes e legislação brasileira pertinente ao negócio, fornecido pela
          DOCTTORPAY por meio da Internet, concordando com o presente Contrato
          de Prestação de Serviços e Outras Avenças, doravante denominada
          simplesmente “Usuário Profissional”.
        </p>
        <p>
          As pessoas jurídicas acima qualificadas celebram o presente Contrato
          de Prestação de Serviços, que passa a viger com base nas seguintes
          cláusulas e condições.
        </p>
        <h6>I – DAS DEFINIÇÕES</h6>
        <p>
          <strong>CLÁUSULA PRIMEIRA:</strong> As seguintes palavras e
          expressões, quando iniciadas por maiúscula neste Contrato, terão os
          seguintes significados:
        </p>
        <p>
          a) Usuário Profissional: pessoa física ou jurídica que é o responsável
          técnico pela realização do procedimento de saúde e/ou venda de
          produtos relacionados, adiante denominado “UPRO”.
        </p>
        <p>
          b) Usuário Paciente: pessoa física que deseje adquirir serviços e/ou
          produtos ofertados pelo Usuário Profissional, utilizando-se dos
          serviços de intermediação financeira da DOCTTORPAY, doravante
          denominado “UPAC”.
        </p>
        <p>
          c) Serviço Docttorpay: refere-se à prestação de serviços de
          intermediação financeira entre Usuário Profissional e Usuário
          Paciente, mediante a oferta de parcelamentos, financiamentos,
          consórcios ou outros produtos que venham a ser disponibilizados, por
          meios próprios ou por Instituições Financeiras Parceiras, sem qualquer
          relação com a prestação de serviços de saúde e/ou produtos
          relacionados.
        </p>
        <p>
          d) Operação: cada procedimento de saúde e/ou venda de produtos
          relacionados.
        </p>
        <p>
          e) Data: referente à data de realização do procedimento de saúde,
          início do tratamento e/ou data de retirada do produto relacionado.
          Data confirmada: dia confirmado pelo UPRO para a realização do
          procedimento de saúde. Data estimada: dia inicialmente previsto no
          orçamento para a realização do procedimento de saúde (esta data pode
          ser alterada conforme o tempo necessário para a liberação do recurso).
        </p>
        <p>
          f) Split: distribuição de valores fracionados, de acordo com a
          responsabilidade técnica e tributária de cada parte da operação.
        </p>
        <h6>II – DO OBJETO</h6>
        <p>
          <strong>CLÁUSULA PRIMEIRA:</strong> A DOCTTORPAY, por si própria ou
          por intermédio de empresa por ela contratada ou com ela associada,
          pela qual se responsabiliza, colocará à disposição do “UPAC” um
          serviço de concierge, através de meios digitais disponíveis,
          recepcionará o orçamento realizado pelo “UPRO” e, após o levantamento
          de informações financeiras, fará junto com “UPAC” a definição do meio
          mais adequado de pagamento a ser utilizado em cada operação.
        </p>
        <p>
          <strong>CLÁUSULA SEGUNDA:</strong> Após a definição do meio de
          pagamento, a DOCTTORPAY se responsabiliza pelo acompanhamento da
          finalização do processo de pagamento e liberação dos recursos
          financeiros, bem como o repasse ao “UPRO”, de acordo com os splits
          (distribuição de valores) definidos previamente no orçamento.
        </p>
        <p>
          <strong>Parágrafo único.</strong> O “UPRO” declara-se ciente de que o
          Serviço Docttorpay não é e nem se destina a ser comparável aos
          serviços financeiros oferecidos por instituições bancárias ou
          administradoras de cartões de crédito, consistindo apenas em uma forma
          de facilitação e acompanhamento da realização do pagamento entre o
          “UPAC” e o “UPRO”, mediante serviços de gestão de pagamentos.
        </p>
        <h6>III – DAS CONDIÇÕES DA PRESTAÇÃO DE SERVIÇO</h6>
        <p>
          <strong>CLÁUSULA TERCEIRA:</strong> O “UPRO” deverá inserir na
          plataforma todos os dados solicitados a respeito do orçamento, tais
          como, mas não se limitando, nome do procedimento, data prevista para
          realização, indicação dos profissionais envolvidos e respectivos
          valores devidos (splits), entre outros.
        </p>
        <p>
          <strong>Parágrafo primeiro.</strong> O “UPRO” fica ciente de que é o
          responsável pelos dados inseridos na plataforma, isentando a
          DOCTTORPAY de qualquer responsabilidade quanto aos valores indicados,
          divisão dos honorários médicos, utilização de recursos como materiais,
          produtos hospitalares, especificação técnica do produto vendido,
          despesas acessórias e/ou adicionais não inclusas no orçamento.
        </p>
        <p>
          <strong>CLÁUSULA QUARTA:</strong> Caso parte do orçamento tenha que
          ser quitada em espécie pelo “UPAC”, o “UPRO” fica ciente de que deverá
          indicar o respectivo valor no campo próprio do formulário, de modo a
          possibilitar a redução do valor total do orçamento a ser tramitado
          pela DOCTTORPAY.
        </p>
        <p>
          <strong>Parágrafo Único.</strong> O “UPRO” fica ciente de que o valor
          a ser pago em espécie pelo “UPAC” (moeda corrente nacional) não poderá
          integrar o orçamento a ser cadastrado na plataforma, devendo ser
          diretamente negociado e pago ao “UPRO” pelo “UPAC”.
        </p>
        <p>
          <strong>CLÁUSULA QUINTA:</strong> O “UPRO” declara que para todos os
          fins, direitos e obrigações consignadas no presente instrumento,
          responde por eventuais deveres e obrigações dos profissionais
          indicados no orçamento.
        </p>
        <p>
          <strong>CLÁUSULA SEXTA:</strong> A DOCTTORPAY liberará os recursos
          para os prestadores indicados no orçamento, conforme:
        </p>
        <ul>
          <li className="list-style-type-l-alpha">
            Procedimento de saúde: o pagamento será liberado com um dia útil de
            antecedência da Data Confirmada para a realização do procedimento.
          </li>
          <li className="list-style-type-l-alpha">
            Tratamentos de saúde: o pagamento será liberado com um dia útil de
            antecedência da Data Confirmada para o início do tratamento.
          </li>
          <li className="list-style-type-l-alpha">
            Venda de produtos relacionados: o pagamento será liberado com um dia
            útil de antecedência da Data Confirmada para a entrega do produto.
          </li>
        </ul>
        <p>
          <strong>CLÁUSULA SÉTIMA:</strong> A DOCTTORPAY fará a tramitação do
          processo considerando a Data Prevista indicada no orçamento pelo UPRO.
          Caso a finalização da liberação dos recursos à DOCTTORPAY não ocorra
          em até 5 (cinco) dias úteis de antecedência da Data Confirmada, deverá
          ser reagendada nova data para realização do procedimento de saúde e/ou
          entrega do produto.
        </p>
        <p>
          <strong>CLÁUSULA OITIVA:</strong> Na hipótese de adiamento e/ou
          cancelamento do procedimento cirúrgico e/ou venda de produtos
          relacionados, serão adotadas as seguintes providências:
        </p>
        <p>
          I – Acaso ocorra o adiamento após a contratação do meio de pagamento
          (pagamento do link do cartão de crédito, PIX, boleto, entre outros),
          mas antes do repasse do recurso ao “UPRO” e profissionais indicados, o
          procedimento deverá ser reagendado em até 48 (quarenta e oito horas)
          junto ao “UPRO”, devendo ser realizado em até 30 (trinta dias),
          passíveis de prorrogação por igual período, ou de acordo com a
          liberalidade das partes. Neste caso, o recurso permanecerá com a
          DOCTTORPAY, no aguardo da próxima Data Definida a ser reagendada.
        </p>
        <p>
          II – Acaso ocorra o cancelamento do procedimento e/ou venda de produto
          relacionado, após o repasse dos valores aos “UPRO” e aos profissionais
          indicados no orçamento:
        </p>
        <ul>
          <li className="list-style-type-l-alpha">
            Por requerimento do “UPRO”: será cancelada a operação, cabendo a
            restituição da integralidade da quantia paga pelo UPAC (representada
            pelo valor total negociado através da DOCTTORPAY, no qual estão
            embutidas as tarifas bancárias e a remuneração da DOCTTORPAY).
            <br />
            Neste caso, o “UPRO” deverá realizar a restituição diretamente ao
            “UPAC”, na conta bancária indicada, no prazo de até 5 (cinco) dias
            úteis, devendo enviar o respectivo comprovante de transferência
            bancária para a DOCTTORPAY via plataforma.
          </li>
          <li className="list-style-type-l-alpha">
            Por requerimento do “UPAC”: será cancelada a operação, cabendo a
            restituição ao UPAC da quantia recebida pelo UPRO e profissionais
            indicados (representada pelo valor original do orçamento).
            <br />
            Neste caso, o UPRO deverá realizar a restituição diretamente ao
            UPAC, na conta bancária indicada, no prazo de até 20 (vinte) dias
            úteis, devendo enviar o respectivo comprovante de transferência
            bancária para a DOCTTORPAY, via orçamento.
          </li>
        </ul>
        <p>
          <strong>CLÁUSULA NONA:</strong> O UPRO declara que está devidamente
          cadastrado nos órgãos competentes, bem como, os demais prestadores
          indicados no orçamento.
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA:</strong> No prazo de até 5 (cinco) dias após
          a realização do procedimento cirúrgico, o UPRO deverá inserir no
          sistema os documentos fiscais de cada prestador indicado no orçamento,
          de modo a amparar a transferência financeira realizada pela DOCTTORPAY
          através dos splits.
        </p>
        <p>
          <strong>Parágrafo Primeiro.</strong> Acaso não seja promovida a
          inserção no sistema dos documentos fiscais mencionados na cláusula
          acima, o UPRO declara que está ciente de que permanecerá responsável
          pela emissão e entrega dos referidos documentos ao UPAC, o qual, por
          sua vez, poderá adotar as medidas cabíveis em face do UPRO.
        </p>
        <p>
          <strong>Parágrafo Segundo:</strong> A DOCTTTORPAY não se
          responsabiliza pela emissão dos documentos fiscais correspondentes e
          recolhimento dos tributos incidentes sobre os serviços de saúde e/ou
          produtos relacionados ofertados pelo UPRO.
        </p>
        <h6>IV – DOS DEVEDORES E OBRIGAÇÕES DO USUÁRIO PROFISSIONAL</h6>
        <p>
          <strong>CLÁUSULA DÉCIMA PRIMEIRA:</strong> O Usuário Profissional
          responsabiliza-se por preencher o formulário/inserir no sistema as
          informações corretas acerca do orçamento do procedimento a ser
          realizado, indicando detalhadamente o valor destinado a cada um dos
          profissionais envolvidos (Centro Cirúrgico, Instrumentador,
          Anestesista etc.).
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA SEGUNDA:</strong> O “UPRO” compromete-se a
          prestar serviços cuja realização possa garantir, observada a data
          previamente agendada, bem como, as disposições legais e regulamentares
          aplicáveis à atividade desenvolvida, em especial as referentes à
          proteção do UPAC, tais como, mas não se limitando à adequação das
          informações referentes aos serviços e produtos ofertados; adequação
          dos produtos ou serviços ofertados aos fins a que se destinam, sendo
          vedada publicidade enganosa ou abusiva, assumindo a responsabilidade
          integral pelos riscos, pelo potencial nocividade ou periculosidade,
          pelos defeitos, pelos vícios de qualidade e pelos vícios de quantidade
          dos produtos ou serviços ofertados;
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA TERCEIRA:</strong> É de responsabilidade do
          usuário profissional o cumprimento dos prazos de fornecimento e
          garantia dos serviços e produtos ofertados, sobretudo no que diz
          respeito à data previamente agendada para realização do(s)
          procedimento(s) e o quanto segue:
        </p>
        <ul>
          <li className="list-style-type-l-alpha">
            Informar o “UPAC” a existência de riscos relativos aos produtos e
            serviços, em especial quanto a complicações oriundas de atos
            cirúrgicos ou procedimentos de saúde, bem como os riscos envolvidos;
          </li>
          <li className="list-style-type-l-alpha">
            Evitar a prática de publicidade enganosa ou abusiva, bem como
            práticas comerciais coercitivas, desleais ou abusivas praticadas
            contra consumidores;
          </li>
        </ul>
        <p>
          <strong>CÁUSULA DÉCIMA QUARTA:</strong> O “UPRO” poderá delegar a quem
          lhe interessar a gestão de seus orçamentos na plataforma, através de
          autorização prévia e expressa no sistema, para a criação de perfil
          próprio para esse usuário vinculado, cabendo ao “UPRO” a
          responsabilidade pelos atos realizados.
        </p>
        <h6>V – DOS DEVEDORES E OBRIGAÇÕES DA DOCTTORPAY</h6>
        <p>
          <strong>CLÁUSULA DÉCIMA QUINTA:</strong> A DOCTTORPAY compromete-se a
          efetuar o pagamento ao “UPRO” e aos demais profissionais indicados no
          orçamento, conforme splits, cumprindo os prazos ajustados.
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA SEXTA:</strong> A DOCTTORPAY se responsabiliza
          por prestar o serviço de concierge ao “UPAC”, verificando a melhor
          possibilidade de liberação financeira.
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA SÉTIMA:</strong> A DOCTTORPAY declara que
          dados cadastrais e informações sobre os serviços ou produtos serão
          compartilhados entre as partes, respeitando a privacidade dos usuários
          bem como as normativas da LGPD.
        </p>
        <p>
          <strong>CLÁUSULA DÉCIMA OITAVA:</strong> A DOCTTORPAY não se
          responsabiliza pelos produtos e serviços comercializados, ofertados e
          prestados pelos profissionais, de forma que não poderá ser considerada
          como fornecedora ou parte na cadeia de fornecimento de tais produtos e
          serviços, não tendo nenhuma responsabilidade quanto à emissão de
          documentos fiscais e recolhimento dos tributos dos serviços e/ou
          produtos ofertados.
        </p>
        <h6>
          VI – DA RESPONSABILIDADE RELACIONADA A COLABORADORES E TERCEIROS
        </h6>
        <p>
          <strong>CLÁUSULA DÉCIMA NONA:</strong> O “UPRO” é única e
          exclusivamente responsável pelas obrigações junto aos seus
          colaboradores, o que inclui, mas não se limita, ao pagamento de
          salários, demais verbas trabalhistas e contribuições previdenciárias,
          inexistindo vínculo de quaisquer naturezas (trabalhista, societária
          etc.) entre a DOCTTORPAY e o “UPRO”, salvo aquela regulamentada pelo
          presente contrato.
        </p>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA:</strong> Na eventualidade de a DOCTTORPAY
          vir a ser incluída no polo passivo de ação judicial movida por
          empregado e/ou colaborador do “UPRO”, fica desde logo ajustado que o
          “UPRO” deverá demandar, em contestação ou defesa, a exclusão da lide
          da DOCTTORPAY, sob pena de pagamento de multa equivalente ao valor
          pleiteado na ação judicial.
        </p>
        <p>
          <strong>Parágrafo Primeiro:</strong> Ainda, na hipótese de inclusão da
          DOCTTORPAY no polo passivo de ação judicial, fica desde logo
          autorizada a realização de retenção de pagamentos relativos a
          eventuais valores devidos ao Usuário Profissional. A retenção poderá
          ser realizada já no momento da ciência da existência da ação judicial,
          independendo, portanto, de efetiva condenação.
        </p>
        <p>
          <strong>Parágrafo Segundo:</strong> Caso a DOCTTORPAY venha a obter
          êxito, com decisão transitada em julgado, em sua exclusão do polo
          passivo da ação judicial, serão descontados dos valores retidos os
          custos da atuação judicial (honorários, custas processuais, custas
          periciais, dentre outros), com posterior pagamento ao Usuário
          Profissional conforme critérios a serem definidos pela DOCTTORPAY,
          conforme sua disponibilidade.
        </p>
        <h6>VII – PRAZO DE VIGÊNCIA</h6>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA PRIMEIRA:</strong> O presente contrato
          vigerá por prazo indeterminado.
        </p>
        <h6>
          VIII – DA INEXISTÊNCIA DE EXCLUSIVIDADE TERRITORIAL E DE CONTRATAÇÃO
        </h6>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA SEGUNDA:</strong> As partes ajustam que o
          presente contrato não contempla nenhuma modalidade de exclusividade
          territorial e de contratação, de modo que:
        </p>
        <p>
          a) A DOCTTORPAY poderá, a seu livre critério, promover a prestação de
          serviço para empresas distintas, inclusive nas áreas territoriais de
          atuação do “UPRO”.
        </p>
        <p>
          b) O “UPRO” poderá firmar contratos similares com outras empresas, a
          seu livre critério.
        </p>
        <h6>VIII – HIPÓTESES DE RESILIÇÃO E RESOLUÇÃO CONTRATUAL</h6>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA TERCEIRA:</strong> O presente contrato
          poderá ser resilido unilateralmente, por qualquer uma das partes, sem
          a incidência de multa e/ou indenização, mediante aviso-prévio por
          escrito com antecedência mínima de 5 (dias) úteis, findo os quais, o
          Cadastro do “UPRO” será inativado na base de dados da DOCTTORPAY,
          desde que não haja orçamento em andamento.
        </p>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA QUARTA:</strong> O presente contrato
          poderá, ainda, ser dado por resolvido em caso de ocorrência de alguma
          das hipóteses listadas a seguir:
        </p>
        <ul>
          <li className="list-style-type-l-roman">
            Recuperação judicial ou falência de qualquer uma das partes;
          </li>
          <li className="list-style-type-l-roman">
            Descumprimento, por parte do UPRO, das obrigações previstas no
            presente instrumento.
          </li>
        </ul>
        <h6>IX – DISPOSIÇÕES GERAIS</h6>
        <p>
          <strong>CLÁUSULA VIGÉSSIMA QUINTA:</strong> Fica eleito o Foro Central
          da Comarca da Região Metropolitana de Curitiba para dirimir eventuais
          controvérsias oriundas do presente instrumento.
        </p>
        <p>
          E por estarem integralmente de acordo com as cláusulas e disposições
          acima, assinam o presente instrumento em 2 (duas) vias de igual teor e
          forma, na presença de 2 (duas) testemunhas.
        </p>
        <p>Curitiba, 11 de agosto de 2022.</p>
      </div>
    </div>
  );
};

export default GeneralTerms;
