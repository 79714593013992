import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import logoLight from "assets/logo-docttorpay-horizontal.svg";
import logoDark from "assets/logo-docttorpay-white-horizontal.svg";
import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import { RoleDescription } from "types/enums";

import DropdownMenu from "./components/DropdownMenu";
import NotificationAlert from "./components/NotificationAlert";
import OffCanvasMenu from "./components/OffCanvasMenu";

const Header = () => {
  const { token, userRole } = useAuth();
  const { pathname } = useLocation();
  const { darkMode } = useDarkMode();

  return (
    <div>
      <nav className="site-nav">
        <div className="sticky-nav">
          <div className="container position-relative">
            <div className="site-navigation text-center">
              <div className="row align-items-center">
                <div className={`pe-0 col ${token && "col-auto"}`}>
                  <Link
                    to="/"
                    className={`logo m-0 ${!token && "logo-center"}`}
                  >
                    {!darkMode ? (
                      <img src={logoLight} alt="Logo DocttorPay" />
                    ) : (
                      <img src={logoDark} alt="Logo DocttorPay" />
                    )}
                  </Link>
                </div>

                {/* Opções de navegação não exibidas enquanto suas respectivas páginas não estão prontas */}
                {token && (
                  <>
                    <div className={
                      userRole === RoleDescription.Admin
                        ? "col text-right d-none d-xxl-block"
                        : "col text-right d-none d-lg-block"
                    }>
                      <ul className={
                        userRole === RoleDescription.Admin
                          ? "js-clone-nav d-none d-xxl-inline-block site-menu pl-0"
                          : "js-clone-nav d-none d-lg-inline-block site-menu pl-0"
                      }>
                        {/* <li
                          className={
                            pathname.includes("/agenda") ? "active" : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link
                              to="/agenda"
                              style={{ pointerEvents: "none" }}
                              id="agenda"
                            >
                              Agenda
                            </Link>
                          )}
                        </li> */}

                        {/* <li
                          className={
                            pathname.includes("/crm") ? "active" : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link
                              to="/crm"
                              style={{ pointerEvents: "none" }}
                              id="crm"
                            >
                              CRM
                            </Link>
                          )}
                        </li> */}

                        <li
                          className={
                            pathname.includes("/orcamentos-profissional") ||
                            pathname.includes("/orcamentos-paciente")
                              ? "active"
                              : undefined
                          }
                        >
                          <Link
                            to={
                              userRole === RoleDescription.Patient
                                ? "/orcamentos-paciente"
                                : "/orcamentos-profissional"
                            }
                            id="orcamentos"
                          >
                            Orçamentos
                          </Link>
                        </li>

                        {/* <li
                          className={
                            pathname.includes("/financeiro")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link
                              to="/financeiro"
                              style={{ pointerEvents: "none" }}
                              id="financeiro"
                            >
                              Financeiro
                            </Link>
                          )}
                        </li> */}

                        {/* <li
                          className={
                            pathname.includes("/instituicoes")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link to="/instituicoes" id="instituicoes">
                              Instituições
                            </Link>
                          )}
                        </li> */}

                        {/* <li
                          className={
                            pathname.includes("/pacientes")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole === RoleDescription.Professional && (
                            <Link to="/pacientes" id="pacientes">
                              Pacientes
                            </Link>
                          )}
                        </li> */}

                        <li
                          className={
                            pathname.includes("/profissionais")
                              ? "active"
                              : undefined
                          }
                        >
                          {/* TODO: verificar se secretária pode visualizar aba profissionais */}
                          {userRole === RoleDescription.Admin && (
                            <Link to="/profissionais" id="profissionais">
                              Profissionais
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/secretarias")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole === RoleDescription.Admin && (
                            <Link to="/secretarias" id="secretarias">
                              Secretárias
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/instituicoes")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole === RoleDescription.Admin && (
                            <Link to="/instituicoes" id="instituicoes">
                              Instituições
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/pacientes")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link to="/pacientes" id="pacientes">
                              Pacientes
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/usuarios")
                              ? "active"
                              : undefined
                          }
                        >
                          {userRole === RoleDescription.Admin && (
                            <Link to="/usuarios" id="usuarios">
                              Usuários
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/agenda") ? "active" : undefined
                          }
                        >
                          {userRole !== RoleDescription.Patient && (
                            <Link to="/agenda" id="agenda">
                              Calendário
                            </Link>
                          )}
                        </li>
                        <li
                          className={
                            pathname.includes("/relatorios")
                              ? "active"
                              : undefined
                          }
                        >
                          <Link to="/relatorios" id="relatorios">
                            Relatórios
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* {userRole === RoleDescription.Admin && (
                      <div className="col col-auto pl-0">
                        <Dropdown>
                          <Dropdown.Toggle className="btn-sm btn-link text-white py-1 px-2 btn-icon dropdown-toggle no-arrow">
                            <i className="uil uil-bell fs-4"></i>
                            <span className="badge badge-danger badge-notification">2</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{"width": "300px", "maxWidth": "90vw"}}>
                            <Dropdown.Header>
                              <div className="row">
                                <div className="col">
                                Notificações
                                </div>
                                <div className="col col-auto">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Configurações</Tooltip>}
                                  >
                                    <a className="btn btn-icon btn-light btn-sm fs-6 mt-n2"><i className="uil uil-cog"></i></a>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </Dropdown.Header>

                            <Dropdown.Item as="a" className="border-top py-2 lh-sm text-wrap">
                              <div className="row justify-content-between align-items-end">
                                <div className="col">
                                  <b>Wagner Langer</b>
                                </div>
                                <div className="col col-auto">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>23 outubro 2022 15:45:00</Tooltip>}
                                  >
                                    <span className="opacity-50 small">a 5 minutos</span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <small>acabou de responder sua mensagem referente ao orçamento <b>#156</b>.</small>
                            </Dropdown.Item>

                            <Dropdown.Item as="a" className="border-top py-2 lh-sm text-wrap">
                              <div className="row justify-content-between align-items-end">
                                <div className="col">
                                  <b>Wagner Langer</b>
                                </div>
                                <div className="col col-auto">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>20 outubro 2020 19:12:00</Tooltip>}
                                  >
                                    <span className="opacity-50 small">20 out.2020</span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <small>acabou de responder sua mensagem referente ao orçamento <b>#156</b>.</small>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <a className="text-white"></a>
                      </div>
                    )} */}

                    <NotificationAlert />

                    <DropdownMenu />

                    <OffCanvasMenu />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
