import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import { useUser } from "providers/User";

const EditProfessionalDataForm = () => {
  const { userData, editUserData, isLoading } = useUser();

  const schema = yup.object().shape({
    professionalRegister: yup.string(),
    rqe: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ professionalRegister: string; rqe: string }>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: { professionalRegister: string; rqe: string }) => {
    editUserData(data);
  };

  return (
    <ContainerAnimation>
      <div className="tab-pane fade active show" id="dados-profissionais">
        <form
          className="form-box p-3 p-sm-4 p-xl-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h5 className="mb-4">Dados Profissionais</h5>
          <div className="row align-items-end">
            <div className="col-md-6">
              <div className="form-group">
                <Input
                  label="Registro Profissional"
                  placeholder="12345"
                  type="text"
                  register={register("professionalRegister")}
                  error={errors?.professionalRegister?.message}
                  defaultValue={userData.professionalRegister}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Input
                  label="Especialidade"
                  placeholder="12345"
                  type="tel"
                  register={register("rqe")}
                  error={errors?.rqe?.message}
                  defaultValue={userData.rqe}
                />
              </div>
            </div>
          </div>

          <div className="text-center pt-4">
            <Button type="submit" className="btn-primary" disabled={isLoading}>
              Salvar
              {!!isLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </ContainerAnimation>
  );
};

export default EditProfessionalDataForm;
