import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import GeneralTerms from "components/GeneralTerms";
import { useAuth } from "providers/Auth";
import { api } from "services";
import { toast } from "utils/toast";

interface TermsOfUseAcceptData {
  isAccepted?: boolean;
}

interface PatientUnconfirmedTermsProps {
  setTermsModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNewPasswordModalShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const PatientUnconfirmedTerms = (props: PatientUnconfirmedTermsProps) => {
  const { setHasTerms } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    isAccepted: yup
      .boolean()
      .test(
        "termsValidator",
        "*É necessário concordar com os termos para prosseguir.",
        (value) => !!value
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TermsOfUseAcceptData>({ resolver: yupResolver(schema) });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await api.patch("/Auth/RegisterTermsOfUse");

      setIsLoading(false);

      sessionStorage.setItem(
        "@docttorpay:hasTermsOfUse",
        JSON.stringify(response.data.hasTermsOfUse)
      );

      setHasTerms(response.data.hasTermsOfUse);

      props.setTermsModalShow(false);
      props.setNewPasswordModalShow(true);
    } catch (error) {
      setIsLoading(false);

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const handleTermsOfUseInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setValue("isAccepted", true);
    } else {
      setValue("isAccepted", false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body text-body">
        <GeneralTerms />

        <div className="form-group px-3">
          <label
            htmlFor="acceptTerms"
            className="control control--checkbox mb-0"
          >
            <span className="caption">Li e concordo com os Termos Gerais.</span>
            <input
              id="acceptTerms"
              type="checkbox"
              {...register("isAccepted")}
              onChange={handleTermsOfUseInput}
            />
            <div
              className={`control__indicator ${
                errors?.isAccepted && "border border-danger"
              }`}
            ></div>
          </label>
          <div className="text-danger">
            <small>{!!errors?.isAccepted && errors.isAccepted.message}</small>
          </div>
        </div>
      </div>

      <Modal.Footer>
        <Button type="submit" className="btn-primary" disabled={isLoading}>
          <i className="uil uil-check mr-2" />
          Prosseguir
          {!!isLoading && (
            <CircularProgress
              color="inherit"
              size={12}
              className="align-text-top ms-2"
            />
          )}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default PatientUnconfirmedTerms;
